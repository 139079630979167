import React, { useState, useRef, useEffect } from "react";
import {
  Input,
  InputGroup,
  InputRightElement,
  Box,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Portal,
  Fade,
  Button,
} from "@chakra-ui/react";

const Keywords = ({ keywords, setKeywords, setOperatorFunction }) => {
  const sampleKeywords = [
    "Makeup", "Sports", "Investing", "Health", "Fitness", "Travel",
    "Fashion", "Photography", "Food", "Lifestyle", "Gaming", "Tech",
    "Music", "Education", "Personal Finance", "Self-Improvement",
    "Home Decor", "Parenting", "Pet Care", "Mental Health",
    "Yoga", "Entrepreneurship", "Real Estate", "Veganism", "Sustainability",
    "Cooking", "Dance", "Film", "Art", "Comedy", "Podcasting",
    "Digital Marketing", "SEO", "Social Media", "Influencer Marketing",
    "E-commerce", "Business Strategy", "Sales", "Copywriting",
    "Freelancing", "Public Speaking", "Coding", "Web Development",
    "Blockchain", "Cryptocurrency", "AI", "Machine Learning",
    "Data Science", "AR/VR", "Graphic Design", "UI/UX Design",
    "Video Production", "Vlogging", "Podcast Editing", "Photography Editing",
    "Mindfulness", "Spirituality", "Fashion Design", "Luxury",
    "Beauty", "Interior Design", "Minimalism", "Outdoor Adventure",
    "Camping", "Hiking", "Fishing", "Cycling", "Running",
    "Skincare", "Hair Care", "Nail Art", "Tattoo Art",
    "Jewelry Design", "Modeling", "Influencers", "Public Relations",
    "Branding", "Event Planning", "Hospitality", "Travel Vlogging",
    "DIY Crafts", "Woodworking", "Gardening", "Sewing",
    "Writing", "Blogging", "Content Writing", "Creative Writing",
    "Nutrition", "Weight Loss", "Bodybuilding", "CrossFit",
    "Pilates", "Martial Arts", "Boxing", "Golf",
    "Tennis", "Basketball", "Football", "Soccer",
    "Esports", "Motorsports", "Productivity", "Time Management"
  ];

  const [inputValue, setInputValue] = useState("");
  const [filteredKeywords, setFilteredKeywords] = useState(sampleKeywords);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState(null);
  const [operator, setOperator] = useState("AND");

  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.trim()) {
      setFilteredKeywords(
        sampleKeywords.filter((keyword) =>
          keyword.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setFilteredKeywords([]);
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      addKeyword(inputValue.trim());
    }
  };

  const addKeyword = (keyword) => {
    if (!keywords.includes(keyword)) {
      setKeywords([...keywords, keyword]);
    }
    setInputValue("");
    setFilteredKeywords([]);
  };

  const handleKeywordClick = (keyword) => {
    if (!keywords.includes(keyword)) {
      setKeywords([...keywords, keyword]);
    }
    setInputValue("");
    setFilteredKeywords([]);
  };

  const handleRemoveKeyword = (keywordToRemove) => {
    setKeywords(keywords.filter((keyword) => keyword !== keywordToRemove));
  };

  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsInputFocused(false);
    }, 200);
  };

  const handleOperatorClick = () => {
    const newOperator = operator === "AND" ? "OR" : "AND";
    setOperator(newOperator);
    if (setOperatorFunction) {
      setOperatorFunction(newOperator);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      const inputBox = inputRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: inputBox.bottom + window.scrollY,
        left: inputBox.left + window.scrollX,
        width: inputBox.width,
      });
    }
  }, [isInputFocused]);

  return (
    <Box>
      <InputGroup>
        <Input
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder="Sort by keywords"
        />
        <InputRightElement width="4.5rem">
          <Button
            h="1.5rem"
            zIndex={1000}
            size="sm"
            onClick={handleOperatorClick}
          >
            {operator}
          </Button>
        </InputRightElement>
      </InputGroup>

      <Portal>
        {isInputFocused && filteredKeywords.length > 0 && dropdownPosition && (
          <Fade in={true}>
            <Box
              mt={1}
              position="absolute"
              top={`${dropdownPosition?.top}px`}
              left={`${dropdownPosition?.left}px`}
              width={`${dropdownPosition?.width}px`}
              zIndex={1000}
              border="1px solid"
              borderColor="gray.200"
              borderRadius="xl"
              bg="white"
              pt={2}
              px={2}
              maxHeight="200px"
              overflowY="auto"
              css={{
                '&::-webkit-scrollbar': { display: 'none' },
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
              }}
            >
              <Flex wrap="wrap">
                {filteredKeywords.map((keyword) => (
                  <Tag
                    size="md"
                    key={keyword}
                    borderRadius="full"
                    cursor="pointer"
                    mr={2}
                    mb={2}
                    onClick={() => handleKeywordClick(keyword)}
                  >
                    <TagLabel>{keyword}</TagLabel>
                  </Tag>
                ))}
              </Flex>
            </Box>
          </Fade>
        )}
      </Portal>

      <Flex wrap="wrap">
        {keywords.map((keyword) => (
          <Tag
            size="md"
            key={keyword}
            borderRadius="full"
            variant="solid"
            colorScheme="blue"
            mr={2}
            mt={2}
          >
            <TagLabel>{keyword}</TagLabel>
            <TagCloseButton onClick={() => handleRemoveKeyword(keyword)} />
          </Tag>
        ))}
      </Flex>
    </Box>
  );
};

export default Keywords;
