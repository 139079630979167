import React, { useState, useEffect } from 'react';
import { 
  // Box, 
  // Heading, 
  // Text, 
  VStack, Input, FormControl, FormLabel, Button } from '@chakra-ui/react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { db, auth } from '../../../Firebase';

function SettingsProfile() {
  const { userData } = useOutletContext();
  const [fullName, setFullName] = useState(userData?.fullName || '');
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      setFullName(userData.fullName || '');
    }
  }, [userData]);

  const handleUpdateName = async () => {
    if (!userData || !userData.userId) return;

    try {
      const userDocRef = doc(db, 'users', userData.userId);
      await updateDoc(userDocRef, { fullName: fullName });
      console.log('Name updated successfully');
    } catch (error) {
      console.error('Error updating name:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  return (
    <VStack spacing={4} p={4} maxW="500px" align="stretch">
      <FormControl>
        <FormLabel>Full Name</FormLabel>
        <Input
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          onBlur={handleUpdateName}
          placeholder="Full Name"
        />
      </FormControl>
      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input
          value={userData?.email || ''}
          isDisabled
          placeholder="Email"
        />
      </FormControl>
      <FormControl>
        <FormLabel>User ID</FormLabel>
        <Input
          value={userData?.userId || ''}
          isDisabled
          placeholder="User ID"
        />
      </FormControl>

      {/* <Box width="100%">
        <Heading size="md" mb={2}>User Data JSON:</Heading>
        <Box bg="gray.50" p={4} borderRadius="md" overflowX="auto">
          <Text as="pre" whiteSpace="pre-wrap" fontSize="sm">
            {JSON.stringify(userData, null, 2)}
          </Text>
        </Box>
      </Box> */}

      <Button 
        onClick={handleLogout} 
        colorScheme='red'
      >
        Logout
      </Button>
    </VStack>
  );
}

export default SettingsProfile;