// ./modalstuff/TagInput.js

import React, { useState } from 'react';
import {
  Flex,
  Input,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  useToast,
} from '@chakra-ui/react';

// Reusable TagInput Component with optional maxTags
const TagInput = ({ tags, setTags, placeholder, onUpdate, maxTags }) => {
  const [inputValue, setInputValue] = useState('');
  const toast = useToast();

  const addTag = async () => {
    const value = inputValue.trim();
    if (!value) {
      toast({
        title: 'Invalid input',
        description: 'Cannot add an empty tag.',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    if (tags.includes(value)) {
      toast({
        title: 'Duplicate tag',
        description: 'This tag already exists.',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    if (maxTags && tags.length >= maxTags) {
      toast({
        title: 'Maximum tags reached',
        description: `You can only add up to ${maxTags} tags.`,
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    const updatedTags = [...tags, value];
    setTags(updatedTags);
    setInputValue('');
    if (onUpdate) {
      try {
        await onUpdate(updatedTags);
      } catch (error) {
        console.error('Error updating tags:', error);
        toast({
          title: 'Error',
          description: 'There was an error adding the tag.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const removeTag = async (tag) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
    if (onUpdate) {
      try {
        await onUpdate(updatedTags);
      } catch (error) {
        console.error('Error removing tag:', error);
        toast({
          title: 'Error',
          description: 'There was an error removing the tag.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addTag();
    }
  };

  return (
    <Flex
      wrap="wrap"
      align="center"
      borderWidth="1px"
      borderRadius="full"
      px={4}
      h="50px"
      _focusWithin={{ borderColor: 'blue.500' }}
    >
      {tags.map((tag, index) => (
        <Tag
          key={index}
          mr={2}
          mb={2}
          size="md"
          bg="gray.200"
          mt={2}
        >
          <TagLabel>{tag}</TagLabel>
          <TagCloseButton onClick={() => removeTag(tag)} />
        </Tag>
      ))}
      {(!maxTags || tags.length < maxTags) && (
        <Input
          variant="unstyled"
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          flex="1"
          minWidth="120px"
        />
      )}
      {(!maxTags || tags.length < maxTags) && (
        <Button size="sm" onClick={addTag} ml={2} colorScheme="blue">
          Add
        </Button>
      )}
    </Flex>
  );
};

export default TagInput;
