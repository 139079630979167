import React, { useMemo } from 'react';
import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import { FormatNumber } from '../../../formatting/FormatNumber';

const ListsOverview = ({ rawData, creatorData }) => {
  const {
    totalFollowers,
    creatorsCount,
    averageEngagementRate,
    totalImpressions,
  } = useMemo(() => {
    if (
      !Array.isArray(creatorData) ||
      creatorData.length === 0 ||
      !rawData ||
      !Array.isArray(rawData.creators)
    ) {
      return {
        totalFollowers: 0,
        creatorsCount: 0,
        averageEngagementRate: 0,
        totalImpressions: 0,
      };
    }

    try {
      const totalFollowers = creatorData.reduce((acc, creator) => {
        const instagramFollowers =
          Number(creator.instagram_profile?.followers_count) || 0;
        return acc + instagramFollowers;
      }, 0);

      const creatorsCount = creatorData.length;

      const totalEngagementRate = creatorData.reduce((acc, creator) => {
        const instagramEngagement =
          Number(creator.instagram_profile?.engagement_rate) || 0;
        return acc + instagramEngagement;
      }, 0);
      const avgEngagementRate =
        creatorsCount > 0 ? totalEngagementRate / creatorsCount : 0;

      const totalImpressions = rawData.creators.reduce((acc, creator) => {
        if (!Array.isArray(creator.deliverables)) {
          return acc;
        }
        const matchingCreator =
          creatorData.find((c) => c.unique_id === creator.creatorId) || {};

        return (
          acc +
          creator.deliverables.reduce((delAcc, deliverable) => {
            const count = deliverable.count || 1;
            switch (deliverable.type) {
              case 'Instagram Reel':
                return (
                  delAcc +
                  (matchingCreator.instagram_profile?.projected_reel_views || 0) *
                    count
                );
              case 'Instagram Story':
                return (
                  delAcc +
                  (matchingCreator.instagram_profile?.projected_story_views || 0) *
                    count
                );
              case 'Instagram Post':
                return (
                  delAcc +
                  (matchingCreator.instagram_profile?.projected_post_views || 0) *
                    count
                );
              case 'TikTok Video':
                return (
                  delAcc +
                  (matchingCreator.tiktok_profile?.projected_video_views || 0) *
                    count
                );
              default:
                return delAcc;
            }
          }, 0)
        );
      }, 0);

      return {
        totalFollowers,
        creatorsCount,
        averageEngagementRate: avgEngagementRate,
        totalImpressions,
      };
    } catch (error) {
      console.error('Error in ListsOverview calculations:', error);
      return {
        totalFollowers: 0,
        creatorsCount: 0,
        averageEngagementRate: 0,
        totalImpressions: 0,
      };
    }
  }, [rawData, creatorData]);

  return (
    <Box width="100%">
      <SimpleGrid columns={[1, 2, 4]} spacing={4} mb={4}>
        <Box p={6} borderWidth="1px" borderRadius="xl">
          <Text as="h2" fontSize="lg" mb={2}>
            Total Followers
          </Text>
          <Text fontWeight="bold" fontSize="3xl">
            {FormatNumber(totalFollowers)}
          </Text>
        </Box>
        <Box p={6} borderWidth="1px" borderRadius="xl">
          <Text as="h2" fontSize="lg" mb={2}>
            Total Creators
          </Text>
          <Text fontWeight="bold" fontSize="3xl">
            {creatorsCount}
          </Text>
        </Box>
        <Box p={6} borderWidth="1px" borderRadius="xl">
          <Text as="h2" fontSize="lg" mb={2}>
            Avg. Engagement Rate
          </Text>
          <Text fontWeight="bold" fontSize="3xl">
            {(averageEngagementRate * 100).toFixed(2)}%
          </Text>
        </Box>
        <Box p={6} borderWidth="1px" borderRadius="xl">
          <Text as="h2" fontSize="lg" mb={2}>
            Total Impressions
          </Text>
          <Text fontWeight="bold" fontSize="3xl">
            {FormatNumber(totalImpressions)}
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default React.memo(ListsOverview);
