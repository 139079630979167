import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Textarea,
  Heading,
  Image,
  HStack,
  IconButton,
  Flex,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import axios from 'axios';
import {
  collection,
  addDoc,
  onSnapshot,
  query,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../../../../Firebase';
import ScrapeProductModal from './modals/ScrapeProductModal';
import ImportProductModal from './modals/ImportProductModal';

const AddProduct = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({
    name: '',
    description: '',
    vendor: '',
    price: ''
  });
  const [images, setImages] = useState([]);
  const { workspaceData } = useOutletContext();
  const [isScrapeModalOpen, setIsScrapeModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  useEffect(() => {
    if (!workspaceData?.workspace_id) {
      console.error('Workspace data is not available');
      setLoading(false);
      return;
    }

    const productsRef = collection(
      db,
      'workspaces',
      workspaceData.workspace_id,
      'products'
    );
    const q = query(productsRef);

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const firebaseProductsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log('Firebase products updated:', firebaseProductsData);
    });

    return unsubscribe;
  }, [workspaceData]);

  const handlePriceChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
      setProduct({ ...product, price: value });
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));
    setImages([...images, ...newImages]);
    console.log('Images added:', newImages.length);
  };

  const removeImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    console.log('Image removed at index:', index);
  };

  const handleSubmit = async () => {
    if (!product.name.trim()) {
      console.error('Product name is required');
      return;
    }

    if (!product.price) {
      console.error('Price is required');
      return;
    }

    setLoading(true);
    console.log('Starting product submission...');

    try {
      const imagesBase64 = await Promise.all(
        images.map(async ({ file }) => {
          const base64 = await fileToBase64(file);
          return { attachment: base64 };
        })
      );

      const productPayload = {
        name: product.name,
        description: product.description,
        vendor: product.vendor || 'Unknown Vendor',
        price: product.price,
        images: imagesBase64,
      };

      console.log('Sending product data to API...');
      const response = await axios.post(
        'https://hoobe-shop-api.onrender.com/add-product',
        productPayload
      );

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      const createdProduct = response.data.product;
      console.log('Product created successfully:', createdProduct);

      const productsRef = collection(
        db,
        'workspaces',
        workspaceData.workspace_id,
        'products'
      );

      const firebaseDoc = await addDoc(productsRef, {
        shopifyProductId: createdProduct.id,
        createdAt: serverTimestamp(),
      });
      console.log('Product added to Firebase:', firebaseDoc.id);

      navigate('/dashboard/products');
    } catch (error) {
      console.error('Error adding new product:', error);
    } finally {
      setLoading(false);
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <Box p={6} maxW="container.lg" mx="auto">
      <Flex justify="space-between" align="center" mb={6}>
        <Heading>Add New Product</Heading>
        <HStack spacing={4}>
          <Button onClick={() => setIsScrapeModalOpen(true)}>Scrape Product</Button>
          <Button onClick={() => setIsImportModalOpen(true)}>Import Product</Button>
        </HStack>
      </Flex>

      <Flex align="flex-start">
        <Box flex="1" mr={6}>
          <FormControl>
            <FormLabel>Images</FormLabel>
            <Input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
              py={1}
            />
            <VStack align="flex-start" spacing={4} mt={2}>
              {images.map((image, index) => (
                <Box key={index} position="relative">
                  <Image
                    src={image.preview}
                    alt={`Preview ${index}`}
                    boxSize="150px"
                    objectFit="cover"
                    borderRadius="md"
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    size="sm"
                    colorScheme="red"
                    position="absolute"
                    top={1}
                    right={1}
                    onClick={() => removeImage(index)}
                  />
                </Box>
              ))}
            </VStack>
          </FormControl>
        </Box>

        <Box flex="2">
          <VStack spacing={4} align="stretch">
            <FormControl isRequired>
              <FormLabel>Product Name</FormLabel>
              <Input
                value={product.name}
                onChange={e => setProduct({ ...product, name: e.target.value })}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={product.description}
                onChange={e => setProduct({ ...product, description: e.target.value })}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Price</FormLabel>
              <Input
                value={product.price}
                onChange={handlePriceChange}
                placeholder="0.00"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Brand Name</FormLabel>
              <Input
                value={product.vendor}
                onChange={e => setProduct({ ...product, vendor: e.target.value })}
              />
            </FormControl>

            <HStack spacing={4} mt={2}>
              <Button
                colorScheme="blue"
                onClick={handleSubmit}
                isLoading={loading}
                loadingText="Adding Product"
              >
                Add Product
              </Button>
              <Button
                onClick={() => navigate('/dashboard/products')}
                isDisabled={loading}
              >
                Cancel
              </Button>
            </HStack>
          </VStack>
        </Box>
      </Flex>

      <ScrapeProductModal
        workspaceData={workspaceData}
        isOpen={isScrapeModalOpen}
        onClose={() => setIsScrapeModalOpen(false)}
      />
      <ImportProductModal
        workspaceData={workspaceData}
        isOpen={isImportModalOpen}
        onClose={() => setIsImportModalOpen(false)}
      />
    </Box>
  );
};

export default AddProduct;