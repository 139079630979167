import React, { useEffect } from "react";
import {
  Select,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const Hoobe = ({ selectedHoobe, setSelectedHoobe }) => {
  
  useEffect(() => {
  }, [selectedHoobe]);

  return (
    <InputGroup>
      <Select
        value={selectedHoobe || ""}
        onChange={(e) => setSelectedHoobe(e.target.value)}
      >
        <option value="" disabled hidden>
          Select hoo.be status
        </option>
        <option value="yes">🔗 Yes, has link in bio</option>
        <option value="no">❌ No, no link in bio</option>
      </Select>

      {selectedHoobe && (
        <InputRightElement width="2.5rem">
          <IconButton
            aria-label="Clear selection"
            icon={<CloseIcon />}
            size="sm"
            borderRadius="xl"
            bg="white"
            onClick={() => setSelectedHoobe("")}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default Hoobe;
