// ProductsSelector.js
import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  Box,
  Flex,
  Spinner,
  VStack,
  HStack,
  Text,
  Image,
  IconButton,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import axios from "axios";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../Firebase";

const ProductsSelector = ({
  offer,
  setOffer,
  workspaceId,
  campaignId,
  setError,
  productsInWorkspace,
}) => {
  const [searchText, setSearchText] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isProductsLoading, setIsProductsLoading] = useState(false);

  // Fetch details of selected products
  useEffect(() => {
    const fetchSelectedProducts = async (productIds) => {
      setIsProductsLoading(true);

      try {
        const response = await axios.post(
          "https://hoobe-shop-api.onrender.com/get-products-from-ids",
          { ids: productIds }
        );
        const productsData = response.data;

        setSelectedProducts(productsData);
      } catch (err) {
        setError("Failed to fetch selected products.");
      } finally {
        setIsProductsLoading(false);
      }
    };

    if (offer.productIds && offer.productIds.length > 0) {
      fetchSelectedProducts(offer.productIds);
    } else {
      setSelectedProducts([]);
    }
  }, [offer.productIds, setError]);

  // Handle product search
  useEffect(() => {
    if (searchText.trim() === "") {
      setFilteredProducts([]);
    } else {
      const filtered = productsInWorkspace.filter((product) =>
        product.title.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  }, [searchText, productsInWorkspace]);

  const handleAddProduct = async (product) => {
    const shopifyProductId = product.id.toString();

    if (offer.productIds.includes(shopifyProductId)) return;

    const updatedProductIds = [...offer.productIds, shopifyProductId];
    setOffer((prev) => ({ ...prev, productIds: updatedProductIds }));
    setSelectedProducts([...selectedProducts, product]);

    try {
      const campaignDocRef = doc(
        db,
        "workspaces",
        workspaceId,
        "campaigns",
        campaignId
      );
      await updateDoc(campaignDocRef, {
        "offer.productIds": updatedProductIds,
      });
    } catch (err) {
      setError("Failed to add product. Please try again.");
    }
  };

  const handleRemoveProduct = async (productId) => {
    const updatedProductIds = offer.productIds.filter(
      (id) => id !== productId.toString()
    );
    const updatedSelectedProducts = selectedProducts.filter(
      (product) => product.id.toString() !== productId.toString()
    );

    setOffer((prev) => ({ ...prev, productIds: updatedProductIds }));
    setSelectedProducts(updatedSelectedProducts);

    try {
      const campaignDocRef = doc(
        db,
        "workspaces",
        workspaceId,
        "campaigns",
        campaignId
      );
      await updateDoc(campaignDocRef, {
        "offer.productIds": updatedProductIds,
      });
    } catch (err) {
      setError("Failed to remove product. Please try again.");
    }
  };

  return (
    <FormControl>
      <FormLabel>Products</FormLabel>
      <InputGroup>
        <Input
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search products"
          size="md"
          borderRadius="xl"
        />
      </InputGroup>
      {searchText.trim() !== "" && (
        <Box
          maxHeight="200px"
          overflowY="auto"
          mt={2}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
        >
          {isProductsLoading ? (
            <Flex justify="center" align="center" height="100px">
              <Spinner size="sm" />
            </Flex>
          ) : filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <Flex
                key={product.id}
                align="center"
                p={2}
                borderBottom="1px solid"
                borderColor="gray.200"
                _hover={{
                  bg: "gray.100",
                  cursor: "pointer",
                }}
                onClick={() => handleAddProduct(product)}
              >
                <Image
                  src={
                    product.image?.src ||
                    (product.images && product.images.length > 0
                      ? product.images[0].src
                      : "")
                  }
                  alt={product.title || "No Name"}
                  boxSize="50px"
                  objectFit="cover"
                  borderRadius="md"
                  mr={2}
                />
                <Text>{product.title || "No Name"}</Text>
              </Flex>
            ))
          ) : (
            <Flex justify="center" align="center" height="100px">
              <Text>No products found.</Text>
            </Flex>
          )}
        </Box>
      )}
      <VStack align="start" mt={4} w="100%">
        {selectedProducts &&
          selectedProducts.map((product) => (
            <Box
              key={product.id}
              border="1px solid"
              borderColor="gray.200"
              bg="gray.100"
              borderRadius="xl"
              p={2}
              w="100%"
            >
              <HStack justify="space-between">
                <Flex align="center">
                  <Image
                    src={
                      product.image?.src ||
                      (product.images && product.images.length > 0
                        ? product.images[0].src
                        : "")
                    }
                    alt={product.title || "No Name"}
                    boxSize="50px"
                    objectFit="cover"
                    borderRadius="md"
                    mr={2}
                  />
                  <Text>{product.title || "No Name"}</Text>
                </Flex>
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => handleRemoveProduct(product.id)}
                  size="sm"
                  aria-label="Remove product"
                  colorScheme="red"
                />
              </HStack>
            </Box>
          ))}
      </VStack>
    </FormControl>
  );
};

export default ProductsSelector;
