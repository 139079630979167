import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Text,
  Image,
  Flex,
  useToast,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  VStack,
  Button,
  Badge,
  useDisclosure,
  Input,
} from "@chakra-ui/react";
import {
  ExternalLinkIcon,
  ChatIcon,
  AddIcon,
  CheckCircleIcon,
  AttachmentIcon,
  EditIcon,
  QuestionIcon,
} from "@chakra-ui/icons";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../Firebase";
import Status from "../../../formatting/Status";
import Deliverables from "../../../formatting/Deliverables";
import CampaignMessagesSendModal from "./CampaignMessagesSendModal";

const CampaignMessageCreator = ({
  creator,
  campaignId,
  workspaceId,
  creatorId,
  onUpdateCreator,
}) => {
  const [status, setStatus] = useState("None");
  const [offerAmount, setOfferAmount] = useState(0);
  const [affiliateAmount, setAffiliateAmount] = useState(0);
  const [deliverables, setDeliverables] = useState([]);
  const [timeline, setTimeline] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [isEditingOffer, setIsEditingOffer] = useState(false);
  const [isEditingAffiliateAmount, setIsEditingAffiliateAmount] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState("");
  const [denyingSubmissionId, setDenyingSubmissionId] = useState(null);
  const [denyNotesMap, setDenyNotesMap] = useState({});
  const [offer, setOffer] = useState(null);
  const toast = useToast();
  const offerInputRef = useRef(null);
  const affiliateAmountInputRef = useRef(null);

  // Initialize useDisclosure for modal control
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Mapping of types to icons
  const typeIconMap = {
    created: AddIcon,
    accepted: CheckCircleIcon,
    offer: AttachmentIcon,
    contract: EditIcon,
    approval: CheckCircleIcon,
    content_upload: AttachmentIcon,
  };

  // Function to capitalize the first letter
  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  // Function to format deliverables
  const formatDeliverables = (deliverables) => {
    if (!deliverables || deliverables.length === 0) return "None";
    return deliverables
      .map((deliverable) => `${deliverable.type} x${deliverable.count}`)
      .join(", ");
  };

  // Update local state when creator or creator's fields change
  useEffect(() => {
    if (creator) {
      setStatus(creator.status || "None");
      setOfferAmount(creator.offer !== undefined ? Number(creator.offer) : 0);
      setAffiliateAmount(
        creator.affiliateAmount !== undefined
          ? Number(creator.affiliateAmount)
          : 0
      );
      setDeliverables(creator.deliverables || []);
      setTimeline(creator.timeline || []);
      setNewName(creator.name || "");
    }
  }, [creator]);

  // Fetch campaign data including offer
  useEffect(() => {
    const fetchCampaignData = async () => {
      if (!campaignId || !workspaceId) return;
      try {
        const campaignRef = doc(
          db,
          "workspaces",
          workspaceId,
          "campaigns",
          campaignId
        );
        const campaignSnapshot = await getDoc(campaignRef);
        if (campaignSnapshot.exists()) {
          const campaignData = campaignSnapshot.data();
          setSubmissions(campaignData.submissions || []);
          setOffer(campaignData.offer || {});
        } else {
          console.error("Campaign not found.");
        }
      } catch (error) {
        console.error("Error fetching campaign data:", error);
      }
    };

    fetchCampaignData();
  }, [campaignId, workspaceId]);

  useEffect(() => {
    if (isEditingOffer && offerInputRef.current) {
      offerInputRef.current.focus();
    }
  }, [isEditingOffer]);

  useEffect(() => {
    if (isEditingAffiliateAmount && affiliateAmountInputRef.current) {
      affiliateAmountInputRef.current.focus();
    }
  }, [isEditingAffiliateAmount]);

  const updateCreatorData = useCallback(
    async (updatedFields) => {
      if (!creator || !campaignId || !workspaceId) return false;
      try {
        const campaignRef = doc(
          db,
          "workspaces",
          workspaceId,
          "campaigns",
          campaignId
        );

        const campaignSnapshot = await getDoc(campaignRef);
        if (campaignSnapshot.exists()) {
          const campaignData = campaignSnapshot.data();

          const creatorIndex = campaignData.creators.findIndex(
            (c) => c.creatorId === creator.creatorId
          );

          if (creatorIndex !== -1) {
            const updatedCreator = {
              ...campaignData.creators[creatorIndex],
              ...updatedFields,
            };

            // Remove undefined fields
            Object.keys(updatedCreator).forEach((key) => {
              if (updatedCreator[key] === undefined) {
                delete updatedCreator[key];
              }
            });

            const updatedCreators = [...campaignData.creators];
            updatedCreators[creatorIndex] = updatedCreator;

            await updateDoc(campaignRef, { creators: updatedCreators });

            if (onUpdateCreator) {
              onUpdateCreator(updatedCreator);
            }
            return true;
          } else {
            toast({
              title: "Error",
              description: "Creator not found in campaign data.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        } else {
          toast({
            title: "Error",
            description: "Campaign not found.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error updating data:", error);
        toast({
          title: "Error",
          description: "An error occurred while updating data.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      return false;
    },
    [creator, campaignId, workspaceId, onUpdateCreator, toast]
  );

  const handleStatusChange = async (newStatus) => {
    setStatus(newStatus);
    const updateSuccess = await updateCreatorData({ status: newStatus });
    if (!updateSuccess) {
      // Revert status back if update failed
      setStatus(creator.status || "None");
    }
  };

  const handleOfferChange = (valueNumber) => {
    setOfferAmount(valueNumber);
  };

  const handleOfferBlur = async () => {
    setIsEditingOffer(false);
    const updateSuccess = await updateCreatorData({ offer: offerAmount });
    if (!updateSuccess) {
      // Revert offer back if update failed
      setOfferAmount(creator.offer !== undefined ? Number(creator.offer) : 0);
    }
  };

  const handleOfferClick = () => {
    setIsEditingOffer(true);
  };

  const handleAffiliateAmountChange = (valueNumber) => {
    setAffiliateAmount(valueNumber);
  };

  const handleAffiliateAmountBlur = async () => {
    setIsEditingAffiliateAmount(false);
    const updateSuccess = await updateCreatorData({ affiliateAmount });
    if (!updateSuccess) {
      // Revert affiliateAmount back if update failed
      setAffiliateAmount(
        creator.affiliateAmount !== undefined
          ? Number(creator.affiliateAmount)
          : 0
      );
    }
  };

  const handleAffiliateAmountClick = () => {
    setIsEditingAffiliateAmount(true);
  };

  const handleDeliverablesChange = async (newDeliverables) => {
    setDeliverables(newDeliverables);
    const updateSuccess = await updateCreatorData({
      deliverables: newDeliverables,
    });
    if (!updateSuccess) {
      // Revert deliverables back if update failed
      setDeliverables(creator.deliverables || []);
    }
  };

  const handleOfferResult = async (timelineItem, result) => {
    try {
      const updatedTimeline = timeline.map((item) =>
        item === timelineItem ? { ...item, result } : item
      );

      const newOffer =
        result === "accept" ? timelineItem.offerAmount : offerAmount;
      const newAffiliateAmount =
        result === "accept" ? timelineItem.affiliateAmount : affiliateAmount;
      const newDeliverables =
        result === "accept" ? timelineItem.deliverables : deliverables;
      const newStatus = result === "accept" ? "Approved" : "Rejected";

      const updateSuccess = await updateCreatorData({
        offer: newOffer,
        affiliateAmount: newAffiliateAmount,
        deliverables: newDeliverables,
        status: newStatus,
        timeline: updatedTimeline,
      });

      if (updateSuccess) {
        setOfferAmount(newOffer);
        setAffiliateAmount(newAffiliateAmount);
        setDeliverables(newDeliverables);
        setStatus(newStatus);
        setTimeline(updatedTimeline);

        toast({
          title: `Offer ${result === "accept" ? "Approved" : "Rejected"}`,
          description: `The offer has been ${
            result === "accept" ? "accepted" : "rejected"
          } and creator details updated.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to update creator data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(
        `Error ${result === "accept" ? "accepting" : "rejecting"} offer:`,
        error
      );
      toast({
        title: "Error",
        description: `An error occurred while ${
          result === "accept" ? "accepting" : "rejecting"
        } the offer.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleContentApprovalResult = async (timelineItem, result, notes) => {
    try {
      // Fetch the campaign data
      const campaignRef = doc(
        db,
        "workspaces",
        workspaceId,
        "campaigns",
        campaignId
      );
      const campaignSnapshot = await getDoc(campaignRef);
      if (campaignSnapshot.exists()) {
        const campaignData = campaignSnapshot.data();

        // Update the submission
        const submissionsArray = campaignData.submissions || [];
        const submissionIndex = submissionsArray.findIndex(
          (sub) => sub.id === timelineItem.submissionId
        );

        if (submissionIndex !== -1) {
          const updatedSubmission = {
            ...submissionsArray[submissionIndex],
            approvalStatus: result,
            notes: notes || "",
          };
          submissionsArray[submissionIndex] = updatedSubmission;
        } else {
          toast({
            title: "Error",
            description: "Submission not found.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        // Update the timeline item
        const updatedTimeline = timeline.map((item) =>
          item === timelineItem
            ? { ...item, approvalStatus: result, notes: notes || "" }
            : item
        );

        // Update the creator's status if content is approved
        let newStatus = creator.status;
        if (result === "approved") {
          newStatus = "Ready To Post";
        }

        // Update the creators array
        const updatedCreators = campaignData.creators.map((c) => {
          if (c.creatorId === creator.creatorId) {
            return { ...c, timeline: updatedTimeline, status: newStatus };
          }
          return c;
        });

        // Update Firestore
        await updateDoc(campaignRef, {
          submissions: submissionsArray,
          creators: updatedCreators,
        });

        // Update local state
        setSubmissions(submissionsArray);
        setTimeline(updatedTimeline);
        setStatus(newStatus);

        toast({
          title: `Content ${result === "approved" ? "Approved" : "Denied"}`,
          description: `The content has been ${
            result === "approved" ? "approved" : "denied"
          }.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Campaign not found.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(
        `Error ${result === "approved" ? "approving" : "denying"} content:`,
        error
      );
      toast({
        title: "Error",
        description: `An error occurred while ${
          result === "approved" ? "approving" : "denying"
        } the content.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleApprovalResult = async (result) => {
    try {
      const newStatus = result === "approve" ? "Approved" : "Rejected";

      // Create a new timeline item
      const newTimelineItem = {
        type: "approval",
        timestamp: new Date().toISOString(),
        message: `Offer has been ${
          result === "approve" ? "approved" : "rejected"
        } by the campaign manager.`,
        result: result,
      };

      // Update the creator's timeline
      const updatedTimeline = [...timeline, newTimelineItem];

      // Update the creator's status
      const updateSuccess = await updateCreatorData({
        status: newStatus,
        timeline: updatedTimeline,
      });

      if (updateSuccess) {
        setStatus(newStatus);
        setTimeline(updatedTimeline);

        toast({
          title: `Offer ${result === "approve" ? "Approved" : "Rejected"}`,
          description: `The offer has been ${
            result === "approve" ? "approved" : "rejected"
          }.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to update creator data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(
        `Error ${result === "approve" ? "approving" : "rejecting"} offer:`,
        error
      );
      toast({
        title: "Error",
        description: `An error occurred while ${
          result === "approve" ? "approving" : "rejecting"
        } the offer.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNameBlur = async () => {
    setIsEditingName(false);
    if (newName !== creator.name) {
      const updateSuccess = await updateCreatorData({ name: newName });
      if (!updateSuccess) {
        // Revert name back if update failed
        setNewName(creator.name || "");
      } else {
        // Show success message
        toast({
          title: "Name Updated",
          description: "Creator's name has been updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  if (!creator) {
    return <Text>Select a creator to view details.</Text>;
  }

  return (
    <Box p={4}>
      <Flex
        justifyContent="space-between"
        borderWidth="1px"
        borderRadius="md"
        align="center"
        p={4}
        flexDirection={{ base: "column", md: "row" }}
        h="auto"
      >
        {/* Creator Info */}
        <Flex w="200px" alignItems="center">
          <Image
            borderRadius="full"
            boxSize="60px"
            src={creator.instagram_profile_pic_url}
            alt={creator.name}
            mr={2}
          />
          <Box>
            {isEditingName ? (
              <Input
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                onBlur={handleNameBlur}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleNameBlur();
                  }
                }}
                size="sm"
                autoFocus
              />
            ) : (
              <Text
                fontSize="lg"
                fontWeight="bold"
                onClick={() => setIsEditingName(true)}
                cursor="pointer"
              >
                {creator.name}
              </Text>
            )}
            <Text fontSize="sm">@{creator.instagram_username}</Text>
          </Box>
        </Flex>

        {/* Status */}
        <Box>
          <Status initialStatus={status} onChange={handleStatusChange} />
        </Box>

{/* Offer Amount */}
{offer && offer.fixedAmountEnabled && (
  <Box>
    <Text fontSize="sm">Offer Amount</Text>
    {isEditingOffer ? (
      <NumberInput
        value={offerAmount}
        onChange={(_, valueNumber) => handleOfferChange(valueNumber)}
        min={0}
        placeholder="Enter offer amount"
        onBlur={handleOfferBlur}
        width="150px"
      >
        <NumberInputField ref={offerInputRef} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    ) : (
      <Text
        onClick={handleOfferClick}
        cursor="pointer"
        fontSize="lg"
        fontWeight="bold"
      >
        ${offerAmount}
      </Text>
    )}
  </Box>
)}

{/* Affiliate Amount */}
{offer && offer.affiliatesEnabled && (
  <Box>
    <Text fontSize="sm">Affiliate Amount</Text>
    {isEditingAffiliateAmount ? (
      <NumberInput
        value={affiliateAmount}
        onChange={(_, valueNumber) =>
          handleAffiliateAmountChange(valueNumber)
        }
        min={0}
        max={100}
        placeholder="Enter affiliate percentage"
        onBlur={handleAffiliateAmountBlur}
        width="150px"
      >
        <NumberInputField ref={affiliateAmountInputRef} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    ) : (
      <Text
        onClick={handleAffiliateAmountClick}
        cursor="pointer"
        fontSize="lg"
        fontWeight="bold"
      >
        {affiliateAmount}%
      </Text>
    )}
  </Box>
)}


        {/* Deliverables */}
        <Box>
          <Deliverables
            initialDeliverables={deliverables}
            onChange={handleDeliverablesChange}
          />
        </Box>

        {/* Buttons */}
        <Flex>
          {/* New Message Creator Button */}
          <Button
            leftIcon={<ChatIcon />}
            variant="solid"
            aria-label="Message"
            size="md"
            bg="white"
            borderWidth="1px"
            onClick={onOpen}
            mr={2}
          >
            Message
          </Button>

          {/* Existing See Offer Page Button */}
          <Button
            as="a"
            href={`https://offer.hoobe.me/${workspaceId}/${campaignId}/${creatorId}`}
            target="_blank"
            rel="noopener noreferrer"
            leftIcon={<ExternalLinkIcon />}
            bg="white"
            variant="solid"
            aria-label="Offer Page"
            size="md"
            borderWidth="1px"
          >
            Offer Page
          </Button>
        </Flex>
      </Flex>

      {/* Timeline */}
      <Box mt={4}>
        {timeline.length > 0 ? (
          <VStack align="start" spacing={3}>
            {timeline.map((item, index) => {
              const IconComponent =
                typeIconMap[item.type.toLowerCase()] || QuestionIcon;

              // Check if we should display the icon and variables
              const showFixedAmount =
                offer &&
                offer.fixedAmountEnabled &&
                item.offerAmount !== undefined;
              const showAffiliateAmount =
                offer &&
                offer.affiliatesEnabled &&
                item.affiliateAmount !== undefined;

              return (
                <Box
                  key={index}
                  p={3}
                  borderWidth="1px"
                  borderRadius="md"
                  width="100%"
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center">
                      {(showFixedAmount || showAffiliateAmount) && (
                        <IconComponent mr={2} />
                      )}
                      <Text fontWeight="bold" fontSize="lg">
                        {capitalizeFirstLetter(item.type)}
                      </Text>
                    </Flex>
                    <Text fontSize="xs" color="gray.500">
                      {new Date(item.timestamp).toLocaleString()}
                    </Text>
                  </Flex>
                  <Text mt={2}>{item.message}</Text>

                  {/* Handle Offer Type */}
                  {item.type.toLowerCase() === "offer" && (
                    <Box mt={2}>
                      {showFixedAmount && (
                        <Text fontSize="sm">
                          Offer Amount: <strong>${item.offerAmount}</strong>
                        </Text>
                      )}
                      {showAffiliateAmount && (
                        <Text fontSize="sm">
                          Affiliate Amount:{" "}
                          <strong>{item.affiliateAmount}%</strong>
                        </Text>
                      )}
                      {item.deliverables && (
                        <Text fontSize="sm">
                          Deliverables:{" "}
                          <strong>{formatDeliverables(item.deliverables)}</strong>
                        </Text>
                      )}
                      {item.result ? (
                        <Badge
                          colorScheme={
                            item.result === "accept" ? "green" : "red"
                          }
                          mt={2}
                        >
                          {item.result === "accept" ? "Approved" : "Rejected"}
                        </Badge>
                      ) : (
                        <Flex mt={2}>
                          <Button
                            size="sm"
                            colorScheme="green"
                            mr={2}
                            onClick={() => handleOfferResult(item, "accept")}
                          >
                            Accept
                          </Button>
                          <Button
                            size="sm"
                            colorScheme="red"
                            onClick={() => handleOfferResult(item, "reject")}
                          >
                            Reject
                          </Button>
                        </Flex>
                      )}
                    </Box>
                  )}

                  {/* Handle Content Upload Type */}
                  {item.type.toLowerCase() === "content_upload" &&
                    item.mediaLink && (
                      <Box mt={2}>
                        <Button
                          as="a"
                          href={item.mediaLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          leftIcon={<AttachmentIcon />}
                          variant="outline"
                          colorScheme="blue"
                          mb={2}
                        >
                          View Uploaded Content
                        </Button>

                        {/* Find the corresponding submission */}
                        {(() => {
                          const submission = submissions.find(
                            (sub) => sub.id === item.submissionId
                          );
                          if (submission && submission.approvalStatus) {
                            // Display the status and notes
                            return (
                              <>
                                <Badge
                                  colorScheme={
                                    submission.approvalStatus === "approved"
                                      ? "green"
                                      : "red"
                                  }
                                  mt={2}
                                >
                                  {submission.approvalStatus === "approved"
                                    ? "Approved"
                                    : "Denied"}
                                </Badge>
                                {submission.notes && (
                                  <Text mt={2}>
                                    Notes: {submission.notes}
                                  </Text>
                                )}
                              </>
                            );
                          } else if (
                            denyingSubmissionId === item.submissionId
                          ) {
                            // Render the input box for notes
                            return (
                              <Box mt={2}>
                                <Input
                                  placeholder="Enter notes"
                                  value={denyNotesMap[item.submissionId] || ""}
                                  onChange={(e) =>
                                    setDenyNotesMap((prev) => ({
                                      ...prev,
                                      [item.submissionId]: e.target.value,
                                    }))
                                  }
                                  mb={2}
                                />
                                <Flex>
                                  <Button
                                    size="sm"
                                    colorScheme="red"
                                    mr={2}
                                    onClick={() => {
                                      handleContentApprovalResult(
                                        item,
                                        "denied",
                                        denyNotesMap[item.submissionId]
                                      );
                                      setDenyingSubmissionId(null);
                                      setDenyNotesMap((prev) => ({
                                        ...prev,
                                        [item.submissionId]: "",
                                      }));
                                    }}
                                  >
                                    Submit Denial
                                  </Button>
                                  <Button
                                    size="sm"
                                    onClick={() =>
                                      setDenyingSubmissionId(null)
                                    }
                                  >
                                    Cancel
                                  </Button>
                                </Flex>
                              </Box>
                            );
                          } else {
                            // Display Accept/Deny buttons
                            return (
                              <Flex mt={2}>
                                <Button
                                  size="sm"
                                  colorScheme="green"
                                  mr={2}
                                  onClick={() =>
                                    handleContentApprovalResult(item, "approved")
                                  }
                                >
                                  Accept
                                </Button>
                                <Button
                                  size="sm"
                                  colorScheme="red"
                                  onClick={() =>
                                    setDenyingSubmissionId(item.submissionId)
                                  }
                                >
                                  Deny
                                </Button>
                              </Flex>
                            );
                          }
                        })()}
                      </Box>
                    )}
                </Box>
              );
            })}
          </VStack>
        ) : (
          <Text>No timeline items available.</Text>
        )}
      </Box>

      {/* Approve/Reject Buttons when status is 'Awaiting Approval' */}
      {status === "Awaiting Approval" && (
        <Flex mt={4}>
          <Button
            colorScheme="green"
            mr={2}
            onClick={() => handleApprovalResult("approve")}
          >
            Approve
          </Button>
          <Button
            colorScheme="red"
            onClick={() => handleApprovalResult("reject")}
          >
            Reject
          </Button>
        </Flex>
      )}

      {/* CampaignMessagesSendModal */}
      {creator && (
        <CampaignMessagesSendModal
          isOpen={isOpen}
          onClose={onClose}
          selectedCreators={[creator]}
          workspaceId={workspaceId}
          campaignId={campaignId}
        />
      )}
    </Box>
  );
};

export default CampaignMessageCreator;
