// Imports at the top
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  Heading,
  Input,
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Textarea,
  Image,
  Text,
  Fade,
} from "@chakra-ui/react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { db, storage } from "../../../Firebase";
import {
  collection,
  onSnapshot,
  setDoc,
  query,
  orderBy,
  doc,
  writeBatch,
  serverTimestamp,
  updateDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import placeholderImage from "../../../assets/placeholder.jpg";
import { TbPlus, TbRobotFace, TbSettings } from "react-icons/tb";

function generateCustomId() {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < 16; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

const ListsGroup = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { workspaceData } = useOutletContext();
  const {
    isOpen: isAddListOpen,
    onOpen: onAddListOpen,
    onClose: onAddListClose,
  } = useDisclosure();
  const {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteConfirmOpen,
    onOpen: onDeleteConfirmOpen,
    onClose: onDeleteConfirmClose,
  } = useDisclosure();

  const [lists, setLists] = useState(null);
  const [newListName, setNewListName] = useState("");
  const [newListDescription, setNewListDescription] = useState("");
  const [newListImage, setNewListImage] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [groupImageURL, setGroupImageURL] = useState(null);
  const [newGroupImage, setNewGroupImage] = useState(null);
  const [filterText, setFilterText] = useState("");

  // States for inline editing of group name
  const [isGroupNameEditing, setIsGroupNameEditing] = useState(false);
  const [editedGroupName, setEditedGroupName] = useState("");
  const groupNameInputRef = useRef(null);

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  );

  // Fetch group data
  useEffect(() => {
    if (!workspaceData?.workspace_id || !groupId) return;
    const groupRef = doc(
      db,
      "workspaces",
      workspaceData.workspace_id,
      "groups",
      groupId
    );
    const unsubscribe = onSnapshot(
      groupRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setGroupName(data.name || "Unknown Group");
          setGroupDescription(data.description || "");
          setGroupImageURL(data.imageURL || null);
        } else {
          setGroupName("Unknown Group");
          setGroupDescription("");
          setGroupImageURL(null);
        }
      },
      (error) => console.error("Error fetching group data:", error)
    );
    return unsubscribe;
  }, [workspaceData, groupId]);

  // Fetch lists data
  useEffect(() => {
    if (!workspaceData?.workspace_id || !groupId) return;
    const listsCollection = collection(
      db,
      "workspaces",
      workspaceData.workspace_id,
      "groups",
      groupId,
      "lists"
    );
    const q = query(listsCollection, orderBy("order"));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) =>
        setLists(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))),
      (error) => {
        console.error("Error fetching lists:", error);
        setLists([]);
      }
    );
    return unsubscribe;
  }, [workspaceData, groupId]);

  // Focus the input when entering edit mode
  useEffect(() => {
    if (isGroupNameEditing && groupNameInputRef.current) {
      groupNameInputRef.current.focus();
    }
  }, [isGroupNameEditing]);

  // Save group name function, memoized to avoid unnecessary re-creations
  const saveGroupName = useCallback(async () => {
    const trimmedName = editedGroupName.trim();
    if (!trimmedName || trimmedName === groupName) {
      setIsGroupNameEditing(false);
      setEditedGroupName(groupName);
      return;
    }

    try {
      const groupRef = doc(
        db,
        "workspaces",
        workspaceData.workspace_id,
        "groups",
        groupId
      );
      await updateDoc(groupRef, { name: trimmedName });
      setIsGroupNameEditing(false);
    } catch (error) {
      console.error("Error updating group name:", error);
      // Optionally, you can set an error state here to inform the user
    }
  }, [editedGroupName, groupName, workspaceData.workspace_id, groupId]);

  // Handle clicking outside the input to save
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        groupNameInputRef.current &&
        !groupNameInputRef.current.contains(event.target)
      ) {
        saveGroupName();
      }
    };

    if (isGroupNameEditing) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      if (isGroupNameEditing) {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, [isGroupNameEditing, saveGroupName]);

  // Handle pressing Enter key to save
  const handleGroupNameKeyDown = (e) => {
    if (e.key === "Enter") {
      saveGroupName();
    }
  };

  // Add new list handler
  const handleAddList = async () => {
    const name = newListName.trim();
    const description = newListDescription.trim();

    if (!name) return;

    try {
      const newListId = generateCustomId();
      const listDocRef = doc(
        db,
        "workspaces",
        workspaceData.workspace_id,
        "groups",
        groupId,
        "lists",
        newListId
      );

      await setDoc(listDocRef, {
        name,
        description,
        order: lists?.length || 0,
        createdAt: serverTimestamp(),
      });

      let imageURL = groupImageURL || null;

      if (newListImage) {
        const imageExtension = newListImage.name.split(".").pop();
        const imagePath = `workspaces/${workspaceData.workspace_id}/groups/${groupId}/lists/${newListId}/images/listImage.${imageExtension}`;
        const imageStorageRef = storageRef(storage, imagePath);

        await uploadBytes(imageStorageRef, newListImage);
        imageURL = await getDownloadURL(imageStorageRef);
      }

      await updateDoc(listDocRef, { imageURL });

      setNewListName("");
      setNewListDescription("");
      setNewListImage(null);
      onAddListClose();
    } catch (error) {
      console.error("Error adding list:", error);
    }
  };

  // Save group settings handler
  const handleSaveGroupSettings = async () => {
    try {
      const groupRef = doc(
        db,
        "workspaces",
        workspaceData.workspace_id,
        "groups",
        groupId
      );
      const updateData = {
        name: groupName,
        description: groupDescription,
      };

      if (newGroupImage) {
        const imageExtension = newGroupImage.name.split(".").pop();
        const imagePath = `workspaces/${workspaceData.workspace_id}/groups/${groupId}/images/groupImage.${imageExtension}`;
        const imageStorageRef = storageRef(storage, imagePath);

        await uploadBytes(imageStorageRef, newGroupImage);
        updateData.imageURL = await getDownloadURL(imageStorageRef);
      }

      await updateDoc(groupRef, updateData);
      setNewGroupImage(null);
      onSettingsClose();
    } catch (error) {
      console.error("Error updating group settings:", error);
    }
  };

  // Delete group handler
  const handleDeleteGroup = async () => {
    try {
      // Delete all lists under the group
      const listsCollectionRef = collection(
        db,
        "workspaces",
        workspaceData.workspace_id,
        "groups",
        groupId,
        "lists"
      );

      const listsSnapshot = await getDocs(listsCollectionRef);
      const batch = writeBatch(db);

      listsSnapshot.forEach((docSnap) => {
        batch.delete(docSnap.ref);
      });

      await batch.commit();

      // Delete the group document
      const groupRef = doc(
        db,
        "workspaces",
        workspaceData.workspace_id,
        "groups",
        groupId
      );
      await deleteDoc(groupRef);

      // Close modals
      onDeleteConfirmClose();
      onSettingsClose();

      // Navigate back to lists page
      navigate("/dashboard/lists");
    } catch (error) {
      console.error("Error deleting group:", error);
      // Optionally show error message to user
    }
  };

  // Drag-and-drop handler
  const handleDragEnd = async ({ active, over }) => {
    if (!over || active.id === over.id || !lists) return;

    const oldIndex = lists.findIndex((item) => item.id === active.id);
    const newIndex = lists.findIndex((item) => item.id === over.id);
    if (oldIndex === -1 || newIndex === -1) return;

    const reordered = arrayMove(lists, oldIndex, newIndex);
    setLists(reordered);

    try {
      const batch = writeBatch(db);
      reordered.forEach((list, index) => {
        const listRef = doc(
          db,
          "workspaces",
          workspaceData.workspace_id,
          "groups",
          groupId,
          "lists",
          list.id
        );
        batch.update(listRef, { order: index });
      });
      await batch.commit();
    } catch (error) {
      console.error("Error updating list order:", error);
    }
  };

  // Filter lists based on search input
  const filteredLists = lists?.filter((list) =>
    list.name.toLowerCase().includes(filterText.toLowerCase())
  );

  if (!workspaceData?.workspace_id || !groupId) {
    return <Box p={4}>Workspace data is unavailable.</Box>;
  }

  return (
    <Box>
      {/* Header */}
      <Flex
        height="60px"
        alignItems="center"
        borderBottomWidth="2px"
        p={4}
        justifyContent="space-between"
      >
        <Heading size="md" as="div">
          <Flex align="center">
            <Text
              as="span"
              cursor="pointer"
              onClick={() => navigate("/dashboard/lists")}
              mr={2}
              fontWeight="bold"
            >
              Lists
            </Text>
            <Text as="span" mr={2}>
              /
            </Text>
            {/* Inline Editable Group Name */}
            {isGroupNameEditing ? (
              <Input
                ref={groupNameInputRef}
                value={editedGroupName}
                onChange={(e) => setEditedGroupName(e.target.value)}
                onBlur={saveGroupName}
                onKeyDown={handleGroupNameKeyDown}
                size="md"
                width="200px"
                borderRadius="md"
              />
            ) : (
              <Text
                as="span"
                cursor="pointer"
                onClick={() => {
                  setIsGroupNameEditing(true);
                  setEditedGroupName(groupName);
                }}
                fontWeight="bold"
              >
                {groupName}
              </Text>
            )}
          </Flex>
        </Heading>

        <Flex align="center">
          <Input
            placeholder="Search lists..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            size="sm"
            borderRadius="md"
            w="300px"
            mr={2}
          />
          <Button
            size="sm"
            colorScheme="gray"
            borderWidth="1px"
            bg="white"
            leftIcon={<TbPlus />}
            onClick={onAddListOpen}
            mr={2}
          >
            Add List
          </Button>
          <Button
            size="sm"
            colorScheme="gray"
            borderWidth="1px"
            bg="white"
            leftIcon={<TbSettings />}
            onClick={onSettingsOpen}
          >
            Settings
          </Button>
        </Flex>
      </Flex>

      {/* Add List Modal */}
      <Modal isOpen={isAddListOpen} onClose={onAddListClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New List</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Button
              borderWidth="1px"
              onClick={() => navigate(`/dashboard/lists/${groupId}/auto`)}
              mb={4}
              w="full"
              h="70px"
              leftIcon={<TbRobotFace/>}
            >
              Auto Generate List
            </Button>
            <Text mb={2} fontWeight="bold">
              Or create new manual list
            </Text>
            <Input
              placeholder="Enter list name"
              value={newListName}
              onChange={(e) => setNewListName(e.target.value)}
              mb={4}
            />
            <Textarea
              placeholder="Enter list description"
              value={newListDescription}
              onChange={(e) => setNewListDescription(e.target.value)}
              mb={4}
            />
            <Input
              type="file"
              accept="image/*"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setNewListImage(e.target.files[0]);
                }
              }}
              mb={2}
            />
            {newListImage && (
              <Flex align="center" mt={2}>
                <Image
                  src={URL.createObjectURL(newListImage)}
                  alt="Selected"
                  boxSize="50px"
                  objectFit="cover"
                  mr={2}
                />
                <Text>{newListImage.name}</Text>
              </Flex>
            )}
            {!newListImage && groupImageURL && (
              <Flex align="center" mt={2}>
                <Image
                  src={groupImageURL}
                  alt="Group Image"
                  boxSize="50px"
                  objectFit="cover"
                  mr={2}
                />
                <Text>Using group image by default</Text>
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleAddList}>
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Group Settings Modal */}
      <Modal isOpen={isSettingsOpen} onClose={onSettingsClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Group Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Group name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              mb={4}
            />
            <Textarea
              placeholder="Group description"
              value={groupDescription}
              onChange={(e) => setGroupDescription(e.target.value)}
              mb={4}
            />
            <Input
              type="file"
              accept="image/*"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setNewGroupImage(e.target.files[0]);
                }
              }}
              mb={2}
            />
            {(newGroupImage || groupImageURL) && (
              <Flex align="center" mt={2}>
                <Image
                  src={
                    newGroupImage
                      ? URL.createObjectURL(newGroupImage)
                      : groupImageURL
                  }
                  alt="Group Image"
                  boxSize="50px"
                  objectFit="cover"
                  mr={2}
                />
                <Text>
                  {newGroupImage ? newGroupImage.name : "Current group image"}
                </Text>
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={onDeleteConfirmOpen} mr={3}>
              Delete Group
            </Button>
            <Button colorScheme="blue" onClick={handleSaveGroupSettings}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteConfirmOpen} onClose={onDeleteConfirmClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to delete this group? This action cannot be
              undone.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onDeleteConfirmClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleDeleteGroup} ml={3}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Lists Section */}
      {lists === null ? (
        <Flex justify="center" align="center" height="200px">
          <Spinner size="lg" />
        </Flex>
      ) : filteredLists?.length ? (
        <>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={filteredLists.map((list) => list.id)}
              strategy={rectSortingStrategy}
            >
              <Flex wrap="wrap" px={2}>
                {filteredLists.map((list, index) => (
                  <Fade
                    key={list.id}
                    in={true}
                    transition={{
                      enter: { duration: 0.5, delay: index * 0.1 },
                    }}
                  >
                    <SortableItem
                      id={list.id}
                      name={list.name}
                      description={list.description}
                      imageURL={list.imageURL}
                      navigate={navigate}
                      groupId={groupId}
                    />
                  </Fade>
                ))}
              </Flex>
            </SortableContext>
          </DndContext>
        </>
      ) : (
        <Box p={4}>No lists available.</Box>
      )}
    </Box>
  );
};

const SortableItem = ({
  id,
  name,
  description,
  imageURL,
  navigate,
  groupId,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    cursor: isDragging ? "grabbing" : "grab",
    userSelect: "none",
    width: "300px",
  };

  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      p={3}
      m={2}
      borderWidth="1px"
      borderRadius="xl"
      bg="white"
      boxShadow="sm"
      onClick={() => navigate(`/dashboard/lists/${groupId}/${id}`)}
      _hover={{ bg: "gray.100" }}
    >
      <Flex>
        <Fade in={imageLoaded} transition={{ enter: { duration: 0.3 } }}>
          <Image
            src={imageURL || placeholderImage}
            alt={`${name} Image`}
            boxSize="80px"
            objectFit="cover"
            borderRadius="md"
            mr={4}
            onLoad={() => setImageLoaded(true)}
          />
        </Fade>
        <Box>
          <Heading size="sm" mb={2}>
            {name || "Unnamed List"}
          </Heading>
          <Text fontSize="xs" color="gray.500">
            {description || "No description provided."}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default ListsGroup;
