import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import {
  Box,
  Heading,
  Text,
  Flex,
  Button,
  useDisclosure,
  Input,
} from "@chakra-ui/react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { db } from "../../../Firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import ListsAPI from "./main/ListsAPI";
import AddModal from "./main/modals/AddModal";
import SettingsModal from "./main/modals/SettingsModal";
import LinkModal from "./main/modals/LinkModal";
import { TbPlus, TbSettings, TbShare } from "react-icons/tb";

const ListsMain = () => {
  const { groupId, listId } = useParams();
  const navigate = useNavigate();
  const { workspaceData } = useOutletContext();
  const [groupName, setGroupName] = useState("");
  const [listName, setListName] = useState("");
  const [isEditingListName, setIsEditingListName] = useState(false);
  const listNameInputRef = useRef(null);

  const {
    isOpen: isAddCreatorsOpen,
    onOpen: onAddCreatorsOpen,
    onClose: onAddCreatorsClose,
  } = useDisclosure();
  const {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure();
  const {
    isOpen: isShareListOpen,
    onOpen: onShareListOpen,
    onClose: onShareListClose,
  } = useDisclosure();

  useEffect(() => {
    const fetchGroupAndListNames = async () => {
      if (!workspaceData?.workspace_id || !groupId || !listId) return;

      try {
        const groupDocRef = doc(
          db,
          "workspaces",
          workspaceData.workspace_id,
          "groups",
          groupId
        );
        const groupDocSnap = await getDoc(groupDocRef);
        setGroupName(
          groupDocSnap.exists()
            ? groupDocSnap.data().name || "Unknown Group"
            : "Unknown Group"
        );

        const listDocRef = doc(
          db,
          "workspaces",
          workspaceData.workspace_id,
          "groups",
          groupId,
          "lists",
          listId
        );
        const listDocSnap = await getDoc(listDocRef);
        setListName(
          listDocSnap.exists()
            ? listDocSnap.data().name || "Unknown List"
            : "Unknown List"
        );
      } catch (error) {
        console.error("Error fetching group and list names:", error);
        setGroupName("Unknown Group");
        setListName("Unknown List");
      }
    };

    fetchGroupAndListNames();
  }, [workspaceData?.workspace_id, groupId, listId]);

  const saveListName = useCallback(async () => {
    if (!workspaceData?.workspace_id || !groupId || !listId) return;

    try {
      const listDocRef = doc(
        db,
        "workspaces",
        workspaceData.workspace_id,
        "groups",
        groupId,
        "lists",
        listId
      );
      await updateDoc(listDocRef, { name: listName });
      setIsEditingListName(false);
    } catch (error) {
      console.error("Error updating list name:", error);
    }
  }, [workspaceData?.workspace_id, groupId, listId, listName]);

  useEffect(() => {
    if (isEditingListName) {
      listNameInputRef.current?.focus();
    }
  }, [isEditingListName]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listNameInputRef.current && !listNameInputRef.current.contains(event.target)) {
        saveListName();
      }
    };

    if (isEditingListName) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditingListName, saveListName]);

  const renderHeader = useMemo(
    () => (
      <Flex
        height="60px"
        alignItems="center"
        borderBottomWidth="2px"
        p={4}
        justifyContent="space-between"
      >
        <Heading size="md">
          <Flex alignItems="center">
            <Text
              as="span"
              cursor="pointer"
              onClick={() => navigate("/dashboard/lists")}
              mr={2}
            >
              Lists
            </Text>
            <Text
              as="span"
              cursor="pointer"
              onClick={() => navigate(`/dashboard/lists/${groupId}`)}
              mr={2}
            >
              / {groupName}
            </Text>
            <Text mr={2} as="span">/ </Text>
            {isEditingListName ? (
              <Input
                ref={listNameInputRef}
                value={listName}
                onChange={(e) => setListName(e.target.value)}
                onBlur={saveListName}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    saveListName();
                  }
                }}
                width="200px"
              />
            ) : (
              <Text
                as="span"
                cursor="pointer"
                onClick={() => setIsEditingListName(true)}
              >
                {listName}
              </Text>
            )}
          </Flex>
        </Heading>
        <Flex>
          <Button
            size="sm"
            colorScheme="gray"
            borderWidth="1px"
            bg="white"
            leftIcon={<TbPlus />}
            mr={2}
            onClick={onAddCreatorsOpen}
          >
            Add Creators
          </Button>
          <Button
            size="sm"
            colorScheme="gray"
            borderWidth="1px"
            bg="white"
            leftIcon={<TbSettings />}
            mr={2}
            onClick={onSettingsOpen}
          >
            Settings
          </Button>
          <Button
            size="sm"
            colorScheme="gray"
            borderWidth="1px"
            bg="white"
            leftIcon={<TbShare />}
            onClick={onShareListOpen}
          >
            Share
          </Button>
        </Flex>
      </Flex>
    ),
    [groupId, groupName, listName, navigate, onAddCreatorsOpen, onSettingsOpen, onShareListOpen, isEditingListName, saveListName]
  );

  return (
    <Box>
      {renderHeader}
      <Box p={4}>
        <ListsAPI
          workspaceId={workspaceData?.workspace_id}
          groupId={groupId}
          listId={listId}
        />
      </Box>
      <AddModal
        isOpen={isAddCreatorsOpen}
        onClose={onAddCreatorsClose}
        workspaceId={workspaceData?.workspace_id}
        listId={listId}
      />
      <SettingsModal
        isOpen={isSettingsOpen}
        onClose={onSettingsClose}
        workspaceId={workspaceData?.workspace_id}
        listId={listId}
        groupId={groupId}
      />
      <LinkModal
        isOpen={isShareListOpen}
        onClose={onShareListClose}
        workspaceId={workspaceData?.workspace_id}
        listId={listId}
        groupId={groupId}
      />
    </Box>
  );
};

export default ListsMain;