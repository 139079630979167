// SearchQuery.js
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Spinner, Box } from '@chakra-ui/react';
import SearchTable from './SearchTable';
import Pagination from './Pagination';

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const SearchQuery = ({
  visibleColumns,
  filters,
  setSelectedCreators,
  selectedCreators,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: 'instagram_profile.followers_count',
    direction: 'descending',
  });

  const combinedState = useMemo(
    () => ({ visibleColumns, filters, sortConfig }),
    [visibleColumns, filters, sortConfig]
  );

  const debouncedState = useDebounce(combinedState, 500);

  const fetchData = useCallback(
    async (state, page) => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_HOOBE_API_URL}/search-table`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              visibleColumns: state.visibleColumns,
              filters: state.filters,
              sort: state.sortConfig,
              page,
              pageSize: 50,
            }),
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setData(result.data);
        setTotalPages(result.totalPages);
        setTotalCount(result.totalCount);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    setPage(1);
  }, [filters, sortConfig]);

  useEffect(() => {
    fetchData(debouncedState, page);
  }, [debouncedState, page, fetchData]);

  const handleSortChange = (sortState) => {
    setSortConfig(sortState);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="calc(100vh - 180px)"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      <SearchTable
        data={data}
        onSortChange={handleSortChange}
        sortConfig={sortConfig}
        visibleColumns={visibleColumns}
        setSelectedCreators={setSelectedCreators}
        selectedCreators={selectedCreators}
      />

      <Pagination
        totalPages={totalPages}
        totalCount={totalCount}
        currentPage={page}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default SearchQuery;
