import React, { useEffect, useState } from "react"
import {
  Box,
  Heading,
  Input,
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Textarea,
  Image,
  Text,
  Fade,
} from "@chakra-ui/react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { db, storage } from "../../../Firebase"
import {
  collection,
  onSnapshot,
  setDoc,
  query,
  orderBy,
  doc,
  writeBatch,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore"
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage"
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import placeholderImage from "../../../assets/placeholder.jpg"
import { TbPlus } from "react-icons/tb"

function generateCustomId() {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  for (let i = 0; i < 16; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

const Lists = () => {
  const [groups, setGroups] = useState(null)
  const [newGroupName, setNewGroupName] = useState("")
  const [groupDescription, setGroupDescription] = useState("")
  const [groupImage, setGroupImage] = useState(null)
  const [filterText, setFilterText] = useState("")
  const navigate = useNavigate()
  const { workspaceData } = useOutletContext()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  )

  useEffect(() => {
    if (!workspaceData?.workspace_id) return

    const q = query(
      collection(db, "workspaces", workspaceData.workspace_id, "groups"),
      orderBy("order")
    )

    const unsubscribe = onSnapshot(
      q,
      (snapshot) =>
        setGroups(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))),
      (error) => {
        console.error("Error fetching groups:", error)
        setGroups([])
      }
    )

    return unsubscribe
  }, [workspaceData])

  const handleAddGroup = async () => {
    const name = newGroupName.trim()
    const description = groupDescription.trim()

    if (name) {
      try {
        const newGroupId = generateCustomId()
        const groupDocRef = doc(db, "workspaces", workspaceData.workspace_id, "groups", newGroupId)
        
        await setDoc(groupDocRef, {
          name,
          description,
          order: groups?.length || 0,
          createdAt: serverTimestamp(),
        })

        if (groupImage) {
          const imageExtension = groupImage.name.split(".").pop()
          const imagePath = `workspaces/${workspaceData.workspace_id}/groups/${newGroupId}/images/groupImage.${imageExtension}`
          const imageStorageRef = storageRef(storage, imagePath)

          await uploadBytes(imageStorageRef, groupImage)
          const downloadURL = await getDownloadURL(imageStorageRef)
          await updateDoc(groupDocRef, { imageURL: downloadURL })
        }

        setNewGroupName("")
        setGroupDescription("")
        setGroupImage(null)
        onClose()
      } catch (error) {
        console.error("Error adding group:", error)
      }
    }
  }

  const handleDragEnd = async ({ active, over }) => {
    if (active.id !== over?.id && groups) {
      const oldIndex = groups.findIndex((g) => g.id === active.id)
      const newIndex = groups.findIndex((g) => g.id === over.id)
      const reordered = arrayMove(groups, oldIndex, newIndex)
      setGroups(reordered)

      const batch = writeBatch(db)
      reordered.forEach((group, index) => {
        batch.update(
          doc(db, "workspaces", workspaceData.workspace_id, "groups", group.id),
          { order: index }
        )
      })
      try {
        await batch.commit()
      } catch (error) {
        console.error("Error updating group order:", error)
      }
    }
  }

  const filteredGroups = groups?.filter((g) =>
    g.name.toLowerCase().includes(filterText.toLowerCase())
  )

  if (!workspaceData?.workspace_id)
    return <Box p={4}>Workspace data is unavailable.</Box>

  return (
    <Box>
      <Flex
        height="60px"
        alignItems="center"
        borderBottomWidth="2px"
        p={4}
        justifyContent="space-between"
      >
        <Heading size="md">Lists</Heading>
        <Flex align="center">
          <Input
            placeholder="Search group..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            size="sm"
            borderRadius="md"
            w="300px"
            mr={2}
          />
          <Button
            size="sm"
            colorScheme="gray"
            borderWidth="1px"
            bg="white"
            leftIcon={<TbPlus />}
            onClick={onOpen}
          >
            Add Group
          </Button>
        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Enter group name"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
              mb={4}
            />
            <Textarea
              placeholder="Enter group description"
              value={groupDescription}
              onChange={(e) => setGroupDescription(e.target.value)}
              mb={4}
            />
            <Input
              type="file"
              accept="image/*"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setGroupImage(e.target.files[0])
                }
              }}
            />
            {groupImage && (
              <Flex mt={2} align="center">
                <Image
                  src={URL.createObjectURL(groupImage)}
                  alt="Selected"
                  boxSize="50px"
                  objectFit="cover"
                  mr={2}
                />
                <Text>{groupImage.name}</Text>
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleAddGroup}>
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {groups === null ? (
        <Flex justify="center" align="center" height="200px">
          <Spinner size="lg" />
        </Flex>
      ) : filteredGroups?.length ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={filteredGroups.map((g) => g.id)}
            strategy={rectSortingStrategy}
          >
            <Flex wrap="wrap" px={2}>
              {filteredGroups.map((group) => (
                <Fade key={group.id} in={true} transition={{ enter: { duration: 0.5, delay: 0.1 } }}>
                  <SortableItem
                    id={group.id}
                    name={group.name}
                    navigate={navigate}
                    description={group.description}
                    imageURL={group.imageURL}
                  />
                </Fade>
              ))}
            </Flex>
          </SortableContext>
        </DndContext>
      ) : (
        <Box p={4}>No groups available.</Box>
      )}
    </Box>
  )
}

const SortableItem = ({ id, name, navigate, description, imageURL }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({ id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    cursor: isDragging ? "grabbing" : "grab",
  }

  const [imageLoaded, setImageLoaded] = useState(false)

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      p={3}
      m={2}
      borderWidth="1px"
      borderRadius="xl"
      minW="300px"
      bg="white"
      boxShadow="sm"
      onClick={() => navigate(`/dashboard/lists/${id}`)}
      _hover={{ bg: "gray.100" }}
    >
      <Flex>
        <Fade in={imageLoaded} transition={{ enter: { duration: 0.3 } }}>
          <Image
            src={imageURL || placeholderImage}
            alt={`${name} Image`}
            boxSize="80px"
            objectFit="cover"
            borderRadius="md"
            mr={4}
            onLoad={() => setImageLoaded(true)}
          />
        </Fade>
        <Box>
          <Heading size="sm" mb={2}>
            {name || "none"}
          </Heading>
          <Text fontSize="xs" color="gray.500">
            {description || "none"}
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}

export default Lists