import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Textarea,
  Heading,
  Image,
  HStack,
  IconButton,
  Spinner,
  Flex,
  useToast,
  Text,
} from '@chakra-ui/react';
import { DeleteIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { SiShopify } from 'react-icons/si';

const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [error, setError] = useState('');
  const [product, setProduct] = useState({
    name: '',
    description: '',
    vendor: '',
    price: '',
    handle: '',
  });
  const [existingImages, setExistingImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const toast = useToast();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://hoobe-shop-api.onrender.com/get-product/${id}`);
        const productData = response.data;

        setProduct({
          name: productData.title || '',
          description: productData.body_html || '',
          vendor: productData.vendor || '',
          price:
            productData.variants && productData.variants.length > 0
              ? productData.variants[0].price
              : '',
          handle: productData.handle || '',
        });

        const currentImages = productData.images.map((img) => ({
          id: img.id,
          src: img.src,
          isDeleted: false,
        }));
        setExistingImages(currentImages);
      } catch (error) {
        console.error('Error fetching product:', error);
        setError('Product not found');
      } finally {
        setInitialLoad(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handlePriceChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
      setProduct({ ...product, price: value });
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newImageFiles = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      isNew: true,
    }));
    setNewImages([...newImages, ...newImageFiles]);
  };

  const removeExistingImage = (index) => {
    const updatedImages = [...existingImages];
    updatedImages[index].isDeleted = true;
    setExistingImages(updatedImages);
  };

  const removeNewImage = (index) => {
    const updatedImages = newImages.filter((_, i) => i !== index);
    setNewImages(updatedImages);
  };

  const handleSubmit = async () => {
    if (!product.name.trim() || !product.price) {
      toast({
        title: 'Validation Error',
        description: 'Product name and price are required',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    try {
      const newImagesBase64 = await Promise.all(
        newImages.map(async ({ file }) => {
          const base64 = await fileToBase64(file);
          return { attachment: base64 };
        })
      );

      const remainingImages = existingImages
        .filter((img) => !img.isDeleted)
        .map((img) => ({ id: img.id }));

      const allImages = [...remainingImages, ...newImagesBase64];

      const productPayload = {
        title: product.name,
        body_html: product.description,
        vendor: product.vendor,
        price: product.price,
        images: allImages,
        variants: [{ price: product.price }],
      };

      const response = await axios.put(
        `https://hoobe-shop-api.onrender.com/update-product/${id}`,
        productPayload
      );

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Product updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/dashboard/products');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update product',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCopyProductId = () => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        toast({
          title: 'Product ID Copied',
          description: `Product ID "${id}" has been copied to clipboard.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: 'Error',
          description: 'Failed to copy Product ID.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleGoToLiveProduct = () => {
    const productUrl = `https://hoobe-store.myshopify.com/products/${product.handle}`;
    window.open(productUrl, '_blank');
  };

  const handleGoToShopifyProduct = () => {
    const shopifyProductUrl = `https://admin.shopify.com/store/hoobe-store/products/${id}`;
    window.open(shopifyProductUrl, '_blank');
  };

  if (initialLoad) {
    return (
      <Flex justify="center" align="center" height="200px">
        <Spinner size="lg" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Box p={6} maxW="container.lg" mx="auto">
        <Heading mb={6}>Edit Product</Heading>
        <Box mb={4} p={4} bg="red.100" borderRadius="md">
          <Text color="red.600" fontWeight="bold">
            {error}
          </Text>
        </Box>
      </Box>
    );
  }

  return (
    <Box p={6} maxW="container.lg" mx="auto">
      <Flex justify="space-between" align="center" mb={6}>
        <Heading>Edit Product</Heading>
        <HStack spacing={4}>
          <Button onClick={handleCopyProductId}>Copy Product ID</Button>
          <Button
            onClick={handleGoToLiveProduct}
            rightIcon={<ExternalLinkIcon />}
            isDisabled={!product.handle}
          >
            View Live Product
          </Button>
          <Button
            onClick={handleGoToShopifyProduct}
            rightIcon={<SiShopify />}
            isDisabled={!id}
          >
            View in Shopify
          </Button>
        </HStack>
      </Flex>

      <Flex direction={{ base: 'column', md: 'row' }} align="start">
        <Box flex="1" mr={{ md: 6 }} mb={{ base: 6, md: 0 }}>
          <FormControl>
            <FormLabel>Images</FormLabel>
            <Input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
              py={1}
            />
            <HStack wrap="wrap" spacing={4} mt={2}>
              {existingImages
                .filter((img) => !img.isDeleted)
                .map((image, index) => (
                  <Box key={image.id} position="relative">
                    <Image
                      src={image.src}
                      alt={`Product Image ${index}`}
                      boxSize="100px"
                      objectFit="cover"
                      borderRadius="md"
                    />
                    <IconButton
                      icon={<DeleteIcon />}
                      size="sm"
                      colorScheme="red"
                      position="absolute"
                      top={1}
                      right={1}
                      onClick={() => removeExistingImage(index)}
                    />
                  </Box>
                ))}
              {newImages.map((image, index) => (
                <Box key={`new-${index}`} position="relative">
                  <Image
                    src={image.preview}
                    alt={`New Image ${index}`}
                    boxSize="100px"
                    objectFit="cover"
                    borderRadius="md"
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    size="sm"
                    colorScheme="red"
                    position="absolute"
                    top={1}
                    right={1}
                    onClick={() => removeNewImage(index)}
                  />
                </Box>
              ))}
            </HStack>
          </FormControl>
        </Box>

        <Box flex="2">
          <VStack spacing={4} align="stretch">
            <FormControl isRequired>
              <FormLabel>Product Name</FormLabel>
              <Input
                value={product.name}
                onChange={(e) => setProduct({ ...product, name: e.target.value })}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={product.description}
                onChange={(e) =>
                  setProduct({ ...product, description: e.target.value })
                }
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Price</FormLabel>
              <Input
                value={product.price}
                onChange={handlePriceChange}
                placeholder="0.00"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Brand Name</FormLabel>
              <Input
                value={product.vendor}
                onChange={(e) => setProduct({ ...product, vendor: e.target.value })}
              />
            </FormControl>

            <HStack spacing={4} mt={4}>
              <Button
                colorScheme="blue"
                onClick={handleSubmit}
                isLoading={loading}
                loadingText="Updating Product"
              >
                Update Product
              </Button>
              <Button onClick={() => navigate('/dashboard/products')} isDisabled={loading}>
                Cancel
              </Button>
            </HStack>
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
};

export default ProductDetails;
