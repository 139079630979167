// CampaignMessagesEmail.js

import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  Text,
  Input,
  Button,
  useToast,
  Select,
} from "@chakra-ui/react";
import TagInput from "./modalstuff/TagInput";
import RichHTML from "./modalstuff/RichHTML";

const ALLOWED_FROM_EMAILS = [
  "jordan@hoobe.email",
  "connor@hoobe.email",
  "nate@hoobe.email",
  "mike@hoobe.email",
  "bhavana@hoobe.email",
  "contact@hoobe.email",
  "isabella@hoobe.email",
  "kayla@hoobe.email",
  "legal@hoobe.email",
  "offers@hoobe.email",
];

const CampaignMessagesEmail = ({
  selectedCreator,
  handleUpdate,
  workspaceId,
  campaignId,
}) => {
  const [fromEmail, setFromEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [subject, setSubject] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (selectedCreator) {
      setFromEmail(selectedCreator.fromEmail || "");
      setEmails(selectedCreator.emails || []);
      setSubject(selectedCreator.subject || "");
      setEmailMessage(selectedCreator.emailMessage || "");
    } else {
      setFromEmail("");
      setEmails([]);
      setSubject("");
      setEmailMessage("");
    }
  }, [selectedCreator]);

  const handleFromEmailChange = async (e) => {
    const selectedEmail = e.target.value;
    setFromEmail(selectedEmail);
    await handleUpdate("fromEmail", selectedEmail);
  };

  const handleEmailsUpdate = async (updatedEmails) => {
    setEmails(updatedEmails);
    await handleUpdate("emails", updatedEmails);
  };

  const handleBlur = (field, value) => {
    handleUpdate(field, value);
  };

  const handleSendEmail = async () => {
    if (!fromEmail) {
      toast({
        title: "From Email Required",
        description: "Please select a From Email before sending.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (emails.length === 0) {
      toast({
        title: "No Recipient Emails",
        description: "Please add at least one recipient email.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!subject.trim()) {
      toast({
        title: "Subject Required",
        description: "Please enter the email subject.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!emailMessage.trim()) {
      toast({
        title: "Message Required",
        description: "Please enter the email message.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsSendingEmail(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOOBE_API_URL}/campaigns-send-one-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            workspaceId,
            campaignId,
            creatorId: selectedCreator.creatorId,
            fromEmail: fromEmail,
            toEmails: emails,
            subject,
            message: emailMessage,
            name: selectedCreator.name,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Email sent successfully:", data);
      toast({
        title: "Email Sent",
        description: "The email has been sent successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error sending email:", error);
      toast({
        title: "Error Sending Email",
        description: "There was an error sending the email. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSendingEmail(false);
    }
  };

  const isButtonDisabled =
    !fromEmail || emails.length === 0 || !subject.trim() || !emailMessage.trim();

  return (
    <Box h="calc(100vh - 1000px)" >
      <VStack align="stretch" spacing={4}>
        <Box>
          <Text fontWeight="semibold" mb={2}>
            From Email
          </Text>
          <Select
            placeholder="Select from email"
            value={fromEmail}
            onChange={handleFromEmailChange}
            size="lg"
            borderRadius="full"
          >
            {ALLOWED_FROM_EMAILS.map((email) => (
              <option key={email} value={email}>
                {email}
              </option>
            ))}
          </Select>
        </Box>

        <Box>
          <Text fontWeight="semibold" mb={2}>
            Email Addresses
          </Text>
          <TagInput
            tags={emails}
            setTags={setEmails}
            placeholder="Add new email"
            onUpdate={handleEmailsUpdate}
          />
        </Box>

        <Box>
          <Text fontWeight="semibold" mb={2}>
            Subject
          </Text>
          <Input
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            onBlur={() => handleBlur("subject", subject)}
            placeholder="Email Subject"
          />
        </Box>

        <Box>
          <Text fontWeight="semibold" mb={2}>
            Email Message
          </Text>
          <RichHTML
            value={emailMessage}
            onChange={(content) => setEmailMessage(content)}
            onBlur={() => handleBlur("emailMessage", emailMessage)}
            placeholder="Compose your email message here..."
          />
        </Box>

        <Button
          onClick={handleSendEmail}
          isLoading={isSendingEmail}
          isDisabled={isButtonDisabled}
          colorScheme="blue"
          mb={100}
        >
          Send Email
        </Button>
      </VStack>
    </Box>
  );
};

export default CampaignMessagesEmail;
