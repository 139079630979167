// RichHTML.js

import React, { useRef } from 'react';
import {
  Box,
  Text,
  HStack,
  IconButton,
  Flex,
} from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaListUl,
  FaListOl,
  FaLink,
} from 'react-icons/fa';

const RichHTML = ({ value, onChange, onBlur, placeholder }) => {
  const editorRef = useRef(null);

  const modules = {
    toolbar: false,
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'list',
    'bullet',
    'link',
  ];

  const formattingOptions = [
    {
      label: 'H1',
      icon: <Text fontSize="sm" fontWeight="bold">H1</Text>,
      command: 'header',
      value: 1,
    },
    {
      label: 'H2',
      icon: <Text fontSize="sm" fontWeight="bold">H2</Text>,
      command: 'header',
      value: 2,
    },
    {
      label: 'H3',
      icon: <Text fontSize="sm" fontWeight="bold">H3</Text>,
      command: 'header',
      value: 3,
    },
    {
      label: 'P',
      icon: <Text fontSize="sm">P</Text>,
      command: 'paragraph',
    },
    {
      label: 'Bold',
      icon: <FaBold size={12} />,
      command: 'bold',
    },
    {
      label: 'Italic',
      icon: <FaItalic size={12} />,
      command: 'italic',
    },
    {
      label: 'Underline',
      icon: <FaUnderline size={12} />,
      command: 'underline',
    },
    {
      label: 'Bullet List',
      icon: <FaListUl size={12} />,
      command: 'bullet',
    },
    {
      label: 'Numbered List',
      icon: <FaListOl size={12} />,
      command: 'ordered',
    },
    {
      label: 'Insert Link',
      icon: <FaLink size={12} />,
      command: 'link',
    },
  ];

  const formatText = (option) => {
    if (!editorRef.current) return;
    const quill = editorRef.current.getEditor();

    switch (option.command) {
      case 'bold':
      case 'italic':
      case 'underline':
        quill.format(option.command, !quill.getFormat()[option.command]);
        break;
      case 'bullet':
      case 'ordered':
        quill.format(
          'list',
          quill.getFormat().list === option.command ? false : option.command
        );
        break;
      case 'link':
        const range = quill.getSelection();
        if (range) {
          const url = prompt('Enter the URL');
          if (url) {
            quill.format('link', url);
          }
        }
        break;
      case 'header':
        quill.format('header', quill.getFormat().header === option.value ? false : option.value);
        break;
      case 'paragraph':
        quill.format('header', false);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Flex justifyContent="flex-start" alignItems="center" mb="12px">
        <HStack spacing={2}>
          {formattingOptions.map((option) => (
            <IconButton
              key={option.label}
              icon={option.icon}
              aria-label={option.label}
              variant="outline"
              color="#70757D"
              _hover={{ bg: 'gray.200' }}
              onClick={() => formatText(option)}
            />
          ))}
        </HStack>
      </Flex>
      <Box
        className="custom-quill"
        borderWidth="1px"
        borderRadius="xl"
        p={2}
        sx={{
          '.ql-container': {
            borderColor: 'white',
          },
        }}
      >
        <ReactQuill
          ref={editorRef}
          theme="snow"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
        />
      </Box>

      <style>
        {`
          .custom-quill .ql-container {
            border: none !important;
            font-size: 14px;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            line-height: 20px;
          }
          .custom-quill .ql-editor {
            padding: 0 !important;
            min-height: 250px;
          }
          .custom-quill .ql-editor:focus {
            outline: none;
          }
        `}
      </style>
    </>
  );
};

export default RichHTML;
