import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Text,
  Spinner,
  Container,
  Center,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth, functions } from "../Firebase";
import { httpsCallable } from "firebase/functions";
import { FcGoogle } from "react-icons/fc";

function Signup() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const logUserInfo = httpsCallable(functions, 'logUserInfo');

  const handleEmailSignup = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await logUserInfo({ fullName, email, uid: userCredential.user.uid });
      navigate("/dashboard");
    } catch (error) {
      console.error("Error signing up:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignup = async () => {
    setIsLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await logUserInfo({ fullName: user.displayName, email: user.email, uid: user.uid });
      navigate("/dashboard");
    } catch (error) {
      console.error("Error signing up with Google:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box bg="gray.200" minHeight="100vh" display="flex" alignItems="center" justifyContent="center">
      <Container maxWidth="500px">
        <Box bg="white" p={8} borderRadius="xl" boxShadow="md">
          <VStack spacing={4} align="stretch">
            <Center>
              <Heading>Sign Up</Heading>
            </Center>
            <form onSubmit={handleEmailSignup}>
              <FormControl>
                <FormLabel>Full Name</FormLabel>
                <Input
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder="Enter your full name"
                  required
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  required
                />
              </FormControl>
              <Button
                type="submit"
                bg="gray.800"
                color="white"
                size="lg"
                width="full"
                _hover={{ bg: "black" }}
                mt={4}
                isLoading={isLoading}
                loadingText="Signing Up"
                spinner={<Spinner size="sm" />}
                disabled={isLoading}
              >
                Sign Up
              </Button>
            </form>
            <Button
              leftIcon={<FcGoogle />}
              onClick={handleGoogleSignup}
              width="full"
              size="lg"
              isLoading={isLoading}
              loadingText="Signing Up"
              spinner={<Spinner size="sm" />}
              disabled={isLoading}
            >
              Continue with Google
            </Button>
            <Center>
              <Text>
                Already have an account? <Link to="/login">Login</Link>
              </Text>
            </Center>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
}

export default Signup;
