import React, { useState, useEffect } from 'react';
import {
  VStack,
  Input,
  FormControl,
  FormLabel,
  Select,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { useOutletContext } from 'react-router-dom';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../../Firebase';

function SettingsWorkspace() {
  const { userData } = useOutletContext();
  const [workspaceIds, setWorkspaceIds] = useState(
    userData?.workspaceIds || (userData?.workspaceId ? [userData.workspaceId] : [])
  );
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(
    userData?.workspaceId || ''
  );
  const [workspaceName, setWorkspaceName] = useState('');
  const [newWorkspaceId, setNewWorkspaceId] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchWorkspaceName = async () => {
      if (!selectedWorkspaceId) {
        setWorkspaceName('');
        return;
      }

      try {
        const workspaceDocRef = doc(db, 'workspaces', selectedWorkspaceId);
        const workspaceDoc = await getDoc(workspaceDocRef);
        if (workspaceDoc.exists()) {
          const data = workspaceDoc.data();
          setWorkspaceName(data.workspace_name || '');
        } else {
          setWorkspaceName('Workspace not found');
        }
      } catch (error) {
        console.error('Error fetching workspace data:', error);
        setWorkspaceName('Error fetching workspace');
      }
    };

    fetchWorkspaceName();
  }, [selectedWorkspaceId]);

  const handleWorkspaceSelect = async (e) => {
    const newWorkspaceId = e.target.value;
    setSelectedWorkspaceId(newWorkspaceId);

    if (userData && userData.userId) {
      try {
        const userDocRef = doc(db, 'users', userData.userId);
        await updateDoc(userDocRef, { workspaceId: newWorkspaceId });
        console.log('Current workspace updated successfully');
      } catch (error) {
        console.error('Error updating current workspace:', error);
      }
    }
  };

  const handleAddWorkspace = async () => {
    if (!newWorkspaceId) return;

    try {
      const workspaceDocRef = doc(db, 'workspaces', newWorkspaceId);
      const workspaceDoc = await getDoc(workspaceDocRef);

      if (workspaceDoc.exists()) {
        const updatedWorkspaceIds = [...workspaceIds, newWorkspaceId];
        setWorkspaceIds(updatedWorkspaceIds);

        // Update userData in Firebase
        if (userData && userData.userId) {
          const userDocRef = doc(db, 'users', userData.userId);
          await updateDoc(userDocRef, { workspaceIds: updatedWorkspaceIds });

          // If no workspace is selected yet, set the newly added one as current
          if (!selectedWorkspaceId) {
            setSelectedWorkspaceId(newWorkspaceId);
            await updateDoc(userDocRef, { workspaceId: newWorkspaceId });
          }
        }

        setNewWorkspaceId('');
        onClose();
        console.log('Workspace added successfully');
      } else {
        alert('Workspace ID not found in database.');
      }
    } catch (error) {
      console.error('Error adding workspace:', error);
    }
  };

  return (
    <VStack spacing={4} p={4} maxW="500px" align="stretch">
      <FormControl>
        <FormLabel>Workspace List</FormLabel>
        <Select
          placeholder="Select a workspace"
          value={selectedWorkspaceId}
          onChange={handleWorkspaceSelect}
        >
          {workspaceIds.map((id) => (
            <option key={id} value={id}>
              {id}
            </option>
          ))}
        </Select>
      </FormControl>

      <Button onClick={onOpen} colorScheme="blue">
        Add Workspace
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Workspace</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Workspace ID</FormLabel>
              <Input
                value={newWorkspaceId}
                onChange={(e) => setNewWorkspaceId(e.target.value)}
                placeholder="Enter Workspace ID"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleAddWorkspace} colorScheme="blue" mr={3}>
              Add
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <FormControl>
        <FormLabel>Workspace Name</FormLabel>
        <Input value={workspaceName} isReadOnly placeholder="Workspace Name" />
      </FormControl>
    </VStack>
  );
}

export default SettingsWorkspace;
