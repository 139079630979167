import React, { useState, useEffect } from "react";
import {
  Select,
  InputGroup,
  Box,
  Image,
  Text,
  Flex,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Input,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import instagramPost from "../../../../assets/instagrampost.png";
import instagramReel from "../../../../assets/instagramreel.png";
import instagramStory from "../../../../assets/instagramstory.png";
import tiktokVideo from "../../../../assets/tiktok.png";

const formatImpressions = (value) => {
  if (value === 100000000) return "10M+";
  if (value >= 1000000) {
    const formatted = (value / 1000000).toFixed(1);
    return formatted.endsWith(".0")
      ? `${formatted.slice(0, -2)}M`
      : `${formatted}M`;
  }
  if (value >= 1000) {
    const formatted = (value / 1000).toFixed(1);
    return formatted.endsWith(".0")
      ? `${formatted.slice(0, -2)}k`
      : `${formatted}k`;
  }
  return value.toString();
};

const convertToImpressions = (value) => {
  if (value <= 50) {
    return Math.round(value * 20000);
  } else if (value === 100) {
    return 100000000;
  } else {
    return Math.round(1000000 + (value - 50) * 180000);
  }
};

const convertToSliderValue = (impressions) => {
  if (impressions <= 1000000) {
    return Math.round(impressions / 20000);
  } else if (impressions === 100000000) {
    return 100;
  } else {
    return Math.round(50 + (impressions - 1000000) / 180000);
  }
};

const Deliverables = ({ selectedDeliverables, setSelectedDeliverables }) => {
  const [impressionsRanges, setImpressionsRanges] = useState({});
  const [isMinEditing, setIsMinEditing] = useState({});
  const [isMaxEditing, setIsMaxEditing] = useState({});
  const [tempValues, setTempValues] = useState({});

  const handleSelectChange = (event) => {
    const value = event.target.value;
    if (value && !selectedDeliverables.some((d) => d.name === value)) {
      setSelectedDeliverables([
        ...selectedDeliverables,
        { name: value, range: [1000, 100000000] },
      ]);
      setImpressionsRanges({
        ...impressionsRanges,
        [value]: [1000, 100000000],
      });
    }
  };

  const removeDeliverable = (deliverableToRemove) => {
    setSelectedDeliverables(
      selectedDeliverables.filter(
        (deliverable) => deliverable.name !== deliverableToRemove
      )
    );
    const updatedRanges = { ...impressionsRanges };
    delete updatedRanges[deliverableToRemove];
    setImpressionsRanges(updatedRanges);
  };

  const handleSliderChange = (deliverable, values) => {
    const newMin = convertToImpressions(values[0]);
    const newMax = convertToImpressions(values[1]);

    setImpressionsRanges({
      ...impressionsRanges,
      [deliverable]: [newMin, newMax],
    });

    setSelectedDeliverables(
      selectedDeliverables.map((d) =>
        d.name === deliverable ? { ...d, range: [newMin, newMax] } : d
      )
    );
  };

  const handleMinBlur = (deliverable) => {
    const validMin = Math.max(
      0,
      Math.min(
        tempValues[deliverable]?.min || 1000,
        impressionsRanges[deliverable]?.[1] - 1
      )
    );
    setImpressionsRanges({
      ...impressionsRanges,
      [deliverable]: [validMin, impressionsRanges[deliverable][1]],
    });
    setIsMinEditing({ ...isMinEditing, [deliverable]: false });
  };

  const handleMaxBlur = (deliverable) => {
    const validMax = Math.min(
      100000000,
      Math.max(
        tempValues[deliverable]?.max || 1000,
        impressionsRanges[deliverable]?.[0] + 1
      )
    );
    setImpressionsRanges({
      ...impressionsRanges,
      [deliverable]: [impressionsRanges[deliverable][0], validMax],
    });
    setIsMaxEditing({ ...isMaxEditing, [deliverable]: false });
  };

  const handleKeyPress = (e, type, deliverable) => {
    if (e.key === "Enter") {
      if (type === "min") {
        handleMinBlur(deliverable);
      } else {
        handleMaxBlur(deliverable);
      }
    }
  };

  const handleNumberInput = (e, deliverable, type) => {
    const value = e.target.value.replace(/\D/g, "");
    setTempValues({
      ...tempValues,
      [deliverable]: {
        ...tempValues[deliverable],
        [type]: Number(value),
      },
    });
  };

  useEffect(() => {
    selectedDeliverables.forEach((deliverable) => {
      setTempValues((prev) => ({
        ...prev,
        [deliverable.name]: {
          min: impressionsRanges[deliverable.name]?.[0] || 1000,
          max: impressionsRanges[deliverable.name]?.[1] || 100000000,
        },
      }));
    });
  }, [selectedDeliverables, impressionsRanges]);

  const deliverablesOptions = [
    { value: "instagram reel", label: "Instagram Reel", img: instagramReel },
    { value: "instagram story", label: "Instagram Story", img: instagramStory },
    { value: "instagram post", label: "Instagram Post", img: instagramPost },
    { value: "tiktok video", label: "TikTok Video", img: tiktokVideo },
  ];

  const availableDeliverables = deliverablesOptions.filter(
    (option) => !selectedDeliverables.some((d) => d.name === option.value)
  );

  return (
    <Box align="start" width="100%">
      <InputGroup mb={4}>
        <Select
          placeholder="Select deliverables"
          onChange={handleSelectChange}
          value=""
        >
          {availableDeliverables.map((deliverable) => (
            <option key={deliverable.value} value={deliverable.value}>
              {deliverable.label}
            </option>
          ))}
        </Select>
      </InputGroup>

      {selectedDeliverables.map((deliverable) => {
        const selectedOption = deliverablesOptions.find(
          (d) => d.value === deliverable.name
        );
        return (
          <Box
            key={deliverable.name}
            mb={4}
            p={4}
            borderWidth={1}
            borderRadius="md"
            position="relative"
            _hover={{ ".remove-icon": { opacity: 1, visibility: "visible" } }} // Show X on hover
          >
            <Flex alignItems="center" mb={2}>
              <Image
                src={selectedOption.img}
                alt={selectedOption.label}
                boxSize="20px"
                mr={2}
              />
              <Text fontWeight="bold">{selectedOption.label}</Text>

              <Box
                className="remove-icon"
                opacity={0}
                visibility="hidden"
                transition="opacity 0.2s, visibility 0.2s"
                position="absolute"
                right={4}
                top={3}
                color="red.500"
                cursor="pointer"
                onClick={() => removeDeliverable(deliverable.name)}
              >
                <CloseIcon />
              </Box>
            </Flex>

            <Flex justifyContent="space-between" alignItems="center" mb={2}>
              {isMinEditing[deliverable.name] ? (
                <Input
                  value={tempValues[deliverable.name]?.min || 1000}
                  mr={5}
                  onChange={(e) =>
                    handleNumberInput(e, deliverable.name, "min")
                  }
                  onBlur={() => handleMinBlur(deliverable.name)}
                  onKeyPress={(e) => handleKeyPress(e, "min", deliverable.name)}
                  autoFocus
                />
              ) : (
                <Text
                  fontWeight="bold"
                  onClick={() =>
                    setIsMinEditing({
                      ...isMinEditing,
                      [deliverable.name]: true,
                    })
                  }
                >
                  {formatImpressions(
                    impressionsRanges[deliverable.name]?.[0] || 1000
                  )}
                </Text>
              )}

              {isMaxEditing[deliverable.name] ? (
                <Input
                  value={tempValues[deliverable.name]?.max || 100000000}
                  ml={5}
                  onChange={(e) =>
                    handleNumberInput(e, deliverable.name, "max")
                  }
                  onBlur={() => handleMaxBlur(deliverable.name)}
                  onKeyPress={(e) => handleKeyPress(e, "max", deliverable.name)}
                  autoFocus
                />
              ) : (
                <Text
                  fontWeight="bold"
                  onClick={() =>
                    setIsMaxEditing({
                      ...isMaxEditing,
                      [deliverable.name]: true,
                    })
                  }
                >
                  {formatImpressions(
                    impressionsRanges[deliverable.name]?.[1] || 100000000 // Display as 10M+ for 100M
                  )}
                </Text>
              )}
            </Flex>

            <Box width="90%" mx="auto">
              <RangeSlider
                aria-label="Impressions Range"
                value={[
                  convertToSliderValue(
                    impressionsRanges[deliverable.name]?.[0] || 1000
                  ),
                  convertToSliderValue(
                    impressionsRanges[deliverable.name]?.[1] || 100000000 // Updated default to 100M
                  ),
                ]}
                min={0}
                max={100}
                step={1}
                onChange={(values) =>
                  handleSliderChange(deliverable.name, values)
                }
              >
                <RangeSliderTrack>
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb
                  borderWidth="2px"
                  borderColor="gray.300"
                  boxSize={5}
                  index={0}
                />
                <RangeSliderThumb
                  borderWidth="2px"
                  borderColor="gray.300"
                  boxSize={5}
                  index={1}
                />
              </RangeSlider>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Deliverables;
