import React from "react";
import { ChakraProvider, Box, extendTheme } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Dashboard from "./components/Dashboard";
import PasswordReset from "./components/PasswordReset";
import ProtectedRoute from "./components/utils/ProtectedRoute";
import DirectRoute from "./components/utils/DirectRoute";
import { AuthProvider } from "./components/utils/AuthContext";
import Data from "./components/tabs/data-page/Data";
import Settings from "./components/tabs/settings-page/Settings";
import SettingsProfile from "./components/tabs/settings-page/SettingsProfile";
import SettingsWorkspace from "./components/tabs/settings-page/SettingsWorkspace";
import Search from "./components/tabs/search-page/Search";
import Messages from "./components/tabs/messages-page/Messages";
import Campaigns from "./components/tabs/campaigns-page/Campaigns";
import Lists from "./components/tabs/lists-page/Lists";
import ListsGroup from "./components/tabs/lists-page/ListsGroup";
import ListsMain from "./components/tabs/lists-page/ListsMain";
// import AllMessages from "./components/tabs/messages-page/all-message/AllMessages";
import Templates from "./components/tabs/messages-page/Templates";
// import MessagesGroup from "./components/tabs/messages-page/all-message/MessagesGroup";
import TemplateEditor from "./components/tabs/messages-page/all-templates/TemplateEditor";
import CampaignsDetail from "./components/tabs/campaigns-page/CampaignsDetail";
import CampaignsNew from "./components/tabs/campaigns-page/CampaignsNew";
import CampaignsOverview from "./components/tabs/campaigns-page/tabs/overview/CampaignsOverview";
import CampaignsMessage from "./components/tabs/campaigns-page/tabs/message/CampaignsMessage";
import CampaignsTracking from "./components/tabs/campaigns-page/tabs/tracking/CampaignTracking";
import CampaignsApplication from "./components/tabs/campaigns-page/tabs/application/CampaignsApplication";
import ListsAuto from "./components/tabs/lists-page/main/ListsAuto";
import Products from "./components/tabs/products-page/Products";
import ProductDetails from "./components/tabs/products-page/all-components/ProductDetails";
import AllProducts from "./components/tabs/products-page/all-components/AllProducts";
import AddProduct from "./components/tabs/products-page/all-components/AddProduct";

const theme = extendTheme({
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Poppins, sans-serif",
  },
  colors: {
    blue: {
      300: "#b0e6eb",
      400: "#66cbd4",
      500: "#5cb5bd",
      600: "#44939a",
    },
    gray: {
      100: "#f5f5f5",
      200: "#eeeeee"
    }
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Box>
            <Routes>
              <Route path="/" element={<Navigate to="/login" replace />} />
              <Route path="/login" element={<DirectRoute><Login/></DirectRoute>} />
              <Route path="/password-reset" element={<PasswordReset/>} />
              <Route path="/signup" element={<Signup/>} />
              <Route path="/dashboard" element={<ProtectedRoute><Dashboard/></ProtectedRoute>}>
                <Route index element={<Navigate to="search" replace />} />
                <Route path="data" element={<Data/>} />
                <Route path="products" element={<Products />}>
                  <Route index element={<AllProducts />} />
                  <Route path="add" element={<AddProduct />} />
                  <Route path=":id" element={<ProductDetails />} />
                </Route>
                <Route path="search" element={<Search/>} />
                <Route path="messages" element={<Messages/>}>
                  <Route index element={<Navigate to="templates" replace />} />
                  {/* <Route path="all" element={<AllMessages />} /> */}
                  <Route path="templates" element={<Templates />} />
                  <Route path="templates/:templateId" element={<TemplateEditor />} />
                  {/* <Route path=":messagesId" element={<MessagesGroup />} /> */}
                </Route>
                <Route path="campaigns" element={<Campaigns/>} />
                <Route path="campaigns/new" element={<CampaignsNew/>} />
                <Route path="campaigns/:campaignId" element={<CampaignsDetail/>}>
                  <Route index element={<Navigate to="overview" replace />} />
                  <Route path="overview" element={<CampaignsOverview />} />
                  <Route path="messages" element={<CampaignsMessage />} />
                  <Route path="tracking" element={<CampaignsTracking />} />
                  <Route path="applications" element={<CampaignsApplication />} />
                </Route>
                <Route path="lists" element={<Lists/>} />
                <Route path="lists/:groupId" element={<ListsGroup/>} />
                <Route path="lists/:groupId/auto" element={<ListsAuto/>} />
                <Route path="lists/:groupId/:listId" element={<ListsMain />} />
                <Route path="settings" element={<Settings />}>
                  <Route index element={<Navigate to="profile" replace />} />
                  <Route path="profile" element={<SettingsProfile />} />
                  <Route path="workspace" element={<SettingsWorkspace />} />
                </Route>
              </Route>
            </Routes>
          </Box>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
