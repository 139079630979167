import React, { useEffect, useState, useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Checkbox,
  VStack,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  HStack,
  useClipboard,
  Tooltip,
  Flex,
  Text,
  Center,
  useToast,
} from "@chakra-ui/react";
import { addDoc, collection, query, where, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../../../../Firebase";
import { CopyIcon, ExternalLinkIcon, DeleteIcon } from "@chakra-ui/icons";
import { FiShare, FiPlus } from "react-icons/fi";

const LinkModal = ({ isOpen, onClose, workspaceId, groupId, listId }) => {
  const [sharedLists, setSharedLists] = useState([]);
  const toast = useToast();

  const fetchSharedLists = useCallback(async () => {
    try {
      const q = query(collection(db, "share"), where("listId", "==", listId));
      const querySnapshot = await getDocs(q);
      const lists = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        allowEdit: doc.data().allowEdit || {},
      }));
      setSharedLists(lists);
    } catch (error) {
      console.error("Error fetching shared lists: ", error);
    }
  }, [listId]);

  useEffect(() => {
    if (listId) {
      fetchSharedLists();
    }
  }, [listId, fetchSharedLists]);

  const createNewShareUrl = async () => {
    try {
      const shareCollectionRef = collection(db, "share");
      const newVisibleColumns = {
        listImage: false,
        description: false,
        creator: false,
        category: false,
        IGFollowers: false,
        TikTokFollowers: false,
        "hoo.be Link": false,
        engagementRate: false,
        IGReelsImpressions: false,
        IGStoriesImpressions: false,
        IGPostImpressions: false,
        TikTokPostImpressions: false,
        expectedImpressions: false,
        deliverables: false,
        favorite: false,
        notes: false,
      };
      const newAllowEdit = {
        favorite: false,
        notes: false,
        deliverables: false,
      };
      await addDoc(shareCollectionRef, {
        visibleColumns: newVisibleColumns,
        allowEdit: newAllowEdit,
        workspaceId,
        groupId,
        listId,
        createdAt: new Date(),
      });
      fetchSharedLists();
    } catch (error) {
      console.error("Error creating new share URL: ", error);
    }
  };

  const updateShareSettings = async (shareId, updatedSettings) => {
    try {
      const shareDocRef = doc(db, "share", shareId);
      await updateDoc(shareDocRef, updatedSettings);
      fetchSharedLists();
    } catch (error) {
      console.error("Error updating share settings: ", error);
    }
  };

  const deleteShareUrl = async (shareId) => {
    try {
      await deleteDoc(doc(db, "share", shareId));
      fetchSharedLists();
    } catch (error) {
      console.error("Error deleting share URL: ", error);
    }
  };

  const handleCheckboxChange = (shareId, column) => {
    const updatedSharedLists = sharedLists.map((list) => {
      if (list.id === shareId) {
        const updatedVisibleColumns = {
          ...list.visibleColumns,
          [column]: !list.visibleColumns[column],
        };
        const updatedAllowEdit = { ...list.allowEdit };
        
        // If the column is no longer visible, set its allowEdit to false instead of removing it
        if (!updatedVisibleColumns[column]) {
          updatedAllowEdit[column] = false;
        }
        
        updateShareSettings(shareId, { visibleColumns: updatedVisibleColumns, allowEdit: updatedAllowEdit });
        return { ...list, visibleColumns: updatedVisibleColumns, allowEdit: updatedAllowEdit };
      }
      return list;
    });
    setSharedLists(updatedSharedLists);
  };

  const handleAllowEditChange = (shareId, field) => {
    const updatedSharedLists = sharedLists.map((list) => {
      if (list.id === shareId) {
        const updatedAllowEdit = {
          ...list.allowEdit,
          [field]: !list.allowEdit[field],
        };
        const allowedFields = ['favorite', 'notes', 'deliverables'];
        Object.keys(updatedAllowEdit).forEach(key => {
          if (!allowedFields.includes(key)) {
            delete updatedAllowEdit[key];
          }
        });
        updateShareSettings(shareId, { allowEdit: updatedAllowEdit });
        return { ...list, allowEdit: updatedAllowEdit };
      }
      return list;
    });
    setSharedLists(updatedSharedLists);
  };

  const columnGroups = [
    {
      title: "Basic Info",
      columns: ["listImage", "description", "creator", "category", "IGFollowers", "TikTokFollowers", "hoo.be Link"],
    },
    {
      title: "Engagement",
      columns: ["IGReelsImpressions", "IGStoriesImpressions", "IGPostImpressions", "engagementRate", "TikTokPostImpressions", "expectedImpressions"],
    },
    {
      title: "Additional Info",
      columns: ["deliverables", "favorite", "notes"],
    },
  ];

  const ShareUrlActions = ({ shareId }) => {
    const shareUrl = `https://share.hoobe.me/lists/${shareId}`;
    const { onCopy } = useClipboard(shareUrl);

    const handleCopy = () => {
      onCopy();
      toast({
        title: "URL Copied",
        description: "The share URL has been copied to your clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    };

    return (
      <HStack spacing={2}>
        <Tooltip label="Copy URL">
          <Button
            leftIcon={<CopyIcon />}
            onClick={handleCopy}
            size="sm"
            borderWidth="1px"
            borderRadius="xl"
            bg="white"
          >
            Copy URL
          </Button>
        </Tooltip>
        <Tooltip label="Open external URL">
          <Button
            as="a"
            href={shareUrl}
            target="_blank"
            rel="noopener noreferrer"
            leftIcon={<ExternalLinkIcon />}
            size="sm"
            borderWidth="1px"
            borderRadius="xl"
            bg="white"
          >
            External URL
          </Button>
        </Tooltip>
        <Tooltip label="Delete share URL">
          <Button
            leftIcon={<DeleteIcon />}
            onClick={() => deleteShareUrl(shareId)}
            size="sm"
            borderWidth="1px"
            borderRadius="xl"
            colorScheme="red"
          >
            Delete URL
          </Button>
        </Tooltip>
      </HStack>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent minHeight="50vh">
        <ModalHeader>
          <Flex align="center" h="30px">
            <FiShare style={{ marginRight: '10px' }} />
            <Text mr="20px">Share</Text>
            {sharedLists.length > 0 && (
              <Button 
                leftIcon={<FiPlus />} 
                onClick={createNewShareUrl} 
                borderWidth="1px" 
                borderRadius="xl" 
                bg="white" 
                size="sm"
                alignSelf="flex-start"
              >
                Create Share URL
              </Button>
            )}
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDirection="column">
          {sharedLists.length === 0 ? (
            <Center flex={1}>
              <Button 
                leftIcon={<FiPlus />} 
                onClick={createNewShareUrl} 
                borderWidth="1px" 
                borderRadius="xl" 
                bg="white" 
                size="lg"
              >
                Create New Share URL
              </Button>
            </Center>
          ) : (
            <Accordion allowMultiple flex={1} overflowY="auto">
              {sharedLists.map((share) => (
                <AccordionItem
                  key={share.id}
                  mb={4}
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="md"
                  overflow="hidden"
                >
                  <h2>
                    <AccordionButton _hover={{ bg: "gray.100" }}>
                      <Box flex="1" textAlign="left" fontWeight="medium">
                        Created: {new Date(share.createdAt.seconds * 1000).toLocaleString()}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} bg="white">
                    <VStack align="start" spacing={4}>
                      <SimpleGrid columns={3} spacing={4} width="100%">
                        {columnGroups.map((group, index) => (
                          <VStack key={index} align="start">
                            <Text fontWeight="bold">{group.title}</Text>
                            {group.columns.map((column) => (
                              <VStack key={column} align="start">
                                <Checkbox
                                  isChecked={share.visibleColumns[column]}
                                  onChange={() => handleCheckboxChange(share.id, column)}
                                >
                                  {column.charAt(0).toUpperCase() +
                                    column.slice(1).replace(/([A-Z])/g, " $1").trim()}
                                </Checkbox>
                                {(['favorite', 'notes', 'deliverables'].includes(column)) && (
                                  <Checkbox
                                    isChecked={share.allowEdit[column]}
                                    onChange={() => handleAllowEditChange(share.id, column)}
                                    isDisabled={!share.visibleColumns[column]}
                                  >
                                    Allow edit {column}
                                  </Checkbox>
                                )}
                              </VStack>
                            ))}
                          </VStack>
                        ))}
                      </SimpleGrid>
                      <HStack justify="space-between" width="100%">
                        <ShareUrlActions shareId={share.id} />
                      </HStack>
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LinkModal;