// CampaignsEditApplication.js
import React, { useEffect, useState, useCallback } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Textarea,
  Input,
  Spinner,
  Alert,
  AlertIcon,
  Button,
  IconButton,
  Progress,
  Image as ChakraImage,
  Switch,
  Text,
} from "@chakra-ui/react";
import { doc, getDoc, writeBatch } from "firebase/firestore";
import { db } from "../../../Firebase";
import { formatISO, parseISO, isBefore } from "date-fns";
import CampaignsDatePicker from "./CampaignsDatePicker";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const CampaignsEditApplication = ({ isOpen, onClose, workspaceId, campaignId }) => {
  const [application, setApplication] = useState({
    title: "",
    description: "",
    image: "",
    hostHandle: "",
    discount: "",
    requirements: "",
    questions: [],
    status: false,
    startDate: null,
    endDate: null,
  });
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [imageUploadError, setImageUploadError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [initialData, setInitialData] = useState(null);

  // Added state for campaign image URL
  const [campaignImageURL, setCampaignImageURL] = useState("");

  const storage = getStorage();

  useEffect(() => {
    if (!isOpen) return;
    const fetchApplicationData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const campaignDocRef = doc(db, "workspaces", workspaceId, "campaigns", campaignId);
        const campaignSnapshot = await getDoc(campaignDocRef);
        if (campaignSnapshot.exists()) {
          const data = campaignSnapshot.data();
          const applicationData = data.application || {};

          const startDate = applicationData.startDate ? parseISO(applicationData.startDate) : null;
          const endDate = applicationData.endDate ? parseISO(applicationData.endDate) : null;

          setApplication({
            title: applicationData.title || "",
            description: applicationData.description || "",
            image: applicationData.image || "",
            hostHandle: applicationData.hostHandle || "",
            discount: applicationData.discount || "",
            requirements: applicationData.requirements || "",
            questions: applicationData.questions || [],
            status: applicationData.status || false,
            startDate: startDate,
            endDate: endDate,
          });

          setImagePreviewUrl(applicationData.image || "");

          // Set the campaign image URL
          setCampaignImageURL(data.imageURL || "");

          setInitialData({
            ...applicationData,
            startDate: startDate,
            endDate: endDate,
          });
        } else {
          setError("Campaign not found.");
        }
      } catch (err) {
        console.error("Error fetching application data:", err);
        setError("Failed to fetch application data.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchApplicationData();
  }, [isOpen, workspaceId, campaignId]);

  const handleFieldUpdate = useCallback(
    async (field, value) => {
      setError(null);
      try {
        const batch = writeBatch(db);
        const campaignDocRef = doc(db, "workspaces", workspaceId, "campaigns", campaignId);
        batch.update(campaignDocRef, { [`application.${field}`]: value });

        // Include the updated field value in applicationData
        const applicationData = {
          campaignId,
          workspaceId,
          updatedAt: new Date(),
          application: {
            ...application,
            [field]: value, // Override the field being updated
            startDate: application.startDate ? formatISO(application.startDate) : null,
            endDate: application.endDate ? formatISO(application.endDate) : null,
          },
          imageURL: campaignImageURL,
        };

        const applicationDocRef = doc(db, "applications", campaignId);
        batch.set(applicationDocRef, applicationData, { merge: true });
        await batch.commit();

        setInitialData((prev) => ({
          ...prev,
          [field]: field.includes("Date") ? (value ? parseISO(value) : null) : value,
        }));
      } catch (err) {
        console.error(`Error updating ${field}:`, err);
        setError(`Failed to update ${field}. Please try again.`);
      }
    },
    [workspaceId, campaignId, application, campaignImageURL]
  );

  const handleStartDateChange = (date) => {
    const selectedDate = date?.toDate() || null;
    setApplication((prevApplication) => ({
      ...prevApplication,
      startDate: selectedDate,
    }));
    handleFieldUpdate("startDate", selectedDate ? formatISO(selectedDate) : null);
    if (selectedDate && application.endDate && isBefore(application.endDate, selectedDate)) {
      setApplication((prevApplication) => ({
        ...prevApplication,
        endDate: null,
      }));
      handleFieldUpdate("endDate", null);
    }
  };

  const handleEndDateChange = (date) => {
    const selectedDate = date?.toDate() || null;
    setApplication((prevApplication) => ({
      ...prevApplication,
      endDate: selectedDate,
    }));
    handleFieldUpdate("endDate", selectedDate ? formatISO(selectedDate) : null);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setImageUploadError(null);
    setIsUploadingImage(true);
    try {
      const imageExtension = file.name.split(".").pop();
      const imagePath = `applications/${campaignId}/${Date.now()}.${imageExtension}`;
      const storageRef = ref(storage, imagePath);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setUploadProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        },
        (error) => {
          console.error("Image upload error:", error);
          setImageUploadError("Failed to upload image. Please try again.");
          setIsUploadingImage(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setApplication((prevApplication) => ({
            ...prevApplication,
            image: downloadURL,
          }));
          setImagePreviewUrl(downloadURL);
          handleFieldUpdate("image", downloadURL);
          setIsUploadingImage(false);
        }
      );
    } catch (error) {
      console.error("Image upload error:", error);
      setImageUploadError("Failed to upload image. Please try again.");
      setIsUploadingImage(false);
    }
  };

  const handleStatusToggle = async () => {
    const newStatus = !application.status;
    setApplication((prevApplication) => ({
      ...prevApplication,
      status: newStatus,
    }));
    handleFieldUpdate("status", newStatus);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay>
        <DrawerContent maxW="700px">
          {/* Removed DrawerCloseButton */}
          <DrawerHeader>
            <Flex align="center" justify="space-between" width="100%">
              <Heading size="lg" fontFamily="Poppins, sans-serif">
                Edit Application
              </Heading>
              <Flex align="center" gap={2}>
                <Text fontSize="sm" fontFamily="Poppins, sans-serif">
                  Application Status
                </Text>
                <Switch
                  isChecked={application.status}
                  onChange={handleStatusToggle}
                  colorScheme="teal"
                />
              </Flex>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            {isLoading ? (
              <Flex justify="center" align="center" height="200px">
                <Spinner size="lg" />
              </Flex>
            ) : error ? (
              <Alert status="error" borderRadius="md" mb={4}>
                <AlertIcon />
                {error}
              </Alert>
            ) : (
              <Flex direction="column" gap={4}>
                <FormControl>
                  <FormLabel fontFamily="Poppins, sans-serif">Application Title</FormLabel>
                  <Input
                    value={application.title}
                    onChange={(e) =>
                      setApplication((prev) => ({ ...prev, title: e.target.value }))
                    }
                    onBlur={() => {
                      if (application.title !== initialData?.title) {
                        handleFieldUpdate("title", application.title);
                      }
                    }}
                    placeholder="Enter application title"
                    fontFamily="Poppins, sans-serif"
                    size="md"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontFamily="Poppins, sans-serif">Application Description</FormLabel>
                  <Textarea
                    value={application.description}
                    onChange={(e) =>
                      setApplication((prev) => ({ ...prev, description: e.target.value }))
                    }
                    onBlur={() => {
                      if (application.description !== initialData?.description) {
                        handleFieldUpdate("description", application.description);
                      }
                    }}
                    placeholder="Enter application description"
                    fontFamily="Poppins, sans-serif"
                    size="md"
                    resize="vertical"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontFamily="Poppins, sans-serif">
                    Application Image (Recommended size: 1280x720px)
                  </FormLabel>
                  {imagePreviewUrl && (
                    <ChakraImage
                      src={imagePreviewUrl}
                      alt="Application Image"
                      borderRadius="md"
                      mb={2}
                      w="80px"
                    />
                  )}
                  <Input type="file" accept="image/*" onChange={handleImageUpload} />
                  {isUploadingImage && (
                    <Progress value={uploadProgress} size="sm" mt={2} hasStripe isAnimated />
                  )}
                  {imageUploadError && (
                    <Alert status="error" borderRadius="md" mt={2}>
                      <AlertIcon />
                      {imageUploadError}
                    </Alert>
                  )}
                </FormControl>

                <CampaignsDatePicker
                  campaignStartDate={application.startDate}
                  campaignEndDate={application.endDate}
                  onStartDateChange={handleStartDateChange}
                  onEndDateChange={handleEndDateChange}
                />

                <Flex direction="row" gap={6}>
                  <FormControl flex="1">
                    <FormLabel fontFamily="Poppins, sans-serif">Offer Host Handle</FormLabel>
                    <Input
                      value={application.hostHandle}
                      onChange={(e) =>
                        setApplication((prev) => ({ ...prev, hostHandle: e.target.value }))
                      }
                      onBlur={() => {
                        if (application.hostHandle !== initialData?.hostHandle) {
                          handleFieldUpdate("hostHandle", application.hostHandle);
                        }
                      }}
                      placeholder="Enter offer host handle"
                      fontFamily="Poppins, sans-serif"
                      size="md"
                    />
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel fontFamily="Poppins, sans-serif">Discount</FormLabel>
                    <Input
                      value={application.discount}
                      onChange={(e) =>
                        setApplication((prev) => ({ ...prev, discount: e.target.value }))
                      }
                      onBlur={() => {
                        if (application.discount !== initialData?.discount) {
                          handleFieldUpdate("discount", application.discount);
                        }
                      }}
                      placeholder="Enter discount"
                      fontFamily="Poppins, sans-serif"
                      size="md"
                    />
                  </FormControl>
                </Flex>

                <FormControl>
                  <FormLabel fontFamily="Poppins, sans-serif">Application Requirements</FormLabel>
                  <Textarea
                    value={application.requirements}
                    onChange={(e) =>
                      setApplication((prev) => ({ ...prev, requirements: e.target.value }))
                    }
                    onBlur={() => {
                      if (application.requirements !== initialData?.requirements) {
                        handleFieldUpdate("requirements", application.requirements);
                      }
                    }}
                    placeholder="Enter application requirements"
                    fontFamily="Poppins, sans-serif"
                    size="md"
                    resize="vertical"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontFamily="Poppins, sans-serif">Application Questions</FormLabel>
                  {application.questions.map((question, index) => (
                    <Flex key={index} alignItems="center" mb={2}>
                      <Input
                        value={question}
                        onChange={(e) => {
                          const newQuestions = [...application.questions];
                          newQuestions[index] = e.target.value;
                          setApplication((prev) => ({
                            ...prev,
                            questions: newQuestions,
                          }));
                        }}
                        onBlur={() => {
                          if (
                            JSON.stringify(application.questions) !==
                            JSON.stringify(initialData?.questions)
                          ) {
                            handleFieldUpdate("questions", application.questions);
                          }
                        }}
                        placeholder={`Question ${index + 1}`}
                        fontFamily="Poppins, sans-serif"
                        size="md"
                        flex="1"
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => {
                          const newQuestions = application.questions.filter((_, i) => i !== index);
                          setApplication((prev) => ({
                            ...prev,
                            questions: newQuestions,
                          }));
                          handleFieldUpdate("questions", newQuestions);
                        }}
                        colorScheme="red"
                        ml={2}
                        aria-label="Delete question"
                      />
                    </Flex>
                  ))}
                  <Button
                    leftIcon={<AddIcon />}
                    onClick={() => {
                      const newQuestions = [...application.questions, ""];
                      setApplication((prev) => ({
                        ...prev,
                        questions: newQuestions,
                      }));
                    }}
                    variant="outline"
                    mt={2}
                    fontFamily="Poppins, sans-serif"
                  >
                    Add Question
                  </Button>
                </FormControl>

                {error && (
                  <Alert status="error" borderRadius="md">
                    <AlertIcon />
                    {error}
                  </Alert>
                )}
              </Flex>
            )}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default CampaignsEditApplication;
