// Affiliates.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Text, Input, Box, VStack } from '@chakra-ui/react';

const Affiliates = ({ initialAffiliateCode, initialAffiliateAmount, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [affiliateCode, setAffiliateCode] = useState(initialAffiliateCode || '');
  const [affiliateAmount, setAffiliateAmount] = useState(initialAffiliateAmount || '');
  const codeInputRef = useRef(null);
  const amountInputRef = useRef(null);

  // Update affiliateCode when initialAffiliateCode changes
  useEffect(() => {
    setAffiliateCode(initialAffiliateCode || '');
  }, [initialAffiliateCode]);

  // Update affiliateAmount when initialAffiliateAmount changes
  useEffect(() => {
    setAffiliateAmount(initialAffiliateAmount || '');
  }, [initialAffiliateAmount]);

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleCodeChange = (e) => {
    setAffiliateCode(e.target.value);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and decimal point, up to two decimal places
    const regex = /^\d*\.?\d{0,2}$/;
    if (value === '' || regex.test(value)) {
      setAffiliateAmount(value);
    }
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    // Convert affiliateAmount to a number if possible
    const numericAmount = parseFloat(affiliateAmount) || '';
    setAffiliateAmount(numericAmount.toString());
    onChange(affiliateCode, numericAmount);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleInputBlur();
    }
  };

  useEffect(() => {
    if (isEditing && codeInputRef.current) {
      codeInputRef.current.focus();
    }
  }, [isEditing]);

  const formatPercentage = (value) => {
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) return '';
    return `${numericValue}%`;
  };

  return (
    <>
      {isEditing ? (
        <VStack spacing={2}>
          <Input
            ref={codeInputRef}
            value={affiliateCode}
            onChange={handleCodeChange}
            placeholder="Code"
            size="sm"
            borderRadius="md"
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
            w="150px"
          />
          <Input
            ref={amountInputRef}
            value={affiliateAmount}
            onChange={handleAmountChange}
            placeholder="Percentage"
            size="sm"
            borderRadius="md"
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
            w="150px"
          />
        </VStack>
      ) : (
        <Box onClick={handleTextClick} cursor="text" w="150px">
          <Text mb={2} color={affiliateCode ? 'black' : 'gray.500'} isTruncated>
            {affiliateCode || 'Add code...'}
          </Text>
          <Text color={affiliateAmount ? 'black' : 'gray.500'} isTruncated>
            {affiliateAmount ? formatPercentage(affiliateAmount) : 'Add percentage...'}
          </Text>
        </Box>
      )}
    </>
  );
};

export default Affiliates;
