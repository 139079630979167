import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Text,
  Spinner,
  Container,
  Center,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../Firebase";

function PasswordReset() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      toast({
        title: "Password reset email sent",
        description: "Please check your email for further instructions.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      bg="gray.200"
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Container maxWidth="500px">
        <Box bg="white" p={8} borderRadius="xl" boxShadow="md">
          <VStack spacing={4} align="stretch">
            <Center>
              <Heading>Reset Password</Heading>
            </Center>
            <form onSubmit={handlePasswordReset}>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                />
              </FormControl>
              <Button
                type="submit"
                bg="gray.800"
                color="white"
                size="lg"
                width="full"
                _hover={{ bg: "black" }}
                mt={4}
                isLoading={isLoading}
                loadingText="Sending"
                spinner={<Spinner size="sm" />}
                disabled={isLoading}
              >
                Send Reset Link
              </Button>
            </form>
            <Center>
              <Text>
                Remember your password?{" "}
                <Link to="/login">
                  Login
                </Link>
              </Text>
            </Center>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
}

export default PasswordReset;
