// ImportProductModal.js
import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../../../Firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

const ImportProductModal = ({ isOpen, onClose, workspaceData }) => {
  const [productId, setProductId] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleAddProduct = async () => {
    if (!productId.trim()) {
      console.error('Product ID is required');
      return;
    }

    setLoading(true);

    try {
      const productsRef = collection(
        db,
        'workspaces',
        workspaceData.workspace_id,
        'products'
      );

      // Add product to Firebase
      const firebaseDoc = await addDoc(productsRef, {
        shopifyProductId: productId,
        createdAt: serverTimestamp(),
      });
      console.log('Product added to Firebase:', firebaseDoc.id);

      // Close the modal
      onClose();

      // Navigate to the product page
      navigate(`/dashboard/products/${firebaseDoc.id}`);
    } catch (error) {
      console.error('Error adding product to Firebase:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel>Shopify Product ID</FormLabel>
            <Input
              placeholder="Enter Shopify Product ID"
              value={productId}
              onChange={(e) => setProductId(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleAddProduct}
            isLoading={loading}
          >
            Add
          </Button>
          <Button onClick={onClose} isDisabled={loading}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImportProductModal;
