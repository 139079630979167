// Column.js
import React, { useState, useRef } from 'react';
import {
  Button,
  Icon,
  Box,
  Checkbox,
  VStack,
  useOutsideClick,
} from '@chakra-ui/react';
import { FiColumns } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';

const ColumnDropdown = ({ isOpen, visibleColumns, handleCheckboxChange }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        transition={{ duration: 0.2 }}
        style={{
          position: 'absolute',
          top: '50px',
          right: 0,
          zIndex: 1000,
          width: '250px',
        }}
      >
        <Box
          p={4}
          bg="white"
          borderRadius="md"
          borderWidth="1px"
          boxShadow="md"
        >
          <VStack align="start" spacing={2}>
            {Object.entries(visibleColumns).map(([columnKey, isVisible]) => (
              <Checkbox
                key={columnKey}
                isChecked={isVisible}
                onChange={() => handleCheckboxChange(columnKey)}
              >
                {columnKey}
              </Checkbox>
            ))}
          </VStack>
        </Box>
      </motion.div>
    )}
  </AnimatePresence>
);

const Column = ({ visibleColumns, setVisibleColumns }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  const handleCheckboxChange = (columnKey) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnKey]: !prev[columnKey],
    }));
  };

  return (
    <Box position="relative" ref={dropdownRef}>
      <Button
        size="md"
        color="white"
        bg="gray.800"
        leftIcon={<Icon as={FiColumns} />}
        _hover={{ bg: 'black' }}
        borderRadius="md"
        onClick={() => setIsOpen(!isOpen)}
        ml={2}
      >
        Columns
      </Button>
      <ColumnDropdown
        isOpen={isOpen}
        visibleColumns={visibleColumns}
        handleCheckboxChange={handleCheckboxChange}
      />
    </Box>
  );
};

export default Column;
