import React, { useState } from 'react';
import { Box, Flex, Button, Input, useDisclosure } from '@chakra-ui/react';
import {
  useLocation,
  useNavigate,
  Outlet,
  useOutletContext,
  useMatch,
} from 'react-router-dom';
import {
  TbPlus,
  TbSettings,
  TbUserPlus,
  TbTemplate,
  // TbMessageCircle,
} from 'react-icons/tb'; // Ensure you have installed react-icons
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../Firebase';
import AddCreatorsModal from './all-message/modals/AddCreatorsModal';
import SettingsModal from './all-message/modals/SettingsModal';
import TemplatesModal from './all-message/modals/TemplatesModal';

const Messages = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData, workspaceData } = useOutletContext();
  const [searchText, setSearchText] = useState('');

  // Array of top items with icons
  const topItems = [
    // {
    //   path: 'all',
    //   label: 'All Messages',
    //   icon: <TbMessageCircle />, // Uncomment and use when needed
    // },
    {
      path: 'templates',
      label: 'Templates',
      icon: <TbTemplate />, // Icon for Templates
    },
  ];

  const messageMatch = useMatch('/dashboard/messages/:messageId');

  const isSpecificMessage =
    messageMatch &&
    messageMatch.params.messageId !== 'all' &&
    messageMatch.params.messageId !== 'templates';

  const {
    isOpen: isAddCreatorOpen,
    onOpen: onAddCreatorOpen,
    onClose: onAddCreatorClose,
  } = useDisclosure();

  const {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure();

  const {
    isOpen: isTemplatesOpen,
    onOpen: onTemplatesOpen,
    onClose: onTemplatesClose,
  } = useDisclosure();

  const handleNewItem = async () => {
    const isTemplate = location.pathname.includes('templates');
    try {
      const collectionName = isTemplate ? 'templates' : 'messages';
      const collectionRef = collection(
        db,
        'workspaces',
        workspaceData.workspace_id,
        collectionName
      );

      // Set 'order' to current timestamp
      const newDoc = await addDoc(collectionRef, {
        createdAt: serverTimestamp(),
        name: '',
        order: Date.now(),
        type: "email"
      });

      if (isTemplate) {
        navigate(`/dashboard/messages/templates/${newDoc.id}`);
      } else {
        navigate(`/dashboard/messages/${newDoc.id}`);
      }
    } catch (error) {
      console.error(
        `Error creating new ${isTemplate ? 'template' : 'message group'}:`,
        error
      );
    }
  };

  const showSearchInput =
    location.pathname.endsWith('all') ||
    location.pathname.includes('templates');

  return (
    <Box>
      <Flex
        align="center"
        justify="space-between"
        borderBottom="2px"
        borderColor="gray.200"
        h="60px"
        px="4"
      >
        <Flex>
          {topItems.map(({ path, label, icon }) => (
            <Button
              key={path}
              borderWidth="1px"
              bg="white"
              size="sm"
              onClick={() => navigate(path)}
              leftIcon={icon} // Added leftIcon prop
              mr="2" // Added margin for spacing between buttons
            >
              {label}
            </Button>
          ))}
        </Flex>
        <Flex align="center">
          {showSearchInput && (
            <Input
              placeholder={`Search ${
                location.pathname.includes('templates') ? 'templates' : 'messages'
              }...`}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              size="sm"
              w="200px"
              mr="2"
              borderRadius="md"
            />
          )}

          {isSpecificMessage && (
            <Flex mr="2">
              <Button
                size="sm"
                variant="outline"
                borderWidth="1px"
                bg="white"
                leftIcon={<TbUserPlus />}
                onClick={onAddCreatorOpen}
                mr="2"
              >
                Add Creators
              </Button>
              <Button
                size="sm"
                variant="outline"
                borderWidth="1px"
                bg="white"
                leftIcon={<TbTemplate />}
                onClick={onTemplatesOpen}
                mr="2"
              >
                Templates
              </Button>
              <Button
                size="sm"
                variant="outline"
                borderWidth="1px"
                bg="white"
                leftIcon={<TbSettings />}
                onClick={onSettingsOpen}
              >
                Settings
              </Button>
            </Flex>
          )}

          <Button
            w="150px"
            size="sm"
            borderWidth="1px"
            bg="white"
            onClick={handleNewItem}
            leftIcon={<TbPlus />}
          >
            New {location.pathname.includes('templates') ? 'Template' : 'Message'}
          </Button>
        </Flex>
      </Flex>
      <Outlet context={{ userData, workspaceData, searchText }} />

      {/* Modals */}
      <AddCreatorsModal isOpen={isAddCreatorOpen} onClose={onAddCreatorClose} />
      <SettingsModal isOpen={isSettingsOpen} onClose={onSettingsClose} />
      <TemplatesModal isOpen={isTemplatesOpen} onClose={onTemplatesClose} />
    </Box>
  );
};

export default Messages;
