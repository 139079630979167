import React, { useState, useEffect } from 'react';
import { useParams, useOutletContext } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../../../Firebase'; // Adjust this import path as needed
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  List,
  ListItem,
  IconButton,
  Flex,
  Text,
  VStack,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';

const TemplatesModal = ({ isOpen, onClose }) => {
  const { messagesId } = useParams();
  const { workspaceData } = useOutletContext();
  const [messageData, setMessageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!workspaceData.workspace_id || !messagesId) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    const docRef = doc(
      db,
      "workspaces",
      workspaceData.workspace_id,
      "messages",
      messagesId
    );

    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const docData = docSnap.data();
          setMessageData({ id: docSnap.id, ...docData });
        } else {
          setError("No such document!");
          setMessageData(null);
        }
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching message data:", error);
        setError(error.message);
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [messagesId, workspaceData.workspace_id]);

  const handleEdit = (id) => {
    console.log(`Edit template with id: ${id}`);
  };

  const handleDelete = (id) => {
    console.log(`Delete template with id: ${id}`);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manage Templates</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading && <Text>Loading message data...</Text>}
          {error && <Text color="red.500">Error: {error}</Text>}
          {!isLoading && !error && messageData && (
            <VStack spacing={4} align="stretch">
              <Text fontWeight="bold">Message Data:</Text>
              <Text as="pre" fontSize="sm" whiteSpace="pre-wrap">
                {JSON.stringify(messageData, null, 2)}
              </Text>
              <Text fontWeight="bold">Templates:</Text>
              <List spacing={3}>
                {messageData.templates?.map((template) => (
                  <ListItem key={template.id}>
                    <Flex align="center" justify="space-between">
                      <Text>{template.name}</Text>
                      <Flex>
                        <IconButton
                          aria-label="Edit Template"
                          icon={<EditIcon />}
                          size="sm"
                          mr={2}
                          onClick={() => handleEdit(template.id)}
                        />
                        <IconButton
                          aria-label="Delete Template"
                          icon={<DeleteIcon />}
                          size="sm"
                          colorScheme="red"
                          onClick={() => handleDelete(template.id)}
                        />
                      </Flex>
                    </Flex>
                  </ListItem>
                ))}
              </List>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="green">Add New Template</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TemplatesModal;