import React, { useState } from 'react';
import {
  Box,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Input,
  useToast,
  Progress,
} from '@chakra-ui/react';
import { getFirestore, doc, runTransaction } from 'firebase/firestore';
import { FormatNumber } from '../../../formatting/FormatNumber';

const CampaignsOverviewStats = ({ campaignData, workspaceId, campaignId }) => {
  const [editingField, setEditingField] = useState(null);
  const [fieldValue, setFieldValue] = useState('');
  const toast = useToast();

  if (!campaignData) {
    return null;
  }

  const totalCreators = campaignData.creators ? campaignData.creators.length : 0;
  const activeCreators = campaignData.creators
    ? campaignData.creators.filter(
        (creator) => creator.status !== 'None' && creator.status !== 'Rejected'
      ).length
    : 0;

  const allocatedBudget = campaignData.creators
    ? campaignData.creators
        .filter(
          (creator) => creator.status !== 'None' && creator.status !== 'Rejected'
        )
        .reduce((sum, creator) => {
          const offer = Number(creator.offer);
          return sum + (isNaN(offer) ? 0 : offer);
        }, 0)
    : 0;

  const totalBudget = Number(campaignData.budget) || 0;
  const cpm = Number(campaignData.cpm) || 0;

  const budgetProgress = totalBudget > 0 ? (allocatedBudget / totalBudget) * 100 : 0;
  const creatorsProgress = totalCreators > 0 ? (activeCreators / totalCreators) * 100 : 0;

  const calculateTotalExpectedImpressions = () => {
    if (!campaignData.creators) return 0;

    let totalImpressions = 0;

    campaignData.creators.forEach((creator) => {
      if (!creator.deliverables) return;

      const impressionsMapping = {
        'Instagram Story': Number(creator.instagram_projected_story_views) || 0,
        'Instagram Post': Number(creator.instagram_projected_post_views) || 0,
        'Instagram Reel': Number(creator.instagram_projected_reel_views) || 0,
        'TikTok Video': Number(creator.tiktok_projected_video_views) || 0,
      };

      creator.deliverables.forEach((deliverable) => {
        const { type, count } = deliverable;
        const projectedImpressionsPerType = impressionsMapping[type] || 0;
        totalImpressions += Number(count) * projectedImpressionsPerType;
      });
    });

    return totalImpressions;
  };

  const totalExpectedImpressions = calculateTotalExpectedImpressions();
  const campaignImpressions = cpm > 0 ? (totalBudget / cpm) * 1000 : 0;
  const impressionsProgress = campaignImpressions > 0
    ? (totalExpectedImpressions / campaignImpressions) * 100
    : 0;

  const getProgressColor = (value, threshold, isBudget = false) => {
    if (isBudget) {
      return value > 100 ? 'red' : 'blue';
    }
    return value >= 100 ? 'green' : 'blue';
  };

  const handleFieldClick = (field) => {
    setEditingField(field);
    setFieldValue(campaignData[field]);
  };

  const handleBlur = async () => {
    if (editingField !== null && fieldValue !== campaignData[editingField]) {
      const numericValue = Number(fieldValue);
      if (!isNaN(numericValue)) {
        await handleUpdate(editingField, numericValue);
      } else {
        toast({
          title: 'Invalid Input',
          description: `Please enter a valid number for ${editingField.toUpperCase()}.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setFieldValue(campaignData[editingField]);
      }
    }
    setEditingField(null);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const handleUpdate = async (field, value) => {
    if (!['cpm', 'budget'].includes(field)) {
      console.warn(`Unsupported field update attempt: ${field}`);
      return;
    }

    const db = getFirestore();
    const campaignRef = doc(db, 'workspaces', workspaceId, 'campaigns', campaignId);

    try {
      await runTransaction(db, async (transaction) => {
        const campaignDoc = await transaction.get(campaignRef);
        if (!campaignDoc.exists()) {
          throw new Error('Campaign does not exist!');
        }

        transaction.update(campaignRef, { [field]: value });
      });

      toast({
        title: 'Update Successful',
        description: `${field.toUpperCase()} updated to ${value}.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating campaign:', error);
      toast({
        title: 'Update Failed',
        description: `Could not update ${field}.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setFieldValue(campaignData[field]);
    }
  };

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 5 }} spacing={4}>
        <Box borderWidth="1px" borderRadius="md" p={4}>
          <Stat>
            <StatLabel>Budget</StatLabel>
            <StatNumber onClick={() => handleFieldClick('budget')} cursor="pointer">
              {editingField === 'budget' ? (
                <Input
                  value={fieldValue}
                  onChange={(e) => setFieldValue(e.target.value)}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  type="number"
                  autoFocus
                  size="sm"
                  width="100px"
                  min={0}
                  borderRadius="md"
                />
              ) : (
                '$' + FormatNumber(Number(campaignData.budget))
              )}
            </StatNumber>
          </Stat>
        </Box>

        <Box borderWidth="1px" borderRadius="md" p={4}>
          <Stat>
            <StatLabel>CPM</StatLabel>
            <StatNumber onClick={() => handleFieldClick('cpm')} cursor="pointer">
              {editingField === 'cpm' ? (
                <Input
                  value={fieldValue}
                  onChange={(e) => setFieldValue(e.target.value)}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  type="number"
                  autoFocus
                  size="sm"
                  width="100px"
                  min={0}
                />
              ) : (
                '$' + FormatNumber(Number(campaignData.cpm))
              )}
            </StatNumber>
          </Stat>
        </Box>

        <Box borderWidth="1px" borderRadius="md" p={4}>
          <Stat>
            <StatLabel>Expected Impressions</StatLabel>
            <StatNumber>
              {FormatNumber(totalExpectedImpressions)} /{' '}
              {FormatNumber(campaignImpressions)}
            </StatNumber>
            <Progress
              value={impressionsProgress}
              colorScheme={getProgressColor(impressionsProgress, 100)}
              size="sm"
              mt={2}
              borderRadius="md"
            />
          </Stat>
        </Box>

        <Box borderWidth="1px" borderRadius="md" p={4}>
          <Stat>
            <StatLabel>Allocated Budget</StatLabel>
            <StatNumber>
              {'$' + FormatNumber(allocatedBudget)} / {'$' + FormatNumber(totalBudget)}
            </StatNumber>
            <Progress
              value={budgetProgress}
              colorScheme={getProgressColor(budgetProgress, 100, true)}
              size="sm"
              mt={2}
              borderRadius="md"
            />
          </Stat>
        </Box>

        <Box borderWidth="1px" borderRadius="md" p={4}>
          <Stat>
            <StatLabel>Active Creators</StatLabel>
            <StatNumber>
              {activeCreators} / {totalCreators}
            </StatNumber>
            <Progress
              value={creatorsProgress}
              colorScheme={getProgressColor(creatorsProgress, 100)}
              size="sm"
              mt={2}
              borderRadius="md"
            />
          </Stat>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default CampaignsOverviewStats;