import React, { useEffect } from "react";
import {
  Select,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const Verified = ({ selectedVerified, setSelectedVerified }) => {

  useEffect(() => {
  }, [selectedVerified]);

  return (
    <InputGroup>
      <Select
        value={selectedVerified || ""}
        onChange={(e) => setSelectedVerified(e.target.value)}
      >
        <option value="" disabled hidden>
          Select verified status
        </option>
        <option value="yes">✅ Yes, is verified</option>
        <option value="no">❌ No, is not verified</option>
      </Select>

      {selectedVerified && (
        <InputRightElement width="2.5rem">
          <IconButton
            aria-label="Clear selection"
            icon={<CloseIcon />}
            size="sm"
            borderRadius="xl"
            bg="white"
            onClick={() => setSelectedVerified("")}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default Verified;
