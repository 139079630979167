import React, { useState, useEffect } from "react";
import { Text, Input, Box } from "@chakra-ui/react";

const Offer = ({ initialOffer, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [offer, setOffer] = useState(
    initialOffer !== undefined ? initialOffer : ""
  );

  // Add this useEffect to update offer when initialOffer changes
  useEffect(() => {
    setOffer(initialOffer !== undefined ? initialOffer : "");
  }, [initialOffer]);

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and decimal point
    const regex = /^\d*\.?\d{0,2}$/; // allows up to 2 decimal places
    if (value === "" || regex.test(value)) {
      setOffer(value);
    }
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    const numericValue = parseFloat(offer) || 0;
    const formattedValue = numericValue.toFixed(2);
    setOffer(formattedValue);
    onChange(numericValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleInputBlur();
    }
  };

  const formatPrice = (price) => {
    const parts = price.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  return (
    <Box>
      {isEditing ? (
        <Input
          value={offer}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          size="sm"
          width="80px"
          borderRadius="md"
          autoFocus
        />
      ) : (
        <Text
          onClick={handleTextClick}
          color={offer ? "black" : "gray.500"}
          cursor="pointer"
          width="80px"
        >
          {offer ? `$${formatPrice(parseFloat(offer).toFixed(2))}` : "$0.00"}
        </Text>
      )}
    </Box>
  );
};

export default Offer;
