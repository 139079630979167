// ContactSection.jsx
import React from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  Link,
  Icon,
  Divider,
  Button,
} from "@chakra-ui/react";
import { TbPhone, TbMail, TbLink } from "react-icons/tb";

const ContactSection = ({ creatorData, getDataOrDefault }) => {
  return (
    <Box>
      {/* Bio Links Section */}
      {creatorData.instagram_profile?.bio_links?.length > 0 && (
        <Box mb={4}>
          <Text
            fontSize="16px"
            fontWeight="500"
            color="var(--grays-black, #000)"
            textTransform="lowercase" // Make text lowercase
          >
            bio links
          </Text>
          <VStack spacing={0} padding={2} align="stretch" mt={2}>
            {creatorData.instagram_profile.bio_links.map((link, index) => (
              <Box key={index} px={2} py={1}>
                {" "}
                {/* Added padding */}
                <HStack align="center" spacing={2}>
                  <Icon as={TbLink} color="#166CBB" />
                  <Link href={link} color="#166CBB" isExternal>
                    {link}
                  </Link>
                </HStack>
                {index < creatorData.instagram_profile.bio_links.length - 1 && (
                  <Divider borderColor="gray.200" />
                )}
              </Box>
            ))}
          </VStack>
        </Box>
      )}

      {/* Email Addresses Section */}
      {creatorData.emails?.length > 0 && (
        <Box mb={4}>
          <Text
            fontFamily="Poppins"
            fontSize="16px"
            fontWeight="500"
            color="var(--grays-black, #000)"
            textTransform="lowercase" // Make text lowercase
          >
            email addresses
          </Text>
          <VStack spacing={0} align="stretch" mt={2}>
            {creatorData.emails.map((email, index) => (
              <Box key={index} px={2} py={1}>
                {" "}
                {/* Added padding */}
                <HStack align="center" spacing={2}>
                  <Icon as={TbMail} color="#166CBB" />
                  <Text
                    fontFamily="Poppins"
                    fontSize="14px"
                    fontWeight="400"
                    color="#166CBB"
                    lineHeight="20px"
                    textTransform="lowercase" // Make text lowercase
                  >
                    {getDataOrDefault(email.address)} (
                    {getDataOrDefault(email.where_it_came_from, "n/a")})
                  </Text>
                </HStack>
                {index < creatorData.emails.length - 1 && (
                  <Divider borderColor="gray.200" />
                )}
              </Box>
            ))}
          </VStack>
        </Box>
      )}

      {/* Phone Numbers Section */}
      {creatorData.phone_numbers?.length > 0 && (
        <Box mb={4}>
          <Text
            fontFamily="Poppins"
            fontSize="16px"
            fontWeight="500"
            color="var(--grays-black, #000)"
            textTransform="lowercase" // Make text lowercase
          >
            phone numbers
          </Text>
          <VStack spacing={0} align="stretch" mt={2}>
            {creatorData.phone_numbers.map((phone, index) => (
              <Box key={index} px={2} py={1}>
                {" "}
                {/* Added padding */}
                <HStack align="center" spacing={2}>
                  <Icon as={TbPhone} color="#166CBB" />
                  <Text
                    fontFamily="Poppins"
                    fontSize="14px"
                    fontWeight="400"
                    color="#166CBB"
                    lineHeight="20px"
                    textTransform="lowercase" // Make text lowercase
                  >
                    {getDataOrDefault(phone.number)} (
                    {getDataOrDefault(phone.where_it_came_from, "n/a")})
                  </Text>
                </HStack>
                {index < creatorData.phone_numbers.length - 1 && (
                  <Divider borderColor="gray.200" />
                )}
              </Box>
            ))}
          </VStack>
        </Box>
      )}

      {/* Keywords Section */}
      {creatorData.keywords?.length > 0 && (
        <Box>
          <Text
            fontFamily="Poppins"
            fontSize="16px"
            fontWeight="500"
            color="var(--grays-black, #000)"
            textTransform="lowercase" // Make text lowercase
          >
            keywords
          </Text>
          <HStack
            spacing={2}
            wrap="wrap"
            mt={2}
            align="center"
            display="flex"
            gap="4px"
          >
            {creatorData.keywords.map((keyword, index) => (
              <Button
                key={index}
                variant="outline"
                colorScheme="blue"
                size="sm"
                borderRadius="40"
                border="1px solid #54A7F4"
                textTransform="lowercase" // Make text lowercase
                cursor="default" // Prevent cursor change
                pointerEvents="none" // Disable clicking
                textColor={"#166CBB"}
              >
                {keyword}
              </Button>
            ))}
          </HStack>
        </Box>
      )}
    </Box>
  );
};

export default ContactSection;
