// ScrapeProductModal.js
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from '@chakra-ui/react';

const ScrapeProductModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Scrape Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>This is the Scrape Product modal content.</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          {/* You can add more actions here */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ScrapeProductModal;
