// CampaignBulkButton.jsx
import React, { useState, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Wrap,
  WrapItem,
  Box,
  HStack,
  IconButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useOutsideClick,
  Image,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
} from "@chakra-ui/react";
import { CloseIcon, AddIcon } from "@chakra-ui/icons";
import { FaDollarSign, FaPercentage } from "react-icons/fa";

// Importing the icon images
import instagramIcon from "../../../../../assets/instagrampost.png";
import instagramReel from "../../../../../assets/instagramreel.png";
import instagramStory from "../../../../../assets/instagramstory.png";
import tiktokVideo from "../../../../../assets/tiktok.png";

const CampaignBulkButton = ({
  isOpen,
  onClose,
  onBulkDeliverablesChange,
}) => {
  const [deliverables, setDeliverables] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const floatingRef = useRef(null);

  const [offerAmount, setOfferAmount] = useState(""); // State for offer amount
  const [percentageMultiplier, setPercentageMultiplier] = useState(""); // State for percentage multiplier

  // New states for affiliate code and percentage
  const [affiliateCode, setAffiliateCode] = useState("");
  const [affiliateAmount, setAffiliateAmount] = useState("");

  // Define the available deliverables with images
  const allDeliverables = [
    { name: "Instagram Story", icon: instagramStory },
    { name: "Instagram Reel", icon: instagramReel },
    { name: "Instagram Post", icon: instagramIcon },
    { name: "TikTok Video", icon: tiktokVideo },
  ];

  // Get icon for a deliverable
  const getIconForDeliverable = (deliverableName) => {
    const deliverable = allDeliverables.find(
      (d) => d.name === deliverableName
    );
    return deliverable ? deliverable.icon : null;
  };

  const handleSubmit = () => {
    // Pass deliverables, offerAmount, percentageMultiplier, affiliateCode, and affiliateAmount to the parent handler
    onBulkDeliverablesChange(
      deliverables,
      offerAmount,
      percentageMultiplier,
      affiliateCode,
      affiliateAmount
    );
    onClose();
  };

  const handleButtonClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleAddButtonClick = () => {
    setIsAdding(!isAdding);
  };

  const handleClose = () => {
    setExpandedIndex(null);
    setIsAdding(false);
  };

  const handleCountChange = (index, newCount) => {
    const updated = [...deliverables];
    updated[index].count = newCount;
    setDeliverables(updated);
  };

  const handleRemoveDeliverable = (index) => {
    const updated = deliverables.filter((_, i) => i !== index);
    setDeliverables(updated);
  };

  const handleSelectDeliverable = (deliverable) => {
    const newDeliverable = { type: deliverable.name, count: 1 };
    const updated = [...deliverables, newDeliverable];
    setDeliverables(updated);
    setIsAdding(false);
  };

  const availableDeliverables = allDeliverables.filter(
    (d) => !deliverables.some((ud) => ud.type === d.name)
  );

  useOutsideClick({
    ref: floatingRef,
    handler: handleClose,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Bulk Update</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="stretch" spacing={4}>
            {/* Offer Amount Input */}
            <FormControl>
              <FormLabel>Offer Amount</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaDollarSign} color="gray.500" />
                </InputLeftElement>
                <NumberInput
                  value={offerAmount}
                  onChange={(valueString) => setOfferAmount(valueString)}
                  min={0}
                  precision={2}
                  step={10}
                >
                  <NumberInputField
                    placeholder="Enter offer amount"
                    pl="10"
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </InputGroup>
            </FormControl>

            {/* Percentage Multiplier Input */}
            <FormControl>
              <FormLabel>Offer Percentage</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaPercentage} color="gray.500" />
                </InputLeftElement>
                <NumberInput
                  value={percentageMultiplier}
                  onChange={(valueString) =>
                    setPercentageMultiplier(valueString)
                  }
                  min={0}
                  max={500}
                  precision={2}
                  step={5}
                >
                  <NumberInputField
                    placeholder="Enter offer percentage"
                    pl="10"
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </InputGroup>
            </FormControl>

            {/* Affiliate Code Input */}
            <FormControl>
              <FormLabel>Affiliate Code</FormLabel>
              <Input
                value={affiliateCode}
                onChange={(e) => setAffiliateCode(e.target.value)}
                placeholder="Enter affiliate code"
              />
            </FormControl>

            {/* Affiliate Percentage Input */}
            <FormControl>
              <FormLabel>Affiliate Percentage</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaPercentage} color="gray.500" />
                </InputLeftElement>
                <NumberInput
                  value={affiliateAmount}
                  onChange={(valueString) => setAffiliateAmount(valueString)}
                  min={0}
                  max={100}
                  precision={2}
                  step={1}
                >
                  <NumberInputField
                    placeholder="Enter affiliate percentage"
                    pl="10"
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </InputGroup>
            </FormControl>

            {/* Deliverables Section */}
            <FormControl>
              <FormLabel>Deliverables</FormLabel>
              <Wrap spacing={2} maxW="340px">
                {deliverables.map((deliverable, index) => (
                  <WrapItem key={index} position="relative">
                    <Button
                      onClick={() => handleButtonClick(index)}
                      leftIcon={
                        <Image
                          boxSize="20px"
                          src={getIconForDeliverable(deliverable.type)}
                          alt={deliverable.type}
                        />
                      }
                      borderRadius="full"
                      borderWidth="1px"
                      bg="gray.50"
                    >
                      x{deliverable.count}
                    </Button>
                    {expandedIndex === index && (
                      <Box
                        ref={floatingRef}
                        position="absolute"
                        top="100%"
                        left="0"
                        bg="gray.50"
                        p={2}
                        borderRadius="xl"
                        borderWidth="1px"
                        boxShadow="lg"
                        zIndex={1000}
                        maxW="300px"
                        mt={2}
                      >
                        <HStack spacing={2}>
                          <NumberInput
                            value={deliverable.count}
                            onChange={(_, valueNumber) =>
                              handleCountChange(index, valueNumber)
                            }
                            min={1}
                            max={99}
                            size="sm"
                            width="80px"
                          >
                            <NumberInputField />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                          <IconButton
                            aria-label="Remove deliverable"
                            icon={<CloseIcon />}
                            size="sm"
                            onClick={() => handleRemoveDeliverable(index)}
                          />
                        </HStack>
                      </Box>
                    )}
                  </WrapItem>
                ))}
                <WrapItem position="relative">
                  <IconButton
                    aria-label="Add deliverable"
                    icon={<AddIcon color="gray.300" />}
                    size="sm"
                    borderRadius="full"
                    bg="white"
                    _hover={{ bg: "gray.100" }}
                    onClick={handleAddButtonClick}
                  />
                  {isAdding && availableDeliverables.length > 0 && (
                    <Box
                      ref={floatingRef}
                      position="absolute"
                      top="100%"
                      left="0"
                      bg="gray.50"
                      p={2}
                      borderRadius="xl"
                      boxShadow="lg"
                      zIndex={1000}
                      maxW="300px"
                      borderWidth="1px"
                      mt={2}
                    >
                      <VStack spacing={2}>
                        {availableDeliverables.map((deliverable, index) => (
                          <Button
                            key={index}
                            leftIcon={
                              <Image
                                boxSize="20px"
                                src={deliverable.icon}
                                alt={deliverable.name}
                              />
                            }
                            onClick={() =>
                              handleSelectDeliverable(deliverable)
                            }
                            size="sm"
                            width="100%"
                            borderWidth="1px"
                            borderRadius="full"
                          >
                            {deliverable.name}
                          </Button>
                        ))}
                      </VStack>
                    </Box>
                  )}
                </WrapItem>
              </Wrap>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Apply to Selected Creators
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CampaignBulkButton;
