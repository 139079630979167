import React, { useState, useEffect, useCallback } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Heading,
  Flex,
  Spacer,
  useToast,
  Textarea,
  Box,
  Tag,
  TagLabel,
  Image,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Checkbox,
  Stack,
  Text,
} from "@chakra-ui/react";
import instagramIcon from "../../../assets/instagrampost.png";
import { db } from "../../../Firebase";
import {
  collection,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";

const CampaignsAddCreator = ({ isOpen, onClose, workspaceId, campaignId }) => {
  const [inputText, setInputText] = useState("");
  const [instagramHandles, setInstagramHandles] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [lists, setLists] = useState([]);
  const [selectedListIds, setSelectedListIds] = useState([]); // Array of { groupId, listId }
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  // Fetch groups from Firestore
  const fetchGroups = useCallback(async () => {
    if (!workspaceId) return;
    try {
      const groupsRef = collection(db, "workspaces", workspaceId, "groups");
      const groupsSnapshot = await getDocs(query(groupsRef, orderBy("order")));
      const fetchedGroups = groupsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setGroups(fetchedGroups);
    } catch (error) {
      console.error("Error fetching groups:", error);
      toast({
        title: "Error",
        description: "Failed to fetch groups.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [workspaceId, toast]);

  // Fetch all lists for all groups
  const fetchAllLists = useCallback(async () => {
    if (!workspaceId || groups.length === 0) {
      setLists([]);
      return;
    }
    try {
      let allLists = [];
      for (const group of groups) {
        const listsRef = collection(db, "workspaces", workspaceId, "groups", group.id, "lists");
        const listsSnapshot = await getDocs(query(listsRef, orderBy("order")));
        const fetchedLists = listsSnapshot.docs.map(doc => ({
          id: doc.id,
          groupId: group.id,
          groupName: group.name,
          ...doc.data(),
        }));
        allLists = allLists.concat(fetchedLists);
      }
      setLists(allLists);
    } catch (error) {
      console.error("Error fetching lists:", error);
      toast({
        title: "Error",
        description: "Failed to fetch lists.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [workspaceId, groups, toast]);

  // Fetch groups and lists when the drawer opens
  useEffect(() => {
    if (isOpen) {
      fetchGroups();
      setSelectedGroupIds([]);
      setSelectedListIds([]);
      setLists([]);
      setInputText("");
      setInstagramHandles([]);
    }
  }, [isOpen, fetchGroups]);

  // Fetch all lists after groups are fetched
  useEffect(() => {
    if (groups.length > 0 && isOpen) {
      fetchAllLists();
    }
  }, [groups, fetchAllLists, isOpen]);

  // Extract Instagram handles from input text
  useEffect(() => {
    const extractHandles = () => {
      const urlRegex =
        /(?:https?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9._]+)|^([a-zA-Z0-9._]+)$/gim;
      const matches = [...inputText.matchAll(urlRegex)];
      const handles = matches.map((match) => match[1] || match[2]).filter(Boolean);
      setInstagramHandles(handles);
    };
    extractHandles();
  }, [inputText]);

  // Handle group checkbox toggle
  const handleGroupToggle = (groupId) => {
    const groupLists = lists.filter(list => list.groupId === groupId);
    const allGroupListsSelected = groupLists.every(list => selectedListIds.some(item => item.listId === list.id));

    if (allGroupListsSelected) {
      // Deselect the group: remove groupId and all its lists from selection
      setSelectedGroupIds(prev => prev.filter(id => id !== groupId));
      setSelectedListIds(prev => prev.filter(item => item.groupId !== groupId));
    } else {
      // Select the group: add groupId and all its lists to selection
      setSelectedGroupIds(prev => [...prev, groupId]);
      const newSelectedLists = groupLists
        .filter(list => !selectedListIds.some(item => item.listId === list.id))
        .map(list => ({ groupId, listId: list.id }));
      setSelectedListIds(prev => [...prev, ...newSelectedLists]);
    }
  };

  // Handle list checkbox toggle
  const handleListToggle = (groupId, listId) => {
    const listExists = selectedListIds.some(item => item.listId === listId);

    if (listExists) {
      // Deselect the list
      setSelectedListIds(prev => prev.filter(item => item.listId !== listId));

      // If all lists in the group are deselected, also deselect the group
      const groupLists = lists.filter(list => list.groupId === groupId);
      const remainingGroupLists = groupLists.filter(list => list.id !== listId);
      const allRemainingSelected = remainingGroupLists.every(list => selectedListIds.some(item => item.listId === list.id));
      if (!allRemainingSelected) {
        setSelectedGroupIds(prev => prev.filter(id => id !== groupId));
      }
    } else {
      // Select the list
      setSelectedListIds(prev => [...prev, { groupId, listId }]);

      // If all lists in the group are now selected, also select the group
      const groupLists = lists.filter(list => list.groupId === groupId);
      const allSelected = groupLists.every(list => 
        [...selectedListIds, { groupId, listId }].some(item => item.listId === list.id)
      );
      if (allSelected) {
        setSelectedGroupIds(prev => [...prev, groupId]);
      }
    }
  };

  // Determine if a group is fully selected, partially selected, or not selected
  const getGroupCheckboxState = (groupId) => {
    const groupLists = lists.filter(list => list.groupId === groupId);
    const selectedGroupListIds = groupLists.filter(list => selectedListIds.some(item => item.listId === list.id)).length;

    if (selectedGroupListIds === groupLists.length && groupLists.length > 0) {
      return { isChecked: true, isIndeterminate: false };
    } else if (selectedGroupListIds > 0 && selectedGroupListIds < groupLists.length) {
      return { isChecked: false, isIndeterminate: true };
    } else {
      return { isChecked: false, isIndeterminate: false };
    }
  };

  // Handle adding creators to the campaign
  const handleAddCreators = async () => {
    if (
      instagramHandles.length === 0 &&
      selectedGroupIds.length === 0 &&
      selectedListIds.length === 0
    ) {
      toast({
        title: "No Creators Selected",
        description:
          "Please enter Instagram handles or select groups/lists to add creators.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!workspaceId || !campaignId) {
      toast({
        title: "Missing Information",
        description: "Workspace ID or Campaign ID is missing.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);

    try {
      const payload = {
        workspaceId,
        campaignId,
        creators: instagramHandles,
        selectedGroups: selectedGroupIds,
        selectedLists: selectedListIds, // Array of { groupId, listId }
      };

      const response = await fetch(
        `${process.env.REACT_APP_HOOBE_API_URL}/campaigns-add-creators`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || `Error: ${response.statusText}`);
      }

      toast({
        title: "Creators Added",
        description:
          responseData.message ||
          "Selected creators have been successfully added.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Reset form
      setInputText("");
      setInstagramHandles([]);
      setSelectedGroupIds([]);
      setSelectedListIds([]);
      setLists([]);
      onClose();
    } catch (error) {
      console.error("Failed to add creators:", error);
      toast({
        title: "Error",
        description:
          error.message ||
          "There was an error adding the creators. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay>
        <DrawerContent maxW="700px">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading size="lg">Add Creators</Heading>
          </DrawerHeader>
          <DrawerBody>
            <VStack align="start" spacing={4}>
              {/* Instagram Handles Input */}
              <Text fontWeight="bold">Add creators with URL</Text>
              <Box width="100%">
                <Textarea
                  placeholder="Enter Instagram handles or URLs, one per line"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  h="200px"
                />
                {/* Display Extracted Instagram Handles */}
                {instagramHandles.length > 0 && (
                  <Box mt={4}>
                    <Flex wrap="wrap" gap={2}>
                      {instagramHandles.map((handle, index) => (
                        <Tag
                          key={index}
                          p={2}
                          borderRadius="full"
                          display="flex"
                          alignItems="center"
                        >
                          <Image
                            src={instagramIcon}
                            alt="Instagram"
                            boxSize="20px"
                            mr={2}
                          />
                          <TagLabel>@{handle}</TagLabel>
                        </Tag>
                      ))}
                    </Flex>
                  </Box>
                )}
              </Box>

              {/* Groups and Lists Selection */}
              <Box width="100%">
                <Text fontWeight="bold" mb={2}>Select from Groups and Lists</Text>
                <Accordion allowMultiple>
                  {groups.map((group) => {
                    const groupLists = lists.filter(list => list.groupId === group.id);
                    const { isChecked, isIndeterminate } = getGroupCheckboxState(group.id);
                    return (
                      <AccordionItem key={group.id}>
                        {({ isExpanded }) => (
                          <>
                            <h2>
                              <AccordionButton>
                                <Checkbox
                                  isChecked={isChecked}
                                  isIndeterminate={isIndeterminate}
                                  onChange={() => handleGroupToggle(group.id)}
                                  onClick={(e) => e.stopPropagation()} // Prevent Accordion toggle when clicking checkbox
                                  mr={2}
                                />
                                <Box flex="1" textAlign="left">
                                  {group.name}
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              {groupLists.length > 0 ? (
                                <Stack spacing={2}>
                                  {groupLists.map(list => (
                                    <Checkbox
                                      key={list.id}
                                      isChecked={selectedListIds.some(item => item.listId === list.id)}
                                      onChange={() => handleListToggle(list.groupId, list.id)}
                                    >
                                      {list.name}
                                    </Checkbox>
                                  ))}
                                </Stack>
                              ) : (
                                <Text>No lists available in this group.</Text>
                              )}
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </Box>
            </VStack>
          </DrawerBody>
          <Flex
            p={4}
            borderTop="1px solid #e2e8f0"
            bg="white"
            alignItems="center"
          >
            <Spacer />
            <Button
              colorScheme="blue"
              onClick={handleAddCreators}
              width="100%"
              isLoading={isLoading}
              loadingText="Adding"
            >
              Add Creators
            </Button>
          </Flex>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default CampaignsAddCreator;
