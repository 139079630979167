import React, { useState, useEffect } from 'react';
import { useParams, useOutletContext } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../../../Firebase'; // Adjust this import path as needed
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Stack,
  Box,
  Text,
  VStack,
} from '@chakra-ui/react';

const SettingsModal = ({ isOpen, onClose }) => {
  const { messagesId } = useParams();
  const { workspaceData } = useOutletContext();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!workspaceData.workspace_id || !messagesId) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    const docRef = doc(
      db,
      "workspaces",
      workspaceData.workspace_id,
      "messages",
      messagesId
    );

    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const docData = docSnap.data();
          setData({ id: docSnap.id, ...docData });
        } else {
          setError("No such document!");
          setData(null);
        }
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching message group:", error);
        setError(error.message);
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [messagesId, workspaceData.workspace_id]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Settings & Message Data</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={6}>
            {/* Settings Form */}
            <Box>
              <Text fontSize="lg" fontWeight="bold" mb={4}>Settings</Text>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Username</FormLabel>
                  <Input placeholder="Enter your username" />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="email-alerts" mb="0">
                    Enable Email Alerts
                  </FormLabel>
                  <Switch id="email-alerts" />
                </FormControl>
              </Stack>
            </Box>

            {/* Message Data Display */}
            <Box>
              <Text fontSize="lg" fontWeight="bold" mb={4}>Message Data</Text>
              {isLoading && <Text>Loading message data...</Text>}
              {error && <Text color="red.500">Error: {error}</Text>}
              {!isLoading && !error && data && (
                <VStack align="stretch" spacing={2} maxHeight="300px" overflowY="auto">
                  <Box p={2} bg="gray.100" borderRadius="md">
                    <Text as="pre" fontSize="sm" whiteSpace="pre-wrap">
                      {JSON.stringify(data, null, 2)}
                    </Text>
                  </Box>
                </VStack>
              )}
            </Box>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="blue">Save Settings</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SettingsModal;