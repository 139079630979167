// StatItem.jsx
import React from "react";
import { HStack, Image, Box, Text } from "@chakra-ui/react";

const StatItem = ({ imgSrc, label, value }) => (
  <HStack spacing={4} align="start" w="100%">
    <Image src={imgSrc} boxSize="24px" alt={`${label} icon`} />
    <Box textAlign="left" w="100%">
      <Text
        fontFamily="Poppins"
        fontSize="12px"
        fontWeight="500"
        color="var(--grays-dark, #51555C)"
      >
        {label}
      </Text>
      <Text fontSize="lg" fontWeight="bold">
        {value}
      </Text>
    </Box>
  </HStack>
);

export default StatItem;
