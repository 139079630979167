// CampaignMessagesSendAll.js

import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Text,
  Box,
  Flex,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { TbMail, TbPhone } from "react-icons/tb";

const CampaignMessagesSendAll = ({
  isOpen,
  onClose,
  workspaceId,
  campaignId,
  selectedCreators,
}) => {
  const toast = useToast();

  // Loading states for each button
  const [isSendingEmails, setIsSendingEmails] = useState(false);
  const [isSendingSMS, setIsSendingSMS] = useState(false);
  const [isSendingAll, setIsSendingAll] = useState(false);

  // Calculate total creators and counts for emails and phone numbers
  const totalCreators = selectedCreators.length;
  const emailCount = selectedCreators.filter(
    (creator) => creator.emails && creator.emails.length > 0
  ).length;
  const phoneCount = selectedCreators.filter(
    (creator) => creator.phoneNumbers && creator.phoneNumbers.length > 0
  ).length;

  const sendAllEmails = async () => {
    setIsSendingEmails(true);

    // Filter creators who have emails and required email data
    const emailCreators = selectedCreators.filter(
      (creator) =>
        creator.emails &&
        creator.emails.length > 0 &&
        creator.fromEmail &&
        creator.subject &&
        creator.emailMessage
    );

    if (emailCreators.length === 0) {
      toast({
        title: "No creators with complete email data.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      setIsSendingEmails(false);
      return;
    }

    // Prepare array of email items
    const emailItems = emailCreators.map((creator) => ({
      fromEmail: creator.fromEmail,
      toEmails: creator.emails,
      message: creator.emailMessage,
      subject: creator.subject,
      creatorId: creator.creatorId,
      workspaceId: workspaceId,
      campaignId: campaignId,
      name: creator.name,
      type: "email",
    }));

    // Send POST request
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOOBE_API_URL}/campaigns-message-all`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailItems),
        }
      );

      if (response.ok) {
        toast({
          title: "Emails sent successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        const errorData = await response.json();
        toast({
          title: "Failed to send emails.",
          description: errorData.message || "An error occurred.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error sending emails:", error);
      toast({
        title: "Error sending emails.",
        description: error.message || "An error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSendingEmails(false);
    }
  };

  const sendAllSMS = async () => {
    setIsSendingSMS(true);

    // Filter creators who have phone numbers and SMS messages
    const smsCreators = selectedCreators.filter(
      (creator) =>
        creator.phoneNumbers &&
        creator.phoneNumbers.length > 0 &&
        creator.smsMessage
    );

    if (smsCreators.length === 0) {
      toast({
        title: "No creators with complete SMS data.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      setIsSendingSMS(false);
      return;
    }

    // Prepare array of SMS items
    const smsItems = smsCreators.map((creator) => ({
      message: creator.smsMessage,
      phoneNumbers: creator.phoneNumbers,
      creatorId: creator.creatorId,
      workspaceId: workspaceId,
      campaignId: campaignId,
      name: creator.name,
      type: "sms",
    }));

    // Send POST request
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOOBE_API_URL}/campaigns-message-all`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(smsItems),
        }
      );

      if (response.ok) {
        toast({
          title: "SMS messages sent successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        const errorData = await response.json();
        toast({
          title: "Failed to send SMS messages.",
          description: errorData.message || "An error occurred.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error sending SMS messages:", error);
      toast({
        title: "Error sending SMS messages.",
        description: error.message || "An error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSendingSMS(false);
    }
  };

  const sendAll = async () => {
    setIsSendingAll(true);

    // Combine email and SMS creators
    const emailCreators = selectedCreators.filter(
      (creator) =>
        creator.emails &&
        creator.emails.length > 0 &&
        creator.fromEmail &&
        creator.subject &&
        creator.emailMessage
    );

    const smsCreators = selectedCreators.filter(
      (creator) =>
        creator.phoneNumbers &&
        creator.phoneNumbers.length > 0 &&
        creator.smsMessage
    );

    if (emailCreators.length === 0 && smsCreators.length === 0) {
      toast({
        title: "No creators with complete message data.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      setIsSendingAll(false);
      return;
    }

    const emailItems = emailCreators.map((creator) => ({
      fromEmail: creator.fromEmail,
      toEmails: creator.emails,
      message: creator.emailMessage,
      subject: creator.subject,
      creatorId: creator.creatorId,
      workspaceId: workspaceId,
      campaignId: campaignId,
      name: creator.name,
      type: "email",
    }));

    const smsItems = smsCreators.map((creator) => ({
      message: creator.smsMessage,
      phoneNumbers: creator.phoneNumbers,
      creatorId: creator.creatorId,
      workspaceId: workspaceId,
      campaignId: campaignId,
      name: creator.name,
      type: "sms",
    }));

    const allItems = [...emailItems, ...smsItems];

    // Send POST request
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOOBE_API_URL}/campaigns-message-all`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(allItems),
        }
      );

      if (response.ok) {
        toast({
          title: "Messages sent successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        const errorData = await response.json();
        toast({
          title: "Failed to send messages.",
          description: errorData.message || "An error occurred.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error sending messages:", error);
      toast({
        title: "Error sending messages.",
        description: error.message || "An error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSendingAll(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justify="space-between" align="center">
            <Text>Send Messages</Text>
            <Button
              variant="ghost"
              borderWidth="1px"
              onClick={onClose}
              leftIcon={<AiOutlineClose />}
            >
              Close
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody>
            <Box>
              <Flex align="center" mt={2}>
                <Text>
                  <strong>{emailCount} / {totalCreators}</strong> creators will be sent <strong>Emails</strong>
                </Text>
                <Spacer />
                <Button
                  borderWidth="1px"
                  leftIcon={<TbMail />}
                  bg="white"
                  w="180px"
                  size="md"
                  onClick={sendAllEmails}
                  isLoading={isSendingEmails}
                  isDisabled={isSendingEmails}
                >
                  Send All Email
                </Button>
              </Flex>
              <Flex align="center" mt={4}>
                <Text>
                <strong>{phoneCount} / {totalCreators}</strong> creators will be sent <strong>SMS Message</strong>
                </Text>
                <Spacer />
                <Button
                  borderWidth="1px"
                  leftIcon={<TbPhone />}
                  bg="white"
                  w="180px"
                  size="md"
                  onClick={sendAllSMS}
                  isLoading={isSendingSMS}
                  isDisabled={isSendingSMS}
                >
                  Send All SMS
                </Button>
              </Flex>
            </Box>
            <Box pt={10} pb={5}>
              <Button
                colorScheme="blue"
                width="100%"
                onClick={sendAll}
                isLoading={isSendingAll}
                isDisabled={isSendingAll}
              >
                Send All
              </Button>
            </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CampaignMessagesSendAll;
