import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useOutletContext } from 'react-router-dom';
import { doc, onSnapshot, collection, getDocs, getDoc } from 'firebase/firestore';
import { db } from '../../../../../Firebase';
import axios from 'axios';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  Select,
  Box,
  useToast,
} from '@chakra-ui/react';

const AddCreatorModal = ({ isOpen, onClose }) => {
  const { messagesId } = useParams();
  const { workspaceData } = useOutletContext();
  const toast = useToast();

  const [messageData, setMessageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [groups, setGroups] = useState([]);
  const [lists, setLists] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [selectedListId, setSelectedListId] = useState('');
  const [creators, setCreators] = useState([]);
  const [isAddingCreators, setIsAddingCreators] = useState(false);

  const fetchGroups = useCallback(async () => {
    try {
      const groupsCollectionRef = collection(db, "workspaces", workspaceData.workspace_id, "groups");
      const groupsSnapshot = await getDocs(groupsCollectionRef);
      const groupsData = groupsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setGroups(groupsData);
    } catch (error) {
      console.error("Error fetching groups:", error);
      setError("Failed to fetch groups");
    }
  }, [workspaceData.workspace_id]);

  const fetchLists = useCallback(async () => {
    if (!selectedGroupId) return;
    try {
      const listsCollectionRef = collection(db, "workspaces", workspaceData.workspace_id, "groups", selectedGroupId, "lists");
      const listsSnapshot = await getDocs(listsCollectionRef);
      const listsData = listsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setLists(listsData);
    } catch (error) {
      console.error("Error fetching lists:", error);
      setError("Failed to fetch lists");
    }
  }, [workspaceData.workspace_id, selectedGroupId]);

  const fetchCreators = useCallback(async () => {
    if (!selectedListId) return;
    try {
      const listDocRef = doc(db, "workspaces", workspaceData.workspace_id, "groups", selectedGroupId, "lists", selectedListId);
      const listSnap = await getDoc(listDocRef);
      if (listSnap.exists()) {
        const existingCreators = listSnap.data().creators || [];
        setCreators(existingCreators);
      } else {
        console.log("No such document!");
        setCreators([]);
      }
    } catch (error) {
      console.error("Error fetching creators:", error);
      setError("Failed to fetch creators");
    }
  }, [workspaceData.workspace_id, selectedGroupId, selectedListId]);

  useEffect(() => {
    if (!workspaceData.workspace_id || !messagesId) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    const docRef = doc(db, "workspaces", workspaceData.workspace_id, "messages", messagesId);

    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          setMessageData({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError("No such document!");
          setMessageData(null);
        }
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching message data:", error);
        setError(error.message);
        setIsLoading(false);
      }
    );

    fetchGroups();

    return () => unsubscribe();
  }, [messagesId, workspaceData.workspace_id, fetchGroups]);

  useEffect(() => {
    fetchLists();
  }, [fetchLists]);

  useEffect(() => {
    fetchCreators();
  }, [fetchCreators]);

  const handleGroupChange = (e) => {
    setSelectedGroupId(e.target.value);
    setSelectedListId('');
  };

  const handleAddCreators = async () => {
    setIsAddingCreators(true);
    console.log(`Sending request to add ${creators.length} creators to message group`);

    try {
      const response = await axios.post('http://localhost:4000/messages-add-creators', null, {
        params: {
          workspaceId: workspaceData.workspace_id,
          messagesId: messagesId,
          groupId: selectedGroupId,
          listId: selectedListId
        }
      });

      console.log('Response from server:', response.data);

      toast({
        title: "Request sent successfully",
        description: "The request to add creators has been sent to the server.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose(); // Close the modal after successful request
    } catch (error) {
      console.error("Error sending request to add creators:", error);
      toast({
        title: "Error sending request",
        description: "There was an error sending the request to add creators. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsAddingCreators(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Creator</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading && <Text>Loading message data...</Text>}
          {error && <Text color="red.500">Error: {error}</Text>}
          {!isLoading && !error && messageData && (
            <VStack spacing={4} align="stretch">
              <Box w="100%">
                <Text mb={1}>Select Group:</Text>
                <Select placeholder="Select group" value={selectedGroupId} onChange={handleGroupChange}>
                  {groups.map(group => (
                    <option key={group.id} value={group.id}>{group.name}</option>
                  ))}
                </Select>
              </Box>
              {selectedGroupId && (
                <Box w="100%">
                  <Text mb={1}>Select List:</Text>
                  <Select placeholder="Select list" value={selectedListId} onChange={(e) => setSelectedListId(e.target.value)}>
                    {lists.map(list => (
                      <option key={list.id} value={list.id}>{list.name}</option>
                    ))}
                  </Select>
                </Box>
              )}
              {selectedListId && (
                <Box>
                  <Text>Add <strong>{creators.length}</strong> creators to this message group.</Text>
                </Box>
              )}
              <Button 
                onClick={handleAddCreators} 
                colorScheme="blue" 
                isDisabled={!selectedListId || isAddingCreators}
                isLoading={isAddingCreators}
                loadingText="Sending Request"
              >
                Add Creators
              </Button>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddCreatorModal;