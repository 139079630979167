import React from 'react';
import { Box, Image, Text, Link } from '@chakra-ui/react';
import verifiedBadge from '../../../assets/verifiedbadge.png';

const Creator = ({ profilePicUrl, username, name, isVerified, hoobeHandle }) => {
  return (
    <Box position="relative" display="flex" alignItems="center">
      <Image
        src={profilePicUrl}
        alt={username}
        boxSize="50px"
        borderRadius="full"
        mr="10px"
      />
      {isVerified && (
        <Image
          src={verifiedBadge}
          alt="Verified"
          boxSize="15px"
          position="absolute"
          top="0"
          left="0"
        />
      )}
      <Box>
        <Text fontWeight="bold">
          {name ? name : username}
        </Text>
        {hoobeHandle ? (
          <Link href={`https://hoo.be/${hoobeHandle}`} color="blue.500" isExternal onClick={(e) => e.stopPropagation()}>
            {hoobeHandle}
          </Link>
        ) : (
          <Text fontSize="sm">{hoobeHandle}</Text>
        )}
      </Box>
    </Box>
  );
};

export default Creator;
