import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  VStack,
  Select,
  useToast,
  Flex,
  IconButton,
  Input,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { db } from "../../../Firebase";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  addDoc,
  query,
  orderBy,
} from "firebase/firestore";
import { useOutletContext } from "react-router-dom";

const AddCreatorsModal = ({ isOpen, onClose, creatorIds, setSelectedCreators }) => {
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [lists, setLists] = useState([]);
  const [selectedListId, setSelectedListId] = useState("");
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);
  const [isAddListModalOpen, setIsAddListModalOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [newListName, setNewListName] = useState("");
  const toast = useToast();
  const { workspaceData } = useOutletContext();

  const fetchGroups = useCallback(async () => {
    if (!workspaceData?.workspace_id) return;
    try {
      const groupsRef = collection(db, "workspaces", workspaceData.workspace_id, "groups");
      const groupsSnapshot = await getDocs(query(groupsRef, orderBy("order")));
      setGroups(groupsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error("Error fetching groups:", error);
      toast({
        title: "Error",
        description: "Failed to fetch groups.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [workspaceData, toast]);

  const fetchLists = useCallback(
    async (groupId) => {
      if (!workspaceData?.workspace_id || !groupId) return;
      try {
        const listsRef = collection(
          db,
          "workspaces",
          workspaceData.workspace_id,
          "groups",
          groupId,
          "lists"
        );
        const listsSnapshot = await getDocs(query(listsRef, orderBy("order")));
        setLists(listsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error fetching lists:", error);
        toast({
          title: "Error",
          description: "Failed to fetch lists.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [workspaceData, toast]
  );

  useEffect(() => {
    if (isOpen) {
      fetchGroups();
      setSelectedGroupId("");
      setSelectedListId("");
      setLists([]);
    }
  }, [isOpen, fetchGroups]);

  const handleGroupChange = (e) => {
    const groupId = e.target.value;
    setSelectedGroupId(groupId);
    setSelectedListId("");
    if (groupId) fetchLists(groupId);
  };

  const handleAddToList = async () => {
    if (!selectedGroupId || !selectedListId) {
      toast({
        title: "Error",
        description: "Please select a group and a list.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const listRef = doc(
        db,
        "workspaces",
        workspaceData.workspace_id,
        "groups",
        selectedGroupId,
        "lists",
        selectedListId
      );
      const listSnap = await getDoc(listRef);
      if (!listSnap.exists()) {
        toast({
          title: "Error",
          description: "List does not exist.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const existingCreators = listSnap.data().creators || [];
      const newCreators = creatorIds.map((creatorId) => ({
        creatorId,
        isFavorite: false,
        notes: "",
        deliverables: [],
      }));
      const mergedCreators = [
        ...existingCreators,
        ...newCreators.filter(
          (newCreator) =>
            !existingCreators.some((creator) => creator.creatorId === newCreator.creatorId)
        ),
      ];

      await updateDoc(listRef, { creators: mergedCreators });
      toast({
        title: "Success",
        description: `${creatorIds.length} creator(s) added to the list.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setSelectedCreators([]);
      onClose();
    } catch (error) {
      console.error("Error adding creators to list:", error);
      toast({
        title: "Error",
        description: "Failed to add creators to the list.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddGroup = async () => {
    if (!newGroupName.trim()) {
      toast({
        title: "Error",
        description: "Group name cannot be empty.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      const maxOrder = groups.reduce((max, group) => Math.max(max, group.order || 0), 0);
      const groupsRef = collection(db, "workspaces", workspaceData.workspace_id, "groups");
      await addDoc(groupsRef, {
        name: newGroupName,
        order: maxOrder + 1,
      });
      toast({
        title: "Success",
        description: "Group added successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsAddGroupModalOpen(false);
      setNewGroupName("");
      fetchGroups();
    } catch (error) {
      console.error("Error adding group:", error);
      toast({
        title: "Error",
        description: "Failed to add group.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddList = async () => {
    if (!newListName.trim()) {
      toast({
        title: "Error",
        description: "List name cannot be empty.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!selectedGroupId) {
      toast({
        title: "Error",
        description: "No group selected.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      const maxOrder = lists.reduce((max, list) => Math.max(max, list.order || 0), 0);
      const listsRef = collection(
        db,
        "workspaces",
        workspaceData.workspace_id,
        "groups",
        selectedGroupId,
        "lists"
      );
      await addDoc(listsRef, {
        name: newListName,
        order: maxOrder + 1,
      });
      toast({
        title: "Success",
        description: "List added successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsAddListModalOpen(false);
      setNewListName("");
      fetchLists(selectedGroupId);
    } catch (error) {
      console.error("Error adding list:", error);
      toast({
        title: "Error",
        description: "Failed to add list.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Creators</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="start" spacing={4}>
            <Flex w="100%" align="center">
              <Box flex="1">
                <Text mb={1}>Select Group:</Text>
                <Select
                  placeholder="Select group"
                  value={selectedGroupId}
                  onChange={handleGroupChange}
                >
                  {groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <IconButton
                aria-label="Add Group"
                icon={<AddIcon />}
                ml={2}
                mt={6}
                onClick={() => setIsAddGroupModalOpen(true)}
              />
            </Flex>

            {selectedGroupId && (
              <Flex w="100%" align="center">
                <Box flex="1">
                  <Text mb={1}>Select List:</Text>
                  <Select
                    placeholder="Select list"
                    value={selectedListId}
                    onChange={(e) => setSelectedListId(e.target.value)}
                  >
                    {lists.map((list) => (
                      <option key={list.id} value={list.id}>
                        {list.name}
                      </option>
                    ))}
                  </Select>
                </Box>
                <IconButton
                  aria-label="Add List"
                  icon={<AddIcon />}
                  ml={2}
                  mt={6}
                  onClick={() => setIsAddListModalOpen(true)}
                />
              </Flex>
            )}

            {selectedGroupId && selectedListId && (
              <Button colorScheme="blue" onClick={handleAddToList}>
                Add to Selected List
              </Button>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter />
      </ModalContent>

      {/* Add Group Modal */}
      <Modal isOpen={isAddGroupModalOpen} onClose={() => setIsAddGroupModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Group Name</FormLabel>
              <Input
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                placeholder="Enter group name"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleAddGroup} colorScheme="blue" mr={3}>
              Add Group
            </Button>
            <Button onClick={() => setIsAddGroupModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Add List Modal */}
      <Modal isOpen={isAddListModalOpen} onClose={() => setIsAddListModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New List</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>List Name</FormLabel>
              <Input
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
                placeholder="Enter list name"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleAddList} colorScheme="blue" mr={3}>
              Add List
            </Button>
            <Button onClick={() => setIsAddListModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Modal>
  );
};

export default AddCreatorsModal;
