import React, { useEffect, useState, useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Checkbox,
  VStack,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  useClipboard,
  Tooltip,
  Flex,
  Text,
  Center,
  useToast,
} from "@chakra-ui/react";
import {
  addDoc,
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../../Firebase";
import { CopyIcon, ExternalLinkIcon, DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import { FiShare, FiPlus } from "react-icons/fi";
import { generateCampaignPDF } from './CampaignsPDF';

const CampaignsShareModal = ({ isOpen, onClose, workspaceId, campaignId }) => {
  const [sharedCampaigns, setSharedCampaigns] = useState([]);

  const fetchSharedCampaigns = useCallback(async () => {
    try {
      const q = query(
        collection(db, "share"),
        where("type", "==", "campaign"),
        where("campaignId", "==", campaignId)
      );
      const querySnapshot = await getDocs(q);
      const campaigns = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSharedCampaigns(campaigns);
    } catch (error) {
      console.error("Error fetching shared campaigns: ", error);
    }
  }, [campaignId]);

  useEffect(() => {
    if (campaignId) {
      fetchSharedCampaigns();
    }
  }, [campaignId, fetchSharedCampaigns]);

  const createNewShareUrl = async () => {
    try {
      const shareCollectionRef = collection(db, "share");
      const newShare = {
        type: "campaign",
        workspaceId,
        campaignId,
        trackedImpressions: false,
        trackedCreatorTotal: false,
        createdAt: new Date(),
      };
      await addDoc(shareCollectionRef, newShare);
      fetchSharedCampaigns();
    } catch (error) {
      console.error("Error creating new share URL: ", error);
    }
  };

  const updateShareSettings = async (shareId, updatedSettings) => {
    try {
      const shareDocRef = doc(db, "share", shareId);
      await updateDoc(shareDocRef, updatedSettings);
      fetchSharedCampaigns();
    } catch (error) {
      console.error("Error updating share settings: ", error);
    }
  };

  const deleteShareUrl = async (shareId) => {
    try {
      await deleteDoc(doc(db, "share", shareId));
      fetchSharedCampaigns();
    } catch (error) {
      console.error("Error deleting share URL: ", error);
    }
  };

  const handleToggleChange = (shareId, field) => {
    const updatedSharedCampaigns = sharedCampaigns.map((campaign) => {
      if (campaign.id === shareId) {
        const updatedField = !campaign[field];
        updateShareSettings(shareId, { [field]: updatedField });
        return { ...campaign, [field]: updatedField };
      }
      return campaign;
    });
    setSharedCampaigns(updatedSharedCampaigns);
  };

  const ShareUrlActions = ({ shareId, deleteShareUrl, workspaceId, campaignId }) => {
    const shareUrl = `https://share.hoobe.me/campaigns/${shareId}`;
    const { onCopy } = useClipboard(shareUrl);
    const toast = useToast();

    const handleCopy = () => {
      onCopy();
      toast({
        title: "URL Copied",
        description: "The share URL has been copied to your clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    };

    const handleGeneratePDF = () => {
      generateCampaignPDF(shareUrl, workspaceId, campaignId);
    };

    return (
      <HStack spacing={2}>
        <Tooltip label="Copy URL">
          <Button
            leftIcon={<CopyIcon />}
            onClick={handleCopy}
            size="sm"
            borderWidth="1px"
            borderRadius="xl"
            bg="white"
          >
            Copy URL
          </Button>
        </Tooltip>
        <Tooltip label="Open external URL">
          <Button
            as="a"
            href={shareUrl}
            target="_blank"
            rel="noopener noreferrer"
            leftIcon={<ExternalLinkIcon />}
            size="sm"
            borderWidth="1px"
            borderRadius="xl"
            bg="white"
          >
            External URL
          </Button>
        </Tooltip>
        <Tooltip label="Generate PDF">
          <Button
            leftIcon={<DownloadIcon />}
            onClick={handleGeneratePDF}
            size="sm"
            borderWidth="1px"
            borderRadius="xl"
            bg="white"
          >
            Generate Report
          </Button>
        </Tooltip>
        <Tooltip label="Delete share URL">
          <Button
            leftIcon={<DeleteIcon />}
            onClick={() => deleteShareUrl(shareId)}
            size="sm"
            borderWidth="1px"
            borderRadius="xl"
            colorScheme="red"
          >
            Delete URL
          </Button>
        </Tooltip>
      </HStack>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent minHeight="50vh">
        <ModalHeader>
          <Flex align="center" h="30px">
            <FiShare style={{ marginRight: '10px' }} />
            <Text mr="20px">Share Campaign</Text>
            <Button
              leftIcon={<FiPlus />}
              onClick={createNewShareUrl}
              borderWidth="1px"
              borderRadius="xl"
              bg="white"
              size="sm"
            >
              Create Share URL
            </Button>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDirection="column">
          {sharedCampaigns.length === 0 ? (
            <Center flex={1}>
              <Button
                leftIcon={<FiPlus />}
                onClick={createNewShareUrl}
                borderWidth="1px"
                borderRadius="xl"
                bg="white"
                size="lg"
              >
                Create New Share URL
              </Button>
            </Center>
          ) : (
            <Accordion allowMultiple flex={1} overflowY="auto">
              {sharedCampaigns.map((share) => (
                <AccordionItem
                  key={share.id}
                  mb={4}
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="md"
                  overflow="hidden"
                >
                  <h2>
                    <AccordionButton _hover={{ bg: "gray.100" }}>
                      <Box flex="1" textAlign="left" fontWeight="medium">
                        Created: {new Date(share.createdAt.seconds * 1000).toLocaleString()}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} bg="white">
                    <VStack align="start" spacing={4}>
                      <Checkbox
                        isChecked={share.trackedImpressions}
                        onChange={() => handleToggleChange(share.id, "trackedImpressions")}
                      >
                        Tracked Impressions
                      </Checkbox>
                      <Checkbox
                        isChecked={share.trackedCreatorTotal}
                        onChange={() => handleToggleChange(share.id, "trackedCreatorTotal")}
                      >
                        Tracked Creator Total
                      </Checkbox>
                      <HStack justify="space-between" width="100%">
                        <ShareUrlActions
                          shareId={share.id}
                          deleteShareUrl={deleteShareUrl}
                          workspaceId={workspaceId}
                          campaignId={campaignId}
                        />
                      </HStack>
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CampaignsShareModal;
