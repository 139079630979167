// CampaignsEditOffer.js
import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerOverlay,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  DrawerCloseButton,
  Spinner,
  Alert,
  AlertIcon,
  Textarea,
  Switch,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../../Firebase";
import ExampleMedia from "./EditOffer/ExampleMedia";
import ProductsSelector from "./EditOffer/ProductsSelector";
import axios from "axios";
import { debounce } from "lodash";

const initialOfferState = {
  name: "",
  companyName: "",
  description: "",
  requirements: "",
  exampleMedia: [],
  productIds: [],
  website: "",
  isOngoing: false,
  budgetEnd: false,
  creatorApproval: false,
  contentApproval: false,
  deliverablesEditable: false,
  deliverableTikTok: false,
  deliverableIgStory: false,
  deliverableIgReel: false,
  deliverableIgPost: false,
  deliverableCustomEnabled: false,
  deliverableCustomName: "",
  affiliatesEnabled: false,
  affiliatesEditable: false,
  fixedAmountEnabled: false,
  fixedAmountEditable: false,
  customCompensationEnabled: false,
  customCompensationName: "",
};

const CampaignsEditOffer = ({ isOpen, onClose, workspaceId, campaignId }) => {
  const [offer, setOffer] = useState(initialOfferState);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [productsInWorkspace, setProductsInWorkspace] = useState([]);

  const campaignDocRef = useMemo(
    () => doc(db, "workspaces", workspaceId, "campaigns", campaignId),
    [workspaceId, campaignId]
  );

  const handleError = useCallback((message) => {
    setError(message);
    setIsLoading(false);
  }, []);

  const debouncedSave = useMemo(
    () =>
      debounce(async (newOffer) => {
        try {
          await updateDoc(campaignDocRef, {
            offer: newOffer,
          });
        } catch (err) {
          handleError("Failed to save changes. Please try again.");
        }
      }, 1000),
    [campaignDocRef, handleError]
  );

  const handleFieldChange = useCallback(
    (fieldName, value) => {
      setOffer((prev) => {
        const newOffer = { ...prev, [fieldName]: value };
        debouncedSave(newOffer);
        return newOffer;
      });
    },
    [debouncedSave]
  );

  useEffect(() => {
    if (!isOpen) {
      setOffer(initialOfferState);
      return;
    }

    const fetchOfferData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const snapshot = await getDoc(campaignDocRef);
        if (!snapshot.exists()) {
          throw new Error("Campaign not found.");
        }

        const data = snapshot.data().offer || initialOfferState;
        setOffer(data);
      } catch (err) {
        handleError(err.message || "Failed to fetch offer data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchOfferData();
  }, [isOpen, campaignDocRef, handleError]);

  useEffect(() => {
    if (!isOpen) return;

    const unsubscribe = onSnapshot(
      query(collection(db, "workspaces", workspaceId, "products")),
      async (snapshot) => {
        const products = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (products.length === 0) {
          setProductsInWorkspace([]);
          return;
        }

        try {
          const { data } = await axios.post(
            "https://hoobe-shop-api.onrender.com/get-products-from-ids",
            { ids: products.map((p) => p.shopifyProductId) }
          );
          setProductsInWorkspace(data);
        } catch (err) {
          handleError("Could not fetch products from Shopify");
        }
      }
    );

    return () => unsubscribe();
  }, [isOpen, workspaceId, handleError]);

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  const renderFormField = useCallback(
    ({ label, field, type = "input" }) => (
      <FormControl>
        <FormLabel>{label}</FormLabel>
        {type === "input" ? (
          <Input
            value={offer[field] || ""}
            onChange={(e) => handleFieldChange(field, e.target.value)}
            placeholder={`Enter ${label.toLowerCase()}`}
            size="md"
          />
        ) : (
          <Textarea
            value={offer[field] || ""}
            onChange={(e) => handleFieldChange(field, e.target.value)}
            placeholder={`Enter ${label.toLowerCase()}`}
            size="md"
            resize="vertical"
            whiteSpace="pre-wrap"
          />
        )}
      </FormControl>
    ),
    [offer, handleFieldChange]
  );

  const renderBudgetSwitches = useCallback(() => (
    <FormControl>
      <FormLabel>Budget Settings</FormLabel>
      <HStack spacing={8}>
        <HStack>
          <Switch
            isChecked={offer.isOngoing}
            onChange={(e) => handleFieldChange("isOngoing", e.target.checked)}
            colorScheme="teal"
          />
          <Text>Is Ongoing</Text>
        </HStack>
        <HStack>
          <Switch
            isChecked={offer.budgetEnd}
            onChange={(e) => handleFieldChange("budgetEnd", e.target.checked)}
            colorScheme="teal"
          />
          <Text>Budget End</Text>
        </HStack>
      </HStack>
    </FormControl>
  ), [offer, handleFieldChange]);

  const renderApprovalSwitches = useCallback(() => (
    <FormControl>
      <FormLabel>Approval Settings</FormLabel>
      <VStack align="left" spacing={4}>
        <HStack>
          <Switch
            isChecked={offer.creatorApproval}
            onChange={(e) =>
              handleFieldChange("creatorApproval", e.target.checked)
            }
            colorScheme="teal"
          />
          <Text>Creator Approval Required</Text>
        </HStack>
        <HStack>
          <Switch
            isChecked={offer.contentApproval}
            onChange={(e) =>
              handleFieldChange("contentApproval", e.target.checked)
            }
            colorScheme="teal"
          />
          <Text>Content Approval Required</Text>
        </HStack>
      </VStack>
    </FormControl>
  ), [offer, handleFieldChange]);

  const renderDeliverablesSwitches = useCallback(() => (
    <FormControl>
      <FormLabel>Deliverables</FormLabel>
      <Flex direction="column" gap={4}>
        <HStack>
          <Text mb="0">Creator can edit deliverables</Text>
          <Switch
            isChecked={offer.deliverablesEditable}
            onChange={(e) =>
              handleFieldChange("deliverablesEditable", e.target.checked)
            }
            colorScheme="teal"
          />
          <Text>{offer.deliverablesEditable ? "Yes" : "No"}</Text>
        </HStack>

        <VStack gap={4} align="left" width="100%">
          <HStack>
            <Switch
              isChecked={offer.deliverableTikTok}
              onChange={(e) =>
                handleFieldChange("deliverableTikTok", e.target.checked)
              }
              colorScheme="teal"
            />
            <Text>TikTok Video</Text>
          </HStack>

          <HStack>
            <Switch
              isChecked={offer.deliverableIgStory}
              onChange={(e) =>
                handleFieldChange("deliverableIgStory", e.target.checked)
              }
              colorScheme="teal"
            />
            <Text>Instagram Story</Text>
          </HStack>

          <HStack>
            <Switch
              isChecked={offer.deliverableIgReel}
              onChange={(e) =>
                handleFieldChange("deliverableIgReel", e.target.checked)
              }
              colorScheme="teal"
            />
            <Text>Instagram Reel</Text>
          </HStack>

          <HStack>
            <Switch
              isChecked={offer.deliverableIgPost}
              onChange={(e) =>
                handleFieldChange("deliverableIgPost", e.target.checked)
              }
              colorScheme="teal"
            />
            <Text>Instagram Post</Text>
          </HStack>

          <HStack spacing={4} width="100%">
            <Switch
              isChecked={offer.deliverableCustomEnabled}
              onChange={(e) =>
                handleFieldChange("deliverableCustomEnabled", e.target.checked)
              }
              colorScheme="teal"
            />
            <Input
              placeholder="Custom Deliverable"
              value={offer.deliverableCustomName}
              onChange={(e) =>
                handleFieldChange("deliverableCustomName", e.target.value)
              }
              isDisabled={!offer.deliverableCustomEnabled}
              size="md"
              maxWidth="300px"
            />
          </HStack>
        </VStack>
      </Flex>
    </FormControl>
  ), [offer, handleFieldChange]);

  const renderSwitchControl = useCallback(
    ({ label, enabledField, editableField }) => (
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <HStack spacing={8}>
          <HStack>
            <Switch
              isChecked={offer[enabledField]}
              onChange={(e) =>
                handleFieldChange(enabledField, e.target.checked)
              }
              colorScheme="teal"
            />
            <Text>{offer[enabledField] ? "On" : "Off"}</Text>
          </HStack>
          <HStack>
            <Text mb="0">Editable</Text>
            <Switch
              isChecked={offer[editableField]}
              onChange={(e) =>
                handleFieldChange(editableField, e.target.checked)
              }
              colorScheme="teal"
              isDisabled={!offer[enabledField]}
            />
            <Text>{offer[editableField] ? "Yes" : "No"}</Text>
          </HStack>
        </HStack>
      </FormControl>
    ),
    [offer, handleFieldChange]
  );

  const renderFixedAmountControl = useCallback(() => (
    <FormControl>
      <FormLabel>Fixed Amount</FormLabel>
      <Flex direction="column" gap={2}>
        <HStack spacing={8}>
          <HStack>
            <Switch
              isChecked={offer.fixedAmountEnabled}
              onChange={(e) =>
                handleFieldChange("fixedAmountEnabled", e.target.checked)
              }
              colorScheme="teal"
            />
            <Text>{offer.fixedAmountEnabled ? "On" : "Off"}</Text>
          </HStack>
          <HStack>
            <Text mb="0">Editable</Text>
            <Switch
              isChecked={offer.fixedAmountEditable}
              onChange={(e) =>
                handleFieldChange("fixedAmountEditable", e.target.checked)
              }
              colorScheme="teal"
              isDisabled={!offer.fixedAmountEnabled}
            />
            <Text>{offer.fixedAmountEditable ? "Yes" : "No"}</Text>
          </HStack>
        </HStack>

        <FormLabel>Custom Compensation</FormLabel>

        <HStack spacing={2} width="100%">
          <Switch
            isChecked={offer.customCompensationEnabled}
            onChange={(e) =>
              handleFieldChange("customCompensationEnabled", e.target.checked)
            }
            colorScheme="teal"
          />
          <Input
            placeholder="Custom Compensation"
            value={offer.customCompensationName}
            onChange={(e) =>
              handleFieldChange("customCompensationName", e.target.value)
            }
            isDisabled={!offer.customCompensationEnabled}
            size="md"
            maxWidth="300px"
          />
        </HStack>
      </Flex>
    </FormControl>
  ), [offer, handleFieldChange]);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay>
        <DrawerContent maxW="700px">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading size="lg">Edit Offer</Heading>
          </DrawerHeader>
          <DrawerBody
            css={{
              "&::-webkit-scrollbar": { display: "none" },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            {isLoading ? (
              <Flex justify="center" align="center" height="200px">
                <Spinner size="lg" />
              </Flex>
            ) : error ? (
              <Alert status="error" borderRadius="md" mb={4}>
                <AlertIcon />
                {error}
              </Alert>
            ) : (
              <Flex direction="column" gap={4}>
                <Flex gap={4}>
                  {renderFormField({ label: "Offer Name", field: "name" })}
                  {renderFormField({
                    label: "Offer Company Name",
                    field: "companyName",
                  })}
                </Flex>

                {renderFormField({
                  label: "Offer Description",
                  field: "description",
                  type: "textarea",
                })}
                {renderFormField({
                  label: "Offer Requirements",
                  field: "requirements",
                  type: "textarea",
                })}
                {renderFormField({ label: "Website", field: "website" })}

                {renderBudgetSwitches()}
                {renderApprovalSwitches()}
                {renderDeliverablesSwitches()}

                {renderSwitchControl({
                  label: "Affiliates",
                  enabledField: "affiliatesEnabled",
                  editableField: "affiliatesEditable",
                })}

                {renderFixedAmountControl()}

                <ExampleMedia
                  offer={offer}
                  setOffer={(updateFn) => {
                    setOffer((prevOffer) => {
                      const newOffer =
                        typeof updateFn === "function"
                          ? updateFn(prevOffer)
                          : updateFn;
                      debouncedSave(newOffer);
                      return newOffer;
                    });
                  }}
                  setError={setError}
                />

                <ProductsSelector
                  offer={offer}
                  setOffer={(updateFn) => {
                    setOffer((prevOffer) => {
                      const newOffer =
                        typeof updateFn === "function"
                          ? updateFn(prevOffer)
                          : updateFn;
                      debouncedSave(newOffer);
                      return newOffer;
                    });
                  }}
                  workspaceId={workspaceId}
                  campaignId={campaignId}
                  setError={setError}
                  productsInWorkspace={productsInWorkspace}
                />

                {error && (
                  <Alert status="error" borderRadius="md">
                    <AlertIcon />
                    {error}
                  </Alert>
                )}
              </Flex>
            )}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default CampaignsEditOffer;
