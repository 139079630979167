// ListsAuto.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Input,
  Button,
  Flex,
  Text,
  Spinner,
  Textarea,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { db } from "../../../../Firebase";
import { doc, onSnapshot } from "firebase/firestore";
import axios from "axios";
import { AnimatePresence } from "framer-motion";
import MotionBox from "./MotionBox";
import ListsAutoDisplay from "./ListsAutoDisplay";

const ListsAuto = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { workspaceData } = useOutletContext();

  const [groupName, setGroupName] = useState("Unknown Group");
  const [brandName, setBrandName] = useState("");
  const [brandDescription, setBrandDescription] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [responseData, setResponseData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!workspaceData?.workspace_id || !groupId) return;

    const groupRef = doc(
      db,
      "workspaces",
      workspaceData.workspace_id,
      "groups",
      groupId
    );

    const unsubscribe = onSnapshot(
      groupRef,
      (docSnap) => {
        if (docSnap.exists()) {
          setGroupName(docSnap.data().name || "Unknown Group");
        } else {
          setGroupName("Unknown Group");
        }
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching group data:", error);
        setIsLoading(false);
      }
    );

    return unsubscribe;
  }, [workspaceData, groupId]);

  const handleSearch = async () => {
    if (!brandName.trim()) {
      alert("Please enter the brand name.");
      return;
    }

    setIsSubmitting(true);
    setError(null);
    setResponseData(null);

    const url = `${process.env.REACT_APP_HOOBE_API_URL}/lists-generate`;

    const payload = {
      brandName: brandName.trim(),
      brandDescription: brandDescription.trim(),
    };

    try {
      const response = await axios.post(url, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response.data);
      setResponseData(response.data);
    } catch (err) {
      console.error("Error during API request:", err);
      setError(err.response?.data || "An unexpected error occurred.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <Flex justify="center" align="center" height="200px">
        <Spinner size="lg" />
      </Flex>
    );
  }

  return (
    <Box>
      <Flex
        height="60px"
        alignItems="center"
        borderBottomWidth="2px"
        p={4}
        justifyContent="space-between"
      >
        <Heading size="md" as="div">
          <Flex align="center">
            <Text
              as="span"
              cursor="pointer"
              onClick={() => navigate("/dashboard/lists")}
              mr={2}
              fontWeight="bold"
            >
              Lists
            </Text>
            <Text as="span" mr={2}>
              /
            </Text>
            <Text
              as="span"
              cursor="pointer"
              onClick={() => navigate(`/dashboard/lists/${groupId}`)}
              mr={2}
              fontWeight="bold"
            >
              {groupName}
            </Text>
            <Text as="span" mr={2}>
              /
            </Text>
            <Text as="span" fontWeight="bold">
              Auto
            </Text>
          </Flex>
        </Heading>
      </Flex>

      <Flex justify="center" align="center" minHeight="calc(100vh - 60px)" p={4}>
        <AnimatePresence mode="wait">
          {!responseData ? (
            <MotionBox
              key="form"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5 }}
              maxW="md"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              p={6}
              boxShadow="md"
              bg="white"
              width="100%"
            >
              <Heading as="h2" size="lg" mb={6} textAlign="center">
                Let's Build a List
              </Heading>
              <Flex direction="column" gap={4}>
                <Box>
                  <Text fontWeight="bold" mb={1}>
                    Brand Name
                  </Text>
                  <Input
                    placeholder="Brand Name"
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                    size="md"
                    borderRadius="md"
                  />
                </Box>

                <Box>
                  <Text fontWeight="bold" mb={1}>
                    Description of Who to Find
                  </Text>
                  <Textarea
                    placeholder="Description of Who to Find"
                    value={brandDescription}
                    onChange={(e) => setBrandDescription(e.target.value)}
                    size="md"
                    borderRadius="md"
                  />
                </Box>

                <Button
                  colorScheme="blue"
                  size="md"
                  onClick={handleSearch}
                  isLoading={isSubmitting}
                  loadingText="Searching"
                  mt={4}
                >
                  Search
                </Button>

                {error && (
                  <Alert status="error" mt={4}>
                    <AlertIcon />
                    <AlertTitle mr={2}>Request Failed!</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                )}
              </Flex>
            </MotionBox>
          ) : (
            <ListsAutoDisplay
              key="response"
              responseData={responseData}
              workspaceData={workspaceData}
            />
          )}
        </AnimatePresence>
      </Flex>
    </Box>
  );
};

export default ListsAuto;
