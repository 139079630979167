import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Button,
  Box,
  Text,
  Flex,
} from "@chakra-ui/react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { FiCalendar } from "react-icons/fi";

const CustomDatePickerButton = ({
  value,
  onClick,
  placeholder,
  isDisabled,
}) => (
  <Button
    onClick={onClick}
    variant="outline"
    leftIcon={<FiCalendar />}
    width="100%"
    justifyContent="space-between"
    isDisabled={isDisabled}
    borderRadius="md"
    bg="white"
    _hover={{ bg: "gray.50" }}
    _active={{ bg: "gray.100" }}
    boxShadow="sm"
    fontFamily="Poppins, sans-serif"
  >
    <Text color={value ? "gray.800" : "gray.500"} flex="1" textAlign="left">
      {value ? new Date(value).toLocaleString() : placeholder}
    </Text>
  </Button>
);

const CampaignsDatePicker = ({
  campaignStartDate,
  campaignEndDate,
  onStartDateChange,
  onEndDateChange,
}) => {
  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);

  const validStart = (current) => {
    return current.isAfter(Datetime.moment().subtract(1, "day"));
  };

  const validEnd = (current) => {
    if (!campaignStartDate) return false;
    return current.isAfter(Datetime.moment(campaignStartDate));
  };

  return (
    <Box>
      <Flex direction={["column", "row"]} gap={4}>
        <FormControl>
          <FormLabel fontFamily="Poppins, sans-serif">Start Date</FormLabel>
          <Box position="relative">
            <CustomDatePickerButton
              onClick={() => setShowStartDatePicker(!showStartDatePicker)}
              value={campaignStartDate}
              placeholder="Select start date and time"
            />
            {showStartDatePicker && (
              <Box
                position="absolute"
                zIndex={10}
                mt={2}
                bg="white"
                borderWidth="1px"
                borderRadius="xl"
                p={4}
              >
                <Datetime
                  value={campaignStartDate}
                  onChange={(date) => {
                    onStartDateChange(date);
                  }}
                  isValidDate={validStart}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm"
                  closeOnSelect={false}
                  input={false}
                  renderInput={(props) => <div />}
                />
              </Box>
            )}
          </Box>
        </FormControl>

        <FormControl>
          <FormLabel fontFamily="Poppins, sans-serif">End Date</FormLabel>
          <Box position="relative">
            <CustomDatePickerButton
              onClick={() => setShowEndDatePicker(!showEndDatePicker)}
              value={campaignEndDate}
              placeholder="Select end date and time"
              isDisabled={!campaignStartDate}
            />
            {showEndDatePicker && (
              <Box
                position="absolute"
                zIndex={10}
                mt={2}
                bg="white"
                borderWidth="1px"
                borderRadius="xl"
                p={4}
              >
                <Datetime
                  value={campaignEndDate}
                  onChange={(date) => {
                    onEndDateChange(date);
                  }}
                  isValidDate={validEnd}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm"
                  closeOnSelect={false}
                  input={false}
                  renderInput={(props) => <div />}
                />
              </Box>
            )}
          </Box>
        </FormControl>
      </Flex>
    </Box>
  );
};

export default CampaignsDatePicker;
