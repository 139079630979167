import React, { useMemo, useState, useCallback, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Input,
  VStack,
  Checkbox,
  IconButton,
  HStack,
  useToast,
  Flex,
  Icon,
} from '@chakra-ui/react';
import {
  DeleteIcon,
  TriangleUpIcon,
  TriangleDownIcon,
  AddIcon,
} from '@chakra-ui/icons';
import { FaStar, FaRegStar } from 'react-icons/fa';
import Column from './Column';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../../Firebase';
import Deliverables from '../../../formatting/Deliverables';
import Creator from '../../../formatting/Creator';
import Followers from '../../../formatting/Followers';
import EditableNotes from './EditableNotes';
import { FormatNumber } from '../../../formatting/FormatNumber';
import ListsBulkButton from './ListsBulkButton';
import CreatorDrawer from '../../../formatting/slideout/CreatorDrawer';

const ListsTable = ({ rawData, creatorData, workspaceId, groupId, listId }) => {
  const [filterText, setFilterText] = useState('');
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState({
    creator: true,
    category: false,
    followers: true,
    engagementRate: false,
    reelsImpressions: false,
    storiesImpressions: false,
    postImpressions: false,
    tiktokVideo: false,
    deliverables: true,
    expectedImpressions: true,
    favorite: true,
    notes: true,
  });
  const [sortConfig, setSortConfig] = useState({
    key: '',
    direction: 'ascending',
  });
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCreatorId, setSelectedCreatorId] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null); // Added state for current index
  const toast = useToast();

  const calculateExpectedImpressions = useCallback((creator, creatorDeliverables) => {
    let totalImpressions = 0;
    creatorDeliverables.forEach((deliverable) => {
      const count = typeof deliverable.count === 'number' ? deliverable.count : 0;
      let impressionsPerUnit = 0;
      switch (deliverable.type) {
        case 'Instagram Reel':
          impressionsPerUnit = creator.instagram_profile?.projected_reel_views || 0;
          break;
        case 'Instagram Story':
          impressionsPerUnit = creator.instagram_profile?.projected_story_views || 0;
          break;
        case 'Instagram Post':
          impressionsPerUnit = creator.instagram_profile?.projected_post_views || 0;
          break;
        case 'TikTok Video':
          impressionsPerUnit = creator.tiktok_profile?.projected_video_views || 0;
          break;
        default:
          impressionsPerUnit = 0;
      }
      totalImpressions += impressionsPerUnit * count;
    });
    return totalImpressions;
  }, []);

  const combinedData = useMemo(() => {
    if (
      !rawData ||
      !rawData.creators ||
      !Array.isArray(rawData.creators) ||
      !creatorData ||
      !Array.isArray(creatorData)
    ) {
      console.warn('Invalid or missing data in ListsTable');
      return [];
    }
    return rawData.creators.map((creator) => {
      const matchingCreator =
        creatorData.find((c) => c.unique_id === creator.creatorId) || {};
      return { ...creator, ...matchingCreator };
    });
  }, [rawData, creatorData]);

  const filteredData = useMemo(() => {
    let data = combinedData
      .map((creator) => ({
        ...creator,
        expectedImpressions: calculateExpectedImpressions(
          creator,
          creator.deliverables || []
        ),
      }))
      .filter((creator) => {
        const searchText = filterText.toLowerCase();
        return (
          creator.instagram_profile?.username?.toLowerCase().includes(searchText) ||
          creator.instagram_profile?.name?.toLowerCase().includes(searchText) ||
          creator.name?.toLowerCase().includes(searchText)
        );
      });

    if (sortConfig.key) {
      data.sort((a, b) => {
        let aValue, bValue;

        switch (sortConfig.key) {
          case 'creator':
            aValue = a.instagram_profile?.username || '';
            bValue = b.instagram_profile?.username || '';
            break;
          case 'category':
            aValue = a.instagram_profile?.category || '';
            bValue = b.instagram_profile?.category || '';
            break;
          case 'followers':
            aValue = a.instagram_profile?.followers_count || 0;
            bValue = b.instagram_profile?.followers_count || 0;
            break;
          case 'engagementRate':
            aValue = a.instagram_profile?.engagement_rate || 0;
            bValue = b.instagram_profile?.engagement_rate || 0;
            break;
          case 'reelsImpressions':
            aValue = a.instagram_profile?.projected_reel_views || 0;
            bValue = b.instagram_profile?.projected_reel_views || 0;
            break;
          case 'storiesImpressions':
            aValue = a.instagram_profile?.projected_story_views || 0;
            bValue = b.instagram_profile?.projected_story_views || 0;
            break;
          case 'postImpressions':
            aValue = a.instagram_profile?.projected_post_views || 0;
            bValue = b.instagram_profile?.projected_post_views || 0;
            break;
          case 'tiktokVideo':
            aValue = a.tiktok_profile?.projected_video_views || 0;
            bValue = b.tiktok_profile?.projected_video_views || 0;
            break;
          case 'deliverables':
            aValue = (a.deliverables || []).length;
            bValue = (b.deliverables || []).length;
            break;
          case 'expectedImpressions':
            aValue = a.expectedImpressions || 0;
            bValue = b.expectedImpressions || 0;
            break;
          case 'favorite':
            aValue = a.isFavorite ? 1 : 0;
            bValue = b.isFavorite ? 1 : 0;
            break;
          case 'notes':
            aValue = a.notes ? 1 : 0;
            bValue = b.notes ? 1 : 0;
            break;
          default:
            return 0;
        }

        if (typeof aValue === 'string') {
          if (sortConfig.direction === 'ascending') {
            return aValue.localeCompare(bValue);
          } else {
            return bValue.localeCompare(aValue);
          }
        } else {
          if (sortConfig.direction === 'ascending') {
            return aValue - bValue;
          } else {
            return bValue - aValue;
          }
        }
      });
    }

    return data;
  }, [combinedData, filterText, sortConfig, calculateExpectedImpressions]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key) {
      direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
    } else {
      direction = 'ascending';
    }
    setSortConfig({ key: key, direction });
  };

  const getSortIcon = (key) => (
    <Box display="inline-block" ml={1} position="relative" width="10px" height="20px">
      <Icon
        as={TriangleUpIcon}
        color={
          sortConfig.key === key && sortConfig.direction === 'ascending'
            ? 'gray.800'
            : 'gray.300'
        }
        boxSize={3}
        position="absolute"
        top="0px"
        left="0"
      />
      <Icon
        as={TriangleDownIcon}
        color={
          sortConfig.key === key && sortConfig.direction === 'descending'
            ? 'gray.800'
            : 'gray.300'
        }
        boxSize={3}
        position="absolute"
        top="10px"
        left="0"
      />
    </Box>
  );

  const handleCreatorUpdate = async (creatorId, updateData) => {
    try {
      const listDocRef = doc(
        db,
        'workspaces',
        workspaceId,
        'groups',
        groupId,
        'lists',
        listId
      );

      const listDocSnapshot = await getDoc(listDocRef);
      if (!listDocSnapshot.exists()) {
        throw new Error('List document does not exist');
      }
      const listData = listDocSnapshot.data();

      const updatedCreators = listData.creators.map((creator) => {
        if (creator.creatorId === creatorId) {
          return {
            ...creator,
            ...updateData,
          };
        }
        return creator;
      });

      await updateDoc(listDocRef, { creators: updatedCreators });
    } catch (error) {
      console.error('Error updating creator data:', error);
      toast({
        title: 'Error updating creator',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleToggleFavorite = async (creatorId, currentFavoriteStatus) => {
    await handleCreatorUpdate(creatorId, { isFavorite: !currentFavoriteStatus });
  };

  const handleCheckboxChange = (creatorId) => {
    setSelectedCreators((prev) =>
      prev.includes(creatorId)
        ? prev.filter((id) => id !== creatorId)
        : [...prev, creatorId]
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedCreators(filteredData.map((creator) => creator.creatorId));
    } else {
      setSelectedCreators([]);
    }
  };

  const handleDeleteCreators = async () => {
    try {
      const listDocRef = doc(
        db,
        'workspaces',
        workspaceId,
        'groups',
        groupId,
        'lists',
        listId
      );

      const listDocSnapshot = await getDoc(listDocRef);
      if (!listDocSnapshot.exists()) {
        throw new Error('List document does not exist');
      }
      const listData = listDocSnapshot.data();

      const updatedCreators = listData.creators.filter(
        (creator) => !selectedCreators.includes(creator.creatorId)
      );

      await updateDoc(listDocRef, { creators: updatedCreators });

      const deletedCount = selectedCreators.length;
      setSelectedCreators([]);

      toast({
        title: 'Creators deleted',
        description: `${deletedCount} creator(s) removed from the list`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting creators:', error);
      toast({
        title: 'Error deleting creators',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleBulkDeliverables = () => {
    setIsBulkModalOpen(true);
  };

  const handleBulkDeliverablesChange = async (updatedDeliverables) => {
    try {
      const listDocRef = doc(
        db,
        'workspaces',
        workspaceId,
        'groups',
        groupId,
        'lists',
        listId
      );

      const listDocSnapshot = await getDoc(listDocRef);
      if (!listDocSnapshot.exists()) {
        throw new Error('List document does not exist');
      }
      const listData = listDocSnapshot.data();

      const updatedCreators = listData.creators.map((creator) => {
        if (selectedCreators.includes(creator.creatorId)) {
          return {
            ...creator,
            deliverables: updatedDeliverables,
          };
        }
        return creator;
      });

      await updateDoc(listDocRef, { creators: updatedCreators });

      toast({
        title: 'Deliverables updated',
        description: `Deliverables have been updated for selected creators`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setIsBulkModalOpen(false);
    } catch (error) {
      console.error('Error updating deliverables:', error);
      toast({
        title: 'Error updating deliverables',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Handle row click to open the CreatorDrawer and set current index
  const handleRowClick = (creatorId, index) => {
    setSelectedCreatorId(creatorId);
    setIsDrawerOpen(true);
    setCurrentIndex(index);
  };

  // Add keydown event listener for arrow key navigation
  useEffect(() => {
    if (isDrawerOpen) {
      const handleKeyDown = (event) => {
        if (event.key === 'ArrowUp') {
          event.preventDefault();
          if (currentIndex > 0) {
            const newIndex = currentIndex - 1;
            setSelectedCreatorId(filteredData[newIndex].creatorId);
            setCurrentIndex(newIndex);
          }
        } else if (event.key === 'ArrowDown') {
          event.preventDefault();
          if (currentIndex < filteredData.length - 1) {
            const newIndex = currentIndex + 1;
            setSelectedCreatorId(filteredData[newIndex].creatorId);
            setCurrentIndex(newIndex);
          }
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isDrawerOpen, currentIndex, filteredData]);

  // Reset currentIndex when the drawer is closed
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setSelectedCreatorId(null);
    setCurrentIndex(null);
  };

  if (!combinedData || combinedData.length === 0) {
    return <Text>No creator data available</Text>;
  }

  const columnConfig = [
    { key: 'creator', label: 'Creator' },
    { key: 'category', label: 'Category' },
    { key: 'followers', label: 'Followers' },
    { key: 'engagementRate', label: 'Eng. Rate' },
    { key: 'reelsImpressions', label: 'IG Reels Views' },
    { key: 'storiesImpressions', label: 'IG Story Views' },
    { key: 'postImpressions', label: 'IG Post Views' },
    { key: 'tiktokVideo', label: 'TikTok Post Views' },
    { key: 'deliverables', label: 'Deliverables' },
    { key: 'expectedImpressions', label: 'Exp. Impressions' },
    { key: 'notes', label: 'Notes' },
    { key: 'favorite', label: 'Favorite' },
  ];

  return (
    <VStack align="stretch">
      <Flex width="100%" justify="space-between" align="center">
        <HStack width="100%" spacing={4}>
          {selectedCreators.length > 0 && (
            <>
              <IconButton
                aria-label="Delete selected creators"
                icon={<DeleteIcon />}
                onClick={handleDeleteCreators}
                colorScheme="red"
                flexShrink={0}
              />
              <IconButton
                aria-label="Add deliverables to selected creators"
                icon={<AddIcon />}
                onClick={handleBulkDeliverables}
                colorScheme="blue"
                flexShrink={0}
              />
            </>
          )}
          <Input
            placeholder="Search creator by username or name..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            flexGrow={1}
          />
        </HStack>
        <Box>
          <Column
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
          />
        </Box>
      </Flex>
      <Box
        height="calc(100vh - 286px)"
        overflowY="scroll"
        css={{
          '&::-webkit-scrollbar': { display: 'none' },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        <Table variant="simple">
          <Thead position="sticky" top={0} bg="white" zIndex={1}>
            <Tr>
              <Th px={2} width="40px">
                <Checkbox
                  isChecked={selectedCreators.length === filteredData.length}
                  isIndeterminate={
                    selectedCreators.length > 0 &&
                    selectedCreators.length < filteredData.length
                  }
                  onChange={handleSelectAll}
                  size="lg"
                  onClick={(e) => e.stopPropagation()}
                />
              </Th>
              {columnConfig.map(
                ({ key, label }) =>
                  visibleColumns[key] && (
                    <Th
                      key={key}
                      onClick={() => handleSort(key)}
                      cursor="pointer"
                      textAlign="left"
                    >
                      <Flex align="center">
                        {label}
                        {getSortIcon(key)}
                      </Flex>
                    </Th>
                  )
              )}
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((creator, index) => (
              <Tr
                key={index}
                onClick={() => handleRowClick(creator.creatorId, index)} // Pass index
                style={{ cursor: 'pointer' }}
                _hover={{ bg: 'gray.100' }}
                bg={
                  selectedCreatorId === creator.creatorId ? 'gray.200' : 'transparent'
                } // Highlight selected row
              >
                <Td
                  px={2}
                  width="40px"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Checkbox
                    isChecked={selectedCreators.includes(creator.creatorId)}
                    onChange={() => handleCheckboxChange(creator.creatorId)}
                    size="lg"
                    onClick={(e) => e.stopPropagation()}
                  />
                </Td>
                {visibleColumns.creator && (
                  <Td py={2}>
                    <Creator
                      profilePicUrl={
                        creator.instagram_profile?.profile_pic_url ||
                        '/api/placeholder/50/50'
                      }
                      username={creator.instagram_profile?.username || 'N/A'}
                      name={creator.instagram_profile?.name || ''}
                      isVerified={creator.instagram_profile?.is_verified || false}
                      hoobeHandle={creator.hoobe_handle || ''}
                    />
                  </Td>
                )}
                {visibleColumns.category && (
                  <Td>{creator.instagram_profile?.category || 'N/A'}</Td>
                )}
                {visibleColumns.followers && (
                  <Td>
                    <Followers
                      followersCount={creator.instagram_profile?.followers_count}
                      username={creator.instagram_profile?.username}
                    />
                  </Td>
                )}
                {visibleColumns.engagementRate && (
                  <Td>
                    {creator.instagram_profile?.engagement_rate != null
                      ? `${(creator.instagram_profile.engagement_rate * 100).toFixed(2)}%`
                      : 'N/A'}
                  </Td>
                )}
                {visibleColumns.reelsImpressions && (
                  <Td>
                    {creator.instagram_profile?.projected_reel_views != null
                      ? FormatNumber(creator.instagram_profile.projected_reel_views)
                      : '0'}
                  </Td>
                )}
                {visibleColumns.storiesImpressions && (
                  <Td>
                    {creator.instagram_profile?.projected_story_views != null
                      ? FormatNumber(creator.instagram_profile.projected_story_views)
                      : '0'}
                  </Td>
                )}
                {visibleColumns.postImpressions && (
                  <Td>
                    {creator.instagram_profile?.projected_post_views != null
                      ? FormatNumber(creator.instagram_profile.projected_post_views)
                      : '0'}
                  </Td>
                )}
                {visibleColumns.tiktokVideo && (
                  <Td>
                    {creator.tiktok_profile?.projected_video_views != null
                      ? FormatNumber(creator.tiktok_profile.projected_video_views)
                      : '0'}
                  </Td>
                )}
                {visibleColumns.deliverables && (
                  <Td onClick={(e) => e.stopPropagation()}>
                    <Deliverables
                      initialDeliverables={creator.deliverables || []}
                      onChange={(newDeliverables) =>
                        handleCreatorUpdate(creator.creatorId, {
                          deliverables: newDeliverables,
                        })
                      }
                    />
                  </Td>
                )}
                {visibleColumns.expectedImpressions && (
                  <Td>{FormatNumber(creator.expectedImpressions)}</Td>
                )}
                {visibleColumns.notes && (
                  <Td onClick={(e) => e.stopPropagation()}>
                    <EditableNotes
                      initialNotes={creator.notes}
                      onSave={(newNotes) =>
                        handleCreatorUpdate(creator.creatorId, { notes: newNotes })
                      }
                    />
                  </Td>
                )}
                {visibleColumns.favorite && (
                  <Td>
                    <IconButton
                      aria-label="Toggle Favorite"
                      icon={
                        creator.isFavorite ? (
                          <FaStar color="#fcba03" />
                        ) : (
                          <FaRegStar />
                        )
                      }
                      variant="ghost"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleFavorite(creator.creatorId, creator.isFavorite);
                      }}
                    />
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <ListsBulkButton
        isOpen={isBulkModalOpen}
        onClose={() => setIsBulkModalOpen(false)}
        onBulkDeliverablesChange={handleBulkDeliverablesChange}
      />
      <CreatorDrawer
        isOpen={isDrawerOpen}
        onClose={handleDrawerClose} // Use the new close handler
        uniqueId={selectedCreatorId}
      />
    </VStack>
  );
};

export default React.memo(ListsTable);
