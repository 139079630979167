import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  HStack,
  VStack,
  Image,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { db, storage } from "../../../../../Firebase";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { CSVLink } from "react-csv"; // Import CSVLink from react-csv
import { TbDownload } from "react-icons/tb";

const SettingsModal = ({ isOpen, onClose, workspaceId, listId, groupId }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState(null);
  const [csvData, setCsvData] = useState([]); // State to hold CSV data
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchListData = async () => {
      if (workspaceId && groupId && listId) {
        const listDocRef = doc(
          db,
          "workspaces",
          workspaceId,
          "groups",
          groupId,
          "lists",
          listId
        );
        const listDocSnap = await getDoc(listDocRef);
        if (listDocSnap.exists()) {
          const data = listDocSnap.data();
          setName(data.name || "");
          setDescription(data.description || "");
          setImageURL(data.imageURL || "");
          setRawData(data); // Store raw data for CSV generation
        }
      }
    };
    fetchListData();
  }, [workspaceId, groupId, listId]);

  useEffect(() => {
    const fetchCreatorData = async () => {
      if (!rawData || !rawData.creators || rawData.creators.length === 0) {
        return;
      }

      setLoading(true);

      try {
        const creatorIds = rawData.creators.map((creator) => creator.creatorId);
        const response = await fetch(
          `${process.env.REACT_APP_HOOBE_API_URL}/lists-full`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ creatorIds }),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch creator data: ${response.statusText}`
          );
        }

        const responseData = await response.json();

        // Generate CSV data
        generateCsvData(rawData.creators, responseData);
      } catch (error) {
        console.error("Error in fetchCreatorData:", error);
        toast({
          title: "Error",
          description: "Failed to fetch creator data. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCreatorData();
  }, [rawData, toast]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImageURL(URL.createObjectURL(file));
    }
  };

  const handleImageUpload = async (file) => {
    if (!file) return null;
    const storageRef = ref(
      storage,
      `workspaces/${workspaceId}/groups/${groupId}/lists/${listId}/listImage`
    );
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      let newImageURL = imageURL;
      if (imageFile) {
        newImageURL = await handleImageUpload(imageFile);
      }

      const listDocRef = doc(
        db,
        "workspaces",
        workspaceId,
        "groups",
        groupId,
        "lists",
        listId
      );
      await updateDoc(listDocRef, {
        name,
        description,
        imageURL: newImageURL,
      });

      toast({
        title: "List updated",
        description: "The list has been successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error("Error updating list:", error);
      toast({
        title: "Error",
        description: "Failed to update the list. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this list? This action cannot be undone."
      )
    ) {
      setLoading(true);
      try {
        const listDocRef = doc(
          db,
          "workspaces",
          workspaceId,
          "groups",
          groupId,
          "lists",
          listId
        );
        await deleteDoc(listDocRef);
        toast({
          title: "List deleted",
          description: "The list has been successfully deleted.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
        navigate(`/dashboard/lists/${groupId}`);
      } catch (error) {
        console.error("Error deleting list:", error);
        toast({
          title: "Error",
          description: "Failed to delete the list. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // Function to generate CSV data
  const generateCsvData = (rawCreators, detailedCreators) => {
    if (!rawCreators || !detailedCreators) {
      setCsvData([]);
      return;
    }

    const combinedData = rawCreators.map((creator) => {
      const matchingCreator =
        detailedCreators.find((c) => c.unique_id === creator.creatorId) || {};
      return { ...creator, ...matchingCreator };
    });

    const csvHeaders = [
      { label: "Creator ID", key: "creatorId" },
      { label: "Name", key: "name" },
      { label: "Instagram Username", key: "instagramUsername" },
      { label: "Instagram Followers", key: "instagramFollowers" },
      { label: "TikTok Username", key: "tiktokUsername" },
      { label: "TikTok Followers", key: "tiktokFollowers" },
      { label: "Instagram Engagement Rate", key: "instagramEngagementRate" },
      { label: "Instagram Reels Impressions", key: "instagramReelViews" },
      { label: "Instagram Story Impressions", key: "instagramStoryViews" },
      { label: "Instagram Post Impressions", key: "instagramPostViews" },
      { label: "TikTok Post Impressions", key: "tiktokVideoViews" },
      { label: "Deliverables", key: "deliverables" },
      { label: "Expected Impressions", key: "expectedImpressions" },
      { label: "Notes", key: "notes" },
      { label: "Favorite", key: "isFavorite" },
    ];

    const data = combinedData.map((creator) => {
      // Calculate Expected Impressions
      const deliverables = creator.deliverables || [];
      let totalImpressions = 0;

      const impressionsMapping = {
        "Instagram Story": creator.instagram_profile?.projected_story_views || 0,
        "Instagram Post": creator.instagram_profile?.projected_post_views || 0,
        "Instagram Reel": creator.instagram_profile?.projected_reel_views || 0,
        "TikTok Video": creator.tiktok_profile?.projected_video_views || 0,
      };

      deliverables.forEach((deliverable) => {
        const { type, count } = deliverable;
        const projectedImpressionsPerType = impressionsMapping[type] || 0;
        totalImpressions += count * projectedImpressionsPerType;
      });

      return {
        creatorId: creator.creatorId || "",
        name:
          creator.instagram_profile?.name ||
          creator.name ||
          creator.tiktok_profile?.name ||
          "",
        instagramUsername: creator.instagram_profile?.username || "",
        instagramFollowers: creator.instagram_profile?.followers_count || "",
        tiktokUsername: creator.tiktok_profile?.username || "",
        tiktokFollowers: creator.tiktok_profile?.followers_count || "",
        instagramEngagementRate: creator.instagram_profile?.engagement_rate
          ? (creator.instagram_profile.engagement_rate * 100).toFixed(2) + "%"
          : "",
        instagramReelViews:
          creator.instagram_profile?.projected_reel_views || "",
        instagramStoryViews:
          creator.instagram_profile?.projected_story_views || "",
        instagramPostViews:
          creator.instagram_profile?.projected_post_views || "",
        tiktokVideoViews: creator.tiktok_profile?.projected_video_views || "",
        deliverables: deliverables
          .map((d) => `${d.count} x ${d.type}`)
          .join("; "),
        expectedImpressions: totalImpressions || "",
        notes: creator.notes || "",
        isFavorite: creator.isFavorite ? "Yes" : "No",
      };
    });

    setCsvData({
      headers: csvHeaders,
      data: data,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Enter a name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="Enter a description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Image</FormLabel>
              <HStack>
                {imageURL && (
                  <Image
                    src={imageURL}
                    alt="List image"
                    maxHeight="50px"
                    mt={2}
                    borderRadius="md"
                  />
                )}
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </HStack>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <CSVLink
              data={csvData.data || []}
              headers={csvData.headers || []}
              filename={`${name || "list"}.csv`}
              style={{ textDecoration: "none" }}
            >
              <Button borderWidth="1px" leftIcon={<TbDownload />} isLoading={loading || !csvData.data}>
                Export List
              </Button>
            </CSVLink>
            <Button colorScheme="blue" onClick={handleSave} isLoading={loading}>
              Save
            </Button>
            <Button colorScheme="red" onClick={handleDelete} isLoading={loading}>
              Delete
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SettingsModal;
