import React, { useEffect } from "react";
import {
  Select,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const Gender = ({ selectedGender, setSelectedGender }) => {
  
  useEffect(() => {}, [selectedGender]);

  return (
    <InputGroup>
      <Select
        value={selectedGender || ""}
        onChange={(e) => setSelectedGender(e.target.value)}
      >
        <option value="" disabled hidden>
          Select gender
        </option>
        <option value="male">👨 Male</option>
        <option value="female">👩 Female</option>
        <option value="brand">💼 Brand</option>
        <option value="other">👽 Other</option>
      </Select>

      {selectedGender && (
        <InputRightElement width="2.5rem">
          <IconButton
            aria-label="Clear selection"
            icon={<CloseIcon />}
            size="sm"
            borderRadius="xl"
            bg="white"
            onClick={() => setSelectedGender("")}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default Gender;
