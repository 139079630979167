import React from 'react';
import {
  Box,
  Grid,
  GridItem,
  Stat,
  StatLabel,
  StatNumber,
  Progress,
} from '@chakra-ui/react';
import { FormatNumber } from '../../../formatting/FormatNumber';
import PropTypes from 'prop-types';

const CampaignTrackingOverview = ({
  posts = [],
  creators = [],
  cpm = 0,
  budget = 0,
}) => {
  // Create a Set of creator IDs for quick lookup
  const creatorIdsSet = new Set(creators.map((creator) => creator.creatorId));

  // Initialize total metrics
  let totalImpressions = 0;
  let totalLikes = 0;
  let totalShares = 0;
  let totalComments = 0;

  // Aggregate metrics from posts
  if (posts.length > 0) {
    posts.forEach((post) => {
      if (creatorIdsSet.has(post.creatorId)) {
        totalImpressions += post.impressions || 0;
        totalLikes += post.likes || 0;
        totalShares += post.shares || 0;
        totalComments += post.comments || 0;
      }
    });
  }

  const totalCreators = creators.length;

  // Safely calculate creators with posts
  const creatorsWithPostsCount = creators.filter((creator) =>
    posts.some((post) => post.creatorId === creator.creatorId)
  ).length;

  const totalExpectedImpressions =
    cpm && budget ? Math.round((budget / cpm) * 1000) : 0;

  const creatorsProgress =
    totalCreators > 0 ? (creatorsWithPostsCount / totalCreators) * 100 : 0;
  const impressionsProgress =
    totalExpectedImpressions > 0
      ? (totalImpressions / totalExpectedImpressions) * 100
      : 0;

  const cappedCreatorsProgress = Math.min(creatorsProgress, 100);
  const cappedImpressionsProgress = Math.min(impressionsProgress, 100);

  // If both posts and creators are empty, render nothing
  if (posts.length === 0 && creators.length === 0) {
    return null; // Or you can return a message indicating no data
  }

  return (
    <Box mb={4}>
      <Grid templateColumns={{ base: '1fr', md: '2fr 1fr' }} gap={4}>
        <GridItem>
          <Grid templateColumns={{ base: '1fr', sm: '1fr 1fr' }} gap={4}>
            <GridItem>
              <Stat p={4} borderRadius="md" borderWidth="1px" h="110px">
                <StatLabel>Total Impressions</StatLabel>
                <StatNumber>
                  {FormatNumber(totalImpressions)}
                </StatNumber>
              </Stat>
            </GridItem>
            <GridItem>
              <Stat p={4} borderRadius="md" borderWidth="1px" h="110px">
                <StatLabel>Total Likes</StatLabel>
                <StatNumber>{FormatNumber(totalLikes)}</StatNumber>
              </Stat>
            </GridItem>
            <GridItem>
              <Stat p={4} borderRadius="md" borderWidth="1px" h="110px">
                <StatLabel>Total Shares</StatLabel>
                <StatNumber>{FormatNumber(totalShares)}</StatNumber>
              </Stat>
            </GridItem>
            <GridItem>
              <Stat p={4} borderRadius="md" borderWidth="1px" h="110px">
                <StatLabel>Total Comments</StatLabel>
                <StatNumber>{FormatNumber(totalComments)}</StatNumber>
              </Stat>
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem>
          <Grid templateRows="repeat(2, 1fr)" gap={4}>
            <GridItem>
              <Stat p={4} borderRadius="md" borderWidth="1px" h="110px">
                <StatLabel>Creators with Posts</StatLabel>
                <StatNumber>
                  {FormatNumber(creatorsWithPostsCount)} /{' '}
                  {FormatNumber(totalCreators)}
                </StatNumber>
                <Progress
                  mt={2}
                  value={cappedCreatorsProgress}
                  colorScheme={
                    cappedCreatorsProgress >= 100 ? 'green' : 'blue'
                  }
                  size="md"
                  borderRadius="full"
                />
              </Stat>
            </GridItem>
            <GridItem>
              <Stat p={4} borderRadius="md" borderWidth="1px" h="110px">
                <StatLabel>Impressions Achieved</StatLabel>
                <StatNumber>
                  {FormatNumber(totalImpressions)} /{' '}
                  {FormatNumber(totalExpectedImpressions)}
                </StatNumber>
                <Progress
                  mt={2}
                  value={cappedImpressionsProgress}
                  colorScheme={
                    cappedImpressionsProgress >= 100 ? 'green' : 'blue'
                  }
                  size="md"
                  borderRadius="full"
                />
              </Stat>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Box>
  );
};

// Define PropTypes to enforce prop types and provide better documentation
CampaignTrackingOverview.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      creatorId: PropTypes.string.isRequired,
      impressions: PropTypes.number,
      likes: PropTypes.number,
      shares: PropTypes.number,
      comments: PropTypes.number,
    })
  ),
  creators: PropTypes.arrayOf(
    PropTypes.shape({
      creatorId: PropTypes.string.isRequired,
      // Add other creator properties if needed
    })
  ),
  cpm: PropTypes.number,
  budget: PropTypes.number,
};

export default CampaignTrackingOverview;
