import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Text,
  Flex,
  Spinner,
  Container,
  Center,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth, functions } from "../Firebase";
import { httpsCallable } from "firebase/functions";
import { FcGoogle } from "react-icons/fc";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const logUserInfo = httpsCallable(functions, 'logUserInfo');

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      await logUserInfo({ email, uid: userCredential.user.uid });
      navigate("/dashboard");
    } catch (error) {
      console.error("Error logging in:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    setIsLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await logUserInfo({ fullName: user.displayName, email: user.email, uid: user.uid });
      navigate("/dashboard");
    } catch (error) {
      console.error("Error signing in with Google:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box bg="gray.200" minHeight="100vh" display="flex" alignItems="center" justifyContent="center">
      <Container maxWidth="500px">
        <Box bg="white" p={8} borderRadius="xl" boxShadow="md">
          <VStack spacing={4} align="stretch">
            <Center>
              <Heading>Login</Heading>
            </Center>
            <form onSubmit={handleEmailLogin}>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                />
              </FormControl>
              <FormControl mt={4}>
                <Flex justifyContent="space-between" alignItems="center">
                  <FormLabel>Password</FormLabel>
                  <Link to="/password-reset" style={{ fontSize: "0.9em", color: "blue.500" }}>
                    Forgot Password?
                  </Link>
                </Flex>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  required
                />
              </FormControl>
              <Button
                type="submit"
                bg="gray.800"
                color="white"
                size="lg"
                width="full"
                _hover={{ bg: "black" }}
                mt={4}
                isLoading={isLoading}
                loadingText="Logging In"
                spinner={<Spinner size="sm" />}
                disabled={isLoading}
              >
                Login
              </Button>
            </form>
            <Button
              leftIcon={<FcGoogle />}
              onClick={handleGoogleLogin}
              width="full"
              size="lg"
              isLoading={isLoading}
              loadingText="Logging In"
              spinner={<Spinner size="sm" />}
              disabled={isLoading}
            >
              Continue with Google
            </Button>
            <Center>
              <Text>
                Don't have an account? <Link to="/signup">Sign up</Link>
              </Text>
            </Center>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
}

export default Login;