// CampaignsDelete.jsx
import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  useToast,
} from '@chakra-ui/react';
import { getFirestore, doc, runTransaction } from 'firebase/firestore';

const CampaignsDelete = ({
  isOpen,
  onClose,
  selectedCreators,
  workspaceId,
  campaignId,
  onDeletionSuccess,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const toast = useToast();

  const confirmDelete = async () => {
    setIsDeleting(true);
    const db = getFirestore();
    const campaignRef = doc(db, 'workspaces', workspaceId, 'campaigns', campaignId);

    try {
      await runTransaction(db, async (transaction) => {
        const campaignDoc = await transaction.get(campaignRef);
        if (!campaignDoc.exists()) {
          throw new Error('Campaign does not exist!');
        }

        const currentCreators = campaignDoc.data().creators || [];
        const updatedCreators = currentCreators.filter(
          (creator) => !selectedCreators.includes(creator.creatorId)
        );

        transaction.update(campaignRef, { creators: updatedCreators });
      });
      toast({
        title: 'Deletion Successful',
        description: `${selectedCreators.length} creator(s) have been deleted.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
      onDeletionSuccess(); // Callback to parent to reset selectedCreators
    } catch (error) {
      console.error('Error deleting creators:', error);
      toast({
        title: 'Deletion Failed',
        description: 'There was an error deleting the selected creators.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm Deletion</ModalHeader>
        <ModalBody>
          <Text>
            Are you sure you want to delete the selected{' '}
            {selectedCreators.length}{' '}
            {selectedCreators.length === 1 ? 'creator' : 'creators'}?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose} isDisabled={isDeleting}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={confirmDelete}
            isLoading={isDeleting}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CampaignsDelete;
