// src/components/tabs/campaigns-page/CampaignsNew.js
import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Heading,
  Input,
  Button,
  Textarea,
  Flex,
  Image,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  VStack,
  Checkbox,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useToast,
  FormControl,
  FormLabel,
  Progress,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useNavigate, useOutletContext } from "react-router-dom";
import { db, storage } from "../../../Firebase"; // Ensure storage is imported
import {
  collection,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage"; // Import necessary storage functions
import { FaHandPointer, FaRobot } from "react-icons/fa";
import { AnimatePresence } from "framer-motion";
import MotionBox from "./MotionBox"; // Adjust the import path based on your project structure

const CampaignsNew = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 5;

  const [campaignType, setCampaignType] = useState(null);

  const [campaignName, setCampaignName] = useState("");
  const [campaignDescription, setCampaignDescription] = useState("");
  const [campaignImage, setCampaignImage] = useState(null);

  const [budget, setBudget] = useState("");
  const [cpm, setCpm] = useState("");

  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [lists, setLists] = useState({});
  const [selectedLists, setSelectedLists] = useState([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [isLoadingLists, setIsLoadingLists] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [keywords, setKeywords] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [progressPercentage, setProgressPercentage] = useState((currentStep / totalSteps) * 100);

  const { workspaceData } = useOutletContext();
  const navigate = useNavigate();
  const toast = useToast();

  const fetchGroups = useCallback(async () => {
    if (!workspaceData?.workspace_id) return;
    setIsLoadingGroups(true);
    try {
      const groupsRef = collection(db, "workspaces", workspaceData.workspace_id, "groups");
      const groupsSnapshot = await getDocs(query(groupsRef, orderBy("order")));
      const fetchedGroups = groupsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setGroups(fetchedGroups);
    } catch (error) {
      console.error("Error fetching groups:", error);
      toast({
        title: "Error",
        description: "Failed to fetch groups.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingGroups(false);
    }
  }, [workspaceData, toast]);

  const fetchLists = useCallback(async (groupId) => {
    if (!workspaceData?.workspace_id || !groupId) return;
    setIsLoadingLists(true);
    try {
      const listsRef = collection(db, "workspaces", workspaceData.workspace_id, "groups", groupId, "lists");
      const listsSnapshot = await getDocs(query(listsRef, orderBy("order")));
      setLists(prevLists => ({
        ...prevLists,
        [groupId]: listsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
      }));
    } catch (error) {
      console.error("Error fetching lists:", error);
      toast({
        title: "Error",
        description: "Failed to fetch lists.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingLists(false);
    }
  }, [workspaceData, toast]);

  useEffect(() => {
    if (currentStep === 4) {
      const loadGroupsAndLists = async () => {
        await fetchGroups();
      };
      loadGroupsAndLists();
    }
  }, [currentStep, fetchGroups]);

  useEffect(() => {
    if (currentStep === 4 && groups.length > 0) {
      groups.forEach(group => {
        fetchLists(group.id);
      });
    }
  }, [currentStep, groups, fetchLists]);

  useEffect(() => {
    setProgressPercentage((currentStep / totalSteps) * 100);
  }, [currentStep, totalSteps]);

  const handleGroupToggle = (groupId) => {
    setSelectedGroups(prevSelected => {
      if (prevSelected.includes(groupId)) {
        return prevSelected.filter(id => id !== groupId);
      }
      return [...prevSelected, groupId];
    });
  };

  const handleListToggle = (groupId, listId) => {
    setSelectedLists(prevSelected => {
      const exists = prevSelected.find(item => item.groupId === groupId && item.listId === listId);
      if (exists) {
        return prevSelected.filter(item => !(item.groupId === groupId && item.listId === listId));
      } else {
        return [...prevSelected, { groupId, listId }];
      }
    });
  };

  const handleAddKeyword = () => {
    const trimmedKeyword = keyword.trim();
    if (trimmedKeyword && !keywords.includes(trimmedKeyword)) {
      setKeywords(prevKeywords => [...prevKeywords, trimmedKeyword]);
      setKeyword("");
    }
  };

  const handleRemoveKeyword = (keywordToRemove) => {
    setKeywords(prevKeywords => prevKeywords.filter(k => k !== keywordToRemove));
  };

  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 1000000); // Generates a random number between 0 and 999999
  };

  const handleNext = () => {
    switch (currentStep) {
      case 1:
        if (!campaignType) {
          toast({
            title: "Validation Error",
            description: "Please select a campaign type.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
        break;
      case 2:
        if (!campaignName.trim()) {
          toast({
            title: "Validation Error",
            description: "Campaign name is required.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
        break;
      case 3:
        if (budget && isNaN(parseFloat(budget))) {
          toast({
            title: "Validation Error",
            description: "Budget must be a number.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
        if (cpm && isNaN(parseFloat(cpm))) {
          toast({
            title: "Validation Error",
            description: "CPM must be a number.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
        break;
      case 4:
        if (selectedGroups.length === 0 && selectedLists.length === 0) {
          toast({
            title: "Validation Error",
            description: "Please select at least one group or one list.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
        break;
      default:
        break;
    }

    setCurrentStep(prev => Math.min(prev + 1, totalSteps));
  };

  const handleBack = () => {
    setCurrentStep(prev => Math.max(prev - 1, 1));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setProgressPercentage(100);

    let imageUrl = null;

    if (campaignImage) {
      try {
        const imageExtension = campaignImage.name.split('.').pop();
        const randomNumber = generateRandomNumber();
        const imagePath = `workspaces/${workspaceData.workspace_id}/campaigns/${randomNumber}.${imageExtension}`;
        const imageStorageRef = storageRef(storage, imagePath);

        // Upload the image to Firebase Storage
        await uploadBytes(imageStorageRef, campaignImage);

        // Get the download URL
        imageUrl = await getDownloadURL(imageStorageRef);
      } catch (error) {
        console.error("Error uploading image:", error);
        toast({
          title: "Image Upload Error",
          description: "Failed to upload campaign image.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsSubmitting(false);
        setProgressPercentage((currentStep / totalSteps) * 100);
        return;
      }
    }

    const campaignData = {
      type: campaignType,
      name: campaignName.trim(),
      description: campaignDescription.trim(),
      imageUrl, // Updated to include the image URL
      budget: parseFloat(budget) || 0,
      cpm: parseFloat(cpm) || 0,
      selectedGroups,
      selectedLists,
      listeningKeywords: keywords,
      createdAt: new Date().toISOString(),
      workspaceId: workspaceData.workspace_id,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_HOOBE_API_URL}/campaigns-create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(campaignData),
      });

      if (!response.ok) {
        throw new Error(`Server error: ${response.statusText}`);
      }

      const data = await response.json();
      const campaignId = data.id;

      toast({
        title: "Campaign Created",
        description: "Your campaign has been successfully created.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      navigate(`/dashboard/campaigns/${campaignId}`);
    } catch (error) {
      console.error("Error creating campaign:", error);
      toast({
        title: "Error",
        description: "Failed to create campaign. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setProgressPercentage((currentStep / totalSteps) * 100);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!workspaceData?.workspace_id)
    return <Box p={4}>Workspace data is unavailable.</Box>;

  // Define animation variants
  const variants = {
    initial: { opacity: 0, y: 100 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -100 },
  };

  return (
    <Box position="relative" minHeight="100vh">
      {/* Header */}
      <Flex
        height="60px"
        alignItems="center"
        borderBottomWidth="2px"
        p={4}
        justifyContent="space-between"
      >
        <Heading
          size="md"
          cursor="pointer"
          onClick={() => navigate("/dashboard/campaigns")}
        >
          Campaigns
        </Heading>
      </Flex>

      {/* Main Content */}
      <Flex
        direction="column"
        align="center"
        justify="center"
        mt={50}
        px={4}
      >
        <Box width="100%" maxWidth="500px">
          <VStack spacing={4} align="stretch">
            <AnimatePresence mode="wait">
              <MotionBox
                key={currentStep}
                variants={variants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1 }}
              >
                {currentStep === 1 && (
                  <FormControl as="fieldset" id="campaign-type" isRequired>
                    <FormLabel as="legend" fontWeight="bold" fontSize="xl" mb={4} textAlign="center">
                      Select Campaign Type
                    </FormLabel>
                    <HStack spacing={6} justify="center">
                      <Button
                        borderWidth="2px"
                        bg={campaignType === "manual" ? "blue.100" : "white"}
                        onClick={() => setCampaignType("manual")}
                        size="lg"
                        width="240px"
                        fontSize="xl"
                        h="200px"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        _hover={{ bg: "blue.50" }}
                      >
                        <FaHandPointer size={60} />
                        <Text mt={4}>Manual</Text>
                      </Button>
                      <Button
                        borderWidth="2px"
                        bg="white"
                        isDisabled
                        size="lg"
                        width="240px"
                        fontSize="xl"
                        h="200px"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <FaRobot size={60} />
                        <Text mt={4}>Automatic <br /> (Coming Soon)</Text>
                      </Button>
                    </HStack>
                  </FormControl>
                )}

                {currentStep === 2 && (
                  <>
                    <FormControl id="campaign-name" isRequired>
                      <FormLabel fontWeight="bold">Campaign Name</FormLabel>
                      <Input
                        placeholder="Enter campaign name"
                        value={campaignName}
                        onChange={(e) => setCampaignName(e.target.value)}
                      />
                    </FormControl>

                    <FormControl id="campaign-description">
                      <FormLabel fontWeight="bold">Campaign Description</FormLabel>
                      <Textarea
                        placeholder="Enter campaign description"
                        value={campaignDescription}
                        onChange={(e) => setCampaignDescription(e.target.value)}
                      />
                    </FormControl>

                    <FormControl id="campaign-image">
                      <FormLabel fontWeight="bold">Campaign Image</FormLabel>
                      <Input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          if (e.target.files && e.target.files[0]) {
                            setCampaignImage(e.target.files[0]);
                          }
                        }}
                      />
                      {campaignImage && (
                        <Flex mt={2} align="center" justify="flex-start">
                          <Image
                            src={URL.createObjectURL(campaignImage)}
                            alt="Selected"
                            boxSize="50px"
                            objectFit="cover"
                            mr={2}
                            borderRadius="md"
                          />
                          <Text>{campaignImage.name}</Text>
                        </Flex>
                      )}
                    </FormControl>
                  </>
                )}

                {currentStep === 3 && (
                  <>
                    <FormControl id="budget">
                      <FormLabel fontWeight="bold">Budget</FormLabel>
                      <NumberInput
                        value={budget}
                        onChange={(valueString) => setBudget(valueString)}
                        min={0}
                      >
                        <NumberInputField placeholder="Enter budget" />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>

                    <FormControl id="cpm">
                      <FormLabel fontWeight="bold">CPM</FormLabel>
                      <NumberInput
                        value={cpm}
                        onChange={(valueString) => setCpm(valueString)}
                        min={0}
                      >
                        <NumberInputField placeholder="Enter CPM" />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  </>
                )}

                {currentStep === 4 && (
                  <>
                    <FormControl id="groups-lists">
                      <FormLabel fontWeight="bold" display="flex" alignItems="center">
                        Select Groups and Lists
                      </FormLabel>
                      {isLoadingGroups ? (
                        <Flex align="center" justify="center" py={4}>
                          <Spinner />
                          <Text ml={2}>Loading groups...</Text>
                        </Flex>
                      ) : (
                        <Accordion allowMultiple>
                          {groups.map(group => (
                            <AccordionItem key={group.id}>
                              {({ isExpanded }) => (
                                <>
                                  <h2>
                                    <AccordionButton>
                                      <Checkbox
                                        isChecked={selectedGroups.includes(group.id)}
                                        onChange={() => handleGroupToggle(group.id)}
                                        mr={2}
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                      <Box flex="1" textAlign="left">
                                        {group.name}
                                      </Box>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel pb={4}>
                                    {isLoadingLists && !lists[group.id] ? (
                                      <Flex align="center" justify="center" py={2}>
                                        <Spinner size="sm" />
                                        <Text ml={2}>Loading lists...</Text>
                                      </Flex>
                                    ) : (
                                      <VStack align="start" spacing={2}>
                                        {lists[group.id] && lists[group.id].length > 0 ? (
                                          lists[group.id].map(list => (
                                            <Checkbox
                                              key={list.id}
                                              isChecked={
                                                selectedLists.some(item => item.groupId === group.id && item.listId === list.id)
                                              }
                                              onChange={() => handleListToggle(group.id, list.id)}
                                            >
                                              {list.name}
                                            </Checkbox>
                                          ))
                                        ) : (
                                          <Text>No lists available.</Text>
                                        )}
                                      </VStack>
                                    )}
                                  </AccordionPanel>
                                </>
                              )}
                            </AccordionItem>
                          ))}
                        </Accordion>
                      )}
                    </FormControl>

                    <Box>
                      {selectedGroups.length > 0 && (
                        <>
                          <FormLabel fontWeight="bold" display="flex" alignItems="center">
                            Selected Groups
                            <Tooltip label="By adding this group you will add all creators from all lists from this group">
                              <InfoIcon ml={2} />
                            </Tooltip>
                          </FormLabel>
                          <HStack spacing={4} wrap="wrap">
                            {selectedGroups.map(groupId => {
                              const group = groups.find(g => g.id === groupId);
                              return (
                                <Tag key={groupId} size="md" borderRadius="full" variant="solid" colorScheme="blue">
                                  <TagLabel>{group?.name || "Unknown Group"}</TagLabel>
                                  <TagCloseButton onClick={() => handleGroupToggle(groupId)} />
                                </Tag>
                              );
                            })}
                          </HStack>
                        </>
                      )}
                    </Box>

                    <Box>
                      {selectedLists.length > 0 && (
                        <>
                          <FormLabel fontWeight="bold" display="flex" alignItems="center">
                            Selected Lists
                            <Tooltip label="You will only be adding creators to this campaign from this list">
                              <InfoIcon ml={2} />
                            </Tooltip>
                          </FormLabel>
                          <HStack spacing={4} wrap="wrap">
                            {selectedLists.map((item, index) => {
                              const group = groups.find(g => g.id === item.groupId);
                              const list = lists[item.groupId]?.find(l => l.id === item.listId);
                              return (
                                <Tag key={`${item.groupId}-${item.listId}`} size="md" borderRadius="full" variant="solid" colorScheme="blue">
                                  <TagLabel>{group?.name} - {list?.name || "Unknown List"}</TagLabel>
                                  <TagCloseButton onClick={() => handleListToggle(item.groupId, item.listId)} />
                                </Tag>
                              );
                            })}
                          </HStack>
                        </>
                      )}
                    </Box>
                  </>
                )}

                {currentStep === 5 && (
                  <>
                    <FormControl id="keywords">
                      <FormLabel fontWeight="bold">Listening Keywords</FormLabel>
                      <Flex mb={2}>
                        <Input
                          placeholder="Add keyword"
                          value={keyword}
                          onChange={(e) => setKeyword(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              handleAddKeyword();
                            }
                          }}
                          mr={2}
                        />
                        <Button colorScheme="blue" onClick={handleAddKeyword}>
                          Add
                        </Button>
                      </Flex>
                      <HStack spacing={4} wrap="wrap">
                        {keywords.map((k, index) => (
                          <Tag key={index} size="md" borderRadius="full" variant="solid" colorScheme="blue">
                            <TagLabel>{k}</TagLabel>
                            <TagCloseButton onClick={() => handleRemoveKeyword(k)} />
                          </Tag>
                        ))}
                      </HStack>
                    </FormControl>
                  </>
                )}
                    {/* Navigation Buttons */}
            <Flex justify="space-between" mt={6}>
              <Button
                onClick={handleBack}
                isDisabled={currentStep === 1 || isSubmitting}
                borderWidth="1px"
                bg="white"
              >
                Back
              </Button>
              {currentStep < totalSteps && (
                <Button
                  borderWidth="1px"
                  bg="white"
                  onClick={handleNext}
                  isDisabled={
                    (currentStep === 1 && !campaignType) || isSubmitting
                  }
                >
                  Next
                </Button>
              )}
              {currentStep === totalSteps && (
                <Button
                  colorScheme="blue"
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                  loadingText="Creating"
                >
                  Create Campaign
                </Button>
              )}
            </Flex>
              </MotionBox>
            </AnimatePresence>

    
          </VStack>
        </Box>
      </Flex>

      {/* Progress Bar */}
      <Box
        position="fixed"
        bottom="50px"
        left="50%"
        transform="translateX(-50%)"
        width="100%"
        maxWidth="500px"
        px={4}
        zIndex={1000}
        ml="125px"
      >
        <Progress
          value={progressPercentage}
          borderRadius="full"
          bg="gray.300"
          size="lg"
          sx={{
            "& > div": {
              transition: isSubmitting ? "width 1s ease" : "width 0.5s ease",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default CampaignsNew;
