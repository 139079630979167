import React, { useState, useRef, useEffect } from "react";
import { Text, Input } from "@chakra-ui/react";

const EditableNotes = ({ initialNotes, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(initialNotes || "");
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onSave(notes);
  };

  const handleChange = (e) => {
    setNotes(e.target.value);
  };

  if (isEditing) {
    return (
      <Input
        ref={inputRef}
        value={notes}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Add a note..."
        w="150px"
      />
    );
  }

  return (
    <Text color={notes ? "black" : "gray.500"} onClick={handleClick}>
      {notes || "Add a note..."}
    </Text>
  );
};

export default EditableNotes;
