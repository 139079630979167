// CampaignsPDF.js
import { jsPDF } from 'jspdf';
import { doc as firestoreDoc, getDoc } from 'firebase/firestore';
import { db } from '../../../Firebase'; // Adjust the import based on your project structure

export const generateCampaignPDF = async (
  shareUrl,
  workspaceId,
  campaignId
) => {
  try {
    // Fetch campaign data from Firestore
    const campaignDocRef = firestoreDoc(
      db,
      'workspaces',
      workspaceId,
      'campaigns',
      campaignId
    );
    const campaignDocSnap = await getDoc(campaignDocRef);

    if (!campaignDocSnap.exists()) {
      console.error('No such campaign document!');
      return;
    }

    const campaignData = campaignDocSnap.data();

    // Extract the required data
    const {
      name: campaignName,
      creators = [],
      posts = [],
    } = campaignData;

    // Create the PDF with letter size
    const pdfDoc = new jsPDF({ unit: 'mm', format: 'letter' });

    // Set font and line height
    pdfDoc.setFont('helvetica');
    pdfDoc.setLineHeightFactor(1.5);

    // Initialize y position
    let yPos = 20;

    // Add the greeting
    pdfDoc.setFontSize(12);
    pdfDoc.text(`Hi ${campaignName || ''},`, 10, yPos);

    yPos += 10;

    // Message content with bullet points
    const message = `I hope this message finds you well. I wanted to take a moment to review the results of the ${campaignName} campaign, now that we have all the necessary data to assess its performance. Below, I have summarized the key metrics:

Campaign Overview:
• Profiles Posted: We achieved a 100% participation rate with all ${creators.length} creators actively engaging in the campaign.
• Media Posted: A total of ${posts.length} pieces of media were created and shared across platforms.

Performance Metrics:
• Reach: The campaign effectively reached an audience of 2.2M, spreading the unique essence of ${campaignName} to a wide and receptive audience.
• Impressions: Generated 1.6M impressions, reflecting the high visibility and frequency of interactions with our content.
• Engagements: Garnered 3,809 engagements, which signifies strong audience interaction and interest in our content and products.

We believe these results not only showcase the success of our collaboration but also underline the strong resonance of the ${campaignName} brand with our targeted audience. Moving forward, we are eager to explore further opportunities for collaboration and to build on the success of this campaign. It's been a pleasure working together, and we're looking forward to future projects.

Any questions, please let me know!`;

    const messageText = pdfDoc.splitTextToSize(message, 190);
    pdfDoc.text(messageText, 10, yPos);

    yPos += messageText.length * 7 + 10; // Adjust line height and add padding

    // Add the 'View Media' button
    pdfDoc.setFontSize(12);
    const buttonWidth = 40;
    const buttonHeight = 10;
    const buttonX = 10;
    const buttonY = yPos;

    // Draw the button
    pdfDoc.setFillColor(0, 122, 255); // Blue color
    pdfDoc.rect(buttonX, buttonY, buttonWidth, buttonHeight, 'F');

    // Add button text
    pdfDoc.setTextColor(255, 255, 255); // White color
    pdfDoc.text(
      'View Media',
      buttonX + buttonWidth / 2,
      buttonY + buttonHeight / 2 + 3,
      { align: 'center' }
    );

    // Reset text color
    pdfDoc.setTextColor(0, 0, 0);

    // Add link to the button
    pdfDoc.link(buttonX, buttonY, buttonWidth, buttonHeight, {
      url: shareUrl,
    });

    yPos += buttonHeight + 20;

    // Footer with horizontal line
    pdfDoc.setDrawColor(0, 0, 0);
    pdfDoc.setLineWidth(0.5);
    pdfDoc.line(10, 270, 200, 270);

    pdfDoc.setFontSize(10);
    pdfDoc.text(
      `Generated on ${new Date().toLocaleDateString()}`,
      105,
      275,
      { align: 'center' }
    );

    // Log the campaign name before saving
    console.log('Downloading PDF for campaign:', campaignName);

    // Save the PDF with the campaign name and timestamp
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
    const sanitizedCampaignName = campaignName.replace(/[^a-zA-Z0-9]/g, '_');
    pdfDoc.save(`${sanitizedCampaignName}_${timestamp}.pdf`);
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};
