import React, { useEffect, useState } from "react";
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Box,
  Text,
  Flex,
  Input,
} from "@chakra-ui/react";

const formatFollowers = (value) => {
  if (value === 10000000000) return "10M+";
  if (value < 1000) return value.toString();
  if (value < 100000) {
    const formatted = (value / 1000).toFixed(1);
    return formatted.endsWith(".0")
      ? formatted.slice(0, -2) + "k"
      : formatted + "k";
  }
  if (value < 1000000) {
    return Math.round(value / 1000) + "k";
  }
  if (value < 10000000) {
    const formatted = (value / 1000000).toFixed(1);
    return formatted.endsWith(".0")
      ? formatted.slice(0, -2) + "M"
      : formatted + "M";
  }
  return Math.round(value / 1000000) + "M";
};

const Followers = ({
  minFollowers,
  maxFollowers,
  setMinFollowers,
  setMaxFollowers,
}) => {
  const [isMinEditing, setIsMinEditing] = useState(false);
  const [isMaxEditing, setIsMaxEditing] = useState(false);
  const [tempMin, setTempMin] = useState(minFollowers);
  const [tempMax, setTempMax] = useState(maxFollowers);
  const [sliderValues, setSliderValues] = useState([0, 100]);

  const convertToFollowers = (value) => {
    if (value <= 50) {
      return Math.round(value * 20000);
    } else if (value === 100) {
      return 10000000000;
    } else {
      return Math.round(1000000 + (value - 50) * 180000);
    }
  };

  const convertToSliderValue = (followers) => {
    if (followers <= 1000000) {
      return Math.round(followers / 20000);
    } else if (followers === 10000000000) {
      return 100;
    } else {
      return Math.round(50 + (followers - 1000000) / 180000);
    }
  };

  const handleSliderChange = (values) => {
    const newMin = convertToFollowers(values[0]);
    const newMax = convertToFollowers(values[1]);

    setMinFollowers(newMin === 0 ? 1000 : newMin);
    setMaxFollowers(newMax === 0 ? 1000 : newMax);

    setTempMin(newMin === 0 ? 1000 : newMin);
    setTempMax(newMax === 0 ? 1000 : newMax);

    setSliderValues(values);
  };

  const handleMinBlur = () => {
    const validMin = Math.max(0, Math.min(tempMin, maxFollowers - 1));
    setMinFollowers(validMin === 0 ? 1000 : validMin);
    setIsMinEditing(false);
    setSliderValues([
      convertToSliderValue(validMin === 0 ? 1000 : validMin),
      sliderValues[1],
    ]);
  };

  const handleMaxBlur = () => {
    const validMax = Math.min(10000000000, Math.max(tempMax, minFollowers + 1));
    setMaxFollowers(validMax === 0 ? 1000 : validMax);
    setIsMaxEditing(false);
    setSliderValues([
      sliderValues[0],
      convertToSliderValue(validMax === 0 ? 1000 : validMax),
    ]);
  };

  const handleKeyPress = (e, type) => {
    if (e.key === "Enter") {
      if (type === "min") {
        handleMinBlur();
      } else {
        handleMaxBlur();
      }
    }
  };

  const handleNumberInput = (e, setTempValue) => {
    const value = e.target.value.replace(/\D/g, "");
    setTempValue(Number(value));
  };

  useEffect(() => {
    setTempMin(minFollowers);
    setTempMax(maxFollowers);
    setSliderValues([
      convertToSliderValue(minFollowers),
      convertToSliderValue(maxFollowers),
    ]);
  }, [minFollowers, maxFollowers]);

  return (
    <Box>
      <Flex direction="column" gap={4}>
        <Box>
          <Box position="relative">
            <Flex justifyContent="space-between" alignItems="center">
              {isMinEditing ? (
                <Input
                  value={tempMin === 0 ? 1000 : tempMin}
                  onChange={(e) => handleNumberInput(e, setTempMin)}
                  onBlur={handleMinBlur}
                  onKeyPress={(e) => handleKeyPress(e, "min")}
                  autoFocus
                  height="40px"
                  mr={5}
                />
              ) : (
                <Text
                  fontWeight="bold"
                  fontSize="20px"
                  onClick={() => setIsMinEditing(true)}
                  lineHeight="52px"
                >
                  {formatFollowers(minFollowers === 0 ? 1000 : minFollowers)}
                </Text>
              )}

              {!isMinEditing && !isMaxEditing && (
                <Text
                  position="absolute"
                  left="50%"
                  transform="translateX(-50%)"
                >
                  Followers
                </Text>
              )}

              {isMaxEditing ? (
                <Input
                  value={tempMax === 0 ? 1000 : tempMax}
                  onChange={(e) => handleNumberInput(e, setTempMax)}
                  onBlur={handleMaxBlur}
                  onKeyPress={(e) => handleKeyPress(e, "max")}
                  autoFocus
                  height="40px"
                  ml={5}
                />
              ) : (
                <Text
                  fontWeight="bold"
                  fontSize="20px"
                  onClick={() => setIsMaxEditing(true)}
                  lineHeight="52px"
                >
                  {maxFollowers === 10000000000
                    ? "10M+"
                    : formatFollowers(maxFollowers === 0 ? 1000 : maxFollowers)}
                </Text>
              )}
            </Flex>
          </Box>

          <Box width="90%" mx="auto">
            <RangeSlider
              aria-label="followers-range-slider"
              value={sliderValues}
              min={0}
              max={100}
              step={1}
              onChange={(values) => handleSliderChange(values)}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb
                borderWidth="2px"
                borderColor="gray.300"
                boxSize={6}
                index={0}
                aria-label="min-followers-thumb"
              />
              <RangeSliderThumb
                borderWidth="2px"
                borderColor="gray.300"
                boxSize={6}
                index={1}
                aria-label="max-followers-thumb"
              />
            </RangeSlider>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Followers;
