import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Icon,
  Checkbox,
} from "@chakra-ui/react";
import { TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";
import Creator from "../../formatting/Creator";
import Followers from "../../formatting/Followers";
import InstaReel from "../../formatting/InstaReel";
import InstaStory from "../../formatting/InstaStory";
import InstaPost from "../../formatting/InstaPost";
import CreatorDrawer from "../../formatting/slideout/CreatorDrawer";
import LinkClickData from "../../formatting/LinkClickData";

const SearchTable = ({
  data,
  onSortChange,
  sortConfig,
  visibleColumns,
  setSelectedCreators,
  selectedCreators
}) => {
  const [selectedCreatorId, setSelectedCreatorId] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const rowRefs = useRef([]);

  const navigateCreator = useCallback(
    (direction) => {
      if (currentIndex !== null) {
        const newIndex = currentIndex + direction;
        if (newIndex >= 0 && newIndex < data.length) {
          setSelectedCreatorId(data[newIndex].unique_id);
          setCurrentIndex(newIndex);
          if (rowRefs.current[newIndex]) {
            rowRefs.current[newIndex].scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }
      }
    },
    [currentIndex, data]
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (selectedCreatorId !== null) {
        if (event.key === "ArrowUp") {
          navigateCreator(-1);
        } else if (event.key === "ArrowDown") {
          navigateCreator(1);
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedCreatorId, navigateCreator]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    } else if (
      sortConfig.key === key &&
      sortConfig.direction === "descending"
    ) {
      direction = "ascending";
    }
    onSortChange({ key, direction });
  };

  const getSortIcon = (key) => (
    <Box
      display="inline-block"
      ml={2}
      position="relative"
      width="10px"
      height="20px"
    >
      <Icon
        as={TriangleUpIcon}
        color={
          sortConfig.key === key && sortConfig.direction === "ascending"
            ? "gray.800"
            : "gray.300"
        }
        boxSize={3}
        position="absolute"
        top="5px"
        left="0"
      />
      <Icon
        as={TriangleDownIcon}
        color={
          sortConfig.key === key && sortConfig.direction === "descending"
            ? "gray.800"
            : "gray.300"
        }
        boxSize={3}
        position="absolute"
        top="16px"
        left="0"
      />
    </Box>
  );

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = data.map((item) => item.unique_id);
      setSelectedCreators(allIds);
    } else {
      setSelectedCreators([]);
    }
  };

  const handleSelectItem = (id, event) => {
    event.stopPropagation();
    setSelectedCreators((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleRowClick = (uniqueId, index) => {
    setSelectedCreatorId(uniqueId);
    setCurrentIndex(index);
    if (rowRefs.current[index]) {
      rowRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleCloseDrawer = () => {
    setSelectedCreatorId(null);
    setCurrentIndex(null);
  };

  const safeGet = (obj, path, defaultValue = 'N/A') => {
    return path
      .split('.')
      .reduce((o, p) => (o && o[p] !== undefined ? o[p] : undefined), obj) ?? defaultValue;
  };

  return (
    <>
      <Box
        height="calc(100vh - 184px)"
        overflowY="scroll"
        css={{
          "&::-webkit-scrollbar": { display: "none" },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <Table width="100%">
          <Thead position="sticky" top={0} bg="white" zIndex={1}>
            <Tr>
              <Th px={2} width="40px">
                <Checkbox
                  size="lg"
                  isChecked={
                    selectedCreators.length === data.length && data.length > 0
                  }
                  isIndeterminate={
                    selectedCreators.length > 0 &&
                    selectedCreators.length < data.length
                  }
                  onChange={handleSelectAll}
                />
              </Th>
              {visibleColumns.creator && (
                <Th onClick={() => handleSort("instagram_profile.name")}>
                  Creator {getSortIcon("instagram_profile.name")}
                </Th>
              )}
              {visibleColumns.category && (
                <Th onClick={() => handleSort("instagram_profile.category")}>
                  Category {getSortIcon("instagram_profile.category")}
                </Th>
              )}
              {visibleColumns.followers && (
                <Th
                  onClick={() =>
                    handleSort("instagram_profile.followers_count")
                  }
                >
                  Followers {getSortIcon("instagram_profile.followers_count")}
                </Th>
              )}
              {visibleColumns.engagementRate && (
                <Th
                  onClick={() =>
                    handleSort("instagram_profile.engagement_rate")
                  }
                >
                  Eng. Rate {getSortIcon("instagram_profile.engagement_rate")}
                </Th>
              )}
              {visibleColumns.IGReelsImpressions && (
                <Th
                  onClick={() =>
                    handleSort("instagram_profile.projected_reel_views")
                  }
                >
                  IG Reels Impressions
                  {getSortIcon("instagram_profile.projected_reel_views")}
                </Th>
              )}
              {visibleColumns.IGStoriesImpressions && (
                <Th
                  onClick={() =>
                    handleSort("instagram_profile.projected_story_views")
                  }
                >
                  IG Story Impressions
                  {getSortIcon("instagram_profile.projected_story_views")}
                </Th>
              )}
              {visibleColumns.IGPostImpressions && (
                <Th
                  onClick={() =>
                    handleSort("instagram_profile.projected_post_views")
                  }
                >
                  IG Post Impressions
                  {getSortIcon("instagram_profile.projected_post_views")}
                </Th>
              )}
              {visibleColumns.tiktokPostImpressions && (
                <Th
                  onClick={() =>
                    handleSort("tiktok_profile.projected_video_views")
                  }
                >
                  TikTok Post Impressions
                  {getSortIcon("tiktok_profile.projected_video_views")}
                </Th>
              )}
              {/* New Columns */}
              {visibleColumns.pageViews7 && (
                <Th
                  onClick={() =>
                    handleSort("linkdata.7.pageViewCount")
                  }
                >
                  PV 7 Days
                  {getSortIcon("linkdata.7.pageViewCount")}
                </Th>
              )}
              {visibleColumns.pageViews30 && (
                <Th
                  onClick={() =>
                    handleSort("linkdata.30.pageViewCount")
                  }
                >
                  PV 30 Days
                  {getSortIcon("linkdata.30.pageViewCount")}
                </Th>
              )}
              {visibleColumns.pageViews90 && (
                <Th
                  onClick={() =>
                    handleSort("linkdata.90.pageViewCount")
                  }
                >
                  PV 90 Days
                  {getSortIcon("linkdata.90.pageViewCount")}
                </Th>
              )}
              {visibleColumns.linkClicks7 && (
                <Th
                  onClick={() =>
                    handleSort("linkdata.7.linkClickCount")
                  }
                >
                  LC 7 Days
                  {getSortIcon("linkdata.7.linkClickCount")}
                </Th>
              )}
              {visibleColumns.linkClicks30 && (
                <Th
                  onClick={() =>
                    handleSort("linkdata.30.linkClickCount")
                  }
                >
                  LC 30 Days
                  {getSortIcon("linkdata.30.linkClickCount")}
                </Th>
              )}
              {visibleColumns.linkClicks90 && (
                <Th
                  onClick={() =>
                    handleSort("linkdata.90.linkClickCount")
                  }
                >
                  LC 90 Days
                  {getSortIcon("linkdata.90.linkClickCount")}
                </Th>
              )}
            </Tr>
          </Thead>

          <Tbody>
            {data.map((item, index) => (
              <Tr
                key={item.unique_id}
                ref={(el) => (rowRefs.current[index] = el)}
                onClick={() => handleRowClick(item.unique_id, index)}
                cursor="pointer"
                transition="background-color 0.2s ease-in-out"
                _hover={{ backgroundColor: "gray.100" }}
                bg={
                  selectedCreatorId === item.unique_id
                    ? "gray.200"
                    : "transparent"
                }
              >
                <Td px={2} width="40px" onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    size="lg"
                    isChecked={selectedCreators.includes(item.unique_id)}
                    onChange={(e) => {
                      handleSelectItem(item.unique_id, e);
                    }}
                  />
                </Td>

                {visibleColumns.creator && (
                  <Td py={2}>
                    <Creator
                      profilePicUrl={
                        item.instagram_profile.profile_pic_url
                      }
                      username={item.instagram_profile.username}
                      name={item.instagram_profile.name}
                      isVerified={item.instagram_profile.is_verified}
                      hoobeHandle={item.hoobe_handle}
                    />
                  </Td>
                )}

                {visibleColumns.category && (
                  <Td>{item.instagram_profile.category}</Td>
                )}

                {visibleColumns.followers && (
                  <Td>
                    <Followers
                      followersCount={
                        item.instagram_profile.followers_count
                      }
                      username={item.instagram_profile.username}
                    />
                  </Td>
                )}

                {visibleColumns.engagementRate && (
                  <Td>
                    {(
                      item.instagram_profile.engagement_rate * 100
                    ).toFixed(2)}
                    %
                  </Td>
                )}

                {visibleColumns.IGReelsImpressions && (
                  <Td>
                    <InstaReel
                      views={
                        item.instagram_profile.projected_reel_views
                      }
                    />
                  </Td>
                )}

                {visibleColumns.IGStoriesImpressions && (
                  <Td>
                    <InstaStory
                      views={
                        item.instagram_profile.projected_story_views
                      }
                    />
                  </Td>
                )}

                {visibleColumns.IGPostImpressions && (
                  <Td>
                    <InstaPost
                      views={
                        item.instagram_profile.projected_post_views
                      }
                    />
                  </Td>
                )}

                {visibleColumns.tiktokPostImpressions && (
                  <Td>
                    {item.tiktok_profile?.projected_video_views !==
                    undefined ? (
                      <InstaPost
                        views={
                          item.tiktok_profile.projected_video_views
                        }
                      />
                    ) : (
                      "0"
                    )}
                  </Td>
                )}

                    {/* New Columns */}
                    {visibleColumns.pageViews7 && (
                  <Td>
                    <LinkClickData
                      views={
                        safeGet(
                          item,
                          "linkdata.7.pageViewCount"
                        )
                      }
                    />
                  </Td>
                )}
                {visibleColumns.pageViews30 && (
                  <Td>
                    <LinkClickData
                      views={
                        safeGet(
                          item,
                          "linkdata.30.pageViewCount"
                        )
                      }
                    />
                  </Td>
                )}
                {visibleColumns.pageViews90 && (
                  <Td>
                    <LinkClickData
                      views={
                        safeGet(
                          item,
                          "linkdata.90.pageViewCount"
                        )
                      }
                    />
                  </Td>
                )}
                {visibleColumns.linkClicks7 && (
                  <Td>
                    <LinkClickData
                      views={
                        safeGet(
                          item,
                          "linkdata.7.linkClickCount"
                        )
                      }
                    />
                  </Td>
                )}
                {visibleColumns.linkClicks30 && (
                  <Td>
                    <LinkClickData
                      views={
                        safeGet(
                          item,
                          "linkdata.30.linkClickCount"
                        )
                      }
                    />
                  </Td>
                )}
                {visibleColumns.linkClicks90 && (
                  <Td>
                    <LinkClickData
                      views={
                        safeGet(
                          item,
                          "linkdata.90.linkClickCount"
                        )
                      }
                    />
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <CreatorDrawer
        isOpen={!!selectedCreatorId}
        onClose={handleCloseDrawer}
        uniqueId={selectedCreatorId}
      />
    </>
  );
};

export default SearchTable;
