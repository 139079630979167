import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Text,
  Flex,
  Spinner,
  Heading,
  Fade, // Import Fade for transitions
} from '@chakra-ui/react';
import {
  collection,
  query,
  orderBy,
  doc,
  writeBatch,
  onSnapshot,
} from 'firebase/firestore';
import { db } from '../../../Firebase';
import { useOutletContext, useNavigate } from 'react-router-dom';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const Templates = () => {
  const [templates, setTemplates] = useState(null);
  const { workspaceData, searchText } = useOutletContext();
  const navigate = useNavigate();

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  );

  useEffect(() => {
    if (!workspaceData || !workspaceData.workspace_id) {
      console.error('Workspace data is not available');
      return;
    }

    const templatesRef = collection(
      db,
      'workspaces',
      workspaceData.workspace_id,
      'templates'
    );
    const q = query(templatesRef, orderBy('order'));

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const templateData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTemplates(templateData);
      },
      (error) => {
        console.error('Error fetching templates:', error);
      }
    );

    return unsubscribe;
  }, [workspaceData]);

  const filteredTemplates = useMemo(() => {
    if (!searchText) {
      return templates;
    }
    return templates?.filter(
      (template) =>
        (template.name &&
          template.name.toLowerCase().includes(searchText.toLowerCase())) ||
        (template.message &&
          template.message.toLowerCase().includes(searchText.toLowerCase()))
    );
  }, [templates, searchText]);

  const handleDragEnd = async ({ active, over }) => {
    if (active.id !== over?.id && templates) {
      const oldIndex = templates.findIndex((t) => t.id === active.id);
      const newIndex = templates.findIndex((t) => t.id === over.id);
      const reordered = arrayMove(templates, oldIndex, newIndex);
      setTemplates(reordered);

      // Update 'order' field in Firestore
      const batch = writeBatch(db);
      reordered.forEach((template, index) => {
        const templateRef = doc(
          db,
          'workspaces',
          workspaceData.workspace_id,
          'templates',
          template.id
        );
        batch.update(templateRef, { order: index });
      });
      try {
        await batch.commit();
      } catch (error) {
        console.error('Error updating template order:', error);
      }
    }
  };

  const handleTemplateClick = (templateId) => {
    navigate(`/dashboard/messages/templates/${templateId}`);
  };

  if (!templates) {
    return (
      <Flex justify="center" align="center" height="200px">
        <Spinner size="lg" />
      </Flex>
    );
  }

  return (
    <Box>
      {filteredTemplates.length === 0 ? (
        <Flex justify="center" align="center" height="200px">
          <Text color="gray.400" fontSize="lg">
            No templates please create one.
          </Text>
        </Flex>
      ) : (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={filteredTemplates.map((t) => t.id)}
            strategy={rectSortingStrategy}
          >
            <Flex wrap="wrap" px={2}>
              {filteredTemplates.map((template) => (
                <Fade
                  key={template.id}
                  in={true}
                  transition={{ enter: { duration: 0.5, delay: 0.1 } }}
                >
                  <SortableItem
                    id={template.id}
                    name={template.name}
                    message={template.message}
                    onClick={handleTemplateClick}
                  />
                </Fade>
              ))}
            </Flex>
          </SortableContext>
        </DndContext>
      )}
    </Box>
  );
};

const SortableItem = ({ id, name, message, onClick }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    cursor: isDragging ? 'grabbing' : 'grab',
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      p={3}
      m={2}
      borderWidth="1px"
      borderRadius="xl"
      minW="300px"
      bg="white"
      boxShadow="sm"
      onClick={() => onClick(id)}
      _hover={{ bg: 'gray.100' }}
      h="100px"
    >
      <Box>
        <Heading
          size="sm"
          mb={2}
          color={!name ? 'gray.400' : 'inherit'} // Conditional color
        >
          {name || 'No Name'}
        </Heading>
        {message && (
          <Text fontSize="xs" color="gray.500">
            {message}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default Templates;
