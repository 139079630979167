import React from 'react';
import { IconButton, Button, HStack, Text, Spacer } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const Pagination = ({ totalPages, currentPage, onPageChange, totalCount }) => {
  const itemsPerPage = 50;

  const getPageNumbers = () => {
    let pages = [1];
    if (totalPages <= 5) {
      for (let i = 2; i < totalPages; i++) {
        pages.push(i);
      }
    } else if (currentPage <= 3) {
      pages.push(2, 3, 4, totalPages);
    } else if (currentPage >= totalPages - 2) {
      pages.push(totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
    } else {
      pages.push(currentPage - 1, currentPage, currentPage + 1, totalPages);
    }
    return [...new Set(pages)];
  };

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(currentPage * itemsPerPage, totalCount);
  const pageNumbers = getPageNumbers();

  return (
    <HStack mt={1} spacing={2} width="100%">
      <Text>{`${startIndex}-${endIndex} of ${totalCount} results`}</Text>
      <Spacer />
      <HStack spacing={2}>
        <IconButton
          icon={<ChevronLeftIcon />}
          onClick={() => onPageChange(currentPage - 1)}
          isDisabled={currentPage === 1}
          aria-label="Previous Page"
          borderRadius="xl"
          borderWidth="1px"
          bg="white"
        />
        {pageNumbers.map((page) => (
          <Button
            key={page}
            onClick={() => onPageChange(page)}
            isDisabled={currentPage === page}
            size="sm"
            width="40px"
            height="40px"
            variant={currentPage === page ? 'solid' : 'outline'}
            colorScheme={currentPage === page ? 'blue' : 'gray'}
            aria-label={`Go to page ${page}`}
            borderRadius="xl"
          >
            {page}
          </Button>
        ))}
        <IconButton
          icon={<ChevronRightIcon />}
          onClick={() => onPageChange(currentPage + 1)}
          isDisabled={currentPage === totalPages}
          aria-label="Next Page"
          borderRadius="xl"
          borderWidth="1px"
          bg="white"
        />
      </HStack>
    </HStack>
  );
};

export default Pagination;
