// LinkData.js
import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Text,
  Input,
  Select,
  Flex,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from "@chakra-ui/react";

const formatImpressions = (value) => {
  if (value === 10000000) return "10M+";
  if (value >= 1000000) {
    const formatted = (value / 1000000).toFixed(1);
    return formatted.endsWith(".0")
      ? `${formatted.slice(0, -2)}M`
      : `${formatted}M`;
  }
  if (value >= 1000) {
    const formatted = (value / 1000).toFixed(1);
    return formatted.endsWith(".0")
      ? `${formatted.slice(0, -2)}k`
      : `${formatted}k`;
  }
  return value.toString();
};

const convertToImpressions = (value) => {
  if (value <= 50) {
    return Math.round(value * 2000);
  } else if (value === 100) {
    return 10000000;
  } else {
    return Math.round(100000 + (value - 50) * 18000);
  }
};

const convertToSliderValue = (impressions) => {
  if (impressions <= 100000) {
    return Math.round(impressions / 2000);
  } else if (impressions === 10000000) {
    return 100;
  } else {
    return Math.round(50 + (impressions - 100000) / 18000);
  }
};

const LinkData = ({
  pageViews,
  setPageViews,
  linkClicks,
  setLinkClicks,
  ctr,
  setCtr,
}) => {
  // Memoize the periods array to prevent it from changing on every render
  const periods = useMemo(() => [
    { value: "7", label: "7 Days" },
    { value: "30", label: "30 Days" },
    { value: "90", label: "90 Days" },
  ], []);

  const [selectedPageViewsPeriod, setSelectedPageViewsPeriod] = useState("7");
  const [selectedLinkClicksPeriod, setSelectedLinkClicksPeriod] = useState("7");
  const [selectedCtrPeriod, setSelectedCtrPeriod] = useState("7");

  const [isEditing, setIsEditing] = useState({});
  const [tempValues, setTempValues] = useState({});

  // Initialize tempValues
  useEffect(() => {
    periods.forEach((period) => {
      setTempValues((prev) => ({
        ...prev,
        pageViews: {
          ...prev.pageViews,
          [period.value]: {
            min: pageViews[period.value]?.min || 0,
            max: pageViews[period.value]?.max || 10000000,
          },
        },
        linkClicks: {
          ...prev.linkClicks,
          [period.value]: {
            min: linkClicks[period.value]?.min || 0,
            max: linkClicks[period.value]?.max || 10000000,
          },
        },
        ctr: {
          ...prev.ctr,
          [period.value]: {
            min: ctr[period.value]?.min || 0,
            max: ctr[period.value]?.max || 100,
          },
        },
      }));
    });
  }, [periods, pageViews, linkClicks, ctr]);

  const handleSliderChange = (metric, period, values) => {
    let newMin, newMax;
    if (metric === "ctr") {
      newMin = values[0];
      newMax = values[1];
    } else {
      newMin = convertToImpressions(values[0]);
      newMax = convertToImpressions(values[1]);
    }

    if (metric === "pageViews") {
      setPageViews({
        ...pageViews,
        [period]: {
          min: newMin,
          max: newMax,
        },
      });
    } else if (metric === "linkClicks") {
      setLinkClicks({
        ...linkClicks,
        [period]: {
          min: newMin,
          max: newMax,
        },
      });
    } else if (metric === "ctr") {
      setCtr({
        ...ctr,
        [period]: {
          min: newMin,
          max: newMax,
        },
      });
    }
  };

  const handleInputBlur = (metric, period, type) => {
    const value = tempValues[metric][period][type];
    const minKey = metric === "ctr" ? 0 : 0;
    const maxKey = metric === "ctr" ? 100 : 10000000;

    const validValue = Math.min(Math.max(value, minKey), maxKey);

    if (metric === "pageViews") {
      setPageViews({
        ...pageViews,
        [period]: {
          ...pageViews[period],
          [type]: validValue,
        },
      });
    } else if (metric === "linkClicks") {
      setLinkClicks({
        ...linkClicks,
        [period]: {
          ...linkClicks[period],
          [type]: validValue,
        },
      });
    } else if (metric === "ctr") {
      setCtr({
        ...ctr,
        [period]: {
          ...ctr[period],
          [type]: validValue,
        },
      });
    }

    setIsEditing({
      ...isEditing,
      [`${metric}-${period}-${type}`]: false,
    });
  };

  const handleNumberInput = (e, metric, period, type) => {
    const value = e.target.value.replace(/\D/g, "");
    setTempValues({
      ...tempValues,
      [metric]: {
        ...tempValues[metric],
        [period]: {
          ...tempValues[metric][period],
          [type]: Number(value),
        },
      },
    });
  };

  const handleKeyPress = (e, metric, period, type) => {
    if (e.key === "Enter") {
      handleInputBlur(metric, period, type);
    }
  };

  return (
    <Box borderWidth="1px" borderRadius="md" p={4}>
      {/* Page Views Filter */}
      <Box mb={6}>
        <Text fontWeight="bold" mb={2}>
          Page Views
        </Text>
        <Select
          value={selectedPageViewsPeriod}
          onChange={(e) => setSelectedPageViewsPeriod(e.target.value)}
          mb={4}
        >
          {periods.map((period) => (
            <option key={period.value} value={period.value}>
              {period.label}
            </option>
          ))}
        </Select>
        <Flex justifyContent="space-between" mb={2}>
          {isEditing[`pageViews-${selectedPageViewsPeriod}-min`] ? (
            <Input
              value={
                tempValues.pageViews[selectedPageViewsPeriod]?.min || 0
              }
              onChange={(e) =>
                handleNumberInput(e, "pageViews", selectedPageViewsPeriod, "min")
              }
              onBlur={() =>
                handleInputBlur("pageViews", selectedPageViewsPeriod, "min")
              }
              onKeyPress={(e) =>
                handleKeyPress(e, "pageViews", selectedPageViewsPeriod, "min")
              }
              autoFocus
              width="45%"
            />
          ) : (
            <Text
              fontWeight="bold"
              onClick={() =>
                setIsEditing({
                  ...isEditing,
                  [`pageViews-${selectedPageViewsPeriod}-min`]: true,
                })
              }
            >
              {formatImpressions(
                pageViews[selectedPageViewsPeriod]?.min || 0
              )}
            </Text>
          )}
          {isEditing[`pageViews-${selectedPageViewsPeriod}-max`] ? (
            <Input
              value={
                tempValues.pageViews[selectedPageViewsPeriod]?.max || 10000000
              }
              onChange={(e) =>
                handleNumberInput(e, "pageViews", selectedPageViewsPeriod, "max")
              }
              onBlur={() =>
                handleInputBlur("pageViews", selectedPageViewsPeriod, "max")
              }
              onKeyPress={(e) =>
                handleKeyPress(e, "pageViews", selectedPageViewsPeriod, "max")
              }
              autoFocus
              width="45%"
            />
          ) : (
            <Text
              fontWeight="bold"
              onClick={() =>
                setIsEditing({
                  ...isEditing,
                  [`pageViews-${selectedPageViewsPeriod}-max`]: true,
                })
              }
            >
              {formatImpressions(
                pageViews[selectedPageViewsPeriod]?.max || 10000000
              )}
            </Text>
          )}
        </Flex>
        <Box width="90%" mx="auto">
          <RangeSlider
            min={0}
            max={100}
            step={1}
            value={[
              convertToSliderValue(
                pageViews[selectedPageViewsPeriod]?.min || 0
              ),
              convertToSliderValue(
                pageViews[selectedPageViewsPeriod]?.max || 10000000
              ),
            ]}
            onChange={(values) =>
              handleSliderChange("pageViews", selectedPageViewsPeriod, values)
            }
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb
              index={0}
              borderWidth="2px"
              borderColor="gray.300"
              boxSize={5}
            />
            <RangeSliderThumb
              index={1}
              borderWidth="2px"
              borderColor="gray.300"
              boxSize={5}
            />
          </RangeSlider>
        </Box>
      </Box>

      {/* Link Clicks Filter */}
      <Box mb={6}>
        <Text fontWeight="bold" mb={2}>
          Link Clicks
        </Text>
        <Select
          value={selectedLinkClicksPeriod}
          onChange={(e) => setSelectedLinkClicksPeriod(e.target.value)}
          mb={4}
        >
          {periods.map((period) => (
            <option key={period.value} value={period.value}>
              {period.label}
            </option>
          ))}
        </Select>
        <Flex justifyContent="space-between" mb={2}>
          {isEditing[`linkClicks-${selectedLinkClicksPeriod}-min`] ? (
            <Input
              value={
                tempValues.linkClicks[selectedLinkClicksPeriod]?.min || 0
              }
              onChange={(e) =>
                handleNumberInput(
                  e,
                  "linkClicks",
                  selectedLinkClicksPeriod,
                  "min"
                )
              }
              onBlur={() =>
                handleInputBlur("linkClicks", selectedLinkClicksPeriod, "min")
              }
              onKeyPress={(e) =>
                handleKeyPress(e, "linkClicks", selectedLinkClicksPeriod, "min")
              }
              autoFocus
              width="45%"
            />
          ) : (
            <Text
              fontWeight="bold"
              onClick={() =>
                setIsEditing({
                  ...isEditing,
                  [`linkClicks-${selectedLinkClicksPeriod}-min`]: true,
                })
              }
            >
              {formatImpressions(
                linkClicks[selectedLinkClicksPeriod]?.min || 0
              )}
            </Text>
          )}
          {isEditing[`linkClicks-${selectedLinkClicksPeriod}-max`] ? (
            <Input
              value={
                tempValues.linkClicks[selectedLinkClicksPeriod]?.max || 10000000
              }
              onChange={(e) =>
                handleNumberInput(
                  e,
                  "linkClicks",
                  selectedLinkClicksPeriod,
                  "max"
                )
              }
              onBlur={() =>
                handleInputBlur("linkClicks", selectedLinkClicksPeriod, "max")
              }
              onKeyPress={(e) =>
                handleKeyPress(e, "linkClicks", selectedLinkClicksPeriod, "max")
              }
              autoFocus
              width="45%"
            />
          ) : (
            <Text
              fontWeight="bold"
              onClick={() =>
                setIsEditing({
                  ...isEditing,
                  [`linkClicks-${selectedLinkClicksPeriod}-max`]: true,
                })
              }
            >
              {formatImpressions(
                linkClicks[selectedLinkClicksPeriod]?.max || 10000000
              )}
            </Text>
          )}
        </Flex>
        <Box width="90%" mx="auto">
          <RangeSlider
            min={0}
            max={100}
            step={1}
            value={[
              convertToSliderValue(
                linkClicks[selectedLinkClicksPeriod]?.min || 0
              ),
              convertToSliderValue(
                linkClicks[selectedLinkClicksPeriod]?.max || 10000000
              ),
            ]}
            onChange={(values) =>
              handleSliderChange("linkClicks", selectedLinkClicksPeriod, values)
            }
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb
              index={0}
              borderWidth="2px"
              borderColor="gray.300"
              boxSize={5}
            />
            <RangeSliderThumb
              index={1}
              borderWidth="2px"
              borderColor="gray.300"
              boxSize={5}
            />
          </RangeSlider>
        </Box>
      </Box>

      {/* CTR Filter */}
      <Box>
        <Text fontWeight="bold" mb={2}>
          CTR (%)
        </Text>
        <Select
          value={selectedCtrPeriod}
          onChange={(e) => setSelectedCtrPeriod(e.target.value)}
          mb={4}
        >
          {periods.map((period) => (
            <option key={period.value} value={period.value}>
              {period.label}
            </option>
          ))}
        </Select>
        <Flex justifyContent="space-between" mb={2}>
          {isEditing[`ctr-${selectedCtrPeriod}-min`] ? (
            <Input
              value={tempValues.ctr[selectedCtrPeriod]?.min || 0}
              onChange={(e) =>
                handleNumberInput(e, "ctr", selectedCtrPeriod, "min")
              }
              onBlur={() => handleInputBlur("ctr", selectedCtrPeriod, "min")}
              onKeyPress={(e) =>
                handleKeyPress(e, "ctr", selectedCtrPeriod, "min")
              }
              autoFocus
              width="45%"
            />
          ) : (
            <Text
              fontWeight="bold"
              onClick={() =>
                setIsEditing({
                  ...isEditing,
                  [`ctr-${selectedCtrPeriod}-min`]: true,
                })
              }
            >
              {ctr[selectedCtrPeriod]?.min || 0}%
            </Text>
          )}
          {isEditing[`ctr-${selectedCtrPeriod}-max`] ? (
            <Input
              value={tempValues.ctr[selectedCtrPeriod]?.max || 100}
              onChange={(e) =>
                handleNumberInput(e, "ctr", selectedCtrPeriod, "max")
              }
              onBlur={() => handleInputBlur("ctr", selectedCtrPeriod, "max")}
              onKeyPress={(e) =>
                handleKeyPress(e, "ctr", selectedCtrPeriod, "max")
              }
              autoFocus
              width="45%"
            />
          ) : (
            <Text
              fontWeight="bold"
              onClick={() =>
                setIsEditing({
                  ...isEditing,
                  [`ctr-${selectedCtrPeriod}-max`]: true,
                })
              }
            >
              {ctr[selectedCtrPeriod]?.max || 100}%
            </Text>
          )}
        </Flex>
        <Box width="90%" mx="auto">
          <RangeSlider
            min={0}
            max={100}
            step={1}
            value={[
              ctr[selectedCtrPeriod]?.min || 0,
              ctr[selectedCtrPeriod]?.max || 100,
            ]}
            onChange={(values) =>
              handleSliderChange("ctr", selectedCtrPeriod, values)
            }
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb
              index={0}
              borderWidth="2px"
              borderColor="gray.300"
              boxSize={5}
            />
            <RangeSliderThumb
              index={1}
              borderWidth="2px"
              borderColor="gray.300"
              boxSize={5}
            />
          </RangeSlider>
        </Box>
      </Box>
    </Box>
  );
};

export default LinkData;
