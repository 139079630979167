import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Box,
  Text,
  Spinner,
  List,
  ListItem,
  Image,
  HStack,
  Input,
  Checkbox,
  Select,
  InputGroup,
  InputLeftElement,
  Center,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { doc, onSnapshot } from 'firebase/firestore';
import { useOutletContext } from 'react-router-dom';
import { db } from '../../../../../Firebase';
import CampaignMessageCreator from './CampaignMessageCreator';
import Status from '../../../formatting/Status';
import CampaignMessagesSendModal from './CampaignMessagesSendModal';
import { TbSend } from 'react-icons/tb';

const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'None', value: 'None' },
  { label: 'Contacted', value: 'Contacted' },
  { label: 'Awaiting Approval', value: 'Awaiting Approval' },
  { label: 'Countered', value: 'Countered' },
  { label: 'Approved', value: 'Approved' },
  { label: 'Awaiting Contract', value: 'Awaiting Contract' },
  { label: 'Awaiting Content', value: 'Awaiting Content' },
  { label: 'Ready To Post', value: 'Ready To Post' },
  { label: 'Completed', value: 'Completed' },
  { label: 'Rejected', value: 'Rejected' },
];

const CampaignsMessage = () => {
  const { workspaceId, campaignId } = useOutletContext();
  const [campaignData, setCampaignData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCreators, setSelectedCreators] = useState({});
  const [statusFilter, setStatusFilter] = useState('all');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const selectedCreatorRef = useRef(selectedCreator);

  useEffect(() => {
    selectedCreatorRef.current = selectedCreator;
  }, [selectedCreator]);

  useEffect(() => {
    const campaignRef = doc(db, 'workspaces', workspaceId, 'campaigns', campaignId);
    const unsubscribe = onSnapshot(
      campaignRef,
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setCampaignData(data);

          if (data.creators && data.creators.length > 0) {
            // Keep the selected creator if possible
            const currentSelectedCreator = selectedCreatorRef.current;
            if (currentSelectedCreator) {
              const updatedSelectedCreator = data.creators.find(
                (c) => c.creatorId === currentSelectedCreator.creatorId
              );
              if (updatedSelectedCreator) {
                setSelectedCreator(updatedSelectedCreator);
              } else {
                setSelectedCreator(data.creators[0]);
              }
            } else {
              setSelectedCreator(data.creators[0]);
            }
          } else {
            setSelectedCreator(null);
          }
        } else {
          setCampaignData(null);
          setSelectedCreator(null);
        }
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching campaign data:', error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [workspaceId, campaignId]);

  const filterCreators = useCallback(() => {
    if (campaignData && campaignData.creators) {
      return campaignData.creators.filter(
        (creator) =>
          (creator.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            creator.instagram_username.toLowerCase().includes(searchTerm.toLowerCase())) &&
          (statusFilter === 'all' || creator.status === statusFilter)
      );
    }
    return [];
  }, [campaignData, searchTerm, statusFilter]);

  const displayedCreators = filterCreators();

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleCheckboxChange = (creatorId) => {
    setSelectedCreators((prevSelected) => ({
      ...prevSelected,
      [creatorId]: !prevSelected[creatorId],
    }));
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const newSelectedCreators = {};
    displayedCreators.forEach((creator) => {
      newSelectedCreators[creator.creatorId] = isChecked;
    });
    setSelectedCreators(newSelectedCreators);
  };

  const isAllSelected =
    displayedCreators.length > 0 &&
    displayedCreators.every((creator) => selectedCreators[creator.creatorId]);

  const handleUpdateCreator = (updatedCreator) => {
    setSelectedCreator(updatedCreator);
    // No need to update displayedCreators since it's computed from campaignData
  };

  const getSelectedCreatorsData = () => {
    return displayedCreators.filter((creator) => selectedCreators[creator.creatorId]);
  };

  const anyCreatorSelected = Object.values(selectedCreators).some((isSelected) => isSelected);

  return (
    <Box h="calc(100vh - 60px)" overflow="hidden">
      {loading ? (
        <Spinner />
      ) : campaignData ? (
        <Box display="flex" height="100%">
          <Box
            width="25%"
            bg="white"
            borderRight="2px solid"
            borderColor="gray.200"
            display="flex"
            flexDirection="column"
          >
            <Box p={4} borderBottom="2px solid" borderColor="gray.200">
              <HStack spacing={2}>
                <Checkbox isChecked={isAllSelected} onChange={handleSelectAll} size="lg" />
                {anyCreatorSelected && (
                  <IconButton
                    icon={<TbSend />}
                    aria-label="Send message"
                    onClick={onOpen}
                    colorScheme="blue"
                  />
                )}
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
                  <Input placeholder="Search creators" value={searchTerm} onChange={handleSearch} />
                </InputGroup>
                <Select
                  value={statusFilter}
                  onChange={handleStatusFilter}
                  width="auto"
                  minWidth="120px"
                >
                  {statusOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </HStack>
            </Box>
            <Box
              overflowY="auto"
              flexGrow={1}
              css={{
                '&::-webkit-scrollbar': {
                  width: '0px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '0px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'transparent',
                },
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
              }}
            >
              {displayedCreators.length > 0 ? (
                <List>
                  {displayedCreators.map((creator) => (
                    <ListItem
                      key={creator.creatorId}
                      cursor="pointer"
                      bg={
                        selectedCreator && selectedCreator.creatorId === creator.creatorId
                          ? 'gray.100'
                          : 'transparent'
                      }
                      onClick={() => setSelectedCreator(creator)}
                      _hover={{ bg: 'gray.100' }}
                    >
                      <HStack p={3} borderBottomWidth="1px" spacing={3} justifyContent="space-between">
                        <HStack>
                          <Checkbox
                            isChecked={selectedCreators[creator.creatorId] || false}
                            onChange={() => handleCheckboxChange(creator.creatorId)}
                            onClick={(e) => e.stopPropagation()}
                            size="lg"
                          />
                          <Image
                            boxSize="40px"
                            borderRadius="full"
                            src={creator.instagram_profile_pic_url}
                            alt={creator.name}
                          />
                          <Box>
                            <Text fontWeight="medium">{creator.name}</Text>
                            <Text fontSize="sm" color="gray.500">
                              @{creator.instagram_username}
                            </Text>
                          </Box>
                        </HStack>
                        <Box pointerEvents="none">
                          <Status initialStatus={creator.status} onChange={() => {}} />
                        </Box>
                      </HStack>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Center height="100%">
                  <Text fontSize="lg" color="gray.500">
                    Looks like no one is here!
                  </Text>
                </Center>
              )}
            </Box>
          </Box>

          <Box
            width="75%"
            bg="white"
            overflowY="auto"
            css={{
              '&::-webkit-scrollbar': {
                width: '0px',
              },
              '&::-webkit-scrollbar-track': {
                width: '0px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'transparent',
              },
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
          >
            {selectedCreator && (
              <CampaignMessageCreator
                creator={selectedCreator}
                campaignId={campaignId}
                workspaceId={workspaceId}
                onUpdateCreator={handleUpdateCreator}
                creatorId={selectedCreator.creatorId}
              />
            )}
          </Box>
        </Box>
      ) : (
        <Text fontSize="lg" color="gray.600">
          No campaign data found.
        </Text>
      )}
      <CampaignMessagesSendModal
        isOpen={isOpen}
        onClose={onClose}
        selectedCreators={getSelectedCreatorsData()}
        campaignId={campaignId}
        workspaceId={workspaceId}
      />
    </Box>
  );
};

export default CampaignsMessage;
