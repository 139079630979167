// CreatorDrawer.jsx
import React, { useState, useEffect, useCallback } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Spinner,
  Box,
  Text,
  HStack,
  VStack,
  Image,
  Link,
  Flex,
  Input,
  Button,
  FormControl,
  FormLabel,
  useToast,
  Grid,
} from "@chakra-ui/react";
import { FormatNumber } from "../FormatNumber";
import { FiEye, FiLink, FiMousePointer } from "react-icons/fi";

import instagramStoryImg from "../../../../assets/instagramstory.png";
import instagramPostImg from "../../../../assets/instagrampost.png";
import tiktokImg from "../../../../assets/tiktok.png";
import instagramReelImg from "../../../../assets/instagramreel.png";
import verifiedBadge from "../../../../assets/verifiedbadge.png";
import hooBeIcon from "../../../../assets/logo.png";
import instagramIcon from "../../../../assets/instagrampost.png";

import StatItem from "./StatItem";
import ContactSection from "./ContactSection";

const CreatorDrawer = ({ isOpen, onClose, uniqueId }) => {
  const [creatorData, setCreatorData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tiktokInfo, setTikTokInfo] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const toast = useToast();

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://internal-api.hoobe.me/search-individual-person`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uniqueId }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setCreatorData(data);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch creator details");
    } finally {
      setLoading(false);
    }
  }, [uniqueId]);

  useEffect(() => {
    if (isOpen && uniqueId) {
      fetchData();
    }
  }, [isOpen, uniqueId, fetchData]);

  const getDataOrDefault = (data, defaultValue = "N/A") =>
    data !== null && data !== undefined ? data : defaultValue;

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const isTikTokInfoMissing = !creatorData?.tiktok_profile?.username;

  const handleAddTikTokInfo = async () => {
    if (!tiktokInfo.trim()) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid TikTok information.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setSubmitting(true);
    try {
      const payload = {
        platform: "tt",
        handle: tiktokInfo.trim(),
        unique_id: uniqueId,
      };

      const response = await fetch(
        "https://hoobe-backend.onrender.com/profile",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit TikTok information");
      }

      toast({
        title: "Success",
        description: "TikTok information added successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setTikTokInfo("");

      await fetchData();
    } catch (err) {
      console.error(err);
      toast({
        title: "Error",
        description:
          err.message ||
          "An error occurred while submitting TikTok information.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay bg="rgba(0, 0, 0, 0.15)" />
      <DrawerContent
        borderRadius="20px"
        p={6}
        boxShadow="lg"
        bg="white"
        position="relative"
        m={1.5}
      >
        <DrawerCloseButton
          position="absolute"
          top="20px"
          left="-50px"
          zIndex="1"
          p={5}
          bg="white"
          borderRadius="full"
          _hover={{ bg: "gray.200" }}
        />
        <DrawerHeader>Creator Details</DrawerHeader>
        <DrawerBody
          height="calc(100vh - 184px)"
          overflowY="scroll"
          css={{
            "&::-webkit-scrollbar": { display: "none" },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {loading ? (
            <Flex justifyContent="center" alignItems="center" height="100%">
              <Spinner />
            </Flex>
          ) : error ? (
            <Text color="red.500">{error}</Text>
          ) : creatorData ? (
            <Box>
              <VStack spacing={6} align="stretch">
                {/* Profile Section */}
                <HStack align="start" spacing={6}>
                  <Box position="relative">
                    <Image
                      src={getDataOrDefault(
                        creatorData.instagram_profile?.profile_pic_url
                      )}
                      alt={getDataOrDefault(
                        creatorData.instagram_profile?.username,
                        "N/A"
                      )}
                      borderRadius="50%"
                      boxSize="100px"
                    />
                    {creatorData.instagram_profile?.is_verified && (
                      <Image
                        src={verifiedBadge}
                        alt="Verified Badge"
                        boxSize="30px"
                        position="absolute"
                        top="0"
                        left="0"
                      />
                    )}
                  </Box>
                  <Box>
                    <Text
                      fontFamily="Poppins"
                      fontSize="16px"
                      fontWeight="500"
                      color="#000"
                      lineHeight="20px"
                    >
                      {getDataOrDefault(
                        creatorData.instagram_profile?.name,
                        "N/A"
                      )}
                      {creatorData.instagram_profile?.category && (
                        <>
                          <Text
                            as="span"
                            mx="2"
                            color="var(--grays-dark, #51555C)"
                          >
                            •
                          </Text>
                          <Text
                            as="span"
                            fontSize="14px"
                            fontWeight="400"
                            color="var(--grays-dark, #51555C)"
                          >
                            {getDataOrDefault(
                              creatorData.instagram_profile?.category,
                              "No category"
                            )}
                          </Text>
                        </>
                      )}
                    </Text>
                    <HStack spacing={2} mt={1}>
                      <Button
                        leftIcon={<Image src={instagramIcon} boxSize="16px" />}
                        variant="outline"
                        borderRadius="30px"
                        border="1px solid var(--grays-light, #E6E7EB)"
                        bg="var(--grays-white, #FFF)"
                        fontFamily="Poppins"
                        fontSize="14px"
                        fontWeight="500"
                        color="var(--grays-black, #000)"
                        size="sm"
                        as={Link}
                        href={`https://instagram.com/${getDataOrDefault(
                          creatorData.instagram_profile?.username,
                          "N/A"
                        )}`}
                        isExternal
                      >
                        {getDataOrDefault(
                          creatorData.instagram_profile?.username,
                          "N/A"
                        )}
                      </Button>

                      {creatorData.hoobe_handle && (
                        <Button
                          leftIcon={<Image src={hooBeIcon} boxSize="16px" />}
                          variant="outline"
                          borderRadius="30px"
                          border="1px solid var(--grays-light, #E6E7EB)"
                          bg="var(--grays-white, #FFF)"
                          fontFamily="Poppins"
                          fontSize="14px"
                          fontWeight="500"
                          color="var(--grays-black, #000)"
                          size="sm"
                          as={Link}
                          href={`https://hoo.be/${getDataOrDefault(
                            creatorData.hoobe_handle,
                            "N/A"
                          )}`}
                          isExternal
                        >
                          {getDataOrDefault(creatorData.hoobe_handle, "N/A")}
                        </Button>
                      )}

                      {creatorData.tiktok_profile?.username && (
                        <Button
                          leftIcon={<Image src={tiktokImg} boxSize="16px" />}
                          variant="outline"
                          borderRadius="30px"
                          border="1px solid var(--grays-light, #E6E7EB)"
                          bg="var(--grays-white, #FFF)"
                          fontFamily="Poppins"
                          fontSize="14px"
                          fontWeight="500"
                          color="var(--grays-black, #000)"
                          size="sm"
                          as={Link}
                          href={`https://tiktok.com/@${getDataOrDefault(
                            creatorData.tiktok_profile?.username,
                            "N/A"
                          )}`}
                          isExternal
                        >
                          {getDataOrDefault(
                            creatorData.tiktok_profile?.username,
                            "N/A"
                          )}
                        </Button>
                      )}
                    </HStack>
                  </Box>
                </HStack>

                {/* Statistics Section */}
                <Box>
                  <VStack spacing={6} align="stretch">
                    {/* Stats Cards */}
                    <HStack
                      spacing={0}
                      bg="gray.50"
                      borderRadius="md"
                      borderWidth="1px"
                    >
                      <Box
                        p={2}
                        flex="1"
                        textAlign="center"
                        borderRight="2px solid var(--grays-light, #E6E7EB)"
                      >
                        <Text
                          fontFamily="Poppins"
                          fontSize="12px"
                          fontWeight="500"
                          color="var(--grays-dark, #51555C)"
                        >
                          Followers
                        </Text>
                        <Text fontSize="lg" fontWeight="bold">
                          {FormatNumber(
                            getDataOrDefault(
                              creatorData.instagram_profile?.followers_count,
                              0
                            )
                          )}
                        </Text>
                      </Box>

                      <Box
                        p={2}
                        flex="1"
                        textAlign="center"
                        borderRight="2px solid var(--grays-light, #E6E7EB)"
                      >
                        <Text
                          fontFamily="Poppins"
                          fontSize="12px"
                          fontWeight="500"
                          color="var(--grays-dark, #51555C)"
                        >
                          Following
                        </Text>
                        <Text fontSize="lg" fontWeight="bold">
                          {FormatNumber(
                            getDataOrDefault(
                              creatorData.instagram_profile?.following_count,
                              0
                            )
                          )}
                        </Text>
                      </Box>

                      <Box
                        p={2}
                        flex="1"
                        textAlign="center"
                        borderRight="2px solid var(--grays-light, #E6E7EB)"
                      >
                        <Text
                          fontFamily="Poppins"
                          fontSize="12px"
                          fontWeight="500"
                          color="var(--grays-dark, #51555C)"
                        >
                          Eng Rate
                        </Text>
                        <Text fontSize="lg" fontWeight="bold">
                          {(
                            getDataOrDefault(
                              creatorData.instagram_profile?.engagement_rate,
                              0
                            ) * 100
                          ).toFixed(2)}
                          %
                        </Text>
                      </Box>

                      <Box p={4} flex="1" textAlign="center">
                        <Text
                          fontFamily="Poppins"
                          fontSize="12px"
                          fontWeight="500"
                          color="var(--grays-dark, #51555C)"
                        >
                          Reel Imps.
                        </Text>
                        <Text fontSize="lg" fontWeight="bold">
                          {FormatNumber(
                            getDataOrDefault(
                              creatorData.instagram_profile
                                ?.projected_reel_views,
                              0
                            )
                          )}
                        </Text>
                      </Box>
                    </HStack>

                    {/* Detailed Stats */}
                    <Grid
                      templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                      gap={4}
                    >
                      <Box
                        p={4}
                        borderWidth="1px"
                        borderRadius="md"
                        boxShadow="sm"
                        bg="gray.50"
                      >
                        <StatItem
                          imgSrc={instagramReelImg}
                          label="Reel Impressions"
                          value={FormatNumber(
                            getDataOrDefault(
                              creatorData.instagram_profile
                                ?.projected_reel_views,
                              0
                            )
                          )}
                        />
                      </Box>
                      <Box
                        p={4}
                        borderWidth="1px"
                        borderRadius="md"
                        boxShadow="sm"
                        bg="gray.50"
                      >
                        <StatItem
                          imgSrc={instagramStoryImg}
                          label="Story Impressions"
                          value={FormatNumber(
                            getDataOrDefault(
                              creatorData.instagram_profile
                                ?.projected_story_views,
                              0
                            )
                          )}
                        />
                      </Box>
                      <Box
                        p={4}
                        borderWidth="1px"
                        borderRadius="md"
                        boxShadow="sm"
                        bg="gray.50"
                      >
                        <StatItem
                          imgSrc={instagramPostImg}
                          label="Post Impressions"
                          value={FormatNumber(
                            getDataOrDefault(
                              creatorData.instagram_profile
                                ?.projected_post_views,
                              0
                            )
                          )}
                        />
                      </Box>
                      <Box
                        p={4}
                        borderWidth="1px"
                        borderRadius="md"
                        boxShadow="sm"
                        bg="gray.50"
                      >
                        <StatItem
                          imgSrc={tiktokImg}
                          label="TikTok Impressions"
                          value={FormatNumber(
                            getDataOrDefault(
                              creatorData.tiktok_profile
                                ?.projected_video_views,
                              0
                            )
                          )}
                        />
                      </Box>
                    </Grid>
                  </VStack>
                </Box>

                {/* Clicks Data */}
                <HStack
                  spacing={0}
                  bg="gray.50"
                  borderRadius="md"
                  borderWidth="1px"
                  justifyContent="space-between"
                  px={6}
                  py={4}
                >
                  {/* 7 Day Clicks */}
                  <Box textAlign="center">
                    <Text
                      fontFamily="Poppins"
                      fontSize="12px"
                      fontWeight="500"
                      color="var(--grays-dark, #51555C)"
                    >
                      7 Day Clicks
                    </Text>
                    <VStack justifyContent="left">
                      <HStack>
                        <FiEye />
                        <Text fontSize="lg" fontWeight="bold">
                          {FormatNumber(
                            getDataOrDefault(
                              creatorData.linkdata?.["7"]?.pageViewCount,
                              0
                            )
                          )}
                        </Text>
                      </HStack>
                      <HStack>
                        <FiLink />
                        <Text fontSize="lg" fontWeight="bold">
                          {" "}
                          {FormatNumber(
                            getDataOrDefault(
                              creatorData.linkdata?.["7"]?.linkClickCount,
                              0
                            )
                          )}
                        </Text>
                      </HStack>
                      <HStack>
                        <FiMousePointer />
                        <Text fontSize="lg" fontWeight="bold">
                          {" "}
                          {(
                            getDataOrDefault(
                              creatorData.linkdata?.["7"]?.ctr,
                              0
                            )
                          ).toFixed(2)}
                          %
                        </Text>
                      </HStack>
                    </VStack>
                  </Box>

                  {/* 30 Day Clicks */}
                  <Box textAlign="center">
                    <Text
                      fontFamily="Poppins"
                      fontSize="12px"
                      fontWeight="500"
                      color="var(--grays-dark, #51555C)"
                    >
                      30 Day Clicks
                    </Text>
                    <VStack justifyContent="left">
                      <HStack>
                        <FiEye />
                        <Text fontSize="lg" fontWeight="bold">
                          {FormatNumber(
                            getDataOrDefault(
                              creatorData.linkdata?.["30"]?.pageViewCount,
                              0
                            )
                          )}
                        </Text>
                      </HStack>
                      <HStack>
                        <FiLink />
                        <Text fontSize="lg" fontWeight="bold">
                          {" "}
                          {FormatNumber(
                            getDataOrDefault(
                              creatorData.linkdata?.["30"]?.linkClickCount,
                              0
                            )
                          )}
                        </Text>
                      </HStack>
                      <HStack>
                        <FiMousePointer />
                        <Text fontSize="lg" fontWeight="bold">
                          {" "}
                          {(
                            getDataOrDefault(
                              creatorData.linkdata?.["30"]?.ctr,
                              0
                            )
                          ).toFixed(2)}
                          %
                        </Text>
                      </HStack>
                    </VStack>
                  </Box>

                  {/* 90 Day Clicks */}
                  <Box textAlign="center">
                    <Text
                      fontFamily="Poppins"
                      fontSize="12px"
                      fontWeight="500"
                      color="var(--grays-dark, #51555C)"
                    >
                      90 Day Clicks
                    </Text>
                    <VStack justifyContent="left">
                      <HStack>
                        <FiEye />
                        <Text fontSize="lg" fontWeight="bold">
                          {FormatNumber(
                            getDataOrDefault(
                              creatorData.linkdata?.["90"]?.pageViewCount,
                              0
                            )
                          )}
                        </Text>
                      </HStack>
                      <HStack>
                        <FiLink />
                        <Text fontSize="lg" fontWeight="bold">
                          {" "}
                          {FormatNumber(
                            getDataOrDefault(
                              creatorData.linkdata?.["90"]?.linkClickCount,
                              0
                            )
                          )}
                        </Text>
                      </HStack>
                      <HStack>
                        <FiMousePointer />
                        <Text fontSize="lg" fontWeight="bold">
                          {" "}
                          {(
                            getDataOrDefault(
                              creatorData.linkdata?.["90"]?.ctr,
                              0
                            )
                          ).toFixed(2)}
                          %
                        </Text>
                      </HStack>
                    </VStack>
                  </Box>
                </HStack>

                {/* Top Links Section */}
                {creatorData.linkdata?.links &&
                  creatorData.linkdata.links.length > 0 && (
                    <Box>
                      <VStack spacing={4} align="stretch">
                        {creatorData.linkdata.links.map((link, index) => (
                          <Box
                            key={index}
                            p={4}
                            borderWidth="1px"
                            borderRadius="md"
                            bg="gray.50"
                            boxShadow="sm"
                          >
                            <HStack justifyContent="space-between">
                              <Box>
                                <Text fontWeight="bold">
                                  {getDataOrDefault(link.title, "N/A")}
                                </Text>
                                <Link
                                  href={getDataOrDefault(link.url, "#")}
                                  isExternal
                                >
                                  {truncateText(
                                    getDataOrDefault(link.url, "N/A"),
                                    46
                                  )}
                                </Link>
                              </Box>
                              <Box textAlign="right">
                                <Text fontWeight="bold">
                                  {FormatNumber(
                                    getDataOrDefault(link.clickCount, 0)
                                  )}{" "}
                                  clicks
                                </Text>
                                <Text>
                                  {getDataOrDefault(
                                    link.clickPercentage,
                                    0
                                  ).toFixed(2)}
                                  %
                                </Text>
                              </Box>
                            </HStack>
                          </Box>
                        ))}
                      </VStack>
                    </Box>
                  )}

                {/* TikTok Information Section */}
                {isTikTokInfoMissing && (
                  <Box>
                    <FormControl>
                      <FormLabel fontWeight="bold">
                        Add TikTok Information
                      </FormLabel>
                      <HStack mt={2}>
                        <Input
                          placeholder="Enter TikTok Handle"
                          value={tiktokInfo}
                          onChange={(e) => setTikTokInfo(e.target.value)}
                        />
                        <Button
                          onClick={handleAddTikTokInfo}
                          colorScheme="blue"
                          isLoading={submitting}
                          loadingText="Submitting"
                        >
                          Submit
                        </Button>
                      </HStack>
                    </FormControl>
                  </Box>
                )}

                {/* Contact Section */}
                <ContactSection
                  creatorData={creatorData}
                  getDataOrDefault={getDataOrDefault}
                />

                {/* Unique ID Section */}
                <Box textAlign="center" mt={6} p={4}>
                  <Text fontSize="sm" color="gray.500">
                    ID: {getDataOrDefault(creatorData.unique_id, "N/A")}
                  </Text>
                </Box>
              </VStack>
            </Box>
          ) : (
            <Text>No creator data available</Text>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default CreatorDrawer;
