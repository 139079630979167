// src/components/ListsTable/ListsBulkButton.js

import React, { useState, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  useToast,
  Wrap,
  WrapItem,
  Box,
  HStack,
  IconButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useOutsideClick,
  Image,
} from '@chakra-ui/react';
import { CloseIcon, AddIcon } from '@chakra-ui/icons';

// Import your deliverable icons
import instagramIcon from '../../../../../assets/instagrampost.png';
import instagramReel from '../../../../../assets/instagramreel.png';
import instagramStory from '../../../../../assets/instagramstory.png';
import tiktokVideo from '../../../../../assets/tiktok.png';

const ListsBulkButton = ({ isOpen, onClose, onBulkDeliverablesChange }) => {
  const [deliverables, setDeliverables] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const floatingRef = useRef(null);
  const toast = useToast();

  // Define the available deliverables
  const allDeliverables = [
    { name: 'Instagram Story', icon: instagramStory },
    { name: 'Instagram Reel', icon: instagramReel },
    { name: 'Instagram Post', icon: instagramIcon },
    { name: 'TikTok Video', icon: tiktokVideo },
  ];

  // Get icon for a deliverable
  const getIconForDeliverable = (deliverableName) => {
    const deliverable = allDeliverables.find((d) => d.name === deliverableName);
    return deliverable ? deliverable.icon : null;
  };

  const handleSubmit = () => {
    if (deliverables.length === 0) {
      toast({
        title: 'No deliverables selected',
        description: 'Please add at least one deliverable.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    onBulkDeliverablesChange(deliverables);
  };

  const handleButtonClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleAddButtonClick = () => {
    setIsAdding(!isAdding);
  };

  const handleClose = () => {
    setExpandedIndex(null);
    setIsAdding(false);
  };

  const handleCountChange = (index, newCount) => {
    const updated = [...deliverables];
    updated[index].count = newCount;
    setDeliverables(updated);
  };

  const handleRemoveDeliverable = (index) => {
    const updated = deliverables.filter((_, i) => i !== index);
    setDeliverables(updated);
  };

  const handleSelectDeliverable = (deliverable) => {
    const newDeliverable = { type: deliverable.name, count: 1 };
    const updated = [...deliverables, newDeliverable];
    setDeliverables(updated);
    setIsAdding(false);
  };

  const availableDeliverables = allDeliverables.filter(
    (d) => !deliverables.some((ud) => ud.type === d.name)
  );

  useOutsideClick({
    ref: floatingRef,
    handler: handleClose,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Bulk Add Deliverables</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align='stretch' spacing={4}>
            <Wrap spacing={2} maxW='340px'>
              {deliverables.map((deliverable, index) => (
                <WrapItem key={index} position='relative'>
                  <Button
                    onClick={() => handleButtonClick(index)}
                    leftIcon={
                      <Image
                        boxSize='20px'
                        src={getIconForDeliverable(deliverable.type)}
                        alt={deliverable.type}
                      />
                    }
                    borderRadius='full'
                    borderWidth='1px'
                    bg='gray.50'
                  >
                    x{deliverable.count}
                  </Button>
                  {expandedIndex === index && (
                    <Box
                      ref={floatingRef}
                      position='absolute'
                      top='100%'
                      left='0'
                      bg='gray.50'
                      p={2}
                      borderRadius='xl'
                      borderWidth='1px'
                      boxShadow='lg'
                      zIndex={1000}
                      maxW='300px'
                      mt={2}
                    >
                      <HStack spacing={2}>
                        <NumberInput
                          value={deliverable.count}
                          onChange={(_, valueNumber) =>
                            handleCountChange(index, valueNumber)
                          }
                          min={1}
                          max={99}
                          size='sm'
                          width='80px'
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <IconButton
                          aria-label='Remove deliverable'
                          icon={<CloseIcon />}
                          size='sm'
                          onClick={() => handleRemoveDeliverable(index)}
                        />
                      </HStack>
                    </Box>
                  )}
                </WrapItem>
              ))}
              <WrapItem position='relative'>
                <IconButton
                  aria-label='Add deliverable'
                  icon={<AddIcon color='gray.300' />}
                  size='sm'
                  borderRadius='full'
                  bg='white'
                  _hover={{ bg: 'gray.100' }}
                  onClick={handleAddButtonClick}
                />
                {isAdding && availableDeliverables.length > 0 && (
                  <Box
                    ref={floatingRef}
                    position='absolute'
                    top='100%'
                    left='0'
                    bg='gray.50'
                    p={2}
                    borderRadius='xl'
                    boxShadow='lg'
                    zIndex={1000}
                    maxW='300px'
                    borderWidth='1px'
                    mt={2}
                  >
                    <VStack spacing={2}>
                      {availableDeliverables.map((deliverable, index) => (
                        <Button
                          key={index}
                          leftIcon={
                            <Image
                              boxSize='20px'
                              src={deliverable.icon}
                              alt={deliverable.name}
                            />
                          }
                          onClick={() => handleSelectDeliverable(deliverable)}
                          size='sm'
                          width='100%'
                          borderWidth='1px'
                          borderRadius='full'
                        >
                          {deliverable.name}
                        </Button>
                      ))}
                    </VStack>
                  </Box>
                )}
              </WrapItem>
            </Wrap>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant='ghost' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme='blue' onClick={handleSubmit}>
            Apply to Selected Creators
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ListsBulkButton;
