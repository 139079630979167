// ListsAutoDisplay.js
import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  // eslint-disable-next-line no-unused-vars
  Input,
  Icon,
  Flex,
  Heading,
  Button,
} from "@chakra-ui/react";
import { TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";
import Followers from "../../formatting/Followers";
import Creator from "../../formatting/Creator";
import InstaReel from "../../formatting/InstaReel";
import InstaStory from "../../formatting/InstaStory";
// eslint-disable-next-line no-unused-vars
import Column from "./Column";
import TikTokVideo from "../../formatting/TikTokVideo";
import MotionBox from "./MotionBox";

const ListsAutoDisplay = ({ responseData, workspaceData }) => {
  const { groupId } = useParams();
  const navigate = useNavigate();

  const [sortedData, setSortedData] = useState([]);
  const [sortConfig, setSortConfig] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [addedCreators, setAddedCreators] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [visibleColumns, setVisibleColumns] = useState({
    creator: true,
    category: true,
    followers: true,
    engagementRate: true,
    reelsImpressions: true,
    storiesImpressions: false,
    postImpressions: false,
    tiktokVideo: false,
  });

  // New state for loading
  const [isAdding, setIsAdding] = useState(false);

  const getCreatorId = (creator) => creator.unique_id;

  const sortData = useCallback((data, config) => {
    if (!config.key) return data;
    return [...data].sort((a, b) => {
      let aValue, bValue;
      switch (config.key) {
        case "projected_post_views":
          aValue = a.tiktok_profile?.projected_post_views || 0;
          bValue = b.tiktok_profile?.projected_post_views || 0;
          break;
        default:
          aValue =
            config.key === "name"
              ? a.name || ""
              : a.instagram_profile?.[config.key] || 0;
          bValue =
            config.key === "name"
              ? b.name || ""
              : b.instagram_profile?.[config.key] || 0;
      }
      if (aValue < bValue) {
        return config.direction === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return config.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }, []);

  useEffect(() => {
    if (responseData) {
      const sortedArray = sortData(responseData, sortConfig);
      setSortedData(sortedArray);
      setAddedCreators({});
    }
  }, [responseData, sortConfig, sortData]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => (
    <Box display="inline-block" ml={2} position="relative" width="10px" height="20px">
      <Icon
        as={TriangleUpIcon}
        color={
          sortConfig.key === key && sortConfig.direction === "ascending"
            ? "gray.800"
            : "gray.300"
        }
        boxSize={3}
        position="absolute"
        top="5px"
        left="0"
      />
      <Icon
        as={TriangleDownIcon}
        color={
          sortConfig.key === key && sortConfig.direction === "descending"
            ? "gray.800"
            : "gray.300"
        }
        boxSize={3}
        position="absolute"
        top="16px"
        left="0"
      />
    </Box>
  );

  // eslint-disable-next-line no-unused-vars
  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredData = sortedData.filter((creator) => {
    const name = creator.name?.toLowerCase() || "";
    const username = creator.instagram_profile?.username?.toLowerCase() || "";
    const profileName = creator.instagram_profile?.name?.toLowerCase() || "";
    return (
      name.includes(searchTerm) ||
      username.includes(searchTerm) ||
      profileName.includes(searchTerm)
    );
  });

  const toggleAddCreator = (creatorId) => {
    setAddedCreators((prev) => ({
      ...prev,
      [creatorId]: !prev[creatorId],
    }));
  };

  const handleAddAll = () => {
    const allAdded = {};
    filteredData.forEach((creator) => {
      const creatorId = getCreatorId(creator);
      allAdded[creatorId] = true;
    });
    setAddedCreators(allAdded);
  };

  const handleUnAddAll = () => {
    setAddedCreators({});
  };

  const handleAddCreatorsToList = async () => {
    const addedIds = Object.keys(addedCreators).filter((id) => addedCreators[id]);

    // If no creators are added, do nothing
    if (addedIds.length === 0) return;

    setIsAdding(true); // Start loading

    try {
      const response = await fetch(`${process.env.REACT_APP_HOOBE_API_URL}/lists-create-new-auto`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ids: addedIds,
          groupId: groupId,
          workspaceId: workspaceData.workspace_id,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error ${response.status}: ${errorText}`);
      }

      const data = await response.json();
      console.log("Response from lists-generate:", data);

      // Navigate to the new list page
      navigate(`/dashboard/lists/${groupId}/${data.listId}`);
    } catch (error) {
      console.error("Failed to add creators to list:", error);
      // Optionally, display an error message to the user
    } finally {
      setIsAdding(false); // End loading
    }
  };

  const columnConfig = [
    { key: "creator", label: "Creator", sortKey: "name" },
    { key: "category", label: "Category", sortKey: "category" },
    { key: "followers", label: "Followers", sortKey: "followers_count" },
    { key: "engagementRate", label: "Eng. Rate", sortKey: "engagement_rate" },
    {
      key: "reelsImpressions",
      label: "Reels Impressions",
      sortKey: "projected_reel_views",
    },
    {
      key: "storiesImpressions",
      label: "Story Impressions",
      sortKey: "projected_story_views",
    },
    {
      key: "postImpressions",
      label: "Post Impressions",
      sortKey: "projected_post_views",
    },
    {
      key: "tiktokVideo",
      label: "TikTok Video",
      sortKey: "projected_post_views",
    },
  ];

  return (
    <MotionBox
      key="response"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      width="100%"
    >
      <Flex justifyContent="space-between" alignItems="center" mb={6} p={6}>
        <Heading as="h2" size="xl">
          We've found some people we think you might like...
        </Heading>
        <Button
          colorScheme="blue"
          size="lg"
          onClick={handleAddCreatorsToList}
          isDisabled={
            Object.keys(addedCreators).filter((id) => addedCreators[id]).length === 0 ||
            isAdding // Disable if loading
          }
          isLoading={isAdding} // Show spinner when loading
          loadingText="Adding..."
        >
          Add Creators to List
        </Button>
      </Flex>

      <Box>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          {/* 
          <Input
            placeholder="Search by name or username"
            value={searchTerm}
            size="lg"
            borderRadius="xl"
            onChange={handleSearch}
            flex="1"
          /> 
          */}
          {/* 
          <Box ml={4}>
            <Column setVisibleColumns={setVisibleColumns} />
          </Box> 
          */}
        </Flex>

        {filteredData.length > 0 ? (
          <Box
            h="calc(100vh - 230px)"
            overflowY="scroll"
            css={{
              "&::-webkit-scrollbar": { display: "none" },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <Table variant="simple" size="lg" width="100%">
              <Thead position="sticky" top={0} bg="white" zIndex={1}>
                <Tr>
                  {columnConfig.map(
                    ({ key, label, sortKey }) =>
                      visibleColumns[key] && (
                        <Th
                          key={key}
                          fontSize="xs"
                          onClick={() => handleSort(sortKey)}
                          cursor="pointer"
                        >
                          {label} {getSortIcon(sortKey)}
                        </Th>
                      )
                  )}
                  <Th fontSize="xs">
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={handleAddAll}
                      isDisabled={
                        filteredData.length === 0 ||
                        filteredData.every(
                          (creator) => addedCreators[getCreatorId(creator)]
                        )
                      }
                    >
                      Add All
                    </Button>
                    <Button
                      size="sm"
                      colorScheme="gray"
                      borderWidth="1px"
                      onClick={handleUnAddAll}
                      ml={2}
                      isDisabled={
                        filteredData.length === 0 ||
                        filteredData.every(
                          (creator) => !addedCreators[getCreatorId(creator)]
                        )
                      }
                    >
                      Remove All
                    </Button>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredData.map((creator) => {
                  const creatorId = getCreatorId(creator);
                  const isAdded = addedCreators[creatorId] || false;

                  return (
                    <Tr key={creatorId} bg={isAdded ? "gray.50" : "transparent"}>
                      {visibleColumns.creator && (
                        <Td py={2} maxW="400px">
                          <Creator
                            profilePicUrl={creator.instagram_profile?.profile_pic_url}
                            username={creator.instagram_profile?.username || "N/A"}
                            name={creator.name || "N/A"}
                            isVerified={creator.instagram_profile?.is_verified || false}
                            hoobeHandle={creator.hoobe_handle || ""}
                          />
                        </Td>
                      )}
                      {visibleColumns.category && (
                        <Td>
                          {creator.instagram_profile?.category || "N/A"}
                        </Td>
                      )}
                      {visibleColumns.followers && (
                        <Td>
                          <Followers
                            followersCount={creator.instagram_profile?.followers_count || 0}
                            username={creator.instagram_profile?.username || "N/A"}
                          />
                        </Td>
                      )}
                      {visibleColumns.engagementRate && (
                        <Td>
                          {creator.instagram_profile?.engagement_rate !== undefined
                            ? `${(creator.instagram_profile.engagement_rate * 100).toFixed(2)}%`
                            : "N/A"}
                        </Td>
                      )}
                      {visibleColumns.reelsImpressions && (
                        <Td>
                          <InstaReel
                            views={creator.instagram_profile?.projected_reel_views || 0}
                          />
                        </Td>
                      )}
                      {visibleColumns.storiesImpressions && (
                        <Td>
                          <InstaStory
                            views={creator.instagram_profile?.projected_story_views || 0}
                          />
                        </Td>
                      )}
                      {visibleColumns.postImpressions && (
                        <Td>
                          <InstaStory
                            views={creator.instagram_profile?.projected_post_views || 0}
                          />
                        </Td>
                      )}
                      {visibleColumns.tiktokVideo && (
                        <Td>
                          <TikTokVideo
                            views={creator.tiktok_profile?.projected_video_views || 0}
                          />
                        </Td>
                      )}
                      <Td>
                        <Button
                          size="sm"
                          colorScheme={isAdded ? "gray" : "blue"}
                          borderWidth={isAdded ? "1px" : "0px"}
                          onClick={() => toggleAddCreator(creatorId)}
                        >
                          {isAdded ? "Remove" : "Add"}
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        ) : (
          <Text>No creators found</Text>
        )}
      </Box>
    </MotionBox>
  );
};

export default ListsAutoDisplay;
