import React, { useState, useEffect, useRef } from 'react';
import { Text, Input } from '@chakra-ui/react';

const Notes = ({ initialNotes, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(initialNotes || '');
  const inputRef = useRef(null);

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setNotes(e.target.value);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    onChange(notes);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
      onChange(notes);
    }
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <>
      {isEditing ? (
        <Input
          ref={inputRef}
          value={notes}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          size="sm"
          borderRadius="md"
          autoFocus
          w="150px"
        />
      ) : (
        <Text
          onClick={handleTextClick}
          color={notes ? 'black' : 'gray.500'}
          cursor="text"
          w="150px"
          isTruncated
        >
          {notes || 'Add a note...'}
        </Text>
      )}
    </>
  );
};

export default Notes;