import React from 'react';
import { Box, Button, HStack, Flex } from '@chakra-ui/react';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { TbBucket, TbUser } from 'react-icons/tb';

function Settings() {
  const navigate = useNavigate();
  const { userData, workspaceData } = useOutletContext();

  return (
    <Box h="60px" borderBottomWidth="2px">
      <Flex h="100%" alignItems="center" px="4">
        <HStack spacing={2}>
          <Button
            onClick={() => navigate('profile')}
            borderWidth="1px"
            bg="white"
            size="sm"
            leftIcon={<TbUser/>}
          >
            Profile
          </Button>
          <Button
            onClick={() => navigate('workspace')}
            borderWidth="1px"
            bg="white"
            size="sm"
            leftIcon={<TbBucket/>}
          >
            Workspace
          </Button>
        </HStack>
      </Flex>
      <Outlet context={{ userData, workspaceData }} />
    </Box>
  );
}

export default Settings;