// ExampleMedia.js
import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  VStack,
  Box,
  HStack,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import axios from "axios";

// Regex patterns for validating media links
const regexPatterns = {
  instagramPost:
    /(https?:\/\/)?(www\.)?instagram\.com\/p\/[A-Za-z0-9_-]+\/?/,
  instagramReel:
    /(https?:\/\/)?(www\.)?instagram\.com\/reel(s)?\/[A-Za-z0-9_-]+\/?/,
  instagramStory:
    /(https?:\/\/)?(www\.)?instagram\.com\/stories\/[^/]+(?:\/\d+)?\/?/,
  tiktokVideo: /(https?:\/\/)?(www\.)?tiktok\.com\/@[^/]*\/video\/\d+\/?/,
};

const validateLink = (link) =>
  Object.values(regexPatterns).some((pattern) => pattern.test(link));

const ExampleMedia = ({ offer, setOffer, setError }) => {
  const [newMediaLink, setNewMediaLink] = useState("");
  const [isLinkValid, setIsLinkValid] = useState(false);
  const [isMediaLinkAdding, setIsMediaLinkAdding] = useState(false);

  // Validate link as user types
  useEffect(() => {
    const isValid = validateLink(newMediaLink.trim());
    setIsLinkValid(isValid);
  }, [newMediaLink]);

  // Handle adding a new media link
  const handleAddMediaLink = async () => {
    const link = newMediaLink.trim();
    if (!link || !isLinkValid) return;

    setIsMediaLinkAdding(true);

    try {
      const response = await axios.post(
        "https://hoobe-tracking.onrender.com/posts",
        {
          urls: [link],
        }
      );

      const postData = response.data[0];

      const mediaItem = {
        url: postData.url,
        caption: postData.caption,
        thumbnail_url: postData.thumbnail_url,
        content_url: postData.content_url,
      };

      const updatedMedia = [...(offer.exampleMedia || []), mediaItem];
      setOffer((prev) => ({ ...prev, exampleMedia: updatedMedia }));

      setNewMediaLink("");
      setIsLinkValid(false);
    } catch (err) {
      setError("Failed to add media link. Please try again.");
    } finally {
      setIsMediaLinkAdding(false);
    }
  };

  // Handle removing a media link
  const handleRemoveMediaLink = (index) => {
    const updatedMedia = offer.exampleMedia.filter((_, i) => i !== index);
    setOffer((prev) => ({ ...prev, exampleMedia: updatedMedia }));
  };

  return (
    <FormControl>
      <FormLabel>Example Media</FormLabel>
      <InputGroup>
        <Input
          value={newMediaLink}
          onChange={(e) => setNewMediaLink(e.target.value)}
          placeholder="Enter media link"
          size="md"
          borderRadius="xl"
          isInvalid={!isLinkValid && newMediaLink.trim() !== ""}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (isLinkValid && !isMediaLinkAdding) {
                handleAddMediaLink();
              }
            }
          }}
          disabled={isMediaLinkAdding}
        />
        <InputRightElement width="4.5rem">
          <Button
            h="1.75rem"
            size="sm"
            onClick={handleAddMediaLink}
            disabled={!isLinkValid || isMediaLinkAdding}
            isLoading={isMediaLinkAdding}
            colorScheme="blue"
          >
            Add
          </Button>
        </InputRightElement>
      </InputGroup>
      <VStack align="start" mt={4} w="100%">
        {offer.exampleMedia &&
          offer.exampleMedia.map((mediaItem, index) => (
            <Box
              key={index}
              border="1px solid"
              borderColor="gray.200"
              bg="gray.100"
              borderRadius="xl"
              p={1}
              px={2}
              w="100%"
            >
              <HStack justify="space-between" align="center">
                <Text>{mediaItem.url}</Text>
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => handleRemoveMediaLink(index)}
                  size="sm"
                  aria-label="Remove link"
                  colorScheme="red"
                />
              </HStack>
            </Box>
          ))}
      </VStack>
    </FormControl>
  );
};

export default ExampleMedia;
