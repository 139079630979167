// Search.js
import React, { useState, useEffect, useMemo } from "react";
import {
  Flex,
  Heading,
  Input,
  Box,
  Button,
  Fade,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import Gender from "./filters/Gender";
import Followers from "./filters/Followers";
import Verified from "./filters/Verified";
import Keywords from "./filters/Keywords";
import Hoobe from "./filters/Hoobe";
import EngagementRate from "./filters/EngagementRate";
import Deliverables from "./filters/Deliverables";
import Column from "./filters/Column";
import SearchQuery from "./table/SearchQuery";
import AddCreatorsModal from "./AddCreatorsModal";
import LinkData from "./filters/LinkData";
import LinkKeywords from "./filters/LinkKeywords";

// Default values
const defaultMinFollowers = 1000;
const defaultMaxFollowers = 10000000000;
const defaultSearchValue = "";
const defaultGender = "";
const defaultVerified = "";
const defaultHoobe = "";
const defaultMinEngagement = 0;
const defaultMaxEngagement = 10000;
const defaultKeywords = [];
const defaultDeliverables = [];
const defaultOperator = "AND";
const defaultLinkKeywords = [];
const defaultLinkOperator = "AND";

// New default values for LinkData
const defaultPageViews = {
  "7": { min: 0, max: 10000000 },
  "30": { min: 0, max: 10000000 },
  "90": { min: 0, max: 10000000 },
};
const defaultLinkClicks = {
  "7": { min: 0, max: 10000000 },
  "30": { min: 0, max: 10000000 },
  "90": { min: 0, max: 10000000 },
};
const defaultCtr = {
  "7": { min: 0, max: 100 },
  "30": { min: 0, max: 100 },
  "90": { min: 0, max: 100 },
};

const Search = () => {
  // State variables
  const [searchValue, setSearchValue] = useState(defaultSearchValue);
  const [selectedGender, setSelectedGender] = useState(defaultGender);
  const [selectedVerified, setSelectedVerified] = useState(defaultVerified);
  const [minFollowers, setMinFollowers] = useState(defaultMinFollowers);
  const [maxFollowers, setMaxFollowers] = useState(defaultMaxFollowers);
  const [minEngagement, setMinEngagement] = useState(defaultMinEngagement);
  const [maxEngagement, setMaxEngagement] = useState(defaultMaxEngagement);
  const [keywords, setKeywords] = useState(defaultKeywords);
  const [operator, setOperator] = useState(defaultOperator);
  const [linkKeywords, setLinkKeywords] = useState(defaultLinkKeywords);
  const [linkOperator, setLinkOperator] = useState(defaultLinkOperator);
  const [selectedHoobe, setSelectedHoobe] = useState(defaultHoobe);
  const [selectedDeliverables, setSelectedDeliverables] = useState(
    defaultDeliverables
  );
  const [visibleColumns, setVisibleColumns] = useState({
    creator: true,
    followers: true,
    category: true,
    engagementRate: true,
    reelsImpressions: true,
    storiesImpressions: true,
    postImpressions: false,
    tiktokVideo: false,
    // Add new columns if needed for LinkData
    pageViews7: false,
    pageViews30: false,
    pageViews90: false,
    linkClicks7: false,
    linkClicks30: false,
    linkClicks90: false,
    ctr7: false,
    ctr30: false,
    ctr90: false,
  });
  const [selectedCreators, setSelectedCreators] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // New state variables for LinkData
  const [pageViews, setPageViews] = useState(defaultPageViews);
  const [linkClicks, setLinkClicks] = useState(defaultLinkClicks);
  const [ctr, setCtr] = useState(defaultCtr);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const resetFilters = () => {
    setSearchValue(defaultSearchValue);
    setSelectedGender(defaultGender);
    setSelectedVerified(defaultVerified);
    setMinFollowers(defaultMinFollowers);
    setMaxFollowers(defaultMaxFollowers);
    setMinEngagement(defaultMinEngagement);
    setMaxEngagement(defaultMaxEngagement);
    setKeywords(defaultKeywords);
    setOperator(defaultOperator);
    setLinkKeywords(defaultLinkKeywords);
    setLinkOperator(defaultLinkOperator);
    setSelectedHoobe(defaultHoobe);
    setSelectedDeliverables(defaultDeliverables);
    setSelectedCreators([]);
    setPageViews(defaultPageViews); // Reset Page Views
    setLinkClicks(defaultLinkClicks); // Reset LinkData
    setCtr(defaultCtr); // Reset CTR
  };

  const filters = useMemo(
    () => ({
      searchValue,
      selectedGender,
      selectedVerified,
      minFollowers,
      maxFollowers,
      minEngagement,
      maxEngagement,
      keywords,
      operator,
      linkKeywords,
      linkOperator,
      selectedHoobe,
      selectedDeliverables,
      pageViews,
      linkClicks,
      ctr,
    }),
    [
      searchValue,
      selectedGender,
      selectedVerified,
      minFollowers,
      maxFollowers,
      minEngagement,
      maxEngagement,
      keywords,
      operator,
      linkKeywords,
      linkOperator,
      selectedHoobe,
      selectedDeliverables,
      pageViews,
      linkClicks,
      ctr,
    ]
  );

  const [showReset, setShowReset] = useState(false);

  useEffect(() => {
    const isChanged =
      searchValue !== defaultSearchValue ||
      selectedGender !== defaultGender ||
      selectedVerified !== defaultVerified ||
      minFollowers !== defaultMinFollowers ||
      maxFollowers !== defaultMaxFollowers ||
      minEngagement !== defaultMinEngagement ||
      maxEngagement !== defaultMaxEngagement ||
      keywords.length > 0 ||
      linkKeywords.length > 0 ||
      selectedHoobe !== defaultHoobe ||
      selectedDeliverables.length > 0 ||
      JSON.stringify(pageViews) !== JSON.stringify(defaultPageViews) ||
      JSON.stringify(linkClicks) !== JSON.stringify(defaultLinkClicks) ||
      JSON.stringify(ctr) !== JSON.stringify(defaultCtr);

    setShowReset(isChanged);
  }, [
    searchValue,
    selectedGender,
    selectedVerified,
    minFollowers,
    maxFollowers,
    minEngagement,
    maxEngagement,
    keywords,
    linkKeywords,
    selectedHoobe,
    selectedDeliverables,
    pageViews,
    linkClicks,
    ctr,
  ]);

  const handleOperatorChange = (newOperator) => {
    setOperator(newOperator);
    console.log(`Operator changed to: ${newOperator}`);
  };

  const handleLinkOperatorChange = (newOperator) => {
    setLinkOperator(newOperator);
    console.log(`Link Operator changed to: ${newOperator}`);
  };

  const handleAddCreators = () => {
    onOpen();
  };

  return (
    <Box>
      <Flex
        align="center"
        p={4}
        borderColor="gray.200"
        h="60px"
        borderBottomWidth="2px"
      >
        <Heading size="md">Search</Heading>
      </Flex>

      <Flex>
        <Box
          width="300px"
          borderColor="grey.200"
          borderRightWidth="2px"
          p={4}
        >
          <Flex justify="space-between" align="center">
            <Heading as="h1" size="md">
              Filters
            </Heading>
            <Fade in={showReset}>
              <Button
                onClick={resetFilters}
                borderWidth="1px"
                borderRadius="xl"
                bg="white"
                size="sm"
              >
                Reset Filters
              </Button>
            </Fade>
          </Flex>

          <Box
            height="calc(100vh - 124px)"
            overflowY="scroll"
            css={{
              "&::-webkit-scrollbar": { display: "none" },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <Box mt={4}>
              <Followers
                minFollowers={minFollowers}
                maxFollowers={maxFollowers}
                setMinFollowers={setMinFollowers}
                setMaxFollowers={setMaxFollowers}
              />
            </Box>

            <Box mt={4}>
              <EngagementRate
                minEngagement={minEngagement}
                maxEngagement={maxEngagement}
                setMinEngagement={setMinEngagement}
                setMaxEngagement={setMaxEngagement}
              />
            </Box>

            <Box mt={4}>
              <Deliverables
                selectedDeliverables={selectedDeliverables}
                setSelectedDeliverables={setSelectedDeliverables}
              />
            </Box>

            <Box mt={4}>
              <Keywords
                keywords={keywords}
                setKeywords={setKeywords}
                setOperatorFunction={handleOperatorChange}
              />
            </Box>

            <Box mt={4}>
              <LinkKeywords
                linkKeywords={linkKeywords}
                setLinkKeywords={setLinkKeywords}
                setOperatorFunction={handleLinkOperatorChange}
              />
            </Box>

            <Box mt={4}>
              <Gender
                selectedGender={selectedGender}
                setSelectedGender={setSelectedGender}
              />
            </Box>

            <Box mt={4}>
              <Verified
                selectedVerified={selectedVerified}
                setSelectedVerified={setSelectedVerified}
              />
            </Box>

            <Box mt={4}>
              <Hoobe
                selectedHoobe={selectedHoobe}
                setSelectedHoobe={setSelectedHoobe}
              />
            </Box>

            {/* Updated LinkData Filter */}
            <Box mt={4}>
              <LinkData
                pageViews={pageViews}
                setPageViews={setPageViews}
                linkClicks={linkClicks}
                setLinkClicks={setLinkClicks}
                ctr={ctr}
                setCtr={setCtr}
              />
            </Box>
          </Box>
        </Box>

        <Box flex="1" p={4}>
          <Flex align="center">
            {selectedCreators.length > 0 && (
              <Button
                leftIcon={<AddIcon />}
                bg="white"
                borderWidth="1px"
                borderRadius="xl"
                size="lg"
                mr={2}
                px={10}
                onClick={handleAddCreators}
              >
                Add {selectedCreators.length} Creator
                {selectedCreators.length > 1 ? "s" : ""}
              </Button>
            )}
            <Input
              value={searchValue}
              onChange={handleSearchChange}
              placeholder="Search creators..."
              size="lg"
              borderRadius="xl"
              width="100%"
              mr={2}
            />
            <Column setVisibleColumns={setVisibleColumns} />
          </Flex>
          <Box>
            <SearchQuery
              visibleColumns={visibleColumns}
              filters={filters}
              setSelectedCreators={setSelectedCreators}
              selectedCreators={selectedCreators}
            />
          </Box>
        </Box>
      </Flex>

      <AddCreatorsModal
        isOpen={isOpen}
        onClose={onClose}
        creatorIds={selectedCreators}
        setSelectedCreators={setSelectedCreators}
      />
    </Box>
  );
};

export default Search;
