// TemplateEditor.js

import React, { useState, useEffect } from 'react';
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../../../Firebase';
import {
  Box,
  Heading,
  Text,
  VStack,
  Button,
  Input,
  FormControl,
  FormLabel,
  Textarea,
  Flex,
  Spinner,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { AiOutlineDelete } from 'react-icons/ai';
import RichHTML from './RichHTML';

const TemplateEditor = () => {
  const { templateId } = useParams();
  const { workspaceData } = useOutletContext();
  const navigate = useNavigate();
  const [template, setTemplate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [templateName, setTemplateName] = useState('');
  const [templateType, setTemplateType] = useState('email');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const toast = useToast();

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const fetchTemplate = async () => {
      if (!workspaceData || !workspaceData.workspace_id) {
        setError('Workspace data not available');
        setLoading(false);
        return;
      }

      try {
        const templateRef = doc(
          db,
          'workspaces',
          workspaceData.workspace_id,
          'templates',
          templateId
        );
        const templateSnap = await getDoc(templateRef);

        if (templateSnap.exists()) {
          const templateData = { id: templateSnap.id, ...templateSnap.data() };
          setTemplate(templateData);
          setTemplateName(templateData.name || '');
          setTemplateType(templateData.type || 'email');
          setSubject(templateData.subject || '');
          setMessage(templateData.message || '');
        } else {
          setError('Template not found');
        }
      } catch (err) {
        console.error('Error fetching template:', err);
        setError('Failed to fetch template');
      } finally {
        setLoading(false);
      }
    };

    fetchTemplate();
  }, [templateId, workspaceData]);

  const handleSave = async (updates) => {
    if (!template || !workspaceData || !workspaceData.workspace_id) return;

    try {
      const templateRef = doc(
        db,
        'workspaces',
        workspaceData.workspace_id,
        'templates',
        template.id
      );
      await updateDoc(templateRef, updates);
      console.log('Template updated successfully');
    } catch (error) {
      console.error('Error updating template:', error);
      toast({
        title: 'Update Failed',
        description: 'Failed to update the template. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleNameChange = (e) => {
    setTemplateName(e.target.value);
  };

  const handleNameBlur = () => {
    handleSave({ name: templateName });
  };

  const handleTypeChange = (type) => {
    setTemplateType(type);
    handleSave({ type });
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleSubjectBlur = () => {
    handleSave({ subject });
  };

  const handleMessageChange = (content) => {
    setMessage(content);
  };

  const handleMessageBlur = () => {
    handleSave({ message });
  };

  const handleDelete = async () => {
    if (!template || !workspaceData || !workspaceData.workspace_id) return;

    setIsDeleting(true);
    try {
      const templateRef = doc(
        db,
        'workspaces',
        workspaceData.workspace_id,
        'templates',
        template.id
      );
      await deleteDoc(templateRef);
      navigate('/dashboard/messages/templates');
    } catch (error) {
      console.error('Error deleting template:', error);
      toast({
        title: 'Deletion Failed',
        description: 'Failed to delete the template. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
      onDeleteModalClose();
    }
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" height="400px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) return <Text color="red.500">{error}</Text>;
  if (!template) return <Text>No template found</Text>;

  return (
    <Box
      p={4}
      maxW="700px"
      mx="auto"
      fontFamily="'Poppins', sans-serif"
    >
      <Heading mb={4}>Template Details</Heading>
      <VStack spacing={4} align="stretch">
        <FormControl>
          <FormLabel>Template Name</FormLabel>
          <Input
            value={templateName}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
            placeholder="Enter template name"
          />
        </FormControl>

        <FormControl display="flex" alignItems="center">
          <Button
            onClick={() => handleTypeChange('email')}
            colorScheme={templateType === 'email' ? 'blue' : 'gray'}
            mr={2}
            borderWidth="1px"
          >
            Email
          </Button>
          <Button
            onClick={() => handleTypeChange('sms')}
            colorScheme={templateType === 'sms' ? 'blue' : 'gray'}
            borderWidth="1px"
          >
            SMS
          </Button>
        </FormControl>

        {templateType === 'email' && (
          <>
            <FormControl>
              <FormLabel>Subject</FormLabel>
              <Input
                value={subject}
                onChange={handleSubjectChange}
                onBlur={handleSubjectBlur}
                placeholder="Enter email subject"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Message</FormLabel>
              <RichHTML
                value={message}
                onChange={handleMessageChange}
                onBlur={handleMessageBlur}
                placeholder="Compose your email message here..."
              />
            </FormControl>
          </>
        )}

        {templateType === 'sms' && (
          <FormControl>
            <FormLabel>Message</FormLabel>
            <Textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onBlur={handleMessageBlur}
              placeholder="Enter SMS message"
              rows={10}
            />
          </FormControl>
        )}

        <Button
          leftIcon={<AiOutlineDelete />}
          colorScheme="red"
          onClick={onDeleteModalOpen}
          alignSelf="flex-start"
        >
          Delete Template
        </Button>
      </VStack>

      <Modal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Template</ModalHeader>
          <ModalBody>
            <Text>
              Are you sure you want to delete the template "<strong>{templateName}</strong>"?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onDeleteModalClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDelete}
              isLoading={isDeleting}
              loadingText="Deleting"
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TemplateEditor;
