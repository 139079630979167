// CampaignMessagesTemplates.js

import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Text,
  Box,
  List,
  ListItem,
  Flex,
  VStack,
  Spinner,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../../../../../Firebase"; 
import { TbList, TbMail, TbPhone, TbUser } from "react-icons/tb";

const CampaignMessagesTemplates = ({
  isOpen,
  onClose,
  workspaceId,
  selectedCreators,
  selectedCreator, // Added selectedCreator prop
  applyTemplateToCreators, // Added applyTemplateToCreators prop
}) => {
  const [templates, setTemplates] = useState([]);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [errorTemplates, setErrorTemplates] = useState(null);
  const [filterType, setFilterType] = useState("email"); // 'email' or 'sms'
  const toast = useToast();

  useEffect(() => {
    if (!isOpen) return; // Fetch templates only when modal is open

    const fetchTemplates = async () => {
      setLoadingTemplates(true);
      setErrorTemplates(null);
      try {
        const templatesRef = collection(
          db,
          "workspaces",
          workspaceId,
          "templates"
        );
        const q = query(templatesRef, orderBy("type")); // Assuming 'type' field exists
        const querySnapshot = await getDocs(q);
        const fetchedTemplates = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTemplates(fetchedTemplates);
      } catch (error) {
        console.error("Error fetching templates:", error);
        setErrorTemplates("Failed to fetch templates.");
        toast({
          title: "Error",
          description: "Failed to fetch templates.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoadingTemplates(false);
      }
    };

    fetchTemplates();
  }, [isOpen, workspaceId, toast]);

  // Split templates by type
  const emailTemplates = templates.filter(
    (template) => template.type === "email"
  );
  const smsTemplates = templates.filter((template) => template.type === "sms");

  // Handler to toggle filter type
  const handleFilterToggle = (type) => {
    setFilterType(type);
  };

  // Handler when a template is selected
  const handleTemplateSelect = (template, applyTo) => {
    let creatorsToUpdate = [];
    if (applyTo === "selected") {
      if (selectedCreator) {
        creatorsToUpdate = [selectedCreator];
      } else {
        toast({
          title: "No Creator Selected",
          description: "Please select a creator to apply the template.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    } else if (applyTo === "all") {
      creatorsToUpdate = selectedCreators;
    }

    applyTemplateToCreators(template, creatorsToUpdate)
      .then(() => {
        toast({
          title: "Template Applied",
          description: `Template "${template.name}" has been applied to ${applyTo} creators.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose(); // Close the modal
      })
      .catch((error) => {
        console.error("Error applying template:", error);
        toast({
          title: "Error",
          description: "Failed to apply template.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justify="space-between" align="center">
            <Text>Send Messages</Text>
            <Button
              variant="ghost"
              borderWidth="1px"
              onClick={onClose}
              leftIcon={<AiOutlineClose />}
            >
              Close
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <VStack align="stretch" spacing={6}>
            <Flex>
              <Button
                colorScheme={filterType === "email" ? "blue" : "gray"}
                onClick={() => handleFilterToggle("email")}
                borderWidth="1px"
                leftIcon={<TbMail/>}
                mr={2}
              >
                Email Templates
              </Button>
              <Button
                colorScheme={filterType === "sms" ? "blue" : "gray"}
                onClick={() => handleFilterToggle("sms")}
                borderWidth="1px"
                leftIcon={<TbPhone/>}
              >
                SMS Templates
              </Button>
            </Flex>

            {/* Templates List */}
            <Box mb={5}>
              {loadingTemplates ? (
                <Flex justify="center" align="center" height="100px">
                  <Spinner size="lg" />
                </Flex>
              ) : errorTemplates ? (
                <Text color="red.500">{errorTemplates}</Text>
              ) : (
                <>
                  {filterType === "email" && emailTemplates.length === 0 && (
                    <Text>No email templates available.</Text>
                  )}
                  {filterType === "sms" && smsTemplates.length === 0 && (
                    <Text>No SMS templates available.</Text>
                  )}

                  <List spacing={3}>
                    {filterType === "email" &&
                      emailTemplates.map((template) => (
                        <ListItem
                          key={template.id}
                          borderWidth="1px"
                          p={4}
                          borderRadius="md"
                          _hover={{ bg: "gray.50" }}
                          cursor="pointer"
                          height="100px" // Fixed height
                        >
                          <Flex
                            justify="space-between"
                            align="center"
                            height="100%"
                          >
                            <Box>
                              <Text fontWeight="bold">{template.name}</Text>
                              <Text
                                fontSize="sm"
                                color={template.subject ? "gray.600" : "gray.400"}
                              >
                                {template.subject
                                  ? template.subject
                                  : "No Subject"}
                              </Text>
                            </Box>
                            <HStack spacing={2}>
                              <Button
                                size="md"
                                borderWidth="1px"
                                leftIcon={<TbUser/>}
                                onClick={() =>
                                  handleTemplateSelect(template, "selected")
                                }
                              >
                                Apply to Selected
                              </Button>
                              <Button
                                size="md"
                                borderWidth="1px"
                                leftIcon={<TbList/>}
                                onClick={() =>
                                  handleTemplateSelect(template, "all")
                                }
                              >
                                Apply All
                              </Button>
                            </HStack>
                          </Flex>
                        </ListItem>
                      ))}

                    {filterType === "sms" &&
                      smsTemplates.map((template) => (
                        <ListItem
                          key={template.id}
                          borderWidth="1px"
                          p={4}
                          borderRadius="md"
                          _hover={{ bg: "gray.50" }}
                          cursor="pointer"
                          height="100px" // Fixed height
                        >
                          <Flex
                            justify="space-between"
                            align="center"
                            height="100%"
                          >
                            <Box>
                              <Text fontWeight="bold">{template.name}</Text>
                              <Text
                                fontSize="sm"
                                color={template.message ? "gray.600" : "gray.400"}
                              >
                                {template.message
                                  ? `${template.message.substring(0, 50)}${
                                      template.message.length > 50 ? "..." : ""
                                    }`
                                  : "No Message"}
                              </Text>
                            </Box>
                            <HStack spacing={2}>
                              <Button
                                size="md"
                                borderWidth="1px"
                                leftIcon={<TbUser/>}
                                onClick={() =>
                                  handleTemplateSelect(template, "selected")
                                }
                              >
                                Apply to Selected
                              </Button>
                              <Button
                                size="md"
                                borderWidth="1px"
                                leftIcon={<TbList/>}
                                onClick={() =>
                                  handleTemplateSelect(template, "all")
                                }
                              >
                                Apply All
                              </Button>
                            </HStack>
                          </Flex>
                        </ListItem>
                      ))}
                  </List>
                </>
              )}
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CampaignMessagesTemplates;
