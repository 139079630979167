// CampaignMessagesSendModal.js

import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Box,
  Flex,
  List,
  ListItem,
  Avatar,
  Text,
  VStack,
  HStack,
  useToast,
  ModalFooter,
} from "@chakra-ui/react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../Firebase";
import { TbMail, TbPhone, TbSend, TbTemplate } from "react-icons/tb";
import { AiOutlineClose } from "react-icons/ai";
import CampaignMessagesEmail from "./CampaignMessagesEmail";
import CampaignMessagesSms from "./CampaignMessagesSms";
import Status from "../../../formatting/Status";

// Import the modal components
import CampaignMessagesTemplates from "./CampaignMessagesTemplates";
import CampaignMessagesSendAll from "./CampaignMessagesSendAll";

const CampaignMessagesSendModal = ({
  isOpen,
  onClose,
  selectedCreators,
  workspaceId,
  campaignId,
}) => {
  const [selectedCreatorId, setSelectedCreatorId] = useState(null);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [selectedMessageType, setSelectedMessageType] = useState("email");
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);
  const [isSendAllModalOpen, setIsSendAllModalOpen] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (
      !selectedCreatorId ||
      !selectedCreators.some(
        (creator) => creator.creatorId === selectedCreatorId
      )
    ) {
      if (selectedCreators.length > 0) {
        setSelectedCreatorId(selectedCreators[0].creatorId);
      } else {
        setSelectedCreatorId(null);
      }
    }
  }, [selectedCreators, selectedCreatorId]);

  useEffect(() => {
    const creator = selectedCreators.find(
      (creator) => creator.creatorId === selectedCreatorId
    );
    setSelectedCreator(creator);
  }, [selectedCreatorId, selectedCreators]);

  const updateCreatorInFirestore = async (updatedCreator) => {
    const campaignRef = doc(
      db,
      "workspaces",
      workspaceId,
      "campaigns",
      campaignId
    );
    try {
      const campaignDoc = await getDoc(campaignRef);
      if (campaignDoc.exists()) {
        const campaignData = campaignDoc.data();
        const updatedCreators = campaignData.creators.map((creator) =>
          creator.creatorId === updatedCreator.creatorId
            ? updatedCreator
            : creator
        );
        await updateDoc(campaignRef, { creators: updatedCreators });
      }
    } catch (error) {
      console.error("Error updating creator:", error);
      toast({
        title: "Error saving changes",
        description: "There was an error saving the changes. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUpdate = async (field, value) => {
    if (selectedCreator) {
      const updatedCreator = { ...selectedCreator, [field]: value };
      setSelectedCreator(updatedCreator);

      await updateCreatorInFirestore(updatedCreator);
    }
  };

  const handleApplyTemplate = () => {
    setIsTemplatesModalOpen(true);
  };

  const handleSendAll = () => {
    setIsSendAllModalOpen(true);
  };

  const closeTemplatesModal = () => {
    setIsTemplatesModalOpen(false);
  };

  const closeSendAllModal = () => {
    setIsSendAllModalOpen(false);
  };

  // Function to apply template to creators
  const applyTemplateToCreators = async (template, creators) => {
    // For each creator, update their message content based on template type
    const updatedCreators = creators.map((creator) => {
      const updatedCreator = { ...creator };
      if (template.type === "email") {
        updatedCreator.subject = template.subject || "";
        updatedCreator.emailMessage = template.message || "";
      } else if (template.type === "sms") {
        updatedCreator.smsMessage = template.message || "";
      }
      return updatedCreator;
    });

    // Update in Firestore
    const campaignRef = doc(
      db,
      "workspaces",
      workspaceId,
      "campaigns",
      campaignId
    );
    try {
      const campaignDoc = await getDoc(campaignRef);
      if (campaignDoc.exists()) {
        const campaignData = campaignDoc.data();
        const updatedCampaignCreators = campaignData.creators.map((creator) => {
          const updatedCreator = updatedCreators.find(
            (uc) => uc.creatorId === creator.creatorId
          );
          return updatedCreator || creator;
        });
        await updateDoc(campaignRef, { creators: updatedCampaignCreators });
      }

      // Update local selectedCreator if necessary
      if (
        selectedCreator &&
        creators.some((c) => c.creatorId === selectedCreator.creatorId)
      ) {
        const updatedCreator = updatedCreators.find(
          (c) => c.creatorId === selectedCreator.creatorId
        );
        setSelectedCreator(updatedCreator);
      }

      return Promise.resolve();
    } catch (error) {
      console.error("Error updating creators:", error);
      return Promise.reject(error);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p={0}>
            <Flex justify="space-between" align="center" w="100%" p={4}>
              <Text>Send Message</Text>
              <HStack spacing={4}>
                <Button
                  variant="outline"
                  onClick={handleApplyTemplate}
                  leftIcon={<TbTemplate />}
                >
                  Apply Template
                </Button>
                <Button
                  variant="outline"
                  onClick={handleSendAll}
                  leftIcon={<TbSend />}
                >
                  Send All
                </Button>
                <Button
                  variant="outline"
                  onClick={onClose}
                  leftIcon={<AiOutlineClose />}
                >
                  Close
                </Button>
              </HStack>
            </Flex>
          </ModalHeader>
          <ModalBody p={0}>
            <Flex borderTopWidth="2px" h="full" borderBottomWidth="2px">
              {/* Left Side - Creators List */}
              <Box
                w="20%"
                overflowY="auto"
                borderRight="2px"
                borderColor="gray.200"
                h="calc(100vh - 76px)"
              >
                <List>
                  {selectedCreators.map((creator) => (
                    <ListItem
                      key={creator.creatorId}
                      p={3}
                      cursor="pointer"
                      bg={
                        creator.creatorId === selectedCreatorId
                          ? "gray.200"
                          : "transparent"
                      }
                      _hover={{ bg: "gray.100" }}
                      onClick={() => setSelectedCreatorId(creator.creatorId)}
                      borderBottomWidth="1px"
                    >
                      <Flex align="center" justify="space-between">
                        <Flex align="center">
                          <Avatar
                            size="sm"
                            src={creator.instagram_profile_pic_url}
                            mr={3}
                            name={creator.name}
                          />
                          <Box>
                            <Text fontWeight="500">{creator.name}</Text>
                            <Text fontSize="sm" color="gray.500">
                              @{creator.instagram_username}
                            </Text>
                          </Box>
                        </Flex>
                        <HStack spacing={2} align="center">
                          {/* Conditionally render Email Icon */}
                          {creator.emails && creator.emails.length > 0 && (
                            <Box>
                              <TbMail size="1.2em" color="gray.800" />
                            </Box>
                          )}
                          {/* Conditionally render Phone Icon */}
                          {creator.phoneNumbers &&
                            creator.phoneNumbers.length > 0 && (
                              <Box>
                                <TbPhone size="1.2em" color="gray.800" />
                              </Box>
                            )}
                          {/* Status Component */}
                          <Status
                            initialStatus={creator.status}
                            isEditable={false}
                          />
                        </HStack>
                      </Flex>
                    </ListItem>
                  ))}
                </List>
              </Box>

              {/* Right Side - Message Form */}
              <Box flex="1" overflowY="auto" p={4}>
                {selectedCreator ? (
                  <VStack align="stretch">
                    <Flex
                      align="center"
                      justify="space-between"
                      wrap="wrap"
                      borderWidth="1px"
                      p={4}
                      borderRadius="md"
                    >
                      <HStack spacing={4}>
                        <Avatar
                          size="lg"
                          src={selectedCreator.instagram_profile_pic_url}
                          name={selectedCreator.name}
                        />
                        <Box>
                          <Text fontSize="2xl" fontWeight="bold">
                            {selectedCreator.name}
                          </Text>
                          <Text fontSize="md" color="gray.500">
                            @{selectedCreator.instagram_username}
                          </Text>
                          <Text fontSize="sm" color="gray.400">
                            ID: {selectedCreator.creatorId}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack spacing={4} pointerEvents="none">
                        <Status
                          initialStatus={selectedCreator.status}
                          isEditable={false}
                        />
                      </HStack>
                    </Flex>

                    {/* Buttons to switch between Email and SMS */}
                    <Flex justify="center">
                      <HStack spacing={4}>
                        <Button
                          variant={
                            selectedMessageType === "email" ? "solid" : "outline"
                          }
                          onClick={() => setSelectedMessageType("email")}
                          leftIcon={<TbMail />}
                          size="lg"
                          borderRadius="xl"
                          borderWidth="1px"
                        >
                          Email
                        </Button>
                        <Button
                          variant={
                            selectedMessageType === "sms" ? "solid" : "outline"
                          }
                          onClick={() => setSelectedMessageType("sms")}
                          leftIcon={<TbPhone />}
                          size="lg"
                          borderRadius="xl"
                          borderWidth="1px"
                        >
                          SMS
                        </Button>
                      </HStack>
                    </Flex>

                    {/* Centered content area */}
                    <Flex justify="center">
                      <Box width="600px">
                        {selectedMessageType === "email" ? (
                          <CampaignMessagesEmail
                            selectedCreator={selectedCreator}
                            handleUpdate={handleUpdate}
                            workspaceId={workspaceId}
                            campaignId={campaignId}
                          />
                        ) : (
                          <CampaignMessagesSms
                            selectedCreator={selectedCreator}
                            handleUpdate={handleUpdate}
                            workspaceId={workspaceId}
                            campaignId={campaignId}
                          />
                        )}
                      </Box>
                    </Flex>
                  </VStack>
                ) : (
                  <Text>No creator selected.</Text>
                )}
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter p={0}></ModalFooter>
        </ModalContent>
      </Modal>

      {/* Templates Modal */}
      <CampaignMessagesTemplates
        isOpen={isTemplatesModalOpen}
        onClose={closeTemplatesModal}
        workspaceId={workspaceId}
        campaignId={campaignId}
        selectedCreators={selectedCreators}
        selectedCreator={selectedCreator}
        applyTemplateToCreators={applyTemplateToCreators}
      />

      {/* Send All Modal */}
      <CampaignMessagesSendAll
        isOpen={isSendAllModalOpen}
        onClose={closeSendAllModal}
        workspaceId={workspaceId}
        campaignId={campaignId}
        selectedCreators={selectedCreators}
      />
    </>
  );
};

export default CampaignMessagesSendModal;
