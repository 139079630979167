// AllProducts.js
import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Flex,
  Text,
  Spinner,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
} from '@chakra-ui/react';
import {
  collection,
  query,
  onSnapshot,
} from 'firebase/firestore';
import { db } from '../../../../Firebase';
import { useOutletContext, useNavigate } from 'react-router-dom';
import axios from 'axios';
import placeholderImage from '../../../../assets/placeholder.jpg';

const AllProducts = () => {
  const { workspaceData, searchText } = useOutletContext();
  const [shopifyProducts, setShopifyProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (!workspaceData?.workspace_id) {
      console.error('Workspace data is not available');
      setLoading(false);
      return;
    }

    const productsRef = collection(
      db,
      'workspaces',
      workspaceData.workspace_id,
      'products'
    );
    const q = query(productsRef); // Removed orderBy('order')

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const firebaseProductsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (firebaseProductsData.length === 0) {
        setShopifyProducts([]);
        setLoading(false);
        return;
      }

      const shopifyProductIds = firebaseProductsData.map(
        (product) => product.shopifyProductId
      );

      try {
        // Fetch all Shopify products in a single request
        const response = await axios.post(
          'https://hoobe-shop-api.onrender.com/get-products-from-ids',
          { ids: shopifyProductIds }
        );
        const shopifyProductsData = response.data;

        console.log('Raw Shopify Products:', shopifyProductsData);

        // Map Firebase product IDs to Shopify products
        const firebaseProductMap = Object.fromEntries(
          firebaseProductsData.map((p) => [p.shopifyProductId, p.id])
        );

        // Attach firebaseId to each Shopify product
        const productsWithFirebaseId = shopifyProductsData.map((product) => ({
          ...product,
          firebaseId: firebaseProductMap[product.id.toString()],
        }));

        setShopifyProducts(productsWithFirebaseId);
      } catch (error) {
        console.error('Error fetching products:', error);
        toast({
          title: 'Error fetching products',
          description: 'Could not fetch products from Shopify',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [workspaceData, toast]);

  const filteredProducts = useMemo(() => {
    if (!searchText) return shopifyProducts;
    return shopifyProducts.filter((product) =>
      product.title.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [shopifyProducts, searchText]);

  if (loading) {
    return (
      <Flex justify="center" align="center" height="200px">
        <Spinner size="lg" />
      </Flex>
    );
  }

  return (
    <Box p="4">
      {/* Products List */}
      {shopifyProducts.length === 0 ? (
        <Flex
          direction="column"
          align="center"
          justify="center"
          minHeight="200px"
        >
          <Text color="gray.400" fontSize="lg" mb="4">
            No products added yet.
          </Text>
        </Flex>
      ) : (
        <>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Image</Th>
                <Th>Product Name</Th>
                <Th>Description</Th>
                <Th>Price</Th>
                <Th>Vendor</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredProducts.map((product) => {
                // Determine the image URL
                const imageUrl =
                  product.image?.src ||
                  (product.images && product.images.length > 0
                    ? product.images[0].src
                    : placeholderImage);

                // Extract price and vendor
                const price =
                  product.variants && product.variants.length > 0
                    ? `$${product.variants[0].price}`
                    : 'N/A';
                const vendor = product.vendor || 'Unknown Vendor';

                return (
                  <Tr
                    key={product.firebaseId || product.id}
                    onClick={() => navigate(`/dashboard/products/${product.id}`)}
                    _hover={{ bg: 'gray.100', cursor: 'pointer' }}
                  >
                    <Td>
                      <Image
                        src={imageUrl}
                        alt={product.title || 'Product Image'}
                        boxSize="50px"
                        objectFit="cover"
                        borderRadius="md"
                      />
                    </Td>
                    <Td>
                      <Text fontWeight="bold">{product.title || 'No Name'}</Text>
                    </Td>
                    <Td>
                      <Text
                        noOfLines={2}
                        title={
                          product.body_html
                            ? product.body_html.replace(/<[^>]+>/g, '')
                            : 'No Description'
                        }
                      >
                        {product.body_html
                          ? product.body_html.replace(/<[^>]+>/g, '')
                          : 'No Description'}
                      </Text>
                    </Td>
                    <Td>{price}</Td>
                    <Td>{vendor}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </>
      )}
    </Box>
  );
};

export default AllProducts;
