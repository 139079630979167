// formatting/Status.js

import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  VStack,
  useOutsideClick,
  useDisclosure,
} from '@chakra-ui/react';

const statusOptions = [
  { label: 'None', color: '#9e9e9e', hoverColor: '#777777', textColor: '#FFFFFF' },
  { label: 'Contacted', color: '#2B6CB0', hoverColor: '#2C5282', textColor: '#FFFFFF' },
  { label: 'Awaiting Approval', color: '#eb9853', hoverColor: '#DD6B20', textColor: '#FFFFFF' },
  { label: 'Countered', color: '#a953eb', hoverColor: '#460078', textColor: '#FFFFFF' },
  { label: 'Approved', color: '#03939e', hoverColor: '#025f66', textColor: '#FFFFFF' },
  { label: 'Awaiting Contract', color: '#eb9853', hoverColor: '#DD6B20', textColor: '#FFFFFF' },
  { label: 'Awaiting Content', color: '#eb9853', hoverColor: '#DD6B20', textColor: '#FFFFFF' },
  { label: 'Ready To Post', color: '#a953eb', hoverColor: '#460078', textColor: '#FFFFFF' },
  { label: 'Completed', color: '#71b064', hoverColor: '#38A169', textColor: '#FFFFFF' },
  { label: 'Rejected', color: '#e96a5c', hoverColor: '#C53030', textColor: '#FFFFFF' },
];

const Status = ({ initialStatus, onChange, isEditable = true }) => {
  const [status, setStatus] = useState(initialStatus || 'None');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ref = useRef();

  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  });

  const toggleDropdown = () => {
    if (isEditable) {
      isOpen ? onClose() : onOpen();
    }
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    onChange && onChange(newStatus);
    onClose();
  };

  // Update internal status state when initialStatus prop changes
  useEffect(() => {
    setStatus(initialStatus || 'None');
  }, [initialStatus]);

  const currentStatusOption = statusOptions.find(
    (option) => option.label === status
  );

  return (
    <Box position="relative" ref={ref}>
      <Button
        onClick={toggleDropdown}
        bg={currentStatusOption?.color || 'gray.400'}
        color={currentStatusOption?.textColor || 'gray.700'}
        size="xs"
        h={6}
        w="134px"
        _hover={{
          bg: isEditable ? currentStatusOption?.hoverColor : currentStatusOption?.color,
          borderColor: isEditable ? currentStatusOption?.hoverColor : currentStatusOption?.color,
        }}
        cursor={isEditable ? 'pointer' : 'default'}
      >
        {status}
      </Button>
      {isEditable && isOpen && (
        <VStack
          position="absolute"
          top="100%"
          left="0"
          mt={2}
          bg="white"
          borderWidth="1px"
          borderRadius="xl"
          boxShadow="md"
          p={2}
          zIndex={1}
          size="sm"
        >
          {statusOptions.map((option) => (
            <Button
              key={option.label}
              onClick={() => handleStatusChange(option.label)}
              bg={option.color}
              color={option.textColor}
              w="134px"
              size="xs"
              h={6}
              _hover={{
                bg: option.hoverColor,
                borderColor: option.hoverColor,
              }}
            >
              {option.label}
            </Button>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default Status;
