import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyA_L2-0ne5KaUou7hxXSfxjf6qqGMpRe18",
  authDomain: "hoobe-brand-v5.firebaseapp.com",
  projectId: "hoobe-brand-v5",
  storageBucket: "hoobe-brand-v5.appspot.com",
  messagingSenderId: "1083465395649",
  appId: "1:1083465395649:web:5ef2a9d7c895c8f8fc5340",
  measurementId: "G-SCGE647E2F"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export { app, auth, db, storage, functions, analytics };