// Products.js
import React, { useState } from "react";
import {
  Box,
  Flex,
  Button,
  Input,
  Heading,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
} from "@chakra-ui/react";
import { TbPlus, TbSettings } from "react-icons/tb";
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useLocation,
} from "react-router-dom";
import { db } from '../../../Firebase';
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
} from 'firebase/firestore';

const Products = () => {
  const navigate = useNavigate();
  const { workspaceData } = useOutletContext();
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleAddProductClick = () => {
    navigate("/dashboard/products/add");
  };

  const handleHeadingClick = () => {
    navigate("/dashboard/products");
  };

  const showSearchBar = location.pathname === "/dashboard/products";

  const isProductDetailPage =
    location.pathname.startsWith("/dashboard/products/") &&
    !["/dashboard/products", "/dashboard/products/add"].includes(location.pathname);

  let productId = null;
  if (isProductDetailPage) {
    const pathParts = location.pathname.split("/");
    productId = pathParts[pathParts.length - 1];
  }

  const handleSettingsClick = () => {
    onOpen();
  };

  const handleDeleteProduct = async () => {
    if (!workspaceData?.workspace_id || !productId) {
      console.error('Workspace data or product ID is missing');
      return;
    }

    try {
      const productsRef = collection(
        db,
        'workspaces',
        workspaceData.workspace_id,
        'products'
      );
      const q = query(productsRef, where('shopifyProductId', '==', productId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docToDelete = querySnapshot.docs[0];
        await deleteDoc(docToDelete.ref);
        console.log('Product deleted from Firebase:', docToDelete.id);
        onClose();
        navigate('/dashboard/products');
      } else {
        console.error('No matching product found in Firebase');
      }
    } catch (error) {
      console.error('Error deleting product from Firebase:', error);
    }
  };

  return (
    <Box>
      <Flex
        align="center"
        justify="space-between"
        borderBottom="2px"
        borderColor="gray.200"
        h="60px"
        px="4"
      >
        <Heading
          size="md"
          onClick={handleHeadingClick}
          cursor="pointer"
        >
          Products
        </Heading>
        <Flex align="center">
          {showSearchBar && (
            <Input
              placeholder="Search products..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              size="sm"
              w="200px"
              mr="2"
              borderRadius="md"
            />
          )}
          {isProductDetailPage && (
            <Button
              size="sm"
              borderWidth="1px"
              bg="white"
              onClick={handleSettingsClick}
              leftIcon={<TbSettings />}
              mr="2"
            >
              Settings
            </Button>
          )}
          <Button
            size="sm"
            borderWidth="1px"
            bg="white"
            onClick={handleAddProductClick}
            leftIcon={<TbPlus />}
          >
            Add New Product
          </Button>
        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Product</ModalHeader>
          <ModalBody>
            <Text>Are you sure you want to delete this product?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleDeleteProduct}>
              Delete
            </Button>
            <Button variant="ghost" onClick={onClose} ml={3}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Outlet context={{ workspaceData, searchText }} />
    </Box>
  );
};

export default Products;
