// CampaignMessagesSms.js

import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  Text,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/react";
import TagInput from "./modalstuff/TagInput";

const CampaignMessagesSms = ({
  selectedCreator,
  handleUpdate,
  workspaceId,
  campaignId,
}) => {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [smsMessage, setSmsMessage] = useState("");
  const [isSendingSMS, setIsSendingSMS] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (selectedCreator) {
      setPhoneNumbers(selectedCreator.phoneNumbers || []);
      setSmsMessage(selectedCreator.smsMessage || "");
    } else {
      setPhoneNumbers([]);
      setSmsMessage("");
    }
  }, [selectedCreator]);

  const handlePhoneNumbersUpdate = async (updatedPhoneNumbers) => {
    setPhoneNumbers(updatedPhoneNumbers);
    await handleUpdate("phoneNumbers", updatedPhoneNumbers);
  };

  const handleBlur = (field, value) => {
    handleUpdate(field, value);
  };

  const handleSendSMS = async () => {
    if (phoneNumbers.length === 0) {
      toast({
        title: "No Phone Numbers",
        description: "Please add at least one phone number.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!smsMessage.trim()) {
      toast({
        title: "Message Required",
        description: "Please enter the SMS message.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsSendingSMS(true);
    try {
      const response = await fetch(
        `https://hoobe-server-v5-1t0f.onrender.com/campaigns-send-one-sms`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            workspaceId,
            campaignId,
            creatorId: selectedCreator.creatorId,
            phoneNumbers,
            message: smsMessage,
            name: selectedCreator.name,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("SMS sent successfully:", data);
      toast({
        title: "SMS Sent",
        description: "The SMS has been sent successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error sending SMS:", error);
      toast({
        title: "Error Sending SMS",
        description: "There was an error sending the SMS. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSendingSMS(false);
    }
  };

  // Disable the button if phone numbers or SMS message are empty
  const isButtonDisabled =
    phoneNumbers.length === 0 || !smsMessage.trim();

  return (
    <Box>
      <VStack align="stretch" spacing={4}>
        <Box>
          <Text fontWeight="semibold" mb={2}>
            Phone Numbers
          </Text>
          <TagInput
            tags={phoneNumbers}
            setTags={setPhoneNumbers}
            placeholder="Add new phone number"
            onUpdate={handlePhoneNumbersUpdate}
          />
        </Box>

        <Box>
          <Text fontWeight="semibold" mb={2}>
            SMS Message
          </Text>
          <Textarea
            value={smsMessage}
            onChange={(e) => setSmsMessage(e.target.value)}
            onBlur={() => handleBlur("smsMessage", smsMessage)}
            placeholder="Compose your SMS message here..."
            minHeight="150px"
          />
        </Box>

        <Button
          onClick={handleSendSMS}
          isLoading={isSendingSMS}
          isDisabled={isButtonDisabled}
          colorScheme="blue"
        >
          Send SMS
        </Button>
      </VStack>
    </Box>
  );
};

export default CampaignMessagesSms;
