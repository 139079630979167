// CampaignTracking.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Image,
  HStack,
} from '@chakra-ui/react';
import { useOutletContext } from 'react-router-dom';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../../../../../Firebase';
import CampaignTrackingOverview from './CampaignTrackingOverview';
import Status from '../../../formatting/Status';
import CampaignTrackingModal from './CampaignTrackingModal';
import { FormatNumber } from '../../../formatting/FormatNumber';
import instagramIcon from '../../../../../assets/instagrampost.png';
import instagramReel from '../../../../../assets/instagramreel.png';
import instagramStory from '../../../../../assets/instagramstory.png';
import tiktokVideo from '../../../../../assets/tiktok.png';
import verifiedBadge from '../../../../../assets/verifiedbadge.png';
import placeholderImage from '../../../../../assets/placeholder.jpg';

const CampaignTracking = () => {
  const { workspaceId, campaignId } = useOutletContext();
  const [campaignData, setCampaignData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!workspaceId || !campaignId) return;

    const campaignDocRef = doc(
      db,
      'workspaces',
      workspaceId,
      'campaigns',
      campaignId
    );

    const unsubscribe = onSnapshot(
      campaignDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          setCampaignData(docSnapshot.data());
          setError(null);
        } else {
          setError('No such campaign!');
          setCampaignData(null);
        }
      },
      (error) => {
        console.error('Error fetching campaign data:', error);
        setError('Error fetching campaign data. Please try again later.');
        setCampaignData(null);
      }
    );

    return () => unsubscribe();
  }, [workspaceId, campaignId]);

  const handleStatusChange = async (creatorId, newStatus) => {
    if (!campaignData || !campaignData.creators) return;

    const updatedCreators = campaignData.creators.map((creator) => {
      if (String(creator.creatorId) === String(creatorId)) {
        return { ...creator, status: newStatus };
      }
      return creator;
    });

    setCampaignData((prevData) => ({
      ...prevData,
      creators: updatedCreators,
    }));

    const campaignDocRef = doc(
      db,
      'workspaces',
      workspaceId,
      'campaigns',
      campaignId
    );

    try {
      await updateDoc(campaignDocRef, { creators: updatedCreators });
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleRowClick = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleDeletePost = async () => {
    if (!campaignData || !campaignData.posts || selectedIndex === null) return;

    const updatedPosts = [...campaignData.posts];
    updatedPosts.splice(selectedIndex, 1);

    setCampaignData((prevData) => ({
      ...prevData,
      posts: updatedPosts,
    }));

    const campaignDocRef = doc(
      db,
      'workspaces',
      workspaceId,
      'campaigns',
      campaignId
    );

    try {
      await updateDoc(campaignDocRef, { posts: updatedPosts });
      onClose();
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handlePostUpdate = async (index, field, newValue) => {
    if (!campaignData || !campaignData.posts) return;

    console.log(
      'Updating post at index:',
      index,
      'field:',
      field,
      'newValue:',
      newValue
    );

    const updatedPosts = [...campaignData.posts];
    updatedPosts[index] = {
      ...updatedPosts[index],
      [field]: newValue,
    };

    setCampaignData((prevData) => ({
      ...prevData,
      posts: updatedPosts,
    }));

    const campaignDocRef = doc(
      db,
      'workspaces',
      workspaceId,
      'campaigns',
      campaignId
    );

    try {
      await updateDoc(campaignDocRef, { posts: updatedPosts });
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  const getPostIcon = (platform, type) => {
    if (platform === 'instagram') {
      if (type === 'reel') return instagramReel;
      if (type === 'story') return instagramStory;
      return instagramIcon;
    }
    if (platform === 'tiktok') return tiktokVideo;
    return null;
  };

  const getContentTypeText = (platform, type) => {
    if (platform === 'instagram') {
      if (type === 'reel') return 'Instagram Reel';
      if (type === 'story') return 'Instagram Story';
      return 'Instagram Post';
    }
    if (platform === 'tiktok') return 'TikTok Video';
    return 'Unknown Content Type';
  };

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  if (!campaignData) {
    return <Text>Loading campaign data...</Text>;
  }

  return (
    <Box p={4}>
      <CampaignTrackingOverview
        posts={campaignData.posts}
        creators={campaignData.creators}
        cpm={campaignData.cpm}
        budget={campaignData.budget}
      />
      <Box
        height="calc(100vh - 350px)"
        overflowY="scroll"
        css={{
          '&::-webkit-scrollbar': { display: 'none' },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
        overflowX="auto"
      >
        <Table variant="simple">
          <Thead position="sticky" top={0} bg="white" zIndex={1}>
            <Tr>
              <Th>Creator</Th>
              <Th>Content</Th>
              <Th>Impressions</Th>
              <Th>Likes</Th>
              <Th>Shares</Th>
              <Th>Comments</Th>
              <Th>Created At</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {campaignData.posts && campaignData.posts.length > 0 ? (
              campaignData.posts.map((post, index) => {
                const creator = campaignData.creators.find(
                  (creator) =>
                    String(creator.creatorId) === String(post.creatorId)
                );
                const status = creator ? creator.status || 'None' : 'N/A';

                const postIcon = getPostIcon(post.platform, post.type);
                const contentTypeText = getContentTypeText(
                  post.platform,
                  post.type
                );

                let creatorUsername = post.username;
                let creatorProfilePicUrl = post.profile_pic_url;
                let creatorName = post.name;

                if (creator) {
                  if (post.platform === 'instagram') {
                    creatorUsername =
                      creator.instagram_username || post.username;
                    creatorProfilePicUrl =
                      creator.instagram_profile_pic_url ||
                      post.profile_pic_url;
                  } else if (post.platform === 'tiktok') {
                    creatorUsername =
                      creator.tiktok_username || post.username;
                    creatorProfilePicUrl =
                      creator.tiktok_profile_pic_url || post.profile_pic_url;
                  }
                  creatorName = creator.name || post.name;
                }

                return (
                  <Tr
                    key={index}
                    onClick={() => handleRowClick(index)}
                    _hover={{ bg: 'gray.100', cursor: 'pointer' }}
                  >
                    <Td>
                      <HStack spacing={3} align="center">
                        <Box position="relative" width="60px" height="60px">
                          <Image
                            src={creatorProfilePicUrl}
                            alt={`${creatorName}'s profile picture`}
                            boxSize="60px"
                            borderRadius="full"
                            objectFit="cover"
                          />
                          {post && post.is_verified && (
                            <Image
                              src={verifiedBadge}
                              alt="Verified Badge"
                              boxSize="20px"
                              position="absolute"
                              top="0"
                              left="0"
                            />
                          )}
                        </Box>
                        <Box>
                          <Text fontWeight="bold">{creatorName}</Text>
                          <Text>@{creatorUsername}</Text>
                        </Box>
                      </HStack>
                    </Td>
                    <Td>
                      <HStack spacing={2}>
                        <Image
                          src={post.thumbnail_url || placeholderImage}
                          alt="Post thumbnail"
                          width="60px"
                          height="auto"
                          objectFit="cover"
                          borderRadius="10px"
                        />
                        {postIcon && (
                          <Image
                            src={postIcon}
                            alt={`${post.platform} ${post.type}`}
                            boxSize="24px"
                          />
                        )}
                        <Box>
                          <Text fontSize="sm" fontWeight="bold">
                            {contentTypeText}
                          </Text>
                        </Box>
                      </HStack>
                    </Td>
                    <Td>{FormatNumber(post.impressions)}</Td>
                    <Td>{FormatNumber(post.likes)}</Td>
                    <Td>{FormatNumber(post.shares)}</Td>
                    <Td>{FormatNumber(post.comments)}</Td>
                    <Td>
                      {new Date(post.createdAt).toLocaleString(undefined, {
                        timeZoneName: 'short',
                      })}
                    </Td>
                    <Td>
                      {creator ? (
                        <Box onClick={(e) => e.stopPropagation()}>
                          <Status
                            initialStatus={status}
                            onChange={(newStatus) =>
                              handleStatusChange(creator.creatorId, newStatus)
                            }
                          />
                        </Box>
                      ) : (
                        'Not In List'
                      )}
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td colSpan="8" textAlign="center">
                  No posts available.
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>

      {selectedIndex !== null && campaignData.posts[selectedIndex] && (
        <CampaignTrackingModal
          isOpen={isOpen}
          onClose={() => {
            setSelectedIndex(null);
            onClose();
          }}
          post={campaignData.posts[selectedIndex]}
          index={selectedIndex}
          onDelete={handleDeletePost}
          onUpdate={handlePostUpdate}
        />
      )}
    </Box>
  );
};

export default CampaignTracking;
