import React, { useEffect, useState } from "react";
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Box,
  Text,
  Flex,
  Input,
} from "@chakra-ui/react";


const formatEngagementRate = (value) => {
  return value >= 101 ? "100%+" : `${value}%`;
};

const EngagementRate = ({
  minEngagement,
  maxEngagement,
  setMinEngagement,
  setMaxEngagement,
}) => {
  const [isMinEditing, setIsMinEditing] = useState(false);
  const [isMaxEditing, setIsMaxEditing] = useState(false);
  const [tempMin, setTempMin] = useState(minEngagement);
  const [tempMax, setTempMax] = useState(maxEngagement);
  const [sliderValues, setSliderValues] = useState([0, 100]);

  const handleSliderChange = (values) => {
    const newMin = values[0];
    const newMax = values[1] >= 101 ? 10000 : values[1];

    setMinEngagement(newMin);
    setMaxEngagement(newMax);

    setTempMin(newMin);
    setTempMax(newMax);

    setSliderValues(values);
  };

  const handleMinBlur = () => {
    const validMin = Math.max(0, Math.min(tempMin, maxEngagement - 0.1));
    setMinEngagement(validMin);
    setIsMinEditing(false);
    setSliderValues([validMin, sliderValues[1]]);
  };

  const handleMaxBlur = () => {
    const validMax = Math.min(101, Math.max(tempMax, minEngagement + 0.1));
    setMaxEngagement(validMax);
    setIsMaxEditing(false);
    setSliderValues([sliderValues[0], validMax]);
  };

  const handleKeyPress = (e, type) => {
    if (e.key === "Enter") {
      if (type === "min") {
        handleMinBlur();
      } else {
        handleMaxBlur();
      }
    }
  };

  const handleNumberInput = (e, setTempValue) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    if (value.split(".").length > 2) return;
    setTempValue(value);
  };

  useEffect(() => {
    setTempMin(minEngagement);
    setTempMax(maxEngagement);
    setSliderValues([minEngagement, maxEngagement >= 10000 ? 101 : maxEngagement]);
  }, [minEngagement, maxEngagement]);

  return (
    <Box>
      <Flex direction="column" gap={4}>
        <Box>
          <Box position="relative">
            <Flex justifyContent="space-between" alignItems="center">
              {isMinEditing ? (
                <Input
                  value={tempMin}
                  onChange={(e) => handleNumberInput(e, setTempMin)}
                  onBlur={handleMinBlur}
                  onKeyPress={(e) => handleKeyPress(e, "min")}
                  autoFocus
                  height="40px"
                  mr={5}
                />
              ) : (
                <Text
                  fontWeight="bold"
                  fontSize="20px"
                  onClick={() => setIsMinEditing(true)}
                  lineHeight="52px"
                >
                  {formatEngagementRate(minEngagement)}
                </Text>
              )}

              {!isMinEditing && !isMaxEditing && (
                <Text
                  position="absolute"
                  left="50%"
                  transform="translateX(-50%)"
                >
                  Eng. Rate
                </Text>
              )}

              {isMaxEditing ? (
                <Input
                  value={tempMax}
                  onChange={(e) => handleNumberInput(e, setTempMax)}
                  onBlur={handleMaxBlur}
                  onKeyPress={(e) => handleKeyPress(e, "max")}
                  autoFocus
                  height="40px"
                  ml={5}
                />
              ) : (
                <Text
                  fontWeight="bold"
                  fontSize="20px"
                  onClick={() => setIsMaxEditing(true)}
                  lineHeight="52px"
                >
                  {formatEngagementRate(
                    maxEngagement >= 10000 ? 101 : maxEngagement
                  )}
                </Text>
              )}
            </Flex>
          </Box>

          <Box width="90%" mx="auto">
            <RangeSlider
              aria-label="engagement-range-slider"
              value={sliderValues}
              min={0}
              max={101}
              step={0.1}
              onChange={handleSliderChange}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb
                borderWidth="2px"
                borderColor="gray.300"
                boxSize={6}
                index={0}
                aria-label="min-engagement-thumb"
              />
              <RangeSliderThumb
                borderWidth="2px"
                borderColor="gray.300"
                boxSize={6}
                index={1}
                aria-label="max-engagement-thumb"
              />
            </RangeSlider>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default EngagementRate;
