import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  VStack,
  Heading,
  Textarea,
  Text,
  Tag,
  TagLabel,
  Flex,
  Spacer,
  Image,
  Spinner,
} from "@chakra-ui/react";
import instagramIcon from "../../../../../assets/instagrampost.png"; // Adjust path as needed
import placeholderIcon from "../../../../../assets/logo.png"; // Adjust path as needed

const AddModal = ({ isOpen, onClose, workspaceId }) => {
  const { groupId, listId } = useParams();
  const [inputText, setInputText] = useState('');
  const [instagramHandles, setInstagramHandles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const text = e.target.value.toLowerCase();
    setInputText(text);

    const urlRegex = /(?:https?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9._]+)|^([a-zA-Z0-9._]+)$/gim;
    const handles = new Set();
    let match;

    while ((match = urlRegex.exec(text)) !== null) {
      const handle = match[1] || match[2];
      handles.add(handle);
    }

    setInstagramHandles(Array.from(handles));
  };

  const handleAddCreators = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_HOOBE_API_URL}/lists-add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          instagramHandles,
          groupId,
          listId,
          workspaceId,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log("API response:", data);
      onClose();
    } catch (error) {
      console.error("Error adding creators:", error);
      // Here you might want to show an error message to the user
    } finally {
      setIsLoading(false);
    }
  };

  const isNumeric = (str) => /^\d+$/.test(str);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay>
        <DrawerContent maxW="600px">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading size="lg">Add Creators</Heading>
          </DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <Textarea
                value={inputText}
                onChange={handleInputChange}
                placeholder="Add instagram links or handles..."
                size="md"
                height="300px"
              />
              {instagramHandles.length > 0 && (
                <Box mt={2}>
                  <Text mb={2} fontWeight="bold">Curated Instagram Handles</Text>
                  <Flex wrap="wrap" gap={2}>
                    {instagramHandles.map((handle, index) => (
                      <Tag key={index} p={2} borderRadius="full" display="flex" alignItems="center">
                        {isNumeric(handle) ? (
                          <Image src={placeholderIcon} alt="Placeholder" boxSize="20px" borderRadius="full" mr={2} />
                        ) : (
                          <Image src={instagramIcon} alt="Instagram" boxSize="20px" mr={2} />
                        )}
                        <TagLabel>{isNumeric(handle) ? handle : `@${handle}`}</TagLabel>
                      </Tag>
                    ))}
                  </Flex>
                </Box>
              )}
            </VStack>
          </DrawerBody>

          {/* Fixed button at the bottom */}
          <Flex p={4} borderTop="1px solid #e2e8f0" bg="white" alignItems="center">
            <Spacer />
            <Button
              colorScheme="blue"
              onClick={handleAddCreators}
              width="100%"
              isDisabled={isLoading || instagramHandles.length === 0}
            >
              {isLoading ? <Spinner size="sm" mr={2} /> : null}
              {isLoading ? 'Adding Creators...' : 'Add Creators'}
            </Button>
          </Flex>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AddModal;