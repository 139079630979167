import React from 'react';
import { Button, Image, Text } from '@chakra-ui/react';
import instagramIcon from '../../../assets/instagrampost.png';
import { FormatNumber } from './FormatNumber';

const InstagramFollowers = ({ followersCount, username }) => {
  return (
    <Text fontSize="18px">
      {followersCount !== undefined ? (
        <Button
          as="a"
          href={`https://instagram.com/${username}`}
          borderRadius="full"
          borderWidth="2px"
          size="sm"
          leftIcon={<Image src={instagramIcon} alt="Instagram" boxSize="18px" />}
          target="_blank"
          rel="noopener noreferrer"
          textDecoration="none"
          fontSize="16px"
          onClick={(e) => e.stopPropagation()}
        >
          {FormatNumber(followersCount)}
        </Button>
      ) : (
        "N/A"
      )}
    </Text>
  );
};

export default InstagramFollowers;