import React from "react";
import { Box, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import { useOutletContext } from "react-router-dom";

function Data() {
  const { userData, workspaceData } = useOutletContext();

  return (
    <Box>
      <Flex
        align="center"
        p={4}
        borderColor="gray.200"
        h="60px"
        borderBottomWidth="2px"
      >
        <Heading size="md">Data</Heading>
      </Flex>

      <VStack p={4} align="start" spacing={4}>
        <Box>
          <Heading size="md" mb={2}>
            User Data:
          </Heading>
          <Text as="pre" whiteSpace="pre-wrap" fontSize="sm" bg="gray.100">
            {JSON.stringify(userData, null, 2)}
          </Text>
        </Box>
        <Box>
          <Heading size="md" mb={2}>
            Workspace Data:
          </Heading>
          <Text as="pre" whiteSpace="pre-wrap" fontSize="sm" bg="gray.100">
            {JSON.stringify(workspaceData, null, 2)}
          </Text>
        </Box>
      </VStack>
    </Box>
  );
}

export default Data;
